import React, { useState } from 'react';
import {
  Button,
  Form, Input, notification,
} from 'antd';
import api, { User } from '../../api';
import AuthPageLayout from '../layouts/AuthPageLayout';

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onFinish = async (values: Pick<User, 'email'>) => {
    setLoading(true);
    try {
      await api.users.requestPasswordReset({ email: values.email });
      setSuccess(true);
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e.message,
      });
    }
    setLoading(false);
  };

  return (
    <AuthPageLayout>
      <div className="bg-transparent">
        <h1 className="mt-0">
          Forgot your password?
        </h1>
        {
          !success ? (
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Enter your email"
                  size="large"
                />
              </Form.Item>
              <div className="d-flex justify-content-center">
                <Form.Item noStyle>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    htmlType="submit"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </div>
            </Form>
          ) : (
            <p>
              If an account with that email exists, we sent you an email with
              {' '}
              instructions on how to reset your password.
            </p>
          )
        }
      </div>
    </AuthPageLayout>
  );
}
