import {
  Alert,
  Button, Card, Input, message, Space,
} from 'antd';
import { useState } from 'react';
import { BulbOutlined, DeleteOutlined } from '@ant-design/icons';
import api, { ResultRepresentation, Widget } from '../../../api';
import ResultRepresentationView from '../../../results/components/ResultRepresentationView';

interface WidgetResultRepresentationCustomizerProps {
  widgetId: Widget['_id'];
  representation: ResultRepresentation;
  representations?: ResultRepresentation[];
  onWidgetChange: (widget: Widget) => void;
}

export default function WidgetResultRepresentationCustomizer(
  props: WidgetResultRepresentationCustomizerProps,
) {
  const {
    representation, onWidgetChange, widgetId, representations,
  } = props;
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [rollingBack, setRollingBack] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    api.widgets.customizeRepresentation({ id: widgetId, prompt })
      .then((widget) => {
        onWidgetChange(widget);
        message.success('Customization applied');
      })
      .catch(() => {
        message.error('Something went wrong, please try again');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRollBack = async () => {
    if (!representations || !representations.length) {
      message.error('No changes to roll back');
    } else {
      setRollingBack(true);

      try {
        const result = await api.widgets.rollBackRepresentation({ id: widgetId });
        onWidgetChange(result);
      } catch (e) {
        message.error('Something went wrong, please try again');
      }

      setRollingBack(false);
    }
  };

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <ResultRepresentationView
          resultRepresentation={representation}
        />
        <Space.Compact style={{ width: '100%' }} className="mt-4">
          <Input
            placeholder="Ask our AI to customize this view - change the colors, add a title, etc."
            size="large"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onPressEnter={onSubmit}
            disabled={loading}
          />
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            loading={loading}
            disabled={!prompt || loading}
          >
            Customize
          </Button>
        </Space.Compact>
        <div className="mt-4">
          <p>
            Tips:
          </p>
          <Alert
            type="info"
            showIcon
            message="Tips"
            icon={<BulbOutlined />}
            description={(
              <div>
                <p>
                  To change the color, just type
                  {' '}
                  <b>&apos;Make the chart red&apos;</b>
                  ,
                  or
                  {' '}
                  <b>&apos;Make the chart blue&apos;</b>
                </p>
                <p>
                  You can also change the title, for example
                  {' '}
                  <b>
                    &apos;Change title to
                    {' '}
                    &apos;My awesome widget&apos;
                  </b>
                  &apos;
                </p>
                <p>
                  You can roll back to the previous view by clicking on
                  the &quot;Delete&quot; button
                  on the latest  view in the history
                </p>
              </div>
            )}
          />
        </div>
      </div>
      <div
        style={{
          width: 600,
          marginLeft: 20,
          paddingLeft: 20,
          borderLeft: '1px solid #ddd',
        }}
      >
        <h3>History</h3>
        <div
          style={{
            maxHeight: 'calc(100vh - 180px)',
            height: 'calc(100vh - 180px)',
            overflowY: 'auto',
            paddingRight: 20,
            marginLeft: -20,
            paddingLeft: 20,
            paddingTop: 20,
          }}
        >
          {
            !representations?.length && (
              <p className="text-center">
                No changes yet
              </p>
            )
          }
          {
            representations?.map((item, index) => (
              <div key={`${item.prompt}-${item.id}`}>
                <Card
                  size="small"
                  className="mb-5"
                  title={item.prompt || 'Initial'}
                  extra={index === 0 && representations.length > 1 && (
                    <Button
                      danger
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={onRollBack}
                      loading={rollingBack}
                    />
                  )}
                  bordered
                >
                  <ResultRepresentationView
                    key={item.id}
                    resultRepresentation={item}
                  />
                </Card>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
