import { Button, message, Select } from 'antd';
import { useState } from 'react';
import { ClarificationType, DataSource, QueryExecution } from '../../../api';
import Typewriter from '../../../common/components/TypeWriter';

interface QueryExecutionClarificationViewProps {
  clarification?: QueryExecution['clarification'];
  dataSource?: DataSource;
  onSelectionChange?: (value: string) => void;
  isInactive?: boolean;
}

export default function QueryExecutionClarificationView(
  props: QueryExecutionClarificationViewProps,
) {
  const {
    clarification,
    dataSource,
    onSelectionChange,
    isInactive,
  } = props;
  const [value, setValue] = useState<string | undefined>(undefined);

  return (
    <div>
      <Typewriter
        text={clarification?.message || 'Please provide more details about the query and what you are trying to achieve.'}
      />
      {
        clarification?.type === ClarificationType.TABLE_NAME_SELECT && dataSource && (
          <div
            className="d-flex align-items-center gap-2"
            style={{
              flexWrap: 'wrap',
            }}
          >
            <Select
              value={value}
              placeholder="Select a table"
              options={dataSource
                .schemaConfig
                ?.tables
                .map((table) => ({
                  value: table.tableName,
                  label: table.displayTableName || table.tableName,
                }))}
              style={{ minWidth: 200 }}
              onChange={setValue}
              placement="topLeft"
              disabled={isInactive}
            />
            <Button
              onClick={() => {
                if (value) {
                  if (onSelectionChange) {
                    onSelectionChange(value);
                  }
                } else {
                  message.error('Please select a table');
                }
              }}
              disabled={isInactive}
            >
              Select
            </Button>
          </div>
        )
      }
      {
        clarification?.type === ClarificationType.SELECT && clarification.options && (
          <div className="d-flex gap-2">
            {
              clarification.options.map((option) => {
                const isSelected = value === option.value;
                return (
                  <Button
                    key={option.value}
                    onClick={() => {
                      if (onSelectionChange && !isInactive) {
                        setValue(option.value);
                        onSelectionChange(option.value);
                      }
                    }}
                    style={{
                      cursor: isInactive ? 'not-allowed' : 'pointer',
                      opacity: isInactive ? 0.5 : 1,
                    }}
                    type={isSelected ? 'primary' : 'default'}
                  >
                    {option.label}
                  </Button>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
}
