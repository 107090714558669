import { Statistic } from 'antd';
import React from 'react';
import { formatValue } from '../../utils/format';
import { Formatter, ResultRepresentationBuilderOptions, ResultRepresentationFormat } from '../../../api';

interface NumberResultRepresentationViewProps {
  resultRepresentationFormat?: ResultRepresentationFormat;
  builderOptions?: ResultRepresentationBuilderOptions;
  data: any;
}

export default function NumberResultRepresentationView(props: NumberResultRepresentationViewProps) {
  const { resultRepresentationFormat, data, builderOptions } = props;
  const { dataSets } = builderOptions || {};
  const format = dataSets && dataSets[0] ? dataSets[0].format : undefined;

  function getValue() {
    if (format) {
      return Formatter.format(data, format);
    }

    if (resultRepresentationFormat) {
      return formatValue(resultRepresentationFormat, data, '');
    }

    return Number(data).toLocaleString();
  }

  return (<Statistic value={getValue()} />);
}
