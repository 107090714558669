import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';

interface SelectBoxProps {
  active?: boolean;
  title: string;
  subtitle?: string;
  onSelect?: () => void;
  showCloseIcon?: boolean;
}

export default function SelectBox(props: SelectBoxProps) {
  const {
    active = false,
    title,
    subtitle,
    onSelect,
    showCloseIcon,
  } = props;

  const [mouseOver, setMouseOver] = useState(false);

  return (
    <div
      className="border rounded shadow-hover d-flex flex-column justify-content-center align-items-center p-2"
      style={{
        cursor: 'pointer',
        backgroundColor: active ? '#1476ff' : 'white',
        color: active ? 'white' : 'black',
        position: 'relative',
      }}
      onClick={onSelect}
      onKeyDown={(e) => {
        if (onSelect && e.key === 'Enter') {
          onSelect();
        }
      }}
      tabIndex={0}
      role="button"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <p className="fw-bold m-0">{title}</p>
      <small className="text-center">{subtitle}</small>
      {
        showCloseIcon && mouseOver && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 5,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button type="text" size="small">
              <CloseOutlined />
            </Button>
          </div>
        )
      }
    </div>
  );
}
