import { Button, Select } from 'antd';
import { SortDirection } from '../../types';
import { Option } from '../../../common/components/AdvancedSelect';
import BuilderStepCard from '../BuilderStepCard';

interface SortStepProps {
  isEnabled?: boolean;
  setIsEnabled: (value: boolean) => void;
  value?: SortDirection;
  onChange: (value: SortDirection) => void;
  field?: string;
  onFieldChange: (value: string) => void;
  sortOptions: Option[];
}

export default function SortStep(props: SortStepProps) {
  const {
    isEnabled,
    setIsEnabled,
    value,
    onChange, sortOptions,
    field,
    onFieldChange,
  } = props;

  return (
    <BuilderStepCard
      question="Would you like to sort the results?"
      description="Choose whether the results should be sorted and in which direction."
      isVisible={isEnabled}
      setIsVisible={setIsEnabled}
    >
      <div className="d-flex align-items-center justify-content-between">
        <p>Sort By</p>
        <p>Direction</p>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <Select
          placeholder="Sort By"
          value={field}
          onChange={onFieldChange}
          options={sortOptions}
          style={{ flex: 1 }}
        />
        <div className="d-flex justify-content-end gap-1" style={{ flex: 1 }}>
          <Button
            type={value === SortDirection.DESC ? 'primary' : 'default'}
            onClick={() => {
              onChange(SortDirection.DESC);
            }}
          >
            Descending
          </Button>
          <Button
            type={value === SortDirection.ASC ? 'primary' : 'default'}
            onClick={() => {
              onChange(SortDirection.ASC);
            }}
          >
            Ascending
          </Button>
        </div>
      </div>
    </BuilderStepCard>
  );
}
