import {
  Alert, Button, Col, Drawer, Input, notification, Row,
} from 'antd';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Plans from '../utils/plans';
import PlanCard from './PlanCard';
import useRequest from '../../common/hooks/useRequest';
import api, { PlanName, Subscription } from '../../api';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useActiveOrganizationPlan from '../../common/hooks/useActiveOrganizationPlan';
import OrganizationFreeTrialAlert from '../../organizations/components/OrganizationFreeTrialAlert';
import { PlanBillingPeriod } from '../../api/definitions/Plan';
import useQuery from '../../common/hooks/useQuery';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY || '');

interface CheckoutFormProps {
  returnUrl: string;
}

function CheckoutForm(props: CheckoutFormProps) {
  const { returnUrl } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const onSubscribe = async () => {
    if (stripe && elements) {
      setSubscribing(true);
      const result = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        notification.error({
          message: result.error.message,
          type: 'error',
        });
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
      setSubscribing(false);
    }
  };

  return (
    <>
      <PaymentElement />
      <Button
        className="mt-2"
        type="primary"
        loading={subscribing}
        onClick={onSubscribe}
        block
      >
        Subscribe
      </Button>
    </>
  );
}

export default function OrganizationBilling() {
  const organization = useActiveOrganization();
  const [subscription, setSubscription] = useState<Subscription>();
  const [searchParams] = useSearchParams();
  const [billingCycle, setBillingCycle] = useState<PlanBillingPeriod>(PlanBillingPeriod.MONTHLY);
  const [discount, setDiscount] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<string>('');
  const [appliedPromoCode, setAppliedPromoCode] = useState<string>('');
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [actionPlan, setActionPlan] = useState<PlanName>();
  const [loadingAction, setLoadingAction] = useState<'subscribe' | 'unsubscribe' | 'resume' | undefined>();
  const activePlan = useActiveOrganizationPlan();

  const [activeSubscription,, setActiveSubscription] = useQuery(
    async () => {
      const response = await api.subscriptions.find({
        query: {
          isActive: true,
          organizationId: organization?._id,
          $limit: 1,
        },
      });

      return response.data[0];
    },
    [organization?._id],
  );

  const onApplyPromoCode = async () => {
    setLoadingDiscount(true);
    try {
      const appliedDiscount = await api.subscriptions.getPromoCodeDiscount({ code: promoCode });
      if (appliedDiscount.valid) {
        setDiscount(appliedDiscount.discount || 0);
        setAppliedPromoCode(promoCode);
      } else {
        notification.error({
          message: 'Invalid promo code',
          description: 'Please try again',
        });
      }
    } catch (e) {
      notification.error({
        message: 'Invalid promo code',
        description: 'Please try again',
      });
    }

    setLoadingDiscount(false);
  };

  const paymentComplete = searchParams.get('redirect_status') === 'succeeded';

  const request = useRequest(async (planName: PlanName) => {
    if (!organization) {
      throw new Error('Organization not found');
    }

    setActionPlan(planName);
    setLoadingAction('subscribe');

    try {
      const response = await api.subscriptions.create({
        organizationId: organization._id,
        planName,
        billingCycle,
        promoCode: appliedPromoCode,
      });
      setSubscription(response);
    } catch (e) {
      notification.error({
        message: 'Error subscribing to plan',
        description: 'Please try again',
      });
    }

    setActionPlan(undefined);
    setLoadingAction(undefined);
  });

  return (
    <Row gutter={[16, 16]} justify="center">
      <Col
        span={24}
        xxl={20}
        style={{
          maxWidth: 1092,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="d-flex flex-column gap-2">
              {
                paymentComplete && (
                  <Alert
                    message="Payment successful, thank you. Your subscription is active now."
                    type="success"
                    closable
                  />
                )
              }
              <Alert
                message="Current Plan"
                description={(
                  <div>
                    <p className={!activeSubscription ? 'm-0' : ''}>
                      Your current plan is
                      {' '}
                      {activePlan?.name}
                      .
                    </p>
                    {
                      activeSubscription && (
                        <p className="m-0">
                          {
                            !activeSubscription.cancelOnExpire && `Your plan will renew on ${dayjs(activeSubscription.expiresAt).format('MMMM D, YYYY')}.`
                          }
                          {
                            activeSubscription.cancelOnExpire && `Your plan will expire on ${dayjs(activeSubscription.expiresAt).format('MMMM D, YYYY')}.`
                          }
                        </p>
                      )
                    }
                  </div>
                )}
                type={activeSubscription?.cancelOnExpire ? 'error' : 'info'}
                showIcon
              />
              <OrganizationFreeTrialAlert />
            </div>
          </Col>

          <Col span={24}>
            <div className="d-flex gap-2 flex-column align-items-center justify-content-center">
              <h3 className="m-0">Choose billing period</h3>
              <Button.Group>
                <Button
                  type={billingCycle === PlanBillingPeriod.MONTHLY ? 'primary' : 'default'}
                  onClick={() => { setBillingCycle(PlanBillingPeriod.MONTHLY); }}
                >
                  Monthly
                </Button>
                <Button
                  type={billingCycle === PlanBillingPeriod.YEARLY ? 'primary' : 'default'}
                  onClick={() => { setBillingCycle(PlanBillingPeriod.YEARLY); }}
                >
                  <span className="d-flex align-items-center justify-content-center gap-2">
                    <span>
                      Yearly
                    </span>
                    <span
                      style={{
                        borderRadius: 8,
                        backgroundColor: 'rgb(255, 177, 22)',
                        padding: '0 5px',
                        fontWeight: 'bold',
                        color: 'white',
                      }}
                    >
                      Save 20%
                    </span>
                  </span>
                </Button>
              </Button.Group>
            </div>
          </Col>

          <Col span={24}>
            <Row gutter={[16, 16]}>
              {
                organization?._id
                && Plans.getOrganizationPlans(organization._id, billingCycle)
                  .filter((plan) => plan.price !== 0).map((plan) => (
                    <Col key={plan.name} span={24} style={{ flex: 1 }}>
                      <PlanCard
                        name={plan.name}
                        credits={plan.credits}
                        maxUsers={plan.maxUsers}
                        maxProjects={plan.maxProjects}
                        maxDashboards={plan.maxDashboards}
                        maxWidgets={plan.maxWidgets}
                        price={Plans.getMonthlyPrice(plan.name, billingCycle)}
                        loading={actionPlan === plan.name ? loadingAction : undefined}
                        disabled={actionPlan === plan.name && Boolean(loadingAction)}
                        onSubscribeClick={() => {
                          request.submit(plan.name).then();
                        }}
                        billedAnnually={billingCycle === PlanBillingPeriod.YEARLY}
                        isCurrentPlan={activePlan?.name === plan.name}
                        cancelOnExpire={activeSubscription?.cancelOnExpire}
                        apiAccess={plan.apiAccess}
                        fullyEmbedded={plan.fullyEmbedded}
                        whitelabel={plan.whitelabel}
                        dataMonitoring={plan.dataMonitoring}
                        maxDataSources={plan.maxDataSources}
                        onUnsubscribeClick={() => {
                          if (!activeSubscription) {
                            notification.error({
                              message: 'Error',
                              description: 'No active subscription found',
                            });
                          } else if (!activeSubscription.isActive) {
                            notification.error({
                              message: 'Error',
                              description: 'Subscription is already inactive',
                            });
                          } else {
                            setActionPlan(plan.name);
                            setLoadingAction('unsubscribe');

                            api.subscriptions.patch(activeSubscription._id, {
                              cancelOnExpire: true,
                            }).then((response) => {
                              setActiveSubscription(response as Subscription);

                              notification.success({
                                message: 'Subscription canceled',
                                description: 'Your subscription will expire on the next billing date',
                              });
                            }).catch(() => {
                              notification.error({
                                message: 'Error canceling subscription',
                                description: 'Please try again',
                              });
                            }).finally(() => {
                              setActionPlan(undefined);
                              setLoadingAction(undefined);
                            });
                          }
                        }}
                        onResumeClick={() => {
                          if (!activeSubscription) {
                            notification.error({
                              message: 'Error',
                              description: 'No active subscription found',
                            });
                          } else if (!activeSubscription.isActive) {
                            notification.error({
                              message: 'Error',
                              description: 'Subscription is already inactive',
                            });
                          } else {
                            setActionPlan(plan.name);
                            setLoadingAction('resume');

                            api.subscriptions.patch(activeSubscription._id, {
                              cancelOnExpire: false,
                            }).then((response) => {
                              setActiveSubscription(response as Subscription);

                              notification.success({
                                message: 'Subscription resumed',
                                description: 'Your subscription will renew on the next billing date',
                              });
                            }).catch(() => {
                              notification.error({
                                message: 'Error resuming subscription',
                                description: 'Please try again',
                              });
                            }).finally(() => {
                              setActionPlan(undefined);
                              setLoadingAction(undefined);
                            });
                          }
                        }}
                      />
                    </Col>
                  ))
              }
            </Row>
          </Col>

          <Col span={24}>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <h4>Have a Promo Code?</h4>
              <Input
                placeholder="Enter promo code"
                style={{ flex: 'auto', maxWidth: 200 }}
                onChange={(e) => { setPromoCode(e.target.value); }}
              />
              <Button
                loading={loadingDiscount}
                disabled={loadingDiscount}
                onClick={onApplyPromoCode}
              >
                Apply
              </Button>
            </div>
            {
              Boolean(discount) && (
                <Alert
                  message={`Discount of ${discount * 100}% will be applied on checkout`}
                  type="info"
                />
              )
            }
          </Col>
        </Row>
      </Col>
      <Drawer
        open={Boolean(subscription)}
        onClose={() => { setSubscription(undefined); }}
        title="Subscribe"
      >
        {
          subscription && (
            <>
              <h2>
                Total: $
                {Plans.getPriceWithDiscount(subscription.planName, billingCycle, discount)}
              </h2>
              {
                Boolean(discount) && (
                  <Alert
                    message={`Discount of ${discount * 100}% applied`}
                    type="info"
                    className="mb-4"
                  />
                )
              }
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: subscription.stripeClientSecret }}
              >
                <CheckoutForm
                  returnUrl={`${window.location.origin}/subscribe/thank-you`}
                />
              </Elements>
            </>
          )
        }
      </Drawer>
    </Row>
  );
}
