import { useCallback, useState } from 'react';
import { DrawerProps, ModalProps } from 'antd';

export type UseModal = {
  open: () => void;
  close: () => void;
  props: Pick<ModalProps, 'open' | 'onCancel'> & {
    onClose: DrawerProps['onClose'];
  };
};

export default function useModal(): UseModal {
  const [open, setOpen] = useState(false);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    open: onOpen,
    close: onCancel,
    props: {
      open,
      onCancel,
      onClose: onCancel,
    },
  };
}
