import {
  Alert, Button, Drawer, Form, Input, message, notification, Select, Switch, Tag,
} from 'antd';
import { useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import api, { DataSource, DataSourceType, Organization } from '../../api';
import EncryptedLabel from '../../common/components/EncryptedLabel';
import AirtableBasesSelect from '../../airtable/components/common/AirtableBasesSelect';
import OrganizationMemberForm from '../../organizations/components/OrganizationMemberForm';

interface DataSourceFormProps extends Pick<DataSource, 'entityId' | 'scope' | 'organizationId'> {
  onSuccess?: (dataSource: DataSource) => void;
  dataSource?: Pick<DataSource, 'name' | 'type' | 'isDefault' | '_id'>;
  organization: Organization;
}

const SERVER_IPS = process.env.REACT_APP_SERVER_IPS ? process.env.REACT_APP_SERVER_IPS.split(',') : [];

export default function DataSourceForm(props: DataSourceFormProps) {
  const {
    entityId,
    scope,
    onSuccess,
    dataSource,
    organizationId,
    organization,
  } = props;
  const [loading, setLoading] = useState(false);
  const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);

  const onFinish = async (values: Pick<DataSource, 'type' | 'name' | 'config'>) => {
    setLoading(true);
    try {
      let response: DataSource | null;

      if (dataSource) {
        response = await api.dataSources.patch(dataSource._id, {
          ...values,
        }) as unknown as DataSource;
      } else {
        response = await api.dataSources.create({
          ...values,
          entityId,
          scope,
          organizationId,
        });
      }

      if (onSuccess) {
        onSuccess(response as DataSource);
      }
    } catch (e: any) {
      notification.error({
        message: e.message,
        description: 'Please check the connection string and try again',
      });
    }
    setLoading(false);
  };

  return (
    <Form
      layout="vertical"
      initialValues={dataSource ? { ...dataSource, config: {} } : {
        type: DataSourceType.MONGODB,
        name: 'My Data Source',
        config: {},
      }}
      onFinish={onFinish}
    >
      {
        !dataSource && (
          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item label="Type" name="type">
                <Select
                  onChange={(newValue) => {
                    if (newValue === DataSourceType.POSTGRESQL) {
                      setFieldsValue({
                        config: {
                          port: 5432,
                          schema: 'public',
                        },
                      });
                    } else if (newValue === DataSourceType.MYSQL) {
                      setFieldsValue({
                        config: {
                          port: 3306,
                        },
                      });
                    } else if (newValue === DataSourceType.MSSQL) {
                      setFieldsValue({
                        config: {
                          port: 1433,
                          schema: 'dbo',
                        },
                      });
                    }
                  }}
                  options={[
                    {
                      label: 'MongoDB',
                      value: DataSourceType.MONGODB,
                    },
                    {
                      label: 'PostgreSQL',
                      value: DataSourceType.POSTGRESQL,
                    },
                    {
                      label: 'MySQL',
                      value: DataSourceType.MYSQL,
                    },
                    {
                      label: 'MSSQL (SQL Server)',
                      value: DataSourceType.MSSQL,
                    },
                    {
                      label: 'Airtable',
                      value: DataSourceType.AIRTABLE,
                    },
                  ]}
                />
              </Form.Item>
            )}
          </Form.Item>
        )
      }
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Data source name" />
      </Form.Item>
      {
        !dataSource && (
          <Form.Item noStyle shouldUpdate>
            {
              ({ getFieldValue }) => {
                const type = (getFieldValue('type') as DataSourceType);
                const airtableConnected = organization.integrations?.airtable?.enabled;

                if (type === DataSourceType.MONGODB) {
                  return (
                    <Form.Item
                      label={<EncryptedLabel label="Connection String" />}
                      name={['config', 'connectionString']}
                      rules={[{ required: true, message: 'Please enter the connection string' }]}
                    >
                      <Input placeholder="Connection String" />
                    </Form.Item>
                  );
                }

                if (type === DataSourceType.AIRTABLE) {
                  if (!airtableConnected) {
                    return (
                      <Alert
                        type="info"
                        message="You need to connect your organization to Airtable before you can use it as a data source."
                        action={(
                          <Link to={`/${organization.slug}/settings/integrations`}>
                            <Button type="primary">
                              Connect
                            </Button>
                          </Link>
                        )}
                        className="mb-4"
                      />
                    );
                  }

                  return (
                    <Form.Item
                      name={['config', 'baseId']}
                      rules={[{ required: true, message: 'Please select a base' }]}
                      label="Base ID"
                    >
                      <AirtableBasesSelect organizationId={organizationId} />
                    </Form.Item>
                  );
                }

                if (
                  type === DataSourceType.POSTGRESQL
                  || type === DataSourceType.MYSQL
                  || type === DataSourceType.MSSQL
                ) {
                  return (
                    <>
                      <Form.Item
                        label={<EncryptedLabel label="Host" />}
                        name={['config', 'host']}
                        rules={[{ required: true, message: 'Please enter the host' }]}
                      >
                        <Input placeholder="Host" />
                      </Form.Item>
                      <Form.Item
                        label={<EncryptedLabel label="Port" />}
                        name={['config', 'port']}
                        rules={[{ required: true, message: 'Please enter the port' }]}
                      >
                        <Input placeholder="Port" />
                      </Form.Item>
                      <Form.Item
                        label={<EncryptedLabel label="Database" />}
                        name={['config', 'database']}
                        rules={[{ required: true, message: 'Please enter the database' }]}
                      >
                        <Input placeholder="Database" />
                      </Form.Item>
                      <Form.Item
                        label={<EncryptedLabel label="User" />}
                        name={['config', 'user']}
                        rules={[{ required: true, message: 'Please enter the user' }]}
                      >
                        <Input placeholder="User" />
                      </Form.Item>
                      <Form.Item
                        label={<EncryptedLabel label="Password" />}
                        name={['config', 'password']}
                        rules={[{ message: 'Please enter the password' }]}
                      >
                        <Input placeholder="Password" />
                      </Form.Item>
                      {
                        type !== DataSourceType.MYSQL && (
                          <Form.Item
                            label={<EncryptedLabel label="Schema" />}
                            name={['config', 'schema']}
                            rules={[{ required: true, message: 'Please enter the schema' }]}
                          >
                            <Input placeholder="Schema" />
                          </Form.Item>
                        )
                      }
                      <Form.Item
                        label="Disable SSL"
                        name={['config', 'disableSsl']}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </>
                  );
                }

                return null;
              }
            }
          </Form.Item>
        )
      }
      <Form.Item
        label="Is default"
        name="isDefault"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Alert
        type="info"
        className="mb-4"
        message={(
          <div>
            <p>
              We will use one of the following ip addresses to connect to your data source.
            </p>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              {
                SERVER_IPS.map((ip) => (
                  <Tag
                    icon={<CopyOutlined />}
                    key={ip}
                    color="red"
                    onClick={() => {
                      navigator.clipboard.writeText(ip);
                      message.success('Copied to clipboard');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {ip}
                  </Tag>
                ))
              }
            </div>
            <p>Please whitelist them so that we can connect to your data source.</p>
          </div>
        )}
      />

      {
        !dataSource && (
          <Alert
            type="warning"
            className="mb-4"
            message={(
              <p>
                Are you having trouble connecting to your data source?
                {' '}
                Invite a technical team member to help you out.
              </p>
            )}
            action={(
              <Button
                className="ms-2"
                onClick={() => {
                  setInviteMemberModalOpen(true);
                }}
              >
                Invite Member
              </Button>
            )}
          />
        )
      }

      <Form.Item noStyle shouldUpdate>
        {
          ({ getFieldValue }) => {
            const type = (getFieldValue('type') as DataSourceType);
            const airtableConnected = organization.integrations?.airtable?.enabled;

            if (type === DataSourceType.AIRTABLE && !airtableConnected) {
              return null;
            }

            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  disabled={loading}
                  block
                >
                  Save
                </Button>
                {
                  loading && !dataSource && (
                    <p className="mt-2 text-center">
                      Please wait, we are trying to connect to the data source
                    </p>
                  )
                }
              </>
            );
          }
        }
      </Form.Item>
      <Drawer
        title="Invite New Member"
        open={inviteMemberModalOpen}
        onClose={() => {
          setInviteMemberModalOpen(false);
        }}
      >
        <OrganizationMemberForm
          organizationId={organization._id}
          onSuccess={(user) => {
            setInviteMemberModalOpen(false);
            notification.success({
              message: 'Invitation sent',
              description: `We have sent an invitation to ${user.email}.`,
            });
          }}
        />
      </Drawer>
    </Form>
  );
}
