import { Sort, SortRule } from '../../api';

// eslint-disable-next-line import/prefer-default-export
export function getSortingRule(columnKey: string, sort?: Sort): SortRule | undefined {
  const rules = sort?.rules;

  if (!rules) {
    return undefined;
  }

  return rules.find((rule) => rule.columnKey === columnKey);
}

export function getSortOrder(columnKey: string, sort?: Sort): SortRule['order'] {
  const rule = getSortingRule(columnKey, sort);
  return rule?.order;
}
