import {
  Button, Card, Col, Modal, notification, Row,
} from 'antd';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import api, { Project } from '../../api';
import ProjectForm from './ProjectForm';
import { globalAtom } from '../../common/state/global-state';

interface GeneralProjectSettingsProps {
  project: Project;
  onUpdateSuccess?: (response: Project) => void;
}

export default function GeneralProjectSettings(props: GeneralProjectSettingsProps) {
  const { project, onUpdateSuccess } = props;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [removing, setRemoving] = useState(false);
  const navigate = useNavigate();
  const setRecoilState = useSetRecoilState(globalAtom);

  const onRemoveConfirm = useCallback(async () => {
    setRemoving(true);
    api.projects.remove(project._id).then(() => {
      setRemoving(false);
      setConfirmDialogOpen(false);
      navigate('../../');
      notification.success({
        message: 'Project Deleted',
      });
      setRecoilState((prev) => ({
        ...prev,
        projects: prev.projects.filter((p) => p._id !== project._id),
      }));
    });
  }, [project._id]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} md={14} lg={12}>
          <Card size="small" title="Project Details">
            <ProjectForm
              project={project}
              onUpdateSuccess={onUpdateSuccess}
            />
          </Card>
          <Card className="mt-3" size="small" title="Project Administration">
            <p>
              This will remove all data associated with this project,
              {' '}
              including the dashboards, and the widgets.
            </p>
            <div className="d-flex justify-content-end">
              <Button
                type="primary"
                danger
                onClick={() => setConfirmDialogOpen(true)}
              >
                Delete Project
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        open={confirmDialogOpen}
        onCancel={() => setConfirmDialogOpen(false)}
        onOk={onRemoveConfirm}
        okText="Delete Project"
        okButtonProps={{
          danger: true,
          loading: removing,
        }}
        cancelText="Cancel"
        title="Delete Project"
      >
        <p>
          Are you sure you want to delete this project?
          {' '}
          This action cannot be undone.
        </p>
      </Modal>
    </>
  );
}
