import { Button, Card } from 'antd';
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Plan, PlanLimits, PlanName } from '../../api';

interface PlanCardProps extends Pick<
Plan,
'credits' | 'price' | 'maxUsers' | 'maxProjects' | 'maxDashboards' | 'maxWidgets'
>, Pick<PlanLimits, 'apiAccess' | 'fullyEmbedded' | 'whitelabel' | 'dataMonitoring' | 'maxDataSources'> {
  name: PlanName;
  onSubscribeClick?: () => void;
  loading?: 'subscribe' | 'unsubscribe' | 'resume';
  disabled?: boolean;
  billedAnnually?: boolean;
  isCurrentPlan?: boolean;
  cancelOnExpire?: boolean;
  onUnsubscribeClick?: () => void;
  onResumeClick?: () => void;
}

function getPlanLabel(planName: PlanName) {
  switch (planName) {
    case 'free':
      return 'Free';
    case PlanName.INDIVIDUAL:
      return 'Individual';
    case PlanName.STARTER:
      return 'Starter';
    case PlanName.PROFESSIONAL:
      return 'Professional';
    case PlanName.ENTERPRISE:
      return 'Enterprise';
    case PlanName.ENTERPRISE_TIER_1_RESELL:
      return 'Enterprise Resell';
    default:
      return 'Unknown';
  }
}

function getMaxDescription(value: number, label: string) {
  if (value === Infinity) {
    return `Unlimited ${label}s`;
  }

  return `${value} ${label}${value > 1 ? 's' : ''}`;
}

export default function PlanCard(props: PlanCardProps) {
  const {
    name,
    credits,
    price,
    onSubscribeClick,
    loading,
    disabled,
    maxUsers,
    maxProjects,
    maxDashboards,
    maxWidgets,
    billedAnnually,
    isCurrentPlan,
    apiAccess,
    fullyEmbedded,
    whitelabel,
    dataMonitoring,
    maxDataSources,
    cancelOnExpire,
    onUnsubscribeClick,
    onResumeClick,
  } = props;

  return (
    <Card size="small">
      <h1 className="mt-0">{getPlanLabel(name)}</h1>
      <h2 className="m-0">
        {
          price !== Infinity ? (`${price.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })} / month`) : 'Contact Us'
        }
      </h2>
      {
        billedAnnually && (
          <p className="m-0 text-muted">Billed annually</p>
        )
      }
      <h3 className="fw-bold">
        {credits !== Infinity ? credits.toLocaleString('en-US') : 'Unlimited'}
        {' '}
        Credits
      </h3>
      <div className="d-flex gap-1 flex-column">
        <p className="m-0">{getMaxDescription(maxUsers, 'User')}</p>
        <p className="m-0">{getMaxDescription(maxDataSources, 'Data Source')}</p>
        <p className="m-0">{getMaxDescription(maxProjects, 'Project')}</p>
        <p className="m-0">{getMaxDescription(maxDashboards, 'Dashboard')}</p>
        <p className="m-0">{getMaxDescription(maxWidgets, 'Widget')}</p>
        <p className="m-0">Unlimited widget updates</p>
        <p className="m-0">
          <span className="me-2">
            {
              apiAccess ? <CheckOutlined className="text-success" /> : <CloseSquareOutlined className="text-danger" />
            }
          </span>
          API Access
        </p>
        <p className="m-0">
          <span className="me-2">
            {
              fullyEmbedded ? <CheckOutlined className="text-success" /> : <CloseSquareOutlined className="text-danger" />
            }
          </span>
          Fully Embedded
        </p>
        <p className="m-0">
          <span className="me-2">
            {
              whitelabel ? <CheckOutlined className="text-success" /> : <CloseSquareOutlined className="text-danger" />
            }
          </span>
          Whitelabel
        </p>
        <p className="m-0">
          <span className="me-2">
            {
              dataMonitoring ? <CheckOutlined className="text-success" /> : <CloseSquareOutlined className="text-danger" />
            }
          </span>
          Data Monitoring
        </p>
      </div>
      <div className="mt-5">
        {
          price !== Infinity && price > 0 && !isCurrentPlan && (
            <Button
              type="primary"
              onClick={onSubscribeClick}
              loading={loading === 'subscribe'}
              disabled={(loading === 'subscribe') || disabled}
              block
            >
              Upgrade
            </Button>
          )
        }
        {
          isCurrentPlan && !cancelOnExpire && (
            <Button
              type="default"
              block
              danger
              onClick={onUnsubscribeClick}
              loading={loading === 'unsubscribe'}
              disabled={(loading === 'unsubscribe') || disabled}
            >
              Unsubscribe
            </Button>
          )
        }
        {
          isCurrentPlan && cancelOnExpire && (
            <Button
              type="default"
              block
              onClick={onResumeClick}
              loading={loading === 'resume'}
              disabled={loading === 'resume' || disabled}
            >
              Resume Subscription
            </Button>
          )
        }
        {
          price === Infinity && (
            <Button type="default" block>Contact Us</Button>
          )
        }
      </div>
    </Card>
  );
}
