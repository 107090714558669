import { useParams } from 'react-router-dom';
import DashboardWidgets from '../components/DashboardWidgets';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import Spin from '../../common/components/Spin';
import AppSharedPage from '../../layout/components/AppSharedPage';

export default function SharedDashboardPage() {
  const { dashboardId } = useParams();

  const [dashboard, loading] = useQuery(
    async () => {
      if (dashboardId) {
        return api.dashboards.get(dashboardId);
      }

      return null;
    },
    [dashboardId],
  );

  if (!dashboardId || loading || (!loading && !dashboard)) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        {
          !loading ? (
            <h1>Dashboard not found</h1>
          ) : (
            <Spin />
          )
        }
      </div>
    );
  }

  if (!dashboard) {
    return null;
  }

  return (
    <AppSharedPage title={dashboard.name}>
      <div className="p-1">
        <DashboardWidgets
          dashboardId={dashboardId}
          projectId={dashboard.projectId}
          organizationId={dashboard.organizationId}
          gridLayout={dashboard.gridLayout}
          useSharedLayout
          sharedMode
        />
      </div>
    </AppSharedPage>
  );
}
