import { OrganizationUserRole } from '../api';

// eslint-disable-next-line import/prefer-default-export
export function getRoleLabel(role: OrganizationUserRole) {
  switch (role) {
    case OrganizationUserRole.OWNER:
      return 'Owner';
    case OrganizationUserRole.ADMIN:
      return 'Admin';
    case OrganizationUserRole.MEMBER:
      return 'Member';
    case OrganizationUserRole.READONLY:
      return 'Read Only';
    default:
      return 'Unknown';
  }
}

export function getRolePermissionsText(role: OrganizationUserRole) {
  switch (role) {
    case OrganizationUserRole.OWNER:
      return 'Can do anything';
    case OrganizationUserRole.ADMIN:
      return 'Can do anything except add or remove other admins';
    case OrganizationUserRole.MEMBER:
      return 'Can create dashboards, widgets, and ask questions';
    case OrganizationUserRole.READONLY:
      return 'View-only role, can view projects, dashboards, and widgets (but not ask questions or create anything)';
    default:
      return 'Unknown';
  }
}
