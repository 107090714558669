// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryBuilderAntD } from '@react-querybuilder/antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Field, QueryBuilder, RuleGroupType, jsonLogicAdditionalOperators, formatQuery,
} from 'react-querybuilder';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-querybuilder/dist/query-builder.css';
import { Button } from 'antd';
import { useState } from 'react';
import { add_operation } from 'json-logic-js';
import { Filter, ResultRepresentation, ResultRepresentationSubType } from '../../api';
import DataSchema, { DataSchemaFieldType } from '../../data/data-schema';

// eslint-disable-next-line no-restricted-syntax
for (const [op, func] of Object.entries(jsonLogicAdditionalOperators)) {
  add_operation(op, func);
}

interface ResultRepresentationFilterSetupProps {
  resultRepresentation?: ResultRepresentation;
  rawData: any;
  value?: RuleGroupType;
  onChange?: (filter: Filter) => void;
  loading?: boolean;
}

const singleValueRepresentations: ResultRepresentationSubType[] = [
  ResultRepresentationSubType.TEXT,
  ResultRepresentationSubType.NUMBER,
  ResultRepresentationSubType.DATE,
  ResultRepresentationSubType.STRING,
];

function extractPossibleFields(rawData: any, resultRepresentation?: ResultRepresentation): Field[] {
  const subType = resultRepresentation?.subType;

  if (subType && singleValueRepresentations.includes(subType)) {
    return [];
  }

  const dataSchema = new DataSchema(rawData);
  const schema = dataSchema.extract();

  if (!schema.items) {
    return [];
  }

  const fields: Field[] = [];
  const itemsList = Array.isArray(schema.items) ? schema.items : [schema.items];
  itemsList.forEach((item) => {
    const itemConfig = DataSchema.getFieldConfig(item.type);

    if (itemConfig.filterable) {
      const field: Field = {
        name: item.name,
        label: item.name,
        ...(itemConfig.filterField || {}),
      };

      if (item.type === DataSchemaFieldType.STRING) {
        // @ts-ignore
        field.values = [...new Set(rawData.map((row: any) => row[item.name]))].map((name) => ({
          name,
          label: name,
        }));
      }

      fields.push(field);
    }
  });

  return fields;
}

export default function ResultRepresentationFilterSetup(
  props: ResultRepresentationFilterSetupProps,
) {
  const {
    resultRepresentation,
    rawData,
    onChange,
    loading,
    value,
  } = props;

  const [query, setQuery] = useState<RuleGroupType | undefined>();

  if (resultRepresentation === undefined || rawData === undefined) {
    return (
      <p>
        Result is unknown, can not apply format.
      </p>
    );
  }

  const possibleFields = extractPossibleFields(rawData, resultRepresentation);
  const dataSchema = new DataSchema(rawData);

  return (
    <div>
      <QueryBuilderAntD>
        <QueryBuilder
          defaultQuery={value}
          fields={possibleFields}
          onQueryChange={(newQuery: RuleGroupType) => {
            setQuery(newQuery);
          }}
          controlClassnames={{
            queryBuilder: 'queryBuilder-branches',
            value: 'value',
          }}
          getValueEditorType={(field, operator) => {
            const item = dataSchema.extractItem(field);

            if (item) {
              const itemConfig = DataSchema.getFieldConfig(item.type);

              if (itemConfig.filterField) {
                if (itemConfig.filterField.valueEditorTypeMap) {
                  return itemConfig.filterField.valueEditorTypeMap[operator];
                }

                if (itemConfig.filterField.valueEditorType) {
                  const { valueEditorType } = itemConfig.filterField;

                  if (typeof valueEditorType === 'function') {
                    return valueEditorType(operator);
                  }

                  return valueEditorType;
                }
              }
            }

            return 'text';
          }}
        />
      </QueryBuilderAntD>
      <div className="d-flex align-items-center justify-content-end">
        <Button
          className="mt-2"
          type="primary"
          loading={loading}
          onClick={() => {
            if (query && onChange) {
              // @ts-ignore
              const jsonLogic = formatQuery(query, 'jsonLogic');
              onChange({
                query,
                jsonLogic,
              });
            }
          }}
        >
          Save
        </Button>
      </div>
      {/*
      @ts-ignore */}
      <style jsx>{'.value .ant-select-multiple { min-width: 200px; }'}</style>
    </div>
  );
}
