import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class DateToNumberTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.DATE;

  to = ResultRepresentationSubType.NUMBER;

  isCompatible(): boolean {
    return !Number.isNaN(this.transformData());
  }

  transformData(): any {
    return new Date(this.resultRepresentation.data).getTime();
  }
}

export default DateToNumberTransformer;
