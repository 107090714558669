import { PieChartOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class PieChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = PieChartOutlined;

  title = 'Pie Chart';

  type = ResultRepresentationType.GRAPH;
}

export default PieChartResultRepresentationSubTypeConfig;
