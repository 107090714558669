import Application from '../definitions/Application';
import User from '../models/User';

type SignUpData = Pick<User, 'firstName' | 'lastName' | 'email'> & {
  password: string;
  params?: Record<string, any>;
};

type SignInData = {
  email: User['email'];
  password: string;
};

export default class Auth {
  constructor(readonly client: Application) {}

  async signUp(data: SignUpData) {
    return this.client.service('users').create(data);
  }

  async signIn(data: SignInData) {
    // @ts-ignore
    return this.client.authenticate({
      strategy: 'local',
      ...data,
    });
  }

  async reAuthenticate() {
    // @ts-ignore
    return this.client.reAuthenticate();
  }

  async logout() {
    // @ts-ignore
    return this.client.logout();
  }
}
