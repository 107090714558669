import { Alert, Button } from 'antd';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useActiveOrganizationPlan from '../../common/hooks/useActiveOrganizationPlan';
import { PlanName } from '../../api';

const FREE_TRIAL_DAYS = 7;

interface OrganizationFreeTrialAlertProps {
  hideWhenExpired?: boolean;
  block?: boolean;
}

export default function OrganizationFreeTrialAlert(props: OrganizationFreeTrialAlertProps) {
  const { hideWhenExpired, block } = props;
  const organization = useActiveOrganization();
  const plan = useActiveOrganizationPlan();
  const { pathname } = useLocation();
  const { organizationSlug } = useParams();
  const navigate = useNavigate();

  if (!organization) {
    return null;
  }

  // Organization is being changed, so don't show the alert
  if (organizationSlug && organizationSlug !== organization.slug) {
    return null;
  }

  const createdAt = new Date(organization.createdAt);
  const expiresAt = new Date(createdAt);
  expiresAt.setDate(expiresAt.getDate() + FREE_TRIAL_DAYS);
  const daysLeft = Math.ceil((expiresAt.getTime() - Date.now()) / (1000 * 60 * 60 * 24));

  if (!organization.freeTrialExpired && plan?.name === PlanName.FREE) {
    return (
      <Link to={`/${organization?.slug}/settings/billing`}>
        <div
          className="shadow-sm shadow-hover bg-white"
          style={{
            border: '1px solid #1677ff',
            padding: '10px',
            color: 'black',
          }}
        >
          <h4 className="m-0 text-center">
            {`You have ${daysLeft} days left in your free trial.`}
          </h4>
          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              className="mt-2"
              block={block}
            >
              Upgrade
            </Button>
          </div>
        </div>
      </Link>
    );
  }

  if (!organization.hasPlanOrTrial) {
    const billingUrl = `/${organization.slug}/settings/billing`;

    if (pathname !== billingUrl) {
      if (organizationSlug) {
        navigate(billingUrl);
      }

      return null;
    }

    if (hideWhenExpired) {
      return null;
    }

    return (
      <Alert
        message="Your free trial has expired. Please upgrade your plan to continue using the app."
        type="error"
      />
    );
  }

  return null;
}
