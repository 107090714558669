import {
  Alert, Button, Checkbox, Collapse, Form, Input, notification, Tag,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import extend from 'just-extend';
import debounce from 'just-debounce';
import api, { DataSource } from '../../api';
import useRequest from '../../common/hooks/useRequest';

interface DataSourceSchemaEditorProps {
  id: DataSource['_id'];
  schemaConfig: DataSource['schemaConfig'];
  showRefreshSchemaButton?: boolean;
  onRefreshSchema?: () => void;
  refreshingSchema?: boolean;
}

export default function DataSourceSchemaEditor(props: DataSourceSchemaEditorProps) {
  const {
    id,
    schemaConfig,
    showRefreshSchemaButton,
    refreshingSchema,
    onRefreshSchema,
  } = props;
  const [form] = Form.useForm();
  const [search, setSearch] = useState('');

  useEffect(() => {
    form.setFieldsValue(schemaConfig);
  }, [schemaConfig]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(setSearch, 1000), []);

  const request = useRequest(
    // @ts-ignore
    async (values: DataSource['schemaConfig']) => api.dataSources.patch(id, {
      schemaConfig: extend(true, extend(true, {}, schemaConfig), values),
    }),
    {
      onSuccess: () => {
        notification.success({
          message: 'Data source schema updated',
        });
      },
    },
  );

  const indexMap: Record<string, number> = {};

  schemaConfig.tables.forEach((table, index) => {
    const { tableName } = table;
    indexMap[tableName] = index;
  });

  return (
    <Form
      layout="vertical"
      onFinish={request.submit}
      initialValues={schemaConfig}
      form={form}
      preserve
    >
      <Alert
        type="info"
        message="Select the tables and fields you want to include in the data source. Only the selected tables and fields will be used to query the data source, all other tables and fields will be ignored."
        className="mb-2 d-none"
      />
      <div className="d-flex align-items-center justify-content-between gap-4 mb-4">
        <div style={{ marginLeft: 4 }}>
          <Form.Item noStyle shouldUpdate>
            {
              ({ getFieldValue }) => {
                const tables = getFieldValue('tables');
                const value = tables.every((table: any) => table.included);

                return (
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      e.stopPropagation();
                      const included = e.target.checked;

                      form.setFieldsValue({
                        tables: schemaConfig.tables.map((table) => ({
                          ...table,
                          included,
                        })),
                      });
                    }}
                  >
                    <span>Select&nbsp;All</span>
                  </Checkbox>
                );
              }
            }
          </Form.Item>
        </div>
        <Input
          placeholder="Search"
          onChange={(e) => void onSearch(e.target.value.trim())}
          allowClear
        />
      </div>
      <div
        style={{
          maxHeight: 'calc(100vh - 370px)',
          overflowY: 'auto',
        }}
      >
        <Collapse
          expandIconPosition="end"
          bordered={false}
          style={{ borderRadius: 0, background: 'white' }}
        >
          {
            schemaConfig.tables.map((item) => {
              const index = indexMap[item.tableName];
              const tableIncluded = (
                item.tableName || item.displayTableName
              ).toLowerCase().includes(search.toLowerCase());
              return (
                <Collapse.Panel
                  className={tableIncluded ? '' : 'd-none'}
                  header={(
                    <div className="d-flex gap-2">
                      <Form.Item
                        name={['tables', index, 'included']}
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Form.Item>
                      <span>
                        {item.displayTableName || item.tableName}
                        {item.isView && ' (View)'}
                      </span>
                    </div>
                  )}
                  key={item.displayTableName || item.tableName}
                >
                  <Form.Item
                    name={['tables', index, 'description']}
                    help="A description for the table to help our AI system understand the data better. You can also use it to provide instructions for our AI system to follow when querying the data source."
                  >
                    <Input placeholder="Table Description" />
                  </Form.Item>
                  <div className="d-flex flex-column gap-3 pt-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Item noStyle shouldUpdate>
                        {
                          ({ getFieldValue }) => {
                            const table = getFieldValue(['tables', index]);
                            const value = Object
                              .values(table.schema.properties)
                              .every((field: any) => field.included);

                            return (
                              <Checkbox
                                checked={value}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const included = e.target.checked;

                                  form.setFieldValue(
                                    ['tables', index, 'schema', 'properties'],
                                    Object
                                      .keys(item.schema.properties)
                                      .reduce((acc: Record<string, any>, schemaFieldName) => {
                                        acc[schemaFieldName] = {
                                          ...item.schema.properties[schemaFieldName],
                                          included,
                                        };
                                        return acc;
                                      }, {}),
                                  );
                                }}
                              >
                                <span>Select All</span>
                              </Checkbox>
                            );
                          }
                        }
                      </Form.Item>
                    </div>
                    {
                      Object.keys(item.schema.properties).map((schemaFieldName) => {
                        const schemaField = item.schema.properties[schemaFieldName];

                        return (
                          <div key={schemaFieldName}>
                            <div className="d-flex align-items-center justify-content-between">
                              <Form.Item
                                name={['tables', index, 'schema', 'properties', schemaFieldName, 'included']}
                                valuePropName="checked"
                                shouldUpdate
                                noStyle
                              >
                                <Checkbox>
                                  <span>{schemaFieldName}</span>
                                </Checkbox>
                              </Form.Item>
                              <Tag>{schemaField.type}</Tag>
                            </div>
                            <Form.Item
                              name={['tables', index, 'schema', 'properties', schemaFieldName, 'description']}
                              className="mt-2"
                            >
                              <Input placeholder="Field Description" />
                            </Form.Item>
                          </div>
                        );
                      })
                    }
                  </div>
                </Collapse.Panel>
              );
            })
          }
        </Collapse>
      </div>

      {
        showRefreshSchemaButton && (
          <Form.Item
            className="mt-4 mb-0"
            help="Refresh the schema to get the latest changes from the data source. This will override the current schema."
          >
            <Button
              loading={refreshingSchema}
              disabled={refreshingSchema}
              onClick={onRefreshSchema}
              block
            >
              Refresh Schema
            </Button>
          </Form.Item>
        )
      }

      <Form.Item noStyle>
        <Button
          className="mt-4"
          htmlType="submit"
          type="primary"
          size="large"
          loading={request.loading}
          disabled={request.loading}
          block
        >
          Save Schema
        </Button>
      </Form.Item>
    </Form>
  );
}
