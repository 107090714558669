import ResultRepresentationManager from './ResultRepresentationManager';
import LineChartToAreaChartTransformer from '../transformers/LineChartToAreaChartTransformer';
import LineChartToTableTransformer from '../transformers/LineChartToTableTransformer';

class LineChartResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new LineChartToAreaChartTransformer(this.representation),
    new LineChartToTableTransformer(this.representation),
  ];
}

export default LineChartResultRepresentationManager;
