import {
  Button, Card, Col, notification, Row,
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';

export default function AppSumoThankYouPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onGetStartedClick = async () => {
    setLoading(true);

    try {
      const response = await api.organizations.find();
      const [organization] = response.data;

      if (organization) {
        const projectResponse = await api.projects.find({
          query: {
            isDemoProject: true,
          },
        });

        const [project] = projectResponse.data;

        if (project) {
          await navigate(`/${organization.slug}/${project.slug}`);
          notification.success({
            message: 'Welcome to InsightBase',
            description: 'This is a demo project, feel free to explore it, create your own dashboards and widgets',
            duration: 20,
          });
        } else {
          const defaultProjectResponse = await api.projects.find({
            query: {},
          });
          if (defaultProjectResponse.data[0]) {
            await navigate(`/${organization.slug}/${defaultProjectResponse.data[0].slug}`);
          }
        }
      }
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e.message,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
  };
  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#0b163f',
        boxSizing: 'border-box',
        backgroundImage: 'url("/page-header-bg.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
        color: 'white',
      }}
      className="d-flex align-items-center p-5"
    >
      <Row justify="center" className="flex-grow-1">
        <Col span={24} md={12} lg={10} xl={8} xxl={6}>
          <div className="d-flex flex-column align-items-center mb-5">
            <img src="/logo-white.svg" height={50} alt="logo" />
          </div>
          <h1 className="text-center">
            Thank you for your purchase!
          </h1>
          <Card>
            <h2 className="text-center">
              Welcome to InsightBase. We are excited to have you on board.
            </h2>
            <h4 className="my-5">
              Start by connecting your database to InsightBase, and build your first dashboard,
              or explore the sample project we have created for you.
            </h4>
            <div className="d-flex align-items-center justify-content-between gap-4">
              <Button
                size="large"
                style={{ flex: 1 }}
                type="primary"
                onClick={onGetStartedClick}
                loading={loading}
                disabled={loading}
              >
                Get Started
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
