import { RangePickerProps as AntRangePickerProps } from 'antd/es/date-picker';
import { Button, DatePicker } from 'antd';
import { useState } from 'react';
import { Dayjs } from 'dayjs';
import CustomDateSelection, { DatePreset } from '../types/CustomDateSelection';
import { getDatePresets } from '../utils/dates';

interface RangePickerProps extends Pick<AntRangePickerProps, 'style' | 'popupStyle'> {
  showPresets?: boolean;
  value?: CustomDateSelection;
  onChange?: (value: CustomDateSelection) => void;
}

const { _InternalRangePanelDoNotUseOrYouWillBeFired: InternalDatePicker } = DatePicker;

export default function RangePicker(props: RangePickerProps) {
  const {
    style = {},
    popupStyle = {},
    showPresets = true,
    value,
    onChange,
    ...rest
  } = props;

  const presets: CustomDateSelection[] = getDatePresets();
  const [customValue, setCustomValue] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

  return (
    <>
      <div className="d-flex">
        {
          showPresets && (
            <div>
              {
                presets.map((preset) => (
                  <div key={preset.preset}>
                    <Button
                      size="small"
                      type={preset.preset === value?.preset ? 'primary' : 'text'}
                      ghost={preset.preset === value?.preset}
                      style={{
                        textAlign: 'left',
                        minWidth: 80,
                      }}
                      onClick={() => {
                        if (onChange) {
                          onChange(preset);
                        }
                      }}
                      block
                    >
                      {preset.label}
                    </Button>
                  </div>
                ))
              }
            </div>
          )
        }
        <div className="picker">
          <InternalDatePicker
            value={customValue}
            onChange={setCustomValue}
            style={{
              opacity: 0,
              width: 0,
              height: 0,
              padding: 0,
              ...style,
            }}
            popupStyle={{
              padding: 0,
              inset: '0 auto auto 0',
              ...popupStyle,
            }}
            onCalendarChange={(dates: [Dayjs | null, Dayjs | null]) => {
              if (dates[1] && onChange) {
                onChange({
                  preset: DatePreset.CUSTOM,
                  label: 'Custom',
                  longLabel: 'Custom',
                  from: dates[0],
                  to: dates[1],
                });
              }
            }}
            {...rest}
          />
        </div>
      </div>
      {/*
      @ts-ignore */}
      <style jsx>
        {`
            .picker {
                margin-bottom: -23px;
            }

            .picker .ant-picker-panel-container {
                box-shadow: none;
            }

            .picker .ant-picker,
            .picker .ant-picker-input,
            .picker .ant-picker-range-separator {
                height: 0;
                opacity: 0;
                max-height: 0;
                border: none;
                padding: 0;
            }

            .picker .ant-picker-range-arrow {
                display: none !important;
            }

            .picker .ant-picker-dropdown {
                inset: 0 auto auto 0 !important;
            }
        `}
      </style>
    </>
  );
}
