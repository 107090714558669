import AppPage from '../../layout/components/AppPage';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import InsightFlowBuilder from '../../insight-flow/components/InsightFlowBuilder';
import useActiveProject from '../../common/hooks/useActiveProject';

export default function ProjectInsightFlowPage() {
  const organization = useActiveOrganization();
  const project = useActiveProject();

  return (
    <AppPage title="InsightFlow">
      {
        organization && project && (
          <InsightFlowBuilder
            organizationId={organization?._id}
            projectId={project?._id}
          />
        )
      }
    </AppPage>
  );
}
