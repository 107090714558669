import {
  Button, Card, Form, Input, notification,
} from 'antd';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';
import { globalAtom } from '../../common/state/global-state';

export default function OrganizationAppSumoStacking() {
  const organization = useActiveOrganization();
  const setRecoilState = useSetRecoilState(globalAtom);
  const [code, setCode] = useState('');

  const request = useRequest(async (values: { code: string }) => {
    if (organization) {
      try {
        const newOrganization = await api.organizations.appSumoStack({
          id: organization?._id,
          code: values.code,
        });
        setRecoilState((prev) => ({
          ...prev,
          organization: newOrganization,
        }));
        notification.success({
          message: 'Success',
          description: 'Your AppSumo code has been stacked!',
        });
      } catch (e: any) {
        notification.error({
          message: 'Error',
          description: e?.message || 'Something went wrong',
        });
      }
    }
  });

  if (!organization) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center">
      <Card
        style={{ width: 400 }}
        title="AppSumo Stacking"
      >
        <Form
          onFinish={request.submit}
          layout="vertical"
        >
          <Form.Item
            rules={[
              { required: true, message: 'Please enter a code' },
            ]}
            name="code"
            label="Code"
          >
            <Input
              placeholder="AppSumo Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              size="large"
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={request.loading}
            block
          >
            Stack Code
          </Button>
        </Form>
      </Card>
    </div>
  );
}
