import Model from './Model';

enum DataSourceType {
  MONGODB = 'mongodb',
  POSTGRESQL = 'postgresql',
  MYSQL = 'mysql',
  MSSQL = 'mssql',
  AIRTABLE = 'airtable',
}

export enum DataSourceScope {
  ORGANIZATION = 'organization',
  PROJECT = 'project',
}

export type DataSourceTableColumnConfig = {
  type: string;
  format?: string;
  description?: string;
};

export type DataSourceTableConfig = {
  tableName: string;
  displayTableName: string;
  schema: {
    properties: {
      [key: string]: DataSourceTableColumnConfig;
    };
    [key: string]: any;
  };
  included: boolean;
  description?: string;
  isView?: boolean;
};

type DataSourceTerm = {
  id: string;
  term: string;
  definition: string;
};

export default interface DataSource extends Model {
  name: string;
  entityId: Model['_id'];
  organizationId: Model['_id'];
  scope: DataSourceScope;
  type: DataSourceType;
  config: Record<string, any>;
  schemaConfig: {
    tables: DataSourceTableConfig[];
  };
  isDefault?: boolean;
  suggestedQuestions?: string[];
  terms?: DataSourceTerm[];
  customInstructions?: string;
}

export {
  DataSourceType,
};

export type { DataSourceTerm };
