import { useParams } from 'react-router-dom';
import {
  Col, notification, Row,
} from 'antd';
import { useState } from 'react';
import AppTabbedPage from '../../layout/components/AppTabbedPage';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import DataSourceForm from '../components/DataSourceForm';
import DataSourceSchemaEditor from '../components/DataSourceSchemaEditor';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import DataSourceTermsEditor from '../components/DataSourceTermsEditor';
import DataSourceCustomInstructionsEditor from '../components/DataSourceCustomInstructionsEditor';
import DataSourceConnectionInfoEditor from '../components/DataSourceConnectionInfoEditor';

export default function DataSourcePage() {
  const { dataSourceId } = useParams();
  const [activeKey, setActiveKey] = useState<string>('edit');
  const [refreshingSchema, setRefreshingSchema] = useState(false);
  const organization = useActiveOrganization();

  const [dataSource, loading, setDataSource] = useQuery(
    async () => {
      if (dataSourceId) {
        return api.dataSources.get(dataSourceId);
      }

      throw new Error('No data source id');
    },
  );

  const onRefreshSchema = () => {
    if (dataSource) {
      setRefreshingSchema(true);
      api.dataSources.refreshSchema({ id: dataSource._id }).then((newDataSource) => {
        setRefreshingSchema(false);
        notification.success({
          message: 'Data source schema refreshed',
        });
        setDataSource(newDataSource);
      }).catch((e) => {
        setRefreshingSchema(false);
        notification.error({
          message: 'Error refreshing data source schema',
          description: e.message,
        });
      });
    } else {
      notification.error({
        message: 'Error refreshing data source schema',
        description: 'Data source not loaded yet',
      });
    }
  };

  return (
    <AppTabbedPage
      title="Data Source"
      loading={loading}
      activeKey={activeKey}
      onChange={setActiveKey}
      syncToUrl
      items={dataSource ? [
        {
          key: 'edit',
          label: 'Edit',
          children: (
            <Row>
              <Col span={24} md={12} lg={10} xl={8} xxl={6}>
                {
                  organization && (
                    <DataSourceForm
                      entityId={dataSource.entityId}
                      organizationId={dataSource.organizationId}
                      scope={dataSource.scope}
                      dataSource={dataSource}
                      onSuccess={() => {
                        notification.success({
                          message: 'Data source updated',
                        });
                      }}
                      organization={organization}
                    />
                  )
                }
              </Col>
            </Row>
          ),
        },
        {
          key: 'schema',
          label: 'Schema',
          children: (
            <Row>
              <Col span={24} md={14} lg={12} xl={10} xxl={8}>
                <DataSourceSchemaEditor
                  id={dataSource._id}
                  schemaConfig={dataSource.schemaConfig}
                  showRefreshSchemaButton
                  onRefreshSchema={onRefreshSchema}
                  refreshingSchema={refreshingSchema}
                />
              </Col>
            </Row>
          ),
        },
        {
          key: 'terms',
          label: 'Terms',
          children: (
            <Row>
              <Col span={24} md={18} lg={16} xl={14} xxl={12}>
                <DataSourceTermsEditor
                  id={dataSource._id}
                  value={dataSource.terms || []}
                  onChange={(terms) => {
                    setDataSource({
                      ...dataSource,
                      terms,
                    });
                  }}
                />
              </Col>
            </Row>
          ),
        },
        {
          key: 'custom-instructions',
          label: 'Custom Instructions',
          children: (
            <Row>
              <Col span={24} md={18} lg={16} xl={14} xxl={12}>
                <DataSourceCustomInstructionsEditor
                  id={dataSource._id}
                  value={dataSource.customInstructions}
                  onChange={(customInstructions) => {
                    setDataSource({
                      ...dataSource,
                      customInstructions,
                    });
                  }}
                />
              </Col>
            </Row>
          ),
        },
        {
          key: 'connection-info-editor',
          label: 'Connection Info',
          children: (
            <Row>
              <Col span={24} md={12} lg={10} xl={8} xxl={6}>
                {
                  organization && (
                    <DataSourceConnectionInfoEditor
                      organization={organization}
                      organizationId={organization._id}
                      dataSource={dataSource}
                    />
                  )
                }
              </Col>
            </Row>
          ),
        },
      ] : undefined}
    />
  );
}
