import { Select, SelectProps } from 'antd';

export type Option = {
  label: string;
  value: string;
  subLabel?: string;
};

export interface AdvancedSelectProps extends Omit<SelectProps, 'options'> {
  options: Option[];
}

export default function AdvancedSelect(props: AdvancedSelectProps) {
  const {
    options,
    optionLabelProp = 'title',
    value,
    ...selectProps
  } = props;

  return (
    <Select
      options={options.map((option) => ({
        ...option,
        label: (
          <div>
            <p className="m-0">{option.label}</p>
            <p
              className="m-0 text-muted"
              style={{ whiteSpace: 'break-spaces', fontSize: 13 }}
            >
              {option.subLabel}
            </p>
          </div>
        ),
        [optionLabelProp]: option.label,
      }))}
      optionLabelProp={optionLabelProp}
      value={value || undefined}
      filterOption={(input, option) => String(option?.[optionLabelProp])
        .toLowerCase()
        .includes(input.toLowerCase())}
      {...selectProps}
    />
  );
}
