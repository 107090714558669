import {
  Entity, EntityField, EntityFieldType, ForeignKeyField,
} from '../../api';
import EntityManager from './EntityManager';

export default class EntitiesRelationshipManager {
  constructor(readonly entities: Entity[]) {}

  getEntity(name: Entity['name']): Entity {
    const entity = this.entities.find((item) => item.name === name);

    if (!entity) {
      throw new Error(`Can not find entity ${name}`);
    }

    return entity;
  }

  getEntityManager(entityName: Entity['name']): EntityManager {
    const entity = this.getEntity(entityName);

    return new EntityManager(entity, this.entities);
  }

  static getForeignKeyFieldDefinition(field: EntityField): ForeignKeyField {
    if (field.definition.type !== EntityFieldType.FOREIGN_KEY) {
      throw new Error(`Field ${field.displayName} is not a foreign key`);
    }

    return field.definition;
  }

  getForeignKeyFieldEntity(field: EntityField) {
    const definition = EntitiesRelationshipManager.getForeignKeyFieldDefinition(field);
    const foreignEntity = this.entities.find(
      (item) => item.name === field.definition.type,
    );

    if (!foreignEntity) {
      throw new Error(`Can not find foreign entity ${definition.entityName}`);
    }

    return foreignEntity;
  }
}
