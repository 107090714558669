import { TableOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class TableResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = TableOutlined;

  title = 'Table';

  type = ResultRepresentationType.TABLE;
}

export default TableResultRepresentationSubTypeConfig;
