import { LineChartOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class LineChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = LineChartOutlined;

  title = 'Line Chart';

  type = ResultRepresentationType.GRAPH;
}

export default LineChartResultRepresentationSubTypeConfig;
