import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useEffect } from 'react';
import AppPage from '../../layout/components/AppPage';
import AuthPageLayout from '../../auth/layouts/AuthPageLayout';
import useQuery from '../../common/hooks/useQuery';
import api, { OnboardingStepKey } from '../../api';
import useOnboarding from '../hooks/useOnboarding';

export default function OnboardingWelcomePage() {
  const [response] = useQuery(() => api.organizations.find({
    query: {
      $limit: 1,
      $sort: {
        createdAt: -1,
      },
    },
  }));
  const [organization] = response?.data || [];
  const onboarding = useOnboarding();

  useEffect(() => {
    if (onboarding?.activeStep?.key === OnboardingStepKey.WELCOME) {
      onboarding.goToNextStep();
    }
  }, [onboarding?.activeStep?.key]);

  return (
    <AppPage title="Welcome to InsightBase" contentPadding={0}>
      <AuthPageLayout
        height="calc(100vh - 64px)"
        title="Welcome to InsightBase"
      >
        <div>
          InsightBase is a powerful tool that allows you to chat with your database using AI.
          {' '}
          You can ask questions, get insights, and create widgets to visualize your data.
        </div>
        {
          onboarding.activeStep?.key === OnboardingStepKey.CONNECT_DATABASE ? (
            <>
              <h3 className="mt-5">
                We will help you get started with a few simple steps.
                {' '}
                Start by connecting your database.
              </h3>
              <Link to={`/${organization?.slug}/data-sources/create`}>
                <Button type="primary" size="large">
                  Connect Database
                </Button>
              </Link>
            </>
          ) : (
            <div className="mt-4">
              <Link to={`/${organization?.slug}`}>
                <Button type="primary" size="large">
                  Get Started
                </Button>
              </Link>
            </div>
          )
        }
      </AuthPageLayout>
    </AppPage>
  );
}
