import {
  Entity, EntityField,
} from '../api';

export default class FieldAdapter {
  constructor(
    readonly field: EntityField,
    readonly entity: Entity,
    readonly entities: Entity[],
  ) {}

  static getJoinedFieldAlias(fieldName: string, asPrefix: string): string {
    // Do not use dots in alias names because it's not supported by some databases
    return `${asPrefix}_${fieldName}`.replace(/\./g, '_');
  }
}
