import { Card, Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import ProjectForm from '../components/ProjectForm';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import { globalAtom } from '../../common/state/global-state';
import AppPage from '../../layout/components/AppPage';
import usePermissions from '../../common/hooks/usePermissions';
import useOrganizationRemainingUsage from '../../common/hooks/useOrganizationRemainingUsage';
import UsageReachedView from '../../billing/components/UsageReachedView';
import useRefreshActiveOrganization from '../../common/hooks/useRefreshActiveOrganization';

export default function ProjectCreatePage() {
  const navigate = useNavigate();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const organization = useActiveOrganization();
  const setGlobalState = useSetRecoilState(globalAtom);
  const permissions = usePermissions();
  const remainingUsage = useOrganizationRemainingUsage();
  const refreshActiveOrganization = useRefreshActiveOrganization();

  return (
    <AppPage title="Create Project" allowed={permissions.projects.write}>
      <div className="h-100 w-100 d-flex align-items-center">
        {
          !organization && (
            <p className="text-center">Organization Not Found</p>
          )
        }
        {
          organization && (
            <Row className="flex-grow-1" justify="center">
              {
                remainingUsage.projects > 0 ? (
                  <Col span={24} md={14} lg={12} xl={10} xxl={8}>
                    <Card title="Create Project">
                      <ProjectForm
                        onUpdateSuccess={(project) => {
                          refreshActiveOrganization();
                          setGlobalState((prev) => ({
                            ...prev,
                            projects: [...prev.projects, project],
                          }));
                          navigate(`/${organizationSlug}/${project.slug}`);
                        }}
                        organizationId={organization._id}
                      />
                    </Card>
                  </Col>
                ) : (
                  <Col span={24} md={14} lg={12} xl={10} xxl={8}>
                    <UsageReachedView
                      subTitle="You have reached the maximum number of projects for your plan. Please upgrade your plan to create more projects."
                    />
                  </Col>
                )
              }
            </Row>
          )
        }
      </div>
    </AppPage>
  );
}
