export enum DevCommand {
  DBAQL_TEST = 'dbaql-test',
  JAVASCRIPT_CODE_RUNNER = 'javascript-code-runner',
}

export default interface DevCommandData {
  command: DevCommand;
  data: any;
  result: any;
}
