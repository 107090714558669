import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class BarChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.BAR_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData(): any {
    return this.resultRepresentation.result;
  }
}

export default BarChartToTableTransformer;
