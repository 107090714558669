import Chart from 'react-apexcharts';
import { formatValueWithRule, getFormatRule } from '../../utils/format';
import {
  Formatter,
  QueryExecution,
  ResultRepresentation,
  ResultRepresentationBuilderOptions,
  ResultRepresentationOverride,
  ResultRepresentationSubType,
} from '../../../api';

interface ChartResultRepresentationViewProps {
  resultRepresentation: ResultRepresentation;
  resultRepresentationOverride?: ResultRepresentationOverride;
  result?: QueryExecution['result'];
  chartHeight?: number;
  builderOptions?: ResultRepresentationBuilderOptions;
}

function cloneData(data: any) {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return [...(data || [])];
    }

    return { ...(data || {}) };
  }

  return data;
}

type GuessedSeriesAndLabels = {
  series: any;
  labels: any;
  labelsField: string;
  seriesField: string;
};

function isNumber(value: any) {
  if (typeof value === 'number') {
    return true;
  }

  // It can be a string consisting of numbers only, which is fine
  return !Number.isNaN(Number(value));
}

function guessSeriesAndLabels(data: any): undefined | GuessedSeriesAndLabels {
  if (!Array.isArray(data) || data.length === 0) {
    return undefined;
  }

  const [sample] = data;

  if (!sample || typeof sample !== 'object') {
    return undefined;
  }

  const keys = Object.keys(sample);
  const firstKey = keys[0];
  const secondKey = keys[1];

  if (
    typeof sample[firstKey] === 'string'
    && isNumber(sample[secondKey])
  ) {
    return {
      series: data.map((item: any) => item[secondKey]),
      labels: data.map((item: any) => item[firstKey]),
      labelsField: firstKey,
      seriesField: secondKey,
    };
  } if (
    isNumber(sample[firstKey])
    && typeof sample[secondKey] === 'string'
  ) {
    return {
      series: data.map((item: any) => item[firstKey]),
      labels: data.map((item: any) => item[secondKey]),
      labelsField: secondKey,
      seriesField: firstKey,
    };
  }

  return undefined;
}

function buildData(
  data: any,
  subType?: ResultRepresentationSubType,
  result?: any,
  builderOptions?: ResultRepresentationBuilderOptions,
) {
  const hasFormat = builderOptions && (
    builderOptions.dataSets?.length
    || builderOptions.labelsFormat
  );

  if (
    !hasFormat
    || !Array.isArray(result)
    || typeof data !== 'object'
  ) {
    return data;
  }

  if (
    subType !== ResultRepresentationSubType.AREA_CHART
    && subType !== ResultRepresentationSubType.BAR_CHART
  ) {
    return data;
  }

  const formatter = (value: any, { seriesIndex }: { seriesIndex: number }) => {
    const seriesFormat = builderOptions.dataSets?.[seriesIndex]?.format;
    if (seriesFormat) {
      return Formatter.format(value, seriesFormat);
    }
    return value;
  };
  const hasSingleDataSetFormat = builderOptions.dataSets?.length === 1;
  const singleDataSetFormat = hasSingleDataSetFormat
    ? builderOptions.dataSets?.[0]?.format
    : undefined;

  return {
    ...data,
    options: {
      ...(data.options || {}),
      xaxis: {
        ...(data.options?.xaxis || {}),
        labels: {
          ...(data.options?.xaxis?.labels || {}),
          formatter: builderOptions.labelsFormat
            ? (value: any) => {
              if (builderOptions.labelsFormat) {
                // Convert to string first, because we want numbers to represent timestamps
                // for instance, the number 2023 is the year 2023, it's not a timestamp
                // otherwise it would result in the year 1970
                return Formatter.format(new Date(String(value)), builderOptions.labelsFormat);
              }

              return value;
            }
            : undefined,
        },
      },
      yaxis: {
        ...(data.options?.yaxis || {}),
        labels: {
          ...(data.options?.yaxis?.labels || {}),
          formatter: hasSingleDataSetFormat && singleDataSetFormat
            ? (value: any) => Formatter.format(value, singleDataSetFormat)
            : undefined,
        },
      },
      dataLabels: {
        ...(data.options?.dataLabels || {}),
        formatter,
      },
      tooltip: {
        ...(data.options?.tooltip || {}),
        y: {
          ...(data.options?.tooltip?.y || {}),
          formatter,
        },
      },
    },
  };
}

export default function ChartResultRepresentationView(props: ChartResultRepresentationViewProps) {
  const {
    resultRepresentation,
    resultRepresentationOverride,
    result,
    builderOptions,
    chartHeight = 350,
  } = props;

  const data = buildData(
    cloneData(resultRepresentation.data),
    resultRepresentation.subType,
    result,
    builderOptions,
  );
  const format = resultRepresentationOverride?.format;

  const chartType = data.type || data?.options?.chart?.type || 'area';
  const options = { ...(data.options || {}) };
  let { series } = data;

  if (format) {
    const guess = guessSeriesAndLabels(result);

    if (guess) {
      const { seriesField, labelsField } = guess;
      const seriesRule = getFormatRule(format, seriesField);
      const labelsRule = getFormatRule(format, labelsField);

      if (chartType === 'pie') {
        if (seriesField && seriesRule) {
          options.tooltip = options.tooltip || {};
          options.tooltip.y = {
            formatter: (value: any) => formatValueWithRule(value, seriesRule),
          };
        }

        if (labelsField && labelsRule) {
          options.labels = result.map(
            (item: any) => formatValueWithRule(item[labelsField], labelsRule),
          );
        }
      }

      if (chartType === 'treemap') {
        if (seriesField && seriesRule) {
          options.tooltip = options.tooltip || {};
          options.tooltip.y = {
            formatter: (value: any) => formatValueWithRule(value, seriesRule),
          };
        }

        if (labelsField && labelsRule) {
          series = series.map((item: any) => ({
            ...item,
            data: item.data.map((dataItem: any) => ({
              ...dataItem,
              x: formatValueWithRule(dataItem.x, labelsRule),
            })),
          }));
        }
      }

      if (chartType === 'line' || chartType === 'area' || chartType === 'bar') {
        if (labelsField && labelsRule) {
          options.xaxis.categories = result.map(
            (item: any) => formatValueWithRule(item[labelsField], labelsRule),
          );
        }

        if (seriesField && seriesRule) {
          options.yaxis = options.yaxis || {};
          options.yaxis.labels = options.yaxis.labels || {};
          options
            .yaxis
            .labels
            .formatter = (value: any) => formatValueWithRule(value, seriesRule);

          if (chartType === 'bar' && !options.dataLabels?.enabled) {
            options.dataLabels = options.dataLabels || {};
            options
              .dataLabels
              .formatter = (value: any) => formatValueWithRule(value, seriesRule);
          }
        }
      }
    }
  }

  // Sometimes the chart overflows (by a few px), so it's fine to hide the overflow
  return (
    <div style={{ overflow: 'hidden' }}>
      <Chart
        key={chartType}
        options={options}
        series={series}
        type={chartType}
        height={chartHeight}
        width="100%"
      />
    </div>
  );
}
