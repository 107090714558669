export default function EmptyChartIcon() {
  return (
    <svg
      width="220"
      height="148"
      viewBox="0 0 110 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110 72.4058V1.5942C110 0.713749 109.286 0 108.406 0L1.59421 0C0.713753 0 0 0.713748 0 1.5942V72.4058C0 73.2863 0.713753 74 1.59421 74H108.406C109.286 74 110 73.2863 110 72.4058Z"
        fill="#E6E6E6"
        fillOpacity="0.2"
      />
      <path
        d="M27.1016 42.7556L41.4494 35.3556L55.7972 42.7556L84.4929 27.9556"
        stroke="#ADADAD"
        strokeOpacity="0.3"
        strokeWidth="3.18841"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <rect x="8.9668" y="5.13889" width="12" height="2" rx="1" fill="#ADADAD" fillOpacity="0.3" />
      <rect
        x="8.9668"
        y="9.25"
        width="5.97826"
        height="2"
        rx="1"
        fill="#ADADAD"
        fillOpacity="0.15"
      />
    </svg>
  );
}
