import { atom, selector } from 'recoil';
import {
  DataSource,
  Organization,
  OrganizationUser,
  Project,
  Permissions,
  DEFAULT_PERMISSIONS,
  UserPreferences,
} from '../../api';
import { getActiveOrganization } from '../../organizations/utils/sync';

export interface GlobalState {
  organizations: Organization[];
  organization: Organization | null;
  projects: Project[];
  loadingOrganizationProjects: boolean;
  project: Project | null;
  dataSource: DataSource | null;
  dataSources: DataSource[];
  organizationUser: OrganizationUser | null;
  permissions: Permissions;
  loadingOrganizationUser: boolean;
  layoutPreferences: UserPreferences | null;
  loadingActiveProject: boolean;
}

export const globalAtom = atom<GlobalState>({
  key: 'global',
  default: {
    organization: getActiveOrganization(),
    project: null,
    organizations: [],
    projects: [],
    loadingOrganizationProjects: true,
    dataSource: null,
    dataSources: [],
    organizationUser: null,
    permissions: DEFAULT_PERMISSIONS,
    loadingOrganizationUser: true,
    layoutPreferences: null,
    loadingActiveProject: true,
  },
  effects: [
    ({ onSet, setSelf }) => {
      // Update projectDataSource when the state changes
      onSet((newValue) => {
        const { project, dataSources, organization } = newValue;
        if (project) {
          const dataSourceId = project.defaultDataSourceId || organization?.defaultDataSourceId;

          if (dataSourceId) {
            const projectDataSource = dataSources.find((ds) => ds._id === dataSourceId) || null;
            setSelf({ ...newValue, dataSource: projectDataSource });
          }
        }
      });
    },
  ],
});

export const loadingOrganizationUserSelector = selector({
  key: 'loading-organization-user',
  get: async ({ get }) => get(globalAtom).loadingOrganizationUser,
});
