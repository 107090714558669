// eslint-disable-next-line max-classes-per-file
import { ResultRepresentation, ResultRepresentationSubType } from '../../../api';
import ResultRepresentationManager from './ResultRepresentationManager';
import NumberRepresentationManager from './NumberRepresentationManager';
import StringRepresentationManager from './StringRepresenationManager';
import DateRepresentationManager from './DateRepresentationManager';
import PieChartResultRepresentationManager from './PieChartResultRepresentationManager';
import BarChartResultRepresentationManager from './BarChartResultRepresentationManager';
import TreeMapChartRepresentationManager from './TreeMapChartRepresentationManager';
import AreaChartResultRepresentationManager from './AreaChartResultRepresentationManager';
import LineChartResultRepresentationManager from './LineChartResultRepresentationManager';
import HeatmapResultRepresentationManager from './HeatmapResultRepresentationManager';
import TableResultRepresentationManager from './TableResultRepresentationManager';
import ScatterChartResultRepresentationManager from './ScatterChartResultRepresentationManager';
import RadarChartResultRepresentationManager from './RadarChartResultRepresentationManager';

class GenericManager extends ResultRepresentationManager {
  transformers = [];
}

class ResultRepresentationManagerFactory {
  public static createManager(
    representation: ResultRepresentation,
  ): ResultRepresentationManager {
    switch (representation?.subType) {
      case ResultRepresentationSubType.NUMBER:
        return new NumberRepresentationManager(representation);
      case ResultRepresentationSubType.STRING || ResultRepresentationSubType.TEXT:
        return new StringRepresentationManager(representation);
      case ResultRepresentationSubType.DATE:
        return new DateRepresentationManager(representation);
      case ResultRepresentationSubType.PIE_CHART:
        return new PieChartResultRepresentationManager(representation);
      case ResultRepresentationSubType.BAR_CHART:
        return new BarChartResultRepresentationManager(representation);
      case ResultRepresentationSubType.TREE_MAP_CHART:
        return new TreeMapChartRepresentationManager(representation);
      case ResultRepresentationSubType.AREA_CHART:
        return new AreaChartResultRepresentationManager(representation);
      case ResultRepresentationSubType.LINE_CHART:
        return new LineChartResultRepresentationManager(representation);
      case ResultRepresentationSubType.HEATMAP_CHART:
        return new HeatmapResultRepresentationManager(representation);
      case ResultRepresentationSubType.TABLE:
        return new TableResultRepresentationManager(representation);
      case ResultRepresentationSubType.SCATTER_CHART:
        return new ScatterChartResultRepresentationManager(representation);
      case ResultRepresentationSubType.RADAR_CHART:
        return new RadarChartResultRepresentationManager(representation);
      default:
        return new GenericManager(representation);
    }
  }
}

export default ResultRepresentationManagerFactory;
