import {
  Button, Card, Modal, Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import api, { DataSource, DataSourceType } from '../../api';
import useModal from '../../common/hooks/useModal';
import useRequest from '../../common/hooks/useRequest';

interface DataSourceCardProps extends Pick<
DataSource, 'name' | 'type' | 'isDefault'
> {
  id: DataSource['_id'];
  onDeleteSuccess?: (dataSource: DataSource) => void;
}

export const TYPE_LABELS: Record<DataSourceType, string> = {
  [DataSourceType.MONGODB]: 'MongoDB',
  [DataSourceType.POSTGRESQL]: 'PostgreSQL',
  [DataSourceType.MYSQL]: 'MySQL',
  [DataSourceType.MSSQL]: 'MSSQL (SQL Server)',
  [DataSourceType.AIRTABLE]: 'Airtable',
};

export default function DataSourceCard(props: DataSourceCardProps) {
  const {
    id, name, type, isDefault, onDeleteSuccess,
  } = props;

  const deleteModal = useModal();
  const deleteRequest = useRequest<DataSource>(
    async () => await api.dataSources.remove(id) as unknown as DataSource,
    {
      onSuccess: (response) => {
        if (onDeleteSuccess) {
          onDeleteSuccess(response);
        }

        deleteModal.close();
      },
    },
  );

  return (
    <Card>
      <div className="d-flex justify-content-between">
        <h3 className="m-0">{name}</h3>
        <p className="m-0">{TYPE_LABELS[type]}</p>
      </div>
      <div className="d-flex align-items-center mt-2">
        <div className="flex-grow-1">
          {
            isDefault && (
              <Tag color="geekblue">Default</Tag>
            )
          }
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <Link to={id}>
            <Button size="small">Edit</Button>
          </Link>
          <Button size="small" onClick={deleteModal.open} danger>Delete</Button>
        </div>
      </div>
      <Modal
        title="Are you sure?"
        okButtonProps={{
          danger: true,
          loading: deleteRequest.loading,
        }}
        okText="Delete"
        {...deleteModal.props}
        onOk={deleteRequest.submit}
      >
        This is an irreversible action
      </Modal>
    </Card>
  );
}
