import { Button, Form, Select } from 'antd';
import { useRecoilValue } from 'recoil';
import { Query } from '../../api';
import { globalAtom } from '../../common/state/global-state';

export type QuerySettingsConfig = Partial<Pick<Query, 'dataSourceId'>>;

export interface QuerySettingsProps {
  value: QuerySettingsConfig;
  onChange: (value: QuerySettingsConfig) => void;
}

export default function QuerySettings(props: QuerySettingsProps) {
  const { value, onChange } = props;
  const { dataSources } = useRecoilValue(globalAtom);

  return (
    <Form<QuerySettingsConfig>
      initialValues={{ ...value }}
      onFinish={onChange}
      layout="vertical"
    >
      <Form.Item
        label="Data Source"
        name="dataSourceId"
      >
        <Select
          placeholder="Use project data source"
          options={[
            ...dataSources.map((dataSource) => ({
              label: dataSource.name,
              value: dataSource._id,
            })),
          ]}
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Save</Button>
      </Form.Item>
    </Form>
  );
}
