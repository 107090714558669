import PieChartToBarChartTransformer from '../transformers/PieChartToBarChartTransformer';
import ResultRepresentationManager from './ResultRepresentationManager';
import PieChartToTreeMapTransformer from '../transformers/PieChartToTreeMapTransformer';
import PieChartToTableTransformer from '../transformers/PieChartToTableTransformer';

class PieChartResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new PieChartToBarChartTransformer(this.representation),
    new PieChartToTreeMapTransformer(this.representation),
    new PieChartToTableTransformer(this.representation),
  ];
}

export default PieChartResultRepresentationManager;
