import React from 'react';
import { useRecoilState } from 'recoil';
import AdvancedSelect, { Option } from '../../../common/components/AdvancedSelect';
import dbaqlAtom from '../../state';
import { DataSourceTableConfig } from '../../../api';

interface TableSelectProps {
  filter?: (table: DataSourceTableConfig) => boolean;
}

export default function TableSelect(props: TableSelectProps) {
  const { filter } = props;
  const [{ table, schema }, setState] = useRecoilState(dbaqlAtom);

  const options: Option[] = [];

  if (schema) {
    schema.tables.forEach((tableItem) => {
      if (!filter || filter(tableItem)) {
        options.push({
          label: tableItem.tableName,
          value: tableItem.tableName,
          subLabel: tableItem.description,
        });
      }
    });
  }

  return (
    <AdvancedSelect
      value={table}
      placeholder="Select a table"
      onChange={(newValue) => {
        setState((prev) => ({
          ...prev,
          table: newValue,
        }));
      }}
      options={options}
      style={{ flex: 1 }}
      showSearch
      allowClear
      filterOption={(inputValue, option) => {
        if (!option) {
          return false;
        }

        return String(option?.title).toLowerCase().includes(inputValue.toLowerCase());
      }}
    />
  );
}
