import React from 'react';
import {
  Button, ButtonProps, Dropdown,
} from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import {
  ResultRepresentation,
  ResultRepresentationSubType,
} from '../../../api';
import ResultRepresentationIcon from '../ResultRepresentationIcon';
import ResultRepresentationSubTypeIcon from '../ResultRepresentationSubTypeIcon';
import ResultRepresentationHelper from '../../../results/utils/ResultRepresentationHelper';

interface ResultRepresentationSelectButtonProps extends Partial<Omit<ButtonProps, 'onChange'>> {
  resultRepresentation: ResultRepresentation;
  subTypeOverride?: ResultRepresentation['subType'];
  onChange?: (subType: ResultRepresentationSubType) => void;
  onCustomCodeSelect?: () => void;
}

export default function ResultRepresentationSelectButton(
  props: ResultRepresentationSelectButtonProps,
) {
  const {
    resultRepresentation,
    onChange,
    subTypeOverride,
    onCustomCodeSelect,
    ...buttonProps
  } = props;
  const compatibleSubTypes: ResultRepresentationSubType[] = ResultRepresentationHelper
    .getCompatibleSubTypes(resultRepresentation);

  const items: ItemType[] = compatibleSubTypes.map((subType) => {
    const config = ResultRepresentationHelper.getSubTypeConfig(subType);

    return {
      key: config.title,
      label: config.title,
      icon: <ResultRepresentationSubTypeIcon subType={subType} />,
      onClick: () => {
        if (onChange) {
          onChange(subType);
        }
      },
    };
  });

  if (onCustomCodeSelect) {
    items.push({
      key: 'Custom Code',
      label: 'Custom Code',
      icon: <ResultRepresentationSubTypeIcon subType={ResultRepresentationSubType.CUSTOM_CODE} />,
      onClick: onCustomCodeSelect,
    });
  }

  if (items.length <= 1) {
    return null;
  }

  return (
    <Dropdown menu={{ items }}>
      <Button
        type="text"
        onClick={() => {}}
        icon={(<ResultRepresentationIcon representation={resultRepresentation} />)}
        {...buttonProps}
      />
    </Dropdown>
  );
}
