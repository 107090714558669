import {
  Badge, Button, Card, Drawer, Dropdown, Input, InputRef, message, Modal, notification, Tooltip,
} from 'antd';
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import {
  ApartmentOutlined,
  ArrowRightOutlined,
  BellOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  FormatPainterOutlined,
  FullscreenOutlined,
  HighlightOutlined,
  InfoCircleOutlined,
  LayoutOutlined,
  LoadingOutlined,
  MoreOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import api, {
  ResultRepresentationSubType, Widget, QueryExecutionCalculationMethod, ParameterData,
} from '../../../api';
import QueryExecutionView from '../../../query-executions/components/QueryExecutionView';
import { timeAgo } from '../../../common/utils/dates';
import DBAQLInfoButton from '../../../common/components/DBAQLInfoButton';
import ResultRepresentationSelectButton from '../../../query-executions/components/ResultRepresentationSelectButton';
import DownloadExecutionButton from '../../../query-executions/components/DownloadExecutionButton';
import QueryExecutionInfoDrawer from '../../../query-executions/components/QueryExecutionInfoDrawer';
import WidgetResultRepresentationCustomizer from '../WidgetResultRepresentationCustomizer';
import QueryExecutionParametersEditor from '../../../parameters/components/QueryExecutionParametersEditor';
import DashboardParameterChangeEvent, {
  DashboardParameterChangeEventData,
} from '../../../parameters/utils/DashboardParameterChangeEvent';
import Spin from '../../../common/components/Spin';
import ResultRepresentationFormatSetup from '../../../results/components/ResultRepresentationFormatSetup';
import ResultRepresentationFilterSetup from '../../../results/components/ResultRepresentationFilterSetup';

interface WidgetCardProps extends Pick<
Widget,
'_id'
| 'title'
| 'execution'
| 'alertsCount'
| 'resultRepresentationSubTypeOverride'
| 'resultRepresentation'
| 'resultRepresentations'
| 'resultRepresentationIndex'
| 'updatedAt'
> {
  onDeleteSuccess?: (id: Widget['_id']) => void;
  onRenameSuccess?: (id: Widget['_id'], title: Widget['title']) => void;
  onEditClick?: () => void;
  onUpdateSuccess?: (widget: Widget) => void;
  onPartialUpdateSuccess?: (widget: Partial<Widget>) => void;
  onAlertsClick?: (id: Widget['_id']) => void;
  onEmbedWidgetClick?: (id: Widget['_id']) => void;
  onMoveWidgetClick?: (id: Widget['_id']) => void;
  embedded?: boolean;
  onClone?: (widget: Widget) => void;
  onCustomCodeResultRepresentationSubTypeSelect?: () => void;
}

type ItemType = {
  key: string;
  title: string;
  label: ReactNode;
  icon: ReactNode;
  onClick: () => void;
};

function getTitle(defaultTitle: string, parameters?: ParameterData[]): string {
  if (!parameters) {
    return defaultTitle;
  }

  let title = defaultTitle;

  try {
    parameters.forEach((parameter) => {
      title = title.replace(`{{${parameter.name}}}`, String(parameter.value));
    });
  } catch {
    // Ignore
  }

  return title;
}

export default function WidgetCard(props: WidgetCardProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _id,
    title,
    resultRepresentationSubTypeOverride,
    execution,
    alertsCount,
    onDeleteSuccess,
    onRenameSuccess,
    onEditClick,
    onUpdateSuccess,
    onPartialUpdateSuccess,
    onAlertsClick,
    onEmbedWidgetClick,
    onMoveWidgetClick,
    embedded = false,
    resultRepresentation,
    resultRepresentations,
    updatedAt,
    onClone,
    onCustomCodeResultRepresentationSubTypeSelect,
  } = props;
  const [mouseOver, setMouseOver] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [editing, setEditing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [customizerOpen, setCustomizerOpen] = useState(false);
  const [showCustomizerContent, setShowCustomizerContent] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showFullScreenContent, setShowFullScreenContent] = useState(false);
  const [parametersEditorOpen, setParametersEditorOpen] = useState(false);
  const [parameters, setParameters] = useState<ParameterData[]>(execution?.parameters || []);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [
    resultRepresentationFormatSetupOpen,
    setResultRepresentationFormatSetupOpen,
  ] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [savingFormat, setSavingFormat] = useState(false);
  const editInputRef = useRef<InputRef>(null);

  const [savingFilter, setSavingFilter] = useState(false);

  const propsSubTypeOverride = resultRepresentationSubTypeOverride
    || execution?.resultRepresentationOverride?.subType;

  const [subTypeOverride, setSubTypeOverride] = useState<ResultRepresentationSubType | undefined>(
    propsSubTypeOverride,
  );

  useEffect(() => {
    setSubTypeOverride(propsSubTypeOverride);
  }, [propsSubTypeOverride]);

  useEffect(() => {
    if (customizerOpen) {
      setTimeout(() => {
        setShowCustomizerContent(true);
      }, 50);
    } else {
      setShowCustomizerContent(false);
    }
  }, [customizerOpen]);

  useEffect(() => {
    setParameters(execution?.parameters || []);
  }, [JSON.stringify(execution?.parameters || [])]);

  useEffect(() => {
    setSubTypeOverride(resultRepresentationSubTypeOverride);
  }, [resultRepresentationSubTypeOverride]);

  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);

  const onRefresh = async (newParameters?: ParameterData[], silent?: boolean) => {
    let refreshParameters: ParameterData[] = [...(newParameters || parameters || [])];

    if (newParameters) {
      setParameters(newParameters);
    }

    setRefreshing(true);

    if (refreshParameters) {
      const parametersWithTemplate = refreshParameters
        .filter((parameter) => parameter.templateId);

      if (parametersWithTemplate.length > 0) {
        // Fetch global values
        const response = await api.parameters.find({
          query: {
            _id: {
              $in: parametersWithTemplate.map((parameter) => parameter.templateId),
            },
            $limit: parametersWithTemplate.length,
          },
        });

        response.data.forEach((item) => {
          refreshParameters = refreshParameters.map((param) => {
            if (param.templateId !== item._id) {
              return param;
            }

            return {
              ...param,
              value: param.overwriteTemplateValue ? param.value : item.value,
            };
          });
        });

        setParameters(refreshParameters);
      }
    }

    try {
      if (!silent) {
        message.info({
          content: 'Refreshing widget, please wait',
        });
      }

      const response = await api.widgets.refresh({ id: _id, parameters: refreshParameters });

      if (onUpdateSuccess) {
        onUpdateSuccess(response);
      }

      if (!silent) {
        message.success({
          content: 'Widget refreshed',
        });
      }
    } catch (e) {
      message.error({
        content: 'Failed to refresh widget',
        type: 'error',
      }).then();
    }
    setRefreshing(false);
  };

  useEffect(() => {
    if (editing && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editing, Boolean(editInputRef.current)]);

  const externalParams = (execution?.parameters?.filter((p) => Boolean(p.templateId)) || []);
  const hasExternalParams = externalParams.length > 0;

  useEffect(() => {
    if (hasExternalParams) {
      const handler = (parameter: DashboardParameterChangeEventData) => {
        const parameterExists = externalParams.find((p) => p.templateId === parameter._id);

        if (parameterExists && !parameterExists.overwriteTemplateValue) {
          onRefresh(
            parameters.map((p) => {
              if (p.templateId === parameter._id) {
                return {
                  ...p,
                  value: parameter.value,
                };
              }

              return p;
            }),
            true,
          );
        }
      };
      DashboardParameterChangeEvent.subscribe(handler);

      return () => {
        DashboardParameterChangeEvent.unsubscribe(handler);
      };
    }

    return () => {};
  }, [hasExternalParams, parameters]);

  const onSave = () => {
    setEditing(false);
    if (onRenameSuccess) {
      onRenameSuccess(_id, newTitle);
    }
    api.widgets.patch(_id, { title: newTitle }).catch(() => {
      message.open({
        content: 'Failed to rename widget',
        type: 'error',
      }).then();
      setNewTitle(title);
    });
  };

  const onDelete = async () => {
    await api.widgets.remove(_id);

    if (onDeleteSuccess) {
      onDeleteSuccess(_id);
    }
  };

  const items: ItemType[] = [
    {
      key: 'customizer',
      title: 'Customize',
      label: 'Customize',
      icon: <HighlightOutlined />,
      onClick: () => { setCustomizerOpen(true); },
    },
  ];

  if (onEmbedWidgetClick) {
    items.push({
      key: 'embed',
      title: 'Embed',
      label: 'Embed',
      icon: <LayoutOutlined />,
      onClick: () => { onEmbedWidgetClick(_id); },
    });
  }

  if (onMoveWidgetClick) {
    items.push({
      key: 'move',
      title: 'Move',
      label: 'Move',
      icon: <ArrowRightOutlined style={{ transform: 'rotate(-45deg)' }} />,
      onClick: () => { onMoveWidgetClick(_id); },
    });
  }

  if (onClone) {
    items.push({
      key: 'clone',
      title: 'Clone',
      label: 'Clone',
      icon: cloneLoading ? <LoadingOutlined /> : <CopyOutlined />,
      onClick: () => {
        if (!cloneLoading) {
          setCloneLoading(true);
          api.widgets.clone({ id: _id }).then((widget) => {
            onClone(widget);
            message.success('Widget cloned!');
          }).finally(() => {
            setCloneLoading(false);
          });
        }
      },
    });
  }

  if ((onAlertsClick || (alertsCount && alertsCount > 0))) {
    items.push({
      key: 'alerts',
      title: 'Alerts',
      label: (
        <Badge count={alertsCount} size="small">
          Alerts
        </Badge>
      ),
      icon: <BellOutlined />,
      onClick: () => {
        if (onAlertsClick) {
          onAlertsClick(_id);
        }
      },
    });
  }

  if (execution) {
    items.push({
      key: 'parameters',
      title: 'Parameters',
      label: 'Parameters',
      icon: <ApartmentOutlined />,
      onClick: () => { setParametersEditorOpen(true); },
    });
  }

  items.push({
    key: 'result-representation-format-setup',
    title: 'Format',
    label: 'Format',
    icon: <FormatPainterOutlined />,
    onClick: () => { setResultRepresentationFormatSetupOpen(true); },
  });

  if (Array.isArray(execution?.result)) {
    items.push({
      key: 'result-representation-filter-setup',
      title: 'Filter',
      label: 'Filter',
      icon: <FilterOutlined />,
      onClick: () => { setFilterDrawerOpen(true); },
    });
  }

  const representation = execution ? (
    <QueryExecutionView
      key={`${String(updatedAt)}-${isFullScreen ? 'full-screen' : 'normal'}`}
      id={execution._id}
      type={execution.type}
      clarification={execution.clarification}
      resultType={execution.resultType}
      result={execution.result}
      responseText={execution.responseText}
      status={execution.status}
      resultRepresentation={resultRepresentation}
      resultRepresentationOverride={execution.resultRepresentationOverride}
      calculationMethod={execution.calculationMethod}
      mode={execution.mode}
      details={execution.details}
      prompt={execution.prompt}
      errorReason={execution.errorReason}
      subTypeOverride={subTypeOverride}
      onOverrideChange={(override) => {
        if (onPartialUpdateSuccess) {
          onPartialUpdateSuccess({
            _id,
            execution: {
              ...execution,
              resultRepresentationOverride: {
                ...execution.resultRepresentationOverride,
                ...override,
              },
            },
          });
        }
        api.queryExecutions.patch(execution._id, {
          resultRepresentationOverride: {
            ...execution?.resultRepresentationOverride,
            ...override,
          },
        }).then(() => {
          if (onUpdateSuccess) {
            api.widgets.get(_id).then(onUpdateSuccess);
          }
        });
      }}
      hideDbaqlButton
      hideExplanation
      isInsightFlowQuery={execution.isInsightFlowQuery}
      insightFlowQueryBuilderData={execution.insightFlowQueryBuilderData}
      resultRepresentationBuilderOptions={execution.resultRepresentationBuilderOptions}
      totalCredits={execution.totalCredits}
      onTablePageChange={(page) => {
        if (execution?.isInsightFlowQuery && execution?.insightFlowQueryBuilderData) {
          if (onPartialUpdateSuccess) {
            onPartialUpdateSuccess({
              _id,
              execution: {
                ...execution,
                insightFlowQueryBuilderData: {
                  ...execution.insightFlowQueryBuilderData,
                  page,
                },
              },
            });
          }
          api.queryExecutions.patch(execution._id, {
            insightFlowQueryBuilderData: {
              ...execution?.insightFlowQueryBuilderData,
              page,
            },
          }).then(() => {
            if (onUpdateSuccess) {
              api.widgets.get(_id).then(onUpdateSuccess);
            }
          });
        }
      }}
    />
  ) : null;

  return (
    <>
      <Card
        title={(
          <span className="d-flex align-items-center drag-handle" style={{ cursor: !embedded ? 'move' : 'initial' }}>
            {
            !editing ? (
              <>
                <span>
                  {getTitle(newTitle, parameters)}
                </span>
                {
                  mouseOver && onRenameSuccess && (
                    <Button
                      key="edit-button"
                      size="small"
                      className="ms-2"
                      onClick={() => { setEditing(true); }}
                      ghost
                    >
                      <EditOutlined />
                    </Button>
                  )
                }
              </>
            ) : (
              <>
                <Input
                  ref={editInputRef}
                  size="small"
                  value={newTitle}
                  onChange={(e) => { setNewTitle(e.target.value); }}
                  onPressEnter={onSave}
                  onKeyUp={(e) => {
                    if (e.key === 'Escape') {
                      setEditing(false);
                      setNewTitle(title);
                    }
                  }}
                />
                <Button
                  key="save-button"
                  size="small"
                  className="ms-2"
                  onClick={onSave}
                  ghost
                >
                  <CheckOutlined />
                </Button>
                <Button
                  key="cancel-button"
                  size="small"
                  className="me-2"
                  onClick={() => {
                    setEditing(false);
                    setNewTitle(title);
                  }}
                  ghost
                >
                  <CloseOutlined />
                </Button>
              </>
            )
          }
          </span>
        )}
        bordered
        size="small"
        onMouseEnter={() => {
          if (!embedded) {
            setMouseOver(true);
          }
        }}
        onMouseLeave={() => {
          if (!embedded) {
            setMouseOver(false);
            setEditing(false);
            setNewTitle(title);
          }
        }}
        extra={mouseOver && (
          <div className="d-flex gap-2">
            {
              onEditClick && (
                <Button size="small" onClick={onEditClick}>Edit</Button>
              )
            }
            <Tooltip title="Full Screen">
              <Button
                size="small"
                onClick={() => {
                  setIsFullScreen(true);
                  // Add a small delay for the drawer animation to finish
                  // otherwise some of the charts will not render properly
                  setTimeout(() => {
                    setShowFullScreenContent(true);
                  }, 1000);
                }}
              >
                <FullscreenOutlined />
              </Button>
            </Tooltip>
            {
              onDeleteSuccess && (
                <Tooltip title="Delete Widget">
                  <Button
                    size="small"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to delete this widget?',
                        icon: <ExclamationCircleOutlined />,
                        content: 'This action cannot be undone',
                        okText: 'Delete Widget',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        onOk: onDelete,
                      });
                    }}
                    danger
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              )
            }
          </div>
        )}
        className="h-100 d-flex flex-column"
        bodyStyle={{ flex: 1, overflow: 'auto' }}
      >
        {
          execution && (
            <div className="h-100 d-flex flex-column justify-content-between">
              <div className="flex-grow-1" style={{ overflowX: 'auto' }}>
                {representation}
              </div>
              <div
                className="mt-2"
                style={{
                  height: 22,
                }}
              >
                {
                  !mouseOver ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <small className="text-muted">
                        <ClockCircleOutlined />
                        {' '}
                        {timeAgo(new Date(execution.createdAt), true)}
                      </small>
                      {
                        execution.calculationMethod === QueryExecutionCalculationMethod.DBAQL && (
                          <DBAQLInfoButton shortVersion iconOnly />
                        )
                      }
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <div>
                        {
                          execution.calculationMethod === QueryExecutionCalculationMethod.DBAQL && (
                            <DBAQLInfoButton shortVersion iconOnly />
                          )
                        }
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Tooltip title="Click for details">
                          <Button
                            type="text"
                            size="small"
                            onClick={() => { setInfoDrawerOpen(true); }}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        </Tooltip>
                        {
                          !embedded && execution.resultRepresentation && (
                            <ResultRepresentationSelectButton
                              resultRepresentation={execution.resultRepresentation}
                              subTypeOverride={subTypeOverride}
                              onChange={(newSubType) => {
                                setSubTypeOverride(newSubType);
                                if (onPartialUpdateSuccess) {
                                  onPartialUpdateSuccess({
                                    _id,
                                    execution: {
                                      ...execution,
                                      resultRepresentationOverride: {
                                        ...execution.resultRepresentationOverride,
                                        subType: newSubType,
                                      },
                                    },
                                  });
                                }
                                api.queryExecutions.patch(execution._id, {
                                  resultRepresentationOverride: {
                                    ...execution?.resultRepresentationOverride,
                                    subType: newSubType,
                                  },
                                }).then(() => {
                                  if (onUpdateSuccess) {
                                    api.widgets.get(_id).then(onUpdateSuccess);
                                  }
                                });
                              }}
                              onCustomCodeSelect={onCustomCodeResultRepresentationSubTypeSelect}
                            />
                          )
                        }
                        {
                          !embedded && execution.result && (
                            <DownloadExecutionButton
                              result={execution.result}
                              title={title}
                            />
                          )
                        }
                        {
                          onUpdateSuccess && (
                            <Tooltip title="Refresh Widget">
                              <Button
                                onClick={() => { onRefresh(); }}
                                loading={refreshing}
                                disabled={refreshing}
                                type="text"
                                size="small"
                                icon={<ReloadOutlined />}
                              />
                            </Tooltip>
                          )
                        }
                        <Dropdown
                          menu={{ items }}
                          placement="bottomRight"
                          overlayStyle={{
                            marginTop: 10,
                          }}
                        >
                          <Button
                            type="text"
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          )
        }
        {
          execution && (
            <QueryExecutionInfoDrawer
              open={infoDrawerOpen}
              onClose={() => { setInfoDrawerOpen(false); }}
              execution={execution}
            />
          )
        }
        {
          resultRepresentation && (
            <Drawer
              title="Customize Widget"
              open={customizerOpen}
              onClose={() => { setCustomizerOpen(false); }}
              width={1300}
            >
              {
                showCustomizerContent && (
                  <WidgetResultRepresentationCustomizer
                    key={String(updatedAt)}
                    widgetId={_id}
                    representation={resultRepresentation}
                    representations={resultRepresentations}
                    onWidgetChange={(result) => {
                      if (onUpdateSuccess) {
                        onUpdateSuccess(result);
                      }
                    }}
                  />
                )
              }
            </Drawer>
          )
        }
      </Card>
      {
        isFullScreen && (
          <Drawer
            open
            onClose={() => {
              setIsFullScreen(false);
              // Add a small delay for the drawer animation to finish
              setTimeout(() => {
                setShowFullScreenContent(false);
              }, 1000);
            }}
            width="100%"
            title={title}
          >
            {showFullScreenContent && representation}
            {isFullScreen && !showFullScreenContent && (<div className="d-flex justify-content-center"><Spin /></div>)}
          </Drawer>
        )
      }
      {
        parametersEditorOpen && (
          <Drawer
            open
            onClose={() => { setParametersEditorOpen(false); }}
            title="Parameters"
          >
            <QueryExecutionParametersEditor
              parameters={parameters}
              onChange={setParameters}
            />
            <div className="d-flex justify-content-center mt-4">
              <Button
                type="primary"
                onClick={() => {
                  onRefresh();
                  setParametersEditorOpen(false);
                }}
              >
                Save & Refresh
              </Button>
            </div>
          </Drawer>
        )
      }
      {
        resultRepresentationFormatSetupOpen && (
          <Drawer
            open
            onClose={() => { setResultRepresentationFormatSetupOpen(false); }}
            title="Format"
            width={600}
          >
            <ResultRepresentationFormatSetup
              resultRepresentation={resultRepresentation}
              rawData={execution?.result}
              loading={savingFormat}
              value={execution?.resultRepresentationOverride?.format}
              onChange={(format) => {
                if (execution) {
                  setSavingFormat(true);
                  if (onPartialUpdateSuccess) {
                    onPartialUpdateSuccess({
                      _id,
                      execution: {
                        ...execution,
                        resultRepresentationOverride: {
                          ...execution.resultRepresentationOverride,
                          format,
                        },
                      },
                    });
                  }
                  api.queryExecutions.patch(execution._id, {
                    resultRepresentationOverride: {
                      ...execution?.resultRepresentationOverride,
                      format,
                    },
                  }).then(() => {
                    if (onUpdateSuccess) {
                      api.widgets.get(_id).then(onUpdateSuccess);
                    }
                    setSavingFormat(false);
                    setResultRepresentationFormatSetupOpen(false);
                  }).catch(() => {
                    setSavingFormat(false);
                    notification.error({
                      message: 'Failed to save format',
                      description: 'An error occurred while saving the format',
                    });
                  });
                } else {
                  notification.error({
                    message: 'Failed to save format',
                    description: 'The widget does not have an execution',
                  });
                }
              }}
            />
          </Drawer>
        )
      }
      {
        filterDrawerOpen && (
          <Drawer
            title="Filters"
            open={filterDrawerOpen}
            onClose={() => { setFilterDrawerOpen(false); }}
            width={600}
          >
            <ResultRepresentationFilterSetup
              rawData={execution?.result}
              resultRepresentation={resultRepresentation}
              value={execution?.resultRepresentationOverride?.filter?.query}
              loading={savingFilter}
              onChange={(filter) => {
                if (execution) {
                  setSavingFilter(true);
                  if (onPartialUpdateSuccess) {
                    onPartialUpdateSuccess({
                      _id,
                      execution: {
                        ...execution,
                        resultRepresentationOverride: {
                          ...execution.resultRepresentationOverride,
                          filter,
                        },
                      },
                    });
                  }
                  api.queryExecutions.patch(execution._id, {
                    resultRepresentationOverride: {
                      ...execution?.resultRepresentationOverride,
                      filter,
                    },
                  }).then(() => {
                    setSavingFilter(false);
                    if (onUpdateSuccess) {
                      api.widgets.get(_id).then(onUpdateSuccess);
                    }
                    setFilterDrawerOpen(false);
                    notification.success({
                      message: 'Filters saved',
                      description: 'The filters have been saved',
                    });
                  }).catch(() => {
                    setSavingFilter(false);
                    notification.error({
                      message: 'Failed to save filter',
                      description: 'An error occurred while saving the filter',
                    });
                  });
                }
              }}
            />
          </Drawer>
        )
      }
    </>
  );
}
