import { useState } from 'react';
import { message, Segmented } from 'antd';
import AlertSetup, { AlertSetupProps } from './AlertSetup';
import WidgetAlertsList from './WidgetAlertsList';
import { Alert } from '../../api';

interface WidgetAlertsProps extends Pick<
AlertSetupProps,
'organizationId'
| 'widgetId'
| 'widgetUpdateInBackground'
| 'slackEnabled'
| 'resultRepresentationType'
> {
  onAlertCreated?: (alert: Alert) => void;
  onAlertDeleted?: (alert: Alert) => void;
}

export default function WidgetAlerts(props: WidgetAlertsProps) {
  const {
    organizationId, widgetId, widgetUpdateInBackground, slackEnabled,
    resultRepresentationType, onAlertCreated,
    onAlertDeleted,
  } = props;
  const [mode, setMode] = useState<string>('List');
  const [newAlerts, setNewAlerts] = useState<Alert[]>([]);

  return (
    <>
      <Segmented
        options={['List', 'Create']}
        value={mode}
        onChange={(newMode) => {
          setMode(String(newMode));
        }}
      />
      <div className="mt-4">
        <div className={mode === 'List' ? 'd-block' : 'd-none'}>
          <WidgetAlertsList
            widgetId={widgetId}
            extraAlerts={newAlerts}
            onAlertDeleted={(alert) => {
              setNewAlerts((prev) => prev.filter((item) => alert._id !== item._id));

              if (onAlertDeleted) {
                onAlertDeleted(alert);
              }
            }}
          />
        </div>
        <div className={mode === 'Create' ? 'd-block' : 'd-none'}>
          <AlertSetup
            organizationId={organizationId}
            widgetId={widgetId}
            widgetUpdateInBackground={widgetUpdateInBackground}
            resultRepresentationType={resultRepresentationType}
            slackEnabled={slackEnabled}
            onCreated={(alert) => {
              setNewAlerts((prev) => [alert, ...prev]);
              setMode('List');
              message.success('Alert created successfully');
              if (onAlertCreated) {
                onAlertCreated(alert);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
