import { Descriptions, Typography } from 'antd';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';

export default function OrganizationInfo() {
  const organization = useActiveOrganization();

  if (!organization) {
    return null;
  }

  return (
    <div>
      <Descriptions title="Organization Info" column={1} bordered>
        <Descriptions.Item label="Public Key">
          <Typography.Paragraph copyable className="m-0">
            {organization.publicKey}
          </Typography.Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Secret Key">
          <Typography.Paragraph copyable className="m-0">
            {organization.secretKey}
          </Typography.Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
