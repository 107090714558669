/* eslint-disable react/no-unknown-property */
import ReactHighlight, { HighlightProps as ReactHighlightProps } from 'react-highlight';
import { Button, message } from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';

interface HighlightProps extends ReactHighlightProps {
  style?: React.CSSProperties;
  onEditClick?: () => void;
}

export default function Highlight(props: HighlightProps) {
  const { style, children, onEditClick } = props;

  // @ts-ignore
  return (
    <div className="position-relative">
      <div
        style={style}
        className="ib-highlight-container"
      >
        <ReactHighlight {...props} />
      </div>
      {
        onEditClick && (
          <Button
            style={{
              position: 'absolute',
              top: 3,
              right: 46,
            }}
            type="primary"
            onClick={onEditClick}
            size="small"
          >
            <EditOutlined />
          </Button>
        )
      }
      <Button
        style={{
          position: 'absolute',
          top: 3,
          right: 12,
        }}
        type="primary"
        onClick={() => {
          navigator.clipboard.writeText(children as string);
          message.success('Copied to clipboard');
        }}
        size="small"
      >
        <CopyOutlined />
      </Button>
      {/*
      @ts-ignore */}
      <style jsx global>
        {`
        .ib-highlight-container pre {
          margin: 0;
        }
      `}
      </style>
    </div>
  );
}
