import { ResultRepresentation, Widget } from '../../api';

// eslint-disable-next-line import/prefer-default-export
export function getWidgetRepresentation(widget: Widget): ResultRepresentation | undefined {
  const {
    resultRepresentation, resultRepresentations, resultRepresentationIndex, execution,
  } = widget;

  if (resultRepresentation) {
    return resultRepresentation;
  }

  if (resultRepresentations && resultRepresentations.length > 0) {
    return resultRepresentations[resultRepresentationIndex || 0];
  }

  return execution?.resultRepresentation;
}
