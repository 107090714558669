export enum PlanBillingPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum PlanName {
  FREE = 'free',
  INDIVIDUAL = 'individual',
  STARTER = 'starter',
  PROFESSIONAL = 'professional',
  ENTERPRISE = 'enterprise',
  ENTERPRISE_TIER_1_RESELL = 'enterprise_tier_1_resell',
}

export interface PlanLimits {
  credits: number;
  maxUsers: number;
  maxDataSources: number;
  maxProjects: number;
  maxDashboards: number;
  maxWidgets: number;
  apiAccess?: boolean;
  fullyEmbedded?: boolean;
  whitelabel?: boolean;
  dataMonitoring?: boolean;
}

export default interface Plan extends PlanLimits {
  name: PlanName;
  price: number;
  yearlyPrice?: number;
  stripeProductId: string;
  stripeProductIdYearly: string;
  restrictToOrganizations?: string[];
}
