import { Button } from 'antd';
import { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import OrganizationMembers from '../components/OrganizationMembers';
import useModal from '../../common/hooks/useModal';
import OrganizationGeneralSettings from '../components/OrganizationGeneralSettings';
import useRole from '../../common/hooks/useRole';
import { OrganizationUserRole } from '../../api';
import usePermissions from '../../common/hooks/usePermissions';
import OrganizationBilling from '../../billing/components/OrganizationBilling';
import AppTabbedPage from '../../layout/components/AppTabbedPage';
import OrganizationUsage from '../../billing/components/OrganizationUsage';
import OrganizationIntegrations from '../components/OrganizationIntegrations';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import OrganizationInfo from '../components/OrganizationInfo';
import OrganizationAppSumoStacking from '../components/OrganizationAppSumoStacking';

export default function OrganizationSettingsPage() {
  const [searchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState(searchParams.has('payment-complete') ? 'billing' : 'general');
  const newMemberModal = useModal();
  const role = useRole();
  const permissions = usePermissions();
  const organization = useActiveOrganization();

  const extraActions = useCallback(() => {
    if (activeKey === 'members' && role.is(OrganizationUserRole.OWNER, OrganizationUserRole.ADMIN)) {
      return (
        <Button
          type="primary"
          onClick={newMemberModal.open}
          icon={<PlusOutlined />}
        >
          Invite New Member
        </Button>
      );
    }

    return null;
  }, [activeKey, role.role]);

  const items = [
    {
      key: 'info',
      label: 'Info',
      children: <OrganizationInfo />,
    },
    {
      key: 'general',
      label: 'General',
      children: <OrganizationGeneralSettings />,
    },
    {
      key: 'members',
      label: 'Members',
      children: <OrganizationMembers modal={newMemberModal} />,
    },
  ];

  if (permissions.organizations.billing) {
    items.push({
      key: 'billing',
      label: 'Billing',
      children: <OrganizationBilling />,
    });

    if (organization?.appSumoPlan) {
      items.push({
        key: 'app-sumo-stacking',
        label: 'AppSumo Stacking',
        children: <OrganizationAppSumoStacking />,
      });
    }
  }

  items.push({
    key: 'usage',
    label: 'Usage',
    children: <OrganizationUsage />,
  });

  if (organization) {
    items.push({
      key: 'integrations',
      label: 'Integrations',
      children: <OrganizationIntegrations organization={organization} />,
    });
  }

  return (
    <AppTabbedPage
      title="Organization Settings"
      allowed={permissions.organizations.write}
      contentPadding={0}
      items={items}
      activeKey={activeKey}
      onChange={setActiveKey}
      tabsProps={{
        tabBarExtraContent: extraActions(),
      }}
      syncToUrl
    />
  );
}
