import {
  Button, Col, Drawer, notification, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import api, {
  DataSource,
  DateFormat,
  Entity,
  EntityFieldType,
  FormatType,
  InsightFlowFilter,
  InsightFlowQueryBuilderData,
  Metric,
  Query,
  QueryExecutionMode,
  Segment,
} from '../../../api';
import useQuery from '../../../common/hooks/useQuery';
import {
  DateTrunc, FieldType, SortDirection,
} from '../../../dbaql-v2/types';
import useActiveProject from '../../../common/hooks/useActiveProject';
import { getDefaultValue } from '../../../dbaql-v2/components/InsightFlowDateFilter';
import CustomDateSelection from '../../../common/types/CustomDateSelection';
import WidgetSetup from '../../../widgets/components/WidgetSetup';
import NotFoundResult from '../../../common/components/NotFoundResult';
import InsightFlowAnalysisSetup, { AnalysisOptions } from './InsightFlowAnalysisSetup';
import InsightFlowVisualization from './InsightFlowVisualization';

interface InsightFlowBuilderProps {
  organizationId: Entity['organizationId'];
  projectId: Entity['projectId'];
}

function getDateTruncFormat(truncate: DateTrunc['truncate']): DateFormat['format'] {
  switch (truncate) {
    case 'month':
      return 'MMM YYYY';
    case 'year':
      return 'YYYY';
    default:
      return 'MMM DD, YYYY';
  }
}

function getDefaultSegmentation(entity?: Entity, truncate: DateTrunc['truncate'] = 'day'): Segment | undefined {
  if (!entity?.createdAtFieldName) {
    return undefined;
  }

  const createdAtField = entity.fields.find((field) => field.name === entity.createdAtFieldName);

  if (!createdAtField) {
    return undefined;
  }

  if (createdAtField.definition.type === EntityFieldType.COLUMN) {
    return {
      key: 'date',
      label: createdAtField.displayName,
      truncate,
      groupField: {
        type: FieldType.DATE_TRUNC,
        column: createdAtField.definition.column,
        as: `${createdAtField.definition.column}_${truncate}`,
        truncate,
      },
      sort: {
        [`${createdAtField.definition.column}_${truncate}`]: SortDirection.ASC,
      },
      dataSet: {
        title: createdAtField.displayName,
        valueFieldName: createdAtField.definition.column,
      },
      field: createdAtField,
      format: {
        type: FormatType.DATE,
        format: getDateTruncFormat(truncate),
      },
    };
  }

  return undefined;
}

function getDatePresetTruncate(value: CustomDateSelection): DateTrunc['truncate'] {
  const { from, to = dayjs() } = value;
  if (from && to) {
    const diff = dayjs(to).diff(dayjs(from), 'day');

    if (diff < 90) {
      return 'day';
    }

    if (diff < 730) {
      return 'month';
    }
  }

  return 'year';
}

export default function InsightFlowBuilder(props: InsightFlowBuilderProps) {
  const { organizationId, projectId } = props;

  const project = useActiveProject();

  const [response, loadingEntities] = useQuery(
    async () => api.entities.find({
      query: {
        $sort: {
          createdAt: -1,
        },
        organizationId,
        projectId,
      },
    }),
    [organizationId, projectId],
  );
  const entities: Entity[] = response?.data || [];
  const [entity, setEntity] = useState<Entity | undefined>(undefined);
  const [dataSource] = useQuery<DataSource>(
    async () => (entity ? api.dataSources.get(entity?.dataSourceId) : undefined),
    [entity?.dataSourceId],
  );
  const [segments, setSegments] = useState<Segment[]>([]);
  const [analysis, setAnalysis] = useState<AnalysisOptions>();
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [query, setQuery] = useState<Query>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(10);
  const [timeFilter, setTimeFilter] = useState<CustomDateSelection>(getDefaultValue());
  const [defaultSegment, setDefaultSegment] = useState<Segment | undefined>(
    getDefaultSegmentation(entity, getDatePresetTruncate(timeFilter)),
  );
  const [insightFlowFilters, setInsightFlowFilters] = useState<InsightFlowFilter[]>([]);
  const [saveWidgetDrawerVisible, setSaveWidgetDrawerVisible] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setInsightFlowFilters([]);
  }, [entity]);

  useEffect(() => {
    setDefaultSegment(getDefaultSegmentation(entity, getDatePresetTruncate(timeFilter)));
  }, [entity, timeFilter]);

  function buildQueryData(): InsightFlowQueryBuilderData | undefined {
    try {
      if (!entity) {
        return undefined;
      }

      if (!analysis) {
        return undefined;
      }

      if (analysis.minRequiredMetrics && metrics.length < analysis.minRequiredMetrics) {
        return undefined;
      }

      if (analysis.minRequiredSegments && segments.length < analysis.minRequiredSegments) {
        return undefined;
      }

      return {
        entityId: entity?._id,
        analysisType: analysis?.type,
        insightFlowFilters,
        timeFilter,
        limit,
        metrics,
        defaultSegment,
        segments,
        page,
      };
    } catch (e) {
      notification.error({
        // @ts-ignore
        message: e?.message || 'Something went wrong',
      });
      throw e;
    }
  }

  function buildQuery(): Partial<Query> | undefined {
    const queryData = buildQueryData();

    if (queryData) {
      return {
        insightFlowQueryBuilderData: buildQueryData(),
        isInsightFlowQuery: true,
      };
    }

    return undefined;
  }

  const onFinish = async () => {
    const newQuery = buildQuery();

    if (newQuery) {
      setError(false);
      setLoading(true);
      api.queries.create({
        mode: QueryExecutionMode.DBAQLV2,
        organizationId,
        projectId: project?._id,
        widgetId: project?._id,
        dataSourceId: dataSource?._id,
        disableAIExplanation: true,
        ...newQuery,
      }).then(setQuery)
        .catch(() => {
          setError(true);
        }).finally(() => { setLoading(false); });
    }
  };

  useEffect(() => {
    if (!analysis?.comingSoon) {
      onFinish();
    } else {
      setError(false);
    }
  }, [entity, analysis, metrics, segments, timeFilter, defaultSegment, insightFlowFilters, page]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {
          entities.length === 0 && !loadingEntities && (
            <div className="d-flex justify-content-center">
              <div className="mt-5">
                <NotFoundResult
                  title="No Entity Found"
                  subtitle="InsightFlow requires at least one entity to start building your flow. Please contact your developer or data engineer if you need help setting up the entities."
                  action={(
                    <Link to="../entities/create">
                      <Button type="primary" size="large">
                        Create Entity
                      </Button>
                    </Link>
                  )}
                />
              </div>
            </div>
          )
        }
        {
          entities.length > 0 && (
            <div className="d-flex gap-4">
              <div
                style={{
                  width: 300,
                  minWidth: 300,
                }}
              >
                {
                  response && (
                    <InsightFlowAnalysisSetup
                      entities={entities}
                      entity={entity}
                      setEntity={setEntity}
                      analysis={analysis}
                      setAnalysis={setAnalysis}
                      metrics={metrics}
                      setMetrics={setMetrics}
                      dataSource={dataSource}
                      segments={segments}
                      setSegments={setSegments}
                      setPage={setPage}
                    />
                  )
                }
              </div>
              <div style={{ flex: 1 }}>
                <InsightFlowVisualization
                  entity={entity}
                  query={query}
                  loading={loading}
                  analysis={analysis}
                  dataSource={dataSource}
                  insightFlowFilters={insightFlowFilters}
                  setInsightFlowFilters={setInsightFlowFilters}
                  entities={entities}
                  timeFilter={timeFilter}
                  setTimeFilter={setTimeFilter}
                  setSaveWidgetDrawerVisible={setSaveWidgetDrawerVisible}
                  setPage={setPage}
                  setQuery={setQuery}
                  error={error}
                />
              </div>
            </div>
          )
        }
      </Col>
      <Drawer
        open={saveWidgetDrawerVisible}
        onClose={() => setSaveWidgetDrawerVisible(false)}
        title="Save as Widget"
      >
        {
          query && (
            <WidgetSetup
              queryId={query?._id}
              projectId={query?.projectId}
              onSuccess={() => {
                setSaveWidgetDrawerVisible(false);
              }}
            />
          )
        }
      </Drawer>
    </Row>
  );
}
