import dayjs from 'dayjs';
import CustomDateSelection, { DatePreset } from '../types/CustomDateSelection';

function formatTimeAgo(value: number, unit: string, shortUnit?: string, short?: boolean) {
  if (short) {
    return `${value}${shortUnit} ago`;
  }

  const finalUnit = value !== 1 ? `${unit}s` : unit;
  return `${value} ${finalUnit} ago`;
}

// eslint-disable-next-line import/prefer-default-export
export function timeAgo(date: Date, short?: boolean): string {
  const now = new Date();
  // @ts-ignore
  const diffInSeconds = Math.floor((now - date) / 1000);
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;

  if (diffInSeconds < minute) {
    return formatTimeAgo(diffInSeconds, 'second', 'sec', short);
  }

  if (diffInSeconds < hour) {
    const minutes = Math.floor(diffInSeconds / minute);
    return formatTimeAgo(minutes, 'minute', 'min', short);
  }

  if (diffInSeconds < day) {
    const hours = Math.floor(diffInSeconds / hour);
    return formatTimeAgo(hours, 'hour', 'h', short);
  }

  if (diffInSeconds < month) {
    const days = Math.floor(diffInSeconds / day);
    return formatTimeAgo(days, 'day', 'd', short);
  }

  if (diffInSeconds < year) {
    const months = Math.floor(diffInSeconds / month);
    return formatTimeAgo(months, 'month', 'mo', short);
  }

  const years = Math.floor(diffInSeconds / year);
  return formatTimeAgo(years, 'year', 'y', short);
}

export function dayMonthYear(value: Date | string) {
  const date = new Date(value);
  // Return in format Mon 01, 2021
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function getLastYearPreset(): CustomDateSelection {
  return {
    preset: DatePreset.ONE_YEAR,
    label: '1Y',
    longLabel: 'Last year',
    from: dayjs().subtract(1, 'year'),
    to: dayjs(),
  };
}

export function getDatePresets() {
  const datePresets: CustomDateSelection[] = [
    {
      preset: DatePreset.ALL_TIME,
      label: 'All',
      longLabel: 'All time',
      from: undefined,
      to: undefined,
    },
    {
      preset: DatePreset.TODAY,
      label: 'Today',
      longLabel: 'Today',
      from: dayjs().startOf('day'),
      to: dayjs().endOf('day'),
    },
    {
      preset: DatePreset.LAST_24H,
      label: '24h',
      longLabel: 'Last 24 hours',
      from: dayjs().subtract(1, 'day'),
    },
    {
      preset: DatePreset.LAST_7D,
      label: '7d',
      longLabel: 'Last 7 days',
      from: dayjs().subtract(7, 'days'),
    },
    {
      preset: DatePreset.LAST_30D,
      label: '30d',
      longLabel: 'Last 30 days',
      from: dayjs().subtract(30, 'days'),
    },
    {
      preset: DatePreset.LAST_90D,
      label: '90d',
      longLabel: 'Last 90 days',
      from: dayjs().subtract(90, 'days'),
    },
    {
      preset: DatePreset.LAST_6M,
      label: '6m',
      longLabel: 'Last 6 months',
      from: dayjs().subtract(6, 'months'),
    },
    getLastYearPreset(),
  ];

  return datePresets;
}
