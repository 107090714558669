import { ResultRepresentationSubType, ResultRepresentation } from '../../../api';

abstract class ResultRepresentationTransformer {
  constructor(readonly resultRepresentation: ResultRepresentation) {}

  abstract from: ResultRepresentationSubType;

  abstract to: ResultRepresentationSubType;

  abstract transformData(): any;

  // eslint-disable-next-line class-methods-use-this
  isCompatible(): boolean {
    return true;
  }

  transform(): ResultRepresentation {
    return {
      ...this.resultRepresentation,
      subType: this.to,
      data: this.transformData(),
    };
  }
}

export default ResultRepresentationTransformer;
