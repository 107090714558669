import { atom } from 'recoil';
import { Organization, Project } from '../../api';

interface OnboardingAtomState {
  initialized: boolean;
  organization?: Organization | null;
  project?: Project | null;
}

const onboardingAtom = atom<OnboardingAtomState>({
  key: 'onboarding',
  default: {
    initialized: false,
  },
});

export default onboardingAtom;
