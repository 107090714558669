import { ReactNode } from 'react';

interface NotFoundResultProps {
  title: string;
  action?: ReactNode;
  subtitle?: string;
}

export default function NotFoundResult(props: NotFoundResultProps) {
  const { title, subtitle, action } = props;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img
        src="/img/empty-results.png"
        style={{ height: 200, width: 200 }}
        alt="Empty Box"
      />
      <h2>{title}</h2>
      {
        subtitle && (
          <p className="text-muted text-xl">{subtitle}</p>
        )
      }
      {action}
    </div>
  );
}
