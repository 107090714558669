import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface SpinProps {
  centered?: boolean;
}

export default function Spin(props: SpinProps) {
  const { centered } = props;

  return (
    <AntSpin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      style={centered ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      } : undefined}
    />
  );
}
