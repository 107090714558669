enum DeploymentMode {
  MANAGED = 'managed',
  SELF_HOSTED = 'self-hosted',
}

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = !IS_PRODUCTION;
const REACT_APP_DEPLOYMENT_MODE = process.env.REACT_APP_DEPLOYMENT_MODE || DeploymentMode.MANAGED;
export const IS_SELF_HOSTED = REACT_APP_DEPLOYMENT_MODE === DeploymentMode.SELF_HOSTED;

export const ENABLE_CRISP = false;
export const ENABLE_ZUPPORT = IS_PRODUCTION && !IS_SELF_HOSTED;
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3030';

export const REACT_APP_STATUS_CHECK_URL = process.env.REACT_APP_STATUS_CHECK_URL || '';
export const REACT_APP_ALLOWED_ORIGINS = (process.env.REACT_APP_ALLOWED_ORIGINS || '').split(',');
export const SKIP_FRONTEND_ORIGIN_VERIFICATION = true;
export const REACT_APP_VERIFY_BACKEND_ORIGIN = process.env.REACT_APP_VERIFY_BACKEND_ORIGIN === 'true';
