import {
  RefObject, useEffect, useRef, useState,
} from 'react';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

export default function useSize(): [Size, RefObject<HTMLDivElement>] {
  const ref = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<Size>({ width: undefined, height: undefined });

  const refSet = Boolean(ref.current);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };

    // Initial setting
    handleResize();

    setTimeout(handleResize, 1000);

    // Use MutationObserver to detect size change
    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [refSet]);

  return [size, ref];
}
