import ResultRepresentationManager from './ResultRepresentationManager';
import AreaChartToTableTransformer from '../transformers/AreaChartToTableTransformer';
import AreaChartToLineChartTransformer from '../transformers/AreaChartToLineChartTransformer';

class AreaChartResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new AreaChartToTableTransformer(this.representation),
    new AreaChartToLineChartTransformer(this.representation),
  ];
}

export default AreaChartResultRepresentationManager;
