import { Select } from 'antd';
import { useEffect, useState } from 'react';
import useQuery from '../../common/hooks/useQuery';
import api, { Dashboard } from '../../api';

interface SelectDashboardProps {
  value?: Dashboard['_id'];
  onChange?: (value: Dashboard['_id']) => void;
  projectId?: Dashboard['projectId'];
}

export default function SelectDashboard(props: SelectDashboardProps) {
  const { onChange, projectId, value } = props;
  const [search, setSearch] = useState('');
  const [response, loading] = useQuery(() => api.dashboards.find({
    query: {
      name: {
        $regex: search,
        $options: 'i',
      },
      projectId,
      $limit: 100,
    },
  }), [search, projectId]);
  const [dashboardId, setDashboardId] = useState<Dashboard['_id']>(value || '');

  useEffect(() => {
    setDashboardId(value || '');
  }, [value]);

  return (
    <Select
      showSearch
      placeholder="Select a dashboard"
      options={(response?.data || []).map((item) => ({
        value: item._id,
        label: item.name,
      }))}
      loading={loading}
      onSearch={(sv) => {
        setSearch(sv);
      }}
      filterOption={false}
      onChange={onChange}
      value={dashboardId || undefined}
      style={{ width: '100%' }}
    />
  );
}
