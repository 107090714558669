import {
  Button, Form, Input, message, notification, Switch,
} from 'antd';
import { useEffect, useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import api, { DataSource, Project } from '../../api';
import DataSourceSelect from '../../data-sources/components/DataSourceSelect';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useRequest from '../../common/hooks/useRequest';

interface ProjectFormProps {
  project?: Project;
  onUpdateSuccess?: (response: Project) => void;
  organizationId?: Project['organizationId'];
}

export default function ProjectForm(props: ProjectFormProps) {
  const { organizationId, project, onUpdateSuccess } = props;
  const [form] = Form.useForm();
  const organization = useActiveOrganization();

  function getEntityIds() {
    const ids = [];

    if (organization) {
      ids.push(organization._id);
    }

    if (project) {
      ids.push(project._id);
    }

    return ids;
  }

  const [entityIds, setEntityIds] = useState<DataSource['entityId'][]>(getEntityIds());

  useEffect(() => {
    if (project) {
      form.setFieldsValue(project);
    } else {
      form.setFieldsValue({
        name: '',
      });
    }
  }, [project?._id]);

  useEffect(() => {
    setEntityIds(getEntityIds());
  }, [project?._id, organization?._id]);

  const request = useRequest(async (values: Pick<Project, 'name' | 'defaultDataSourceId'>) => {
    if (project) {
      return await api.projects.patch(project._id, values) as unknown as Project;
    }

    return await api.projects.create({ organizationId, ...values }) as unknown as Project;
  }, {
    onSuccess: (response) => {
      notification.success({
        message: project ? 'Project Updated' : 'Project Created',
      });

      if (onUpdateSuccess) {
        onUpdateSuccess(response);
      }
    },
  });

  return (
    <Form form={form} layout="vertical" onFinish={request.submit}>
      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter project name' }]}>
        <Input placeholder="Project Name" />
      </Form.Item>
      <Form.Item label="Default data source" name="defaultDataSourceId">
        <DataSourceSelect
          entityIds={entityIds}
          extraOptions={[
            {
              value: null,
              label: 'Use organization default',
            },
          ]}
        />
      </Form.Item>
      <Form.Item>
        <div className="d-flex align-items-center justify-content-between">
          <span>Share Project</span>
          <Form.Item
            name="isShared"
            valuePropName="checked"
            noStyle
          >
            <Switch />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const share = getFieldValue('isShared');

          if (!share) {
            return null;
          }

          return (
            <div style={{ marginTop: -20, marginBottom: 20 }}>
              <div className="fw-bold">
                Everyone with the link can view this project,
                {' '}
                until you turn off the share option.
              </div>
              <div>
                As a security measure, you will still have to enable
                {' '}
                sharing for each dashboard individually.
              </div>
              <Button
                type="text"
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}/share/project/${project?._id}`);
                  message.success('Link copied to clipboard');
                }}
                icon={<CopyOutlined />}
                style={{ marginLeft: -12 }}
              >
                Copy Link
              </Button>
            </div>
          );
        }}
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit" loading={request.loading}>Save</Button>
      </Form.Item>
    </Form>
  );
}
