import authentication from '@feathersjs/authentication-client';
import { feathers } from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import axios, { AxiosInstance } from 'axios';
import User from './models/User';
import Application from './definitions/Application';
import Auth from './services/Auth';
import Services from './definitions/Services';
import Organization from './models/Organization';
import Project from './models/Project';
import Dashboard from './models/Dashboard';
import Widget from './models/Widget';
import Query from './models/Query';
import QueryExecution from './models/QueryExecution';
import DataSource from './models/DataSource';
import ResultRepresentation from './models/ResultRepresentation';
import OrganizationUser from './models/OrganizationUser';
import PermissionsHelper, { Permission, Permissions, DEFAULT_PERMISSIONS } from './services/Permissions';
import Plan, { PlanName, PlanLimits } from './definitions/Plan';
import AlertDefinition, { AlertType, AlertRecurrence } from './definitions/AlertDefinition';
import Alert from './models/Alert';
import SlackChannel from './definitions/SlackChannel';
import AirtableBase from './definitions/AirtableBase';
import RawCodeLanguage from './models/RawCodeLanguage';
import Parameter from './models/Parameter';
import { DevCommand } from './models/DevCommandData';
import { REACT_APP_API_URL, REACT_APP_STATUS_CHECK_URL } from '../config';
import Security from './services/Security';
import StatusCheckResponse from './definitions/StatusCheckResponse';
import UserPreferences from './models/UserPreferences';
import Entity from './models/Entity';
import Subscription from './models/Subscription';
import Format from './definitions/Format';
import Formatter from './utils/Formatter';

const getRestClient = (axiosInstance: AxiosInstance) => rest<Services>(
  REACT_APP_API_URL,
).axios(axiosInstance);

class Api {
  client: Application;

  auth: Auth;

  organizations: Services['organizations'];

  projects: Services['projects'];

  dashboards: Services['dashboards'];

  widgets: Services['widgets'];

  queries: Services['queries'];

  queryExecutions: Services['query-executions'];

  dataSources: Services['data-sources'];

  organizationUsers: Services['organization-users'];

  subscriptions: Services['subscription'];

  users: Services['users'];

  parameters: Services['parameters'];

  permissions: typeof PermissionsHelper;

  alert: Services['alert'];

  dev: Services['dev'];

  entities: Services['entities'];

  security: Security;

  axios: AxiosInstance;

  apiKey?: string;

  userPreferences: Services['user-preferences'];

  setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  initAxios() {
    if (this.axios) {
      this.axios.interceptors.request.use((config) => {
        if (this.apiKey) {
          // eslint-disable-next-line no-param-reassign
          config.headers['x-api-key'] = this.apiKey;
        }

        return config;
      });
    }
  }

  constructor() {
    this.axios = axios.create();
    this.initAxios();

    const restClient = getRestClient(this.axios);
    const client: Application = feathers();

    // @ts-ignore
    client.configure(restClient);
    // @ts-ignore
    client.configure(authentication());

    client.use('widgets', restClient.service('widgets'), {
      methods: [
        'find', 'get', 'create', 'update', 'patch', 'remove', 'refresh', 'customizeRepresentation',
        'rollBackRepresentation', 'clone',
      ],
    });

    client.use('data-sources', restClient.service('data-sources'), {
      methods: ['find', 'get', 'create', 'update', 'patch', 'remove', 'refreshSchema', 'runDbaqlV2Query'],
    });

    client.use('organizations', restClient.service('organizations'), {
      methods: [
        'find', 'get', 'create', 'update', 'patch', 'remove', 'connectSlack', 'listSlackChannels',
        'getAirtableIntegrationUrl', 'connectAirtable', 'listAirtableBases', 'appSumoStack',
      ],
    });

    client.use('subscription', restClient.service('subscription'), {
      methods: ['find', 'get', 'create', 'update', 'patch', 'remove', 'getPromoCodeDiscount'],
    });

    client.use('users', restClient.service('users'), {
      methods: [
        'find', 'get', 'create', 'update', 'patch', 'remove', 'sendVerificationEmail', 'verifyEmail',
        'checkInviteCode', 'manageInvite', 'requestPasswordReset', 'resetPassword',
      ],
    });

    client.use('queries', restClient.service('queries'), {
      methods: ['find', 'get', 'create', 'update', 'patch', 'remove', 'completion'],
    });

    this.client = client;

    this.organizations = this.client.service('organizations');
    this.projects = this.client.service('projects');
    this.dashboards = this.client.service('dashboards');
    this.widgets = this.client.service('widgets');
    this.queries = this.client.service('queries');
    this.queryExecutions = this.client.service('query-executions');
    this.dataSources = this.client.service('data-sources');
    this.organizationUsers = this.client.service('organization-users');
    this.subscriptions = this.client.service('subscription');
    this.auth = new Auth(this.client);
    this.users = this.client.service('users');
    this.permissions = PermissionsHelper;
    this.alert = this.client.service('alert');
    this.dev = this.client.service('dev');
    this.parameters = this.client.service('parameters');
    this.userPreferences = this.client.service('user-preferences');
    this.entities = this.client.service('entities');
    this.security = new Security(REACT_APP_STATUS_CHECK_URL);
  }
}

export default new Api();

export {
  PermissionsHelper, DEFAULT_PERMISSIONS, PlanName, AlertType, AlertRecurrence, DevCommand,
  RawCodeLanguage, Formatter,
};

export type {
  User, Organization, Project, Dashboard, Widget, Query, QueryExecution,
  DataSource, ResultRepresentation, OrganizationUser, Permission, Permissions, Plan, PlanLimits,
  AlertDefinition, Alert, SlackChannel, AirtableBase, Parameter, StatusCheckResponse,
  UserPreferences, Subscription, Entity, Format,
};

export * from './models/ResultRepresentation';
export * from './models/QueryExecution';
export * from './models/DataSource';
export * from './models/OrganizationUser';
export * from './models/Widget';
export * from './models/Parameter';
export * from './models/UserPreferences';
export * from './models/User';
export * from './models/Entity';
export * from './definitions/Format';
