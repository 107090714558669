import { useState } from 'react';
import { notification } from 'antd';

type Callback<T> = (...args: any) => Promise<T>;
type Options<T> = Partial<{
  onSuccess: (response: T) => void;
  onError: (e: any) => void;
}>;

type UseRequest = {
  loading: boolean;
  submit: (...args: any) => Promise<void>;
};

export default function useRequest<T>(callback: Callback<T>, options: Options<T> = {}): UseRequest {
  const { onSuccess, onError } = options;
  const [loading, setLoading] = useState(false);

  const submit = async (...args: any) => {
    setLoading(true);

    try {
      const response = await callback(...args);
      if (onSuccess) {
        onSuccess(response);
      }
    } catch (e: any) {
      if (onError) {
        onError(e);
      } else {
        notification.error({
          message: 'Something went wrong',
          description: e.message || 'Please try again later',
        });
      }
    }

    setLoading(false);
  };

  return {
    loading,
    submit,
  };
}
