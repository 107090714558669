import { ResultRepresentationSubType } from '../../../api';
import StringResultRepresentationSubTypeConfig from './StringResultRepresentationSubTypeConfig';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import NumberResultRepresentationSubTypeConfig from './NumberResultRepresentationSubTypeConfig';
import TextResultRepresentationSubTypeConfig from './TextResultRepresentationSubTypeConfig';
import DateResultRepresentationSubTypeConfig from './DateResultRepresentationSubTypeConfig';
import PieChartResultRepresentationSubTypeConfig from './PieChartResultRepresentationSubTypeConfig';
import BarChartResultRepresentationSubTypeConfig from './BarChartResultRepresentationSubTypeConfig';
import TreeMapChartResultRepresentationSubTypeConfig from './TreeMapChartResultRepresentationSubTypeConfig';
import AreaChartResultRepresentationSubTypeConfig from './AreaChartResultRepresentationSubTypeConfig';
import HeatmapChartResultRepresentationSubTypeConfig from './HeatmapChartResultRepresentationSubTypeConfig';
import TableResultRepresentationSubTypeConfig from './TableResultRepresentationSubTypeConfig';
import LineChartResultRepresentationSubTypeConfig from './LineChartResultRepresentationSubTypeConfig';
import ScatterChartResultRepresentationSubTypeConfig from './ScatterChartResultRepresentationSubTypeConfig';
import RadarChartResultRepresentationSubTypeConfig from './RadarChartResultRepresentationSubTypeConfig';

export default class ResultRepresentationSubTypeConfigFactory {
  static create(type: ResultRepresentationSubType) {
    switch (type) {
      case ResultRepresentationSubType.NUMBER:
        return new NumberResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.STRING:
        return new StringResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.TEXT:
        return new TextResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.DATE:
        return new DateResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.TABLE:
        return new TableResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.PIE_CHART:
        return new PieChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.AREA_CHART:
        return new AreaChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.LINE_CHART:
        return new LineChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.BAR_CHART:
        return new BarChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.SCATTER_CHART:
        return new ScatterChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.HEATMAP_CHART:
        return new HeatmapChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.RADAR_CHART:
        return new RadarChartResultRepresentationSubTypeConfig();
      case ResultRepresentationSubType.TREE_MAP_CHART:
        return new TreeMapChartResultRepresentationSubTypeConfig();
      default:
        return {
          Icon: null,
          title: '',
          type: '',
        } as unknown as ResultRepresentationSubTypeConfig;
    }
  }
}
