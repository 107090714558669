import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class TreeMapToBarChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.TREE_MAP_CHART;

  to = ResultRepresentationSubType.BAR_CHART;

  transformData(): any {
    const { data, categories } = this.resultRepresentation.data.series[0].data
      .reduce((acc: { data: any[]; categories: any[] }, item: any) => {
        acc.categories.push(item.x);
        acc.data.push(item.y);
        return acc;
      }, { data: [], categories: [] });

    return {
      series: [{ data }],
      options: {
        chart: {
          type: 'bar',
        },
        xaxis: {
          categories,
        },
      },
    };
  }
}

export default TreeMapToBarChartTransformer;
