import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class RadarChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.RADAR_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData(): any {
    return this.resultRepresentation.data;
  }
}

export default RadarChartToTableTransformer;
