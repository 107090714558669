import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class PieChartToTreeMapTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.PIE_CHART;

  to = ResultRepresentationSubType.TREE_MAP_CHART;

  transformData(): any {
    const { labels } = this.resultRepresentation.data.options;
    const dataRepresentation = this.resultRepresentation.data.series.reduce(
      (acc: [{ x: any; y: any }], item: any, index: number) => {
        acc.push({
          x: labels[index],
          y: item,
        });
        return acc;
      },
      [],
    );
    return {
      series: [
        {
          data: dataRepresentation,
        },
      ],
      options: {
        chart: {
          type: 'treemap',
        },
      },
    };
  }
}

export default PieChartToTreeMapTransformer;
