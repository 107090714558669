import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd';
import { useAuth } from './modules/auth';
import Spin from './modules/common/components/Spin';
import './global/styles/bootstrap-grid.min.css';
import './global/styles/bootstrap-utilities.min.css';
import './global/styles/global.css';
import MainNavigator from './modules/navigation/components/MainNavigator';
import useStatusCheck from './modules/security/hooks/useStatusCheck';

function Content() {
  const { loading, init } = useAuth();

  useStatusCheck();

  useEffect(() => {
    init().then();
  }, []);

  if (loading) {
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spin />
      </div>
    );
  }

  return (<MainNavigator />);
}

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1476ff',
          borderRadius: 0,
          colorBorderSecondary: '#eaeaea',
        },
      }}
    >
      <BrowserRouter>
        <RecoilRoot>
          <Content />
        </RecoilRoot>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
