export default function guessSeriesAndLabels(data: any): undefined | { series: any; labels: any } {
  if (!Array.isArray(data) || data.length === 0) {
    return undefined;
  }

  const [sample] = data;

  if (!sample || typeof sample !== 'object') {
    return undefined;
  }

  const keys = Object.keys(sample);
  const firstKey = keys[0];
  const secondKey = keys[1];

  if (
    typeof sample[firstKey] === 'string'
    && typeof sample[secondKey] === 'number'
  ) {
    return {
      series: data.map((item: any) => item[secondKey]),
      labels: data.map((item: any) => item[firstKey]),
    };
  } if (
    typeof sample[firstKey] === 'number'
    && typeof sample[secondKey] === 'string'
  ) {
    return {
      series: data.map((item: any) => item[firstKey]),
      labels: data.map((item: any) => item[secondKey]),
    };
  } if (
    typeof sample[firstKey] === 'string'
    && typeof sample[secondKey] === 'string') {
    return {
      series: data.map((item: any) => item[secondKey]),
      labels: data.map((item: any) => item[firstKey]),
    };
  }

  return undefined;
}
