import { Card, Select } from 'antd';
import { useEffect } from 'react';
import {
  AnalysisType, DataSource, Entity, EntityFieldType, FormatType, Metric, Segment,
} from '../../../api';
import InsightFlowSelectBox from '../InsightFlowSelectBox';
import { ExpressionOperation, FieldType, SortDirection } from '../../../dbaql-v2/types';
import EntitiesRelationshipManager from '../../../entities/utils/EntitiesRelationshipManager';
import FieldAdapter from '../../../dbaql-v2-entities-adapters/FieldAdapter';
import { APP_CONTENT_SUBTRACT } from '../../../layout/components/AppLayout';

export type AnalysisOptions = {
  type: AnalysisType;
  label: string;
  description: string;
  requiredField?: keyof Entity;
  comingSoon?: boolean;
  minRequiredMetrics?: number;
  minRequiredSegments?: number;
};

export const defaultAnalysisOptions: AnalysisOptions[] = [
  {
    type: AnalysisType.SEE_DATASET_ENTRIES,
    label: 'See Dataset Entries',
    description: 'See the entries of the dataset',
  },
  {
    type: AnalysisType.UNDERSTAND_TRENDS,
    label: 'Understand Trends',
    description: 'E.g. user growth, revenue growth, etc.',
    requiredField: 'createdAtFieldName',
    minRequiredMetrics: 1,
  },
  {
    type: AnalysisType.ANALYZE_SEGMENTS,
    label: 'Analyze Segments',
    description: 'Analyze segments like country, age, etc.',
    minRequiredSegments: 1,
  },
  {
    type: AnalysisType.ANALYZE_TOTALS,
    label: 'Calculate Totals',
    description: 'Calculate totals like revenue, user count, etc.',
  },
  {
    type: AnalysisType.GEOSPATIAL_ANALYSIS,
    label: 'Geospatial Analysis',
    description: 'Analyze data based on location',
    comingSoon: true,
  },
];

interface InsightFlowAnalysisSetupProps {
  entities: Entity[];
  entity?: Entity;
  setEntity: (entity?: Entity) => void;
  analysis?: AnalysisOptions;
  setAnalysis: (analysis?: AnalysisOptions) => void;
  metrics: Metric[];
  setMetrics: (metrics: Metric[]) => void;
  dataSource?: DataSource;
  segments: Segment[];
  setSegments: (segments: Segment[]) => void;
  setPage: (page: number) => void;
}

export default function InsightFlowAnalysisSetup(props: InsightFlowAnalysisSetupProps) {
  const {
    entity,
    setEntity,
    entities,
    analysis,
    setAnalysis,
    metrics,
    setMetrics,
    dataSource,
    segments,
    setSegments,
    setPage,
  } = props;

  const analysisOptions: AnalysisOptions[] = defaultAnalysisOptions.filter((item) => {
    if (!item.requiredField) {
      return true;
    }

    return entity && item.requiredField in entity;
  });

  const hasMetrics = analysis?.type && [
    AnalysisType.ANALYZE_SEGMENTS,
    AnalysisType.UNDERSTAND_TRENDS,
    AnalysisType.ANALYZE_TOTALS,
  ].includes(analysis?.type);
  const availableMetrics: Metric[] = [];
  const availableSegments: Segment[] = [];

  useEffect(() => {
    if (hasMetrics && availableMetrics[0]) {
      setMetrics([availableMetrics[0]]);
    }
    if (analysis?.type === AnalysisType.ANALYZE_SEGMENTS && availableSegments[0]) {
      setSegments([availableSegments.find((item) => item.default) || availableSegments[0]]);
    }
    setPage(1);
  }, [analysis, entity]);

  const table = dataSource
    ?.schemaConfig
    .tables
    .find((item) => item.tableName === entity?.table);

  if (entity && dataSource) {
    if (hasMetrics) {
      availableMetrics.push({
        key: `${entity.displayName}_Count`,
        label: `${entity.displayName} Count`,
        description: 'Count the number of entries',
        expression: {
          type: FieldType.EXPRESSION,
          operation: ExpressionOperation.COUNT,
          fields: ['*'],
          as: 'count',
        },
        dataSet: {
          title: `${entity.displayName} Count`,
          valueFieldName: 'count',
        },
      });
    }

    if (table) {
      entity.fields.forEach((field) => {
        if (field.definition.type === EntityFieldType.COLUMN) {
          const tableField = table.schema.properties[field.definition.column];

          if (tableField) {
            if (tableField.type === 'number' && field.useInMetrics) {
              availableMetrics.push({
                key: `${entity.displayName}_${field.displayName}_Sum`,
                label: `${entity.displayName} ${field.displayName} Sum`,
                description: `Sum the ${field.displayName} column`,
                expression: {
                  type: FieldType.EXPRESSION,
                  operation: ExpressionOperation.SUM,
                  fields: [field.name],
                  as: `${field.name}_sum`,
                },
                dataSet: {
                  title: `${entity.displayName} ${field.displayName} Sum`,
                  valueFieldName: `${field.name}_sum`,
                  format: {
                    type: FormatType.FROM_FIELD,
                    fieldId: field.id,
                  },
                },
              });
            }

            if (field.useInSegmentation) {
              if (
                field.definition.type === EntityFieldType.COLUMN
                && tableField.type === 'string' && tableField.format === 'date-time'
              ) {
                availableSegments.push({
                  key: `${field.name}-year`,
                  label: field.displayName,
                  truncate: 'year',
                  groupField: {
                    column: field.definition.column,
                    type: FieldType.DATE_TRUNC,
                    truncate: 'year',
                    as: `${field.definition.column}_year`,
                  },
                  sort: {
                    [`${field.definition.column}_year`]: SortDirection.ASC,
                  },
                  field,
                  format: {
                    type: FormatType.DATE,
                    format: 'YYYY',
                  },
                });
                availableSegments.push({
                  key: `${field.name}-month`,
                  label: field.displayName,
                  truncate: 'month',
                  groupField: {
                    column: field.definition.column,
                    truncate: 'month',
                    as: `${field.definition.column}_month`,
                    type: FieldType.DATE_TRUNC,
                  },
                  sort: {
                    [`${field.definition.column}_month`]: SortDirection.ASC,
                  },
                  field,
                  default: true,
                  format: {
                    type: FormatType.DATE,
                    format: 'MMM YYYY',
                  },
                });
                availableSegments.push({
                  key: `${field.name}-day`,
                  label: field.displayName,
                  truncate: 'day',
                  groupField: {
                    column: field.definition.column,
                    truncate: 'day',
                    as: `${field.definition.column}_day`,
                    type: FieldType.DATE_TRUNC,
                  },
                  sort: {
                    [`${field.definition.column}_day`]: SortDirection.ASC,
                  },
                  field,
                  format: {
                    type: FormatType.DATE,
                    format: 'MMM DD, YYYY',
                  },
                });
              } else {
                availableSegments.push({
                  key: field.name,
                  label: field.displayName,
                  groupField: field.name,
                  field,
                });
              }
            }
          }
        } else if (field.definition.type === EntityFieldType.FOREIGN_KEY
          && field.useInSegmentation) {
          const erm = new EntitiesRelationshipManager(entities);
          const em = erm.getEntityManager(field.definition.entityName);
          const entityTable = dataSource
            ?.schemaConfig
            .tables
            .find((item) => item.tableName === em.entity.table);

          if (entityTable && em.entity.titleFieldName) {
            const titleField = em.getFieldByName(em.entity.titleFieldName);

            if (titleField.definition.type === EntityFieldType.COLUMN) {
              const columnAlias = FieldAdapter.getJoinedFieldAlias(
                titleField.definition.column,
                entityTable.tableName,
              );

              availableSegments.push({
                key: field.name,
                label: field.displayName,
                groupField: columnAlias,
                field,
              });
            }
          }
        } else if (field.definition.type === EntityFieldType.COMPUTED) {
          if (field.useInMetrics) {
            const { expression } = field.definition;

            if (
              [
                ExpressionOperation.SUM,
                ExpressionOperation.MULTIPLY,
                ExpressionOperation.DIVIDE,
                ExpressionOperation.SUBTRACT,
              ].includes(expression)
            ) {
              availableMetrics.push({
                key: `${entity.displayName}_${field.displayName}_Sum`,
                label: `${entity.displayName} ${field.displayName} Sum`,
                description: `Sum the ${field.displayName} column`,
                expression: {
                  type: FieldType.EXPRESSION,
                  operation: ExpressionOperation.SUM,
                  fields: [field.name],
                  as: `${field.name}_sum`,
                },
                dataSet: {
                  title: `${entity.displayName} ${field.displayName} Sum`,
                  valueFieldName: `${field.name}_sum`,
                  format: {
                    type: FormatType.FROM_FIELD,
                    fieldId: field.id,
                  },
                },
              });
            }
          }
        }
      });
    }
  }

  return (
    <div
      style={{
        maxHeight: `calc(100vh - ${(APP_CONTENT_SUBTRACT + (16 * 2))}px)`,
        overflowX: 'auto',
      }}
    >
      <Card
        title="Entity"
        size="small"
        style={{
          borderRadius: 0,
          borderColor: 'rgb(222, 223, 226)',
        }}
      >
        <p className="mt-0">
          Select an entity to start building your insight flow
        </p>
        <Select
          options={entities.map((item) => ({
            label: item.displayName,
            value: item.name,
          }))}
          placeholder="Select an entity"
          value={entity?.name}
          onChange={(value) => {
            setEntity(entities.find((item) => item.name === value));
          }}
          className="w-100"
        />
      </Card>
      {
        entity && (
          <>
            <Card
              title="Analysis type"
              size="small"
              className="mt-2"
              style={{
                borderRadius: 0,
                borderColor: 'rgb(222, 223, 226)',
              }}
            >
              <InsightFlowSelectBox
                value={analysis?.type ? [analysis.type] : []}
                onChange={([newValue]) => {
                  if (newValue) {
                    setAnalysis(
                      analysisOptions.find((el) => el.type === newValue) || undefined,
                    );
                  }
                }}
                options={analysisOptions.map((item) => ({
                  title: item.label,
                  subtitle: item.description,
                  value: item.type,
                }))}
                maxItems={1}
                minItems={1}
                expanded
              />
            </Card>
            {
              hasMetrics && (
                <Card
                  title="Metrics"
                  size="small"
                  className="mt-2"
                  style={{
                    borderRadius: 0,
                    borderColor: 'rgb(222, 223, 226)',
                  }}
                >
                  <InsightFlowSelectBox
                    value={metrics.map((metric) => metric.label)}
                    onChange={(value) => {
                      setMetrics(
                        availableMetrics.filter((item) => value.includes(item.label)),
                      );
                    }}
                    options={availableMetrics.map((metric) => ({
                      title: metric.label,
                      subtitle: metric.description,
                      value: metric.label,
                    }))}
                    addButtonTitle="Add Metric"
                    maxItems={
                      analysis?.type === AnalysisType.ANALYZE_TOTALS
                        ? 1
                        : undefined
                    }
                    minItems={1}
                  />
                </Card>
              )
            }
            {
              analysis?.type === AnalysisType.ANALYZE_SEGMENTS && (
                <Card
                  title="Segment by"
                  size="small"
                  className="mt-2"
                  style={{
                    borderRadius: 0,
                    borderColor: 'rgb(222, 223, 226)',
                  }}
                >
                  <InsightFlowSelectBox
                    value={segments.map((item) => item.key)}
                    onChange={(newValue) => {
                      setSegments(
                        availableSegments.filter((item) => newValue.includes(item.key)),
                      );
                    }}
                    options={availableSegments.map((item) => ({
                      title: `${item.label} ${item.truncate ? `(${item.truncate})` : ''}`,
                      value: item.key,
                    }))}
                    maxItems={1}
                    minItems={1}
                  />
                </Card>
              )
            }
          </>
        )
      }
    </div>
  );
}
