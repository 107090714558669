import { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';
import { DatePicker as AntDatePicker } from 'antd';
import { useMemo } from 'react';

interface DatePickerProps extends AntDatePickerProps {
  standalone?: boolean;
}

const { _InternalPanelDoNotUseOrYouWillBeFired: InternalDatePicker } = AntDatePicker;

function StandaloneDatePicker(props: AntDatePickerProps) {
  const {
    style = {},
    popupStyle = {},
    ...rest
  } = props;

  return (
    <>
      <div className="picker">
        <InternalDatePicker
          style={{
            opacity: 0,
            width: 0,
            height: 0,
            padding: 0,
            ...style,
          }}
          popupStyle={{
            padding: 0,
            inset: '0 auto auto 0',
            ...popupStyle,
          }}
          {...rest}
        />
      </div>
      {/*
      @ts-ignore */}
      <style jsx>
        {`
            .picker {
                margin-bottom: -23px;
            }
            .picker .ant-picker-panel-container {
                box-shadow: none;
            }
            .picker .ant-picker,
            .picker .ant-picker-input,
            .picker .ant-picker-range-separator {
                height: 0;
                opacity: 0;
                max-height: 0;
                border: none;
                padding: 0;
            }

            .picker .ant-picker-range-arrow {
                display: none !important;
            }

            .picker .ant-picker-dropdown {
                inset: 0 auto auto 0 !important;
            }
        `}
      </style>
    </>
  );
}

export default function DatePicker(props: DatePickerProps) {
  const { standalone, ...rest } = props;

  const Component = useMemo(
    () => (standalone ? StandaloneDatePicker : DatePicker),
    [standalone],
  );

  return <Component {...rest} />;
}
