import {
  Button, Card, Col, Form, Input, notification, Row,
} from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMarketingParams } from '../../common/utils/tracking';
import { SignUpData, useAuth } from '../../auth';

export default function AppSumoPage() {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const { code: appSumoCode } = useParams();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: SignUpData) => {
    setLoading(true);
    try {
      await signUp({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        params: getMarketingParams(),
        appSumoCode,
      });
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e.message,
      });
      setLoading(false);
      return;
    }

    await navigate('/appsumo/thank-you');

    setLoading(false);
  };

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#0b163f',
        boxSizing: 'border-box',
        backgroundImage: 'url("/page-header-bg.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
      }}
      className="d-flex align-items-center p-5"
    >
      <Row justify="center" className="flex-grow-1">
        <Col span={24} md={12} lg={10} xl={8} xxl={6}>
          <div className="d-flex flex-column align-items-center mb-5">
            <img src="/logo-white.svg" height={50} alt="logo" />
          </div>
          <Card
            headStyle={{ fontSize: '1.75rem', fontWeight: 'bold' }}
            className="bg-light"
          >
            <h1 className="mt-0">Sign Up | AppSumo</h1>
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
                <Input placeholder="Enter your first name" size="large" />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
                <Input placeholder="Enter your last name" size="large" />
              </Form.Item>
              <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                <Input placeholder="Enter your email" size="large" />
              </Form.Item>
              <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                <Input.Password placeholder="Enter your password" size="large" />
              </Form.Item>
              <Form.Item name="confirmPassword" label="Confirm Password" rules={[{ required: true }]}>
                <Input.Password placeholder="Confirm your password" size="large" />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                  size="large"
                  block
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
