import { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon';
import {
  BoxPlotOutlined,
  CalendarOutlined,
  ControlOutlined,
  LineChartOutlined,
  NumberOutlined,
  TableOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import * as React from 'react';
import { ResultRepresentationType } from '../../../api';

interface IconProps extends Pick<AntdIconProps, 'size' | 'color'> {}

interface ResultRepresentationTypeIconProps extends IconProps {
  type: ResultRepresentationType;
}

export default function ResultRepresentationTypeIcon(
  props: ResultRepresentationTypeIconProps,
) {
  const { type, ...iconProps } = props;

  let Icon: React.ForwardRefExoticComponent<IconProps> | undefined;

  switch (type) {
    case ResultRepresentationType.TEXT:
      Icon = TranslationOutlined;
      break;
    case ResultRepresentationType.NUMBER:
      Icon = NumberOutlined;
      break;
    case ResultRepresentationType.STRING:
      Icon = TranslationOutlined;
      break;
    case ResultRepresentationType.DATE:
      Icon = CalendarOutlined;
      break;
    case ResultRepresentationType.OBJECT_VIEW:
      Icon = BoxPlotOutlined;
      break;
    case ResultRepresentationType.TABLE:
      Icon = TableOutlined;
      break;
    case ResultRepresentationType.GRAPH:
      Icon = LineChartOutlined;
      break;
    default:
      Icon = ControlOutlined;
  }

  return <Icon {...iconProps} />;
}
