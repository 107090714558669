import { ResultRepresentationSubType } from '../../../api';
import ResultRepresentationTransformer from './ResultRepresentationTransformer';

export default class StringToDateTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.STRING;

  to = ResultRepresentationSubType.DATE;

  isCompatible() {
    return String(this.transformData()) !== 'Invalid Date';
  }

  transformData() {
    return new Date(this.resultRepresentation.data);
  }
}
