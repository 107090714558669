export enum FormatType {
  CURRENCY = 'currency',
  DATE = 'date',
  FROM_FIELD = 'from-field',
}

export type DateFormat = {
  type: FormatType.DATE;
  format: string;
};

export type CurrencyFormat = {
  type: FormatType.CURRENCY;
  currency: string;
};

export type FromFieldFormat = {
  type: FormatType.FROM_FIELD;
};

type CreateType<T> = T & {
  fieldId?: string;
};

type Format = CreateType<DateFormat> | CreateType<CurrencyFormat> | CreateType<FromFieldFormat>;

export default Format;
