import dayjs from 'dayjs';
import Format, { CurrencyFormat, DateFormat, FormatType } from '../definitions/Format';
import { currencies, Currency } from './currency';

const dateFormats: string[] = [
  'MMM D, YYYY',
  'MMMM D, YYYY',
  'MMM D',
  'MMMM D',
  'MMM, YYYY',
  'MMMM, YYYY',
  'YYYY-MM-DD',
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  'ddd, MMM D, YYYY',
  'h:mm A',
  'YYYY-MM-DDTHH:mm:ss',
  'dddd, MMMM D, YYYY',
  'YYYY-MM-DDTHH:mm:ssZ',
  'dddd, MMM D, YYYY',
  'MMM D, YYYY HH:mm:ss',
  'YYYY-MM-DD HH:mm',
  'MMM D, YYYY h:mm A',
  'MM/DD/YYYY HH:mm:ss',
  'MMMM D, YYYY h:mm A',
  'YYYY-MM-DD HH:mm:ss.SSS',
  'YYYY-MM-DD h:mm A',
  'dddd, MMMM D, YYYY HH:mm:ss',
  'dddd, MMM D, YYYY HH:mm',
  'dddd, MMMM D, YYYY h:mm A',
  'MMMM D, YYYY HH:mm:ss',
  'ddd, MMM D, YYYY h:mm A',
  'ddd, MMMM D, YYYY HH:mm:ss',
  'MMM D, YYYY HH:mm',
  'ddd, MMMM D, YYYY HH:mm',
  'MMMM D, YYYY HH:mm:ss.SSS',
  'ddd, MMMM D, YYYY HH:mm:ss.SSS',
  'ddd, MMM D, YYYY HH:mm:ss',
  'ddd, MMMM D, YYYY h:mm A',
  'MMMM D, YYYY h:mm A',
  'ddd, MMM D, YYYY HH:mm:ss.SSS',
  'MMM D, YYYY HH:mm:ss.SSS',
];

type FormatOption = {
  label: string;
  value: string;
};

export default class Formatter {
  static dateFormats = dateFormats;

  static currencyList = Object.values(currencies) as Currency[];

  static currencies: Record<string, Currency> = currencies;

  static formatOptions: FormatOption[] = [
    {
      label: 'No Formatting',
      value: '',
    },
    {
      label: 'Currency',
      value: FormatType.CURRENCY,
    },
    {
      label: 'Date',
      value: FormatType.DATE,
    },
  ];

  static FormatType = FormatType;

  static formatDate(format: DateFormat, value: any) {
    return dayjs(value).format(format.format);
  }

  static formatCurrency(format: CurrencyFormat, value: any) {
    const currency = Formatter.currencies[format.currency];
    return Number(value).toLocaleString(undefined, {
      style: 'currency',
      currency: currency.code,
    });
  }

  static format(value: any, format: Format) {
    switch (format.type) {
      case FormatType.DATE:
        return Formatter.formatDate(format, value);
      case FormatType.CURRENCY:
        return Formatter.formatCurrency(format, value);
      default:
        return value;
    }
  }
}
