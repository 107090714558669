import Model from './Model';
import ObjectId from '../definitions/ObjectId';
import { ExpressionOperation } from '../../dbaql-v2/types';
import Format from '../definitions/Format';

export type EntityColumnConfig = {
  table: string;
  column: string;
};

export enum EntityFieldType {
  COLUMN = 'column',
  COMPUTED = 'computed',
  FOREIGN_KEY = 'foreign-key',
  RELATED_ENTITY_FIELD = 'related-entity-field',
}

type ComputedFieldArgument = string;

export type ColumnField = Pick<EntityColumnConfig, 'table' | 'column'> & {
  type: EntityFieldType.COLUMN;
};

export type ComputedField = {
  type: EntityFieldType.COMPUTED;
  expression: ExpressionOperation;
  arguments: ComputedFieldArgument[];
};

export type ForeignKeyField = {
  type: EntityFieldType.FOREIGN_KEY;
  entityName: string;
  entityField: string;
  localColumn: string;
};

export type RelatedEntityField = {
  type: EntityFieldType.RELATED_ENTITY_FIELD;
  entityName: string;
  entityField: string;
};

type FieldDefinition = ColumnField | ComputedField | ForeignKeyField | RelatedEntityField;

export type EntityField = {
  id: string;
  displayName: string;
  name: string;
  type: EntityFieldType;
  definition: FieldDefinition;
  filter: boolean;
  group: boolean;
  showInTableView?: boolean;
  useInSegmentation?: boolean;
  useInMetrics?: boolean;
  useInFilters?: boolean;
  format?: Format;
};

export default interface Entity extends Model {
  // Base fields
  name: string;
  displayName: string;
  description?: string;
  table: string;
  fields: EntityField[];
  filter?: any[];

  // Field names
  titleFieldName: string;
  createdAtFieldName?: string;
  countryFieldName?: string;

  dataSourceId: ObjectId;
  organizationId: ObjectId;
  projectId: ObjectId;
}
