import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import api, { Organization, SlackChannel } from '../../api';

interface SlackChannelSelectProps extends SelectProps {
  organizationId: Organization['_id'];
}

export default function SlackChannelSelect(props: SlackChannelSelectProps) {
  const { organizationId, ...selectProps } = props;

  const [options, setOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    api.organizations.listSlackChannels({ id: organizationId }).then((channels: SlackChannel[]) => {
      setOptions(channels.map((channel) => ({
        label: channel.name,
        value: channel.id,
      })));
    });
  }, [organizationId]);

  return (
    <Select
      options={options}
      placeholder="Select a channel"
      {...selectProps}
    />
  );
}
