import ResultRepresentationManager from './ResultRepresentationManager';
import RadarChartToTableTransformer from '../transformers/RadarChartToTableTransformer';

class RadarChartResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new RadarChartToTableTransformer(this.representation),
  ];
}

export default RadarChartResultRepresentationManager;
