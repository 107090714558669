import { useParams } from 'react-router-dom';
import React from 'react';
import { Tabs } from 'antd';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import Spin from '../../common/components/Spin';
import AppSharedPage, { SHARED_PAGE_HEADER_HEIGHT } from '../../layout/components/AppSharedPage';
import DashboardWidgets from '../../dashboards/components/DashboardWidgets';
import { LAYOUT_SIDEBAR_COLOR } from '../../config/styling';

const TABS_HEIGHT = 46;

export default function SharedProjectPage() {
  const { projectId } = useParams();

  const [project, loadingProject] = useQuery(
    async () => {
      if (projectId) {
        return api.projects.get(projectId, {
          query: {
            isShared: true,
          },
        });
      }

      return null;
    },
    [projectId],
  );

  const [dashboards, loadingDashboards] = useQuery(
    async () => {
      if (!project) {
        return null;
      }

      return api.dashboards.find({
        query: {
          isShared: true,
          projectId,
        },
      });
    },
    [project],
  );

  const loading = loadingProject || loadingDashboards;

  if (!projectId || loading || (!loadingProject && !project)) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        {
          !loading ? (
            <h1>Project Not Found</h1>
          ) : (
            <Spin />
          )
        }
      </div>
    );
  }

  if (!project) {
    return (
      <AppSharedPage title="No Dashboards">
        <div className=" d-flex align-items-center justify-content-center">
          <h1>Project Not Found</h1>
        </div>
      </AppSharedPage>
    );
  }

  if (!dashboards?.data?.length) {
    return (
      <AppSharedPage title="No Dashboards">
        <div className=" d-flex align-items-center justify-content-center">
          <h1>No dashboards found</h1>
        </div>
      </AppSharedPage>
    );
  }

  return (
    <AppSharedPage title={project.name} noBorderSeparator>
      <Tabs
        tabBarStyle={{
          padding: '0 16px',
          margin: 0,
          background: LAYOUT_SIDEBAR_COLOR,
          minHeight: TABS_HEIGHT,
        }}
        items={!dashboards ? [] : dashboards.data.map((dashboard) => ({
          key: dashboard._id,
          label: dashboard.name,
          children: (
            <div
              style={{
                height: `calc(100vh - ${(SHARED_PAGE_HEADER_HEIGHT + TABS_HEIGHT + 1)}px)`,
                overflow: 'auto',
              }}
            >
              <div style={{ margin: 16 }}>
                <DashboardWidgets
                  dashboardId={dashboard._id}
                  projectId={projectId}
                  organizationId={dashboard.organizationId}
                  gridLayout={dashboard.gridLayout}
                  useSharedLayout
                  sharedMode
                />
              </div>
            </div>
          ),
        }))}
      />
    </AppSharedPage>
  );
}
