export function wait(ms: number) {
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}

export default async function retry<T>(
  fn: () => Promise<T>,
  attempts: number,
  delay: number,
) {
  let attempt = 1;

  async function run(): Promise<T> {
    try {
      return await fn();
    } catch (e) {
      if (attempt >= attempts) {
        throw e;
      }
      attempt += 1;
      await wait(delay);
      return run();
    }
  }

  return run();
}
