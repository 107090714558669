import { Outlet, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Paginated } from '@feathersjs/feathers';
import useQuery from '../../common/hooks/useQuery';
import api, { DataSource, DEFAULT_PERMISSIONS, PermissionsHelper } from '../../api';
import { organizationsAtom } from '../state';
import Spin from '../../common/components/Spin';
import { globalAtom } from '../../common/state/global-state';
import { useAuth } from '../../auth';
import AppPage from '../../layout/components/AppPage';
import { setActiveOrganization } from '../utils/sync';

export default function OrganizationIndexPage() {
  const { organizationSlug } = useParams();
  const { user } = useAuth();
  const setOrganizationState = useSetRecoilState(organizationsAtom);
  const setGlobalState = useSetRecoilState(globalAtom);

  const [organization, loading] = useQuery(async () => {
    const response = await api.organizations.find({
      query: {
        slug: organizationSlug,
        $limit: 1,
      },
    });

    const responseOrganization = response.data[0];

    setOrganizationState((prev) => ({
      ...prev,
      organization: responseOrganization || null,
    }));

    setGlobalState((prev) => ({
      ...prev,
      organization: responseOrganization || null,
    }));

    if (responseOrganization) {
      setActiveOrganization(responseOrganization);
    }

    return responseOrganization;
  });

  useQuery(async () => {
    if (!organization || !user) {
      return null;
    }

    setGlobalState((prev) => ({
      ...prev,
      loadingOrganizationUser: true,
      loadingOrganizationProjects: true,
    }));

    // Do not wait for this request
    // because it's a little bit slow
    api.dataSources.find({
      query: {
        $limit: 100,
      },
    }).then((dataSourcesResponse: Paginated<DataSource>) => {
      setGlobalState((prev) => ({
        ...prev,
        dataSources: dataSourcesResponse.data,
      }));
    });

    const [response, organizationUsersResponse] = await Promise.all([
      api.projects.find({
        query: {
          $limit: 100,
          organizationId: organization._id,
        },
      }),
      api.organizationUsers.find({
        query: {
          userId: user._id,
          organizationId: organization._id,
          $limit: 1,
        },
      }),
    ]);

    const [organizationUser] = organizationUsersResponse.data;

    setGlobalState((prev) => ({
      ...prev,
      projects: response.data,
      organizationUser: organizationUser || null,
      permissions: organizationUser
        ? PermissionsHelper.getPermissions(organizationUser.role) : DEFAULT_PERMISSIONS,
      loadingOrganizationUser: false,
      loadingOrganizationProjects: false,
    }));

    return response.data;
  }, [organization?._id, user?._id]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spin />
      </div>
    );
  }

  if (!organization) {
    return (
      <AppPage title="Not found">
        <div className="d-flex justify-content-center align-items-center">
          <h1>Organization Not Found</h1>
        </div>
      </AppPage>
    );
  }

  return (<Outlet />);
}
