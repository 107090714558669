import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import api, { Organization, AirtableBase } from '../../../api';

interface AirtableBasesSelectProps extends SelectProps {
  organizationId: Organization['_id'];
}

export default function AirtableBasesSelect(props: AirtableBasesSelectProps) {
  const { organizationId, ...selectProps } = props;

  const [options, setOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    api.organizations.listAirtableBases({ id: organizationId }).then((bases: AirtableBase[]) => {
      setOptions(bases.map((base) => ({
        label: base.name,
        value: base.id,
      })));
    });
  }, [organizationId]);

  return (
    <Select
      options={options}
      placeholder="Select a base"
      filterOption={(input, option) => String(option?.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
      showSearch
      {...selectProps}
    />
  );
}
