import { Dayjs } from 'dayjs';

export enum DatePreset {
  TODAY = 'TODAY',
  LAST_24H = 'LAST_24H',
  LAST_7D = 'LAST_7D',
  LAST_30D = 'LAST_30D',
  LAST_90D = 'LAST_90D',
  LAST_6M = 'LAST_6M',
  ONE_YEAR = 'ONE_YEAR',
  CUSTOM = 'CUSTOM',
  ALL_TIME = 'all-time',
}

type CustomDateSelection = {
  preset: DatePreset;
  label: string;
  longLabel: string;
  from?: Dayjs | null;
  to?: Dayjs | null;
  value?: Dayjs | null;
};

export default CustomDateSelection;
