import dayjs from 'dayjs';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button, DatePicker, Input, InputNumber, message, notification, Select, Switch, Tour,
} from 'antd';
import api, {
  Dashboard, OnboardingStepKey, Query, Widget, WidgetUpdateSchedule,
} from '../../../api';
import SelectDashboard from '../../../dashboards/components/SelectDashboard';
import useOnboarding from '../../../onboarding/hooks/useOnboarding';

interface WidgetSetupProps {
  queryId: Query['_id'];
  projectId?: Widget['projectId'];
  dashboardId?: Widget['dashboardId'];
  widget?: Widget;
  onSuccess?: (widget: Widget) => void;
  resultRepresentationSubTypeOverride?: Widget['resultRepresentationSubTypeOverride'];
}

function getSchedule(): WidgetUpdateSchedule {
  return {
    interval: 12,
    unit: 'hour',
    startTime: new Date(),
  };
}

const TOUR_KEY = 'create-widget-tour-shown';

function checkCreateWidgetTourShown() {
  return localStorage.getItem(TOUR_KEY) === 'true';
}

function setCreateWidgetTourShown() {
  localStorage.setItem(TOUR_KEY, 'true');
}

export default function WidgetSetup(props: WidgetSetupProps) {
  const {
    queryId,
    onSuccess,
    projectId,
    dashboardId: propsDashboardId,
    widget,
    resultRepresentationSubTypeOverride,
  } = props;
  const [title, setTitle] = useState('');
  const [updateInBackground, setUpdateInBackground] = useState(widget?.updateInBackground || false);
  const [schedule, setSchedule] = useState<WidgetUpdateSchedule>(getSchedule());
  const [dashboardId, setDashboardId] = useState<Dashboard['_id']>(widget?.dashboardId || '');
  const [embeddable, setEmbeddable] = useState<Widget['embeddable']>(widget?.embeddable || false);
  const titleRef = useRef(null);
  const dashboardSelectRef = useRef(null);

  const saveWidgetRef = useRef(null);
  const updateInBackgroundRef = useRef(null);
  const embeddableRef = useRef(null);
  const tourShownAlready = checkCreateWidgetTourShown();
  const onboarding = useOnboarding();
  const onboardingEnabled = onboarding?.activeStep?.key === OnboardingStepKey.CREATE_A_WIDGET;
  const [tourOpen, setTourOpen] = useState(false);
  const [showTour, setShowTour] = useState(onboardingEnabled);

  useEffect(() => {
    if (onboardingEnabled) {
      setTimeout(() => {
        setShowTour(true);
        setTourOpen(true);
      }, 500);
    }
  }, [onboardingEnabled]);

  const [loading, setLoading] = useState(false);

  const reset = useCallback(() => {
    setTitle('');
    setUpdateInBackground(false);
    setSchedule(getSchedule());
    setDashboardId('');
    setEmbeddable(false);
  }, []);

  useEffect(reset, [queryId, reset]);

  useEffect(() => {
    if (!widget) {
      reset();
    } else {
      setTitle(widget.title);
      setUpdateInBackground(widget.updateInBackground);
      setSchedule(widget.updateSchedule || getSchedule());
      setDashboardId(widget.dashboardId);
      setEmbeddable(widget.embeddable);
    }
  }, [widget?._id]);

  const onSaveWidget = async () => {
    if (!title.trim()) {
      message.open({
        type: 'error',
        content: 'Widget title is required',
      });
    } else if (!dashboardId && !propsDashboardId) {
      message.open({
        type: 'error',
        content: 'Please select a dashboard',
      });
    } else {
      let fn: Promise<Widget>;

      if (!widget) {
        fn = api.widgets.create({
          title,
          updateInBackground,
          updateSchedule: schedule,
          dashboardId: propsDashboardId || dashboardId,
          queryId,
          embeddable,
          resultRepresentationSubTypeOverride,
        });
      } else {
        fn = api.widgets.patch(widget._id, {
          title,
          updateInBackground,
          updateSchedule: schedule,
          embeddable,
        }) as unknown as Promise<Widget>;
      }

      setLoading(true);
      fn.then((response) => {
        notification.success({
          message: !widget ? 'Widget created' : 'Widget updated',
        });

        if (onSuccess) {
          onSuccess(response);
        }

        reset();
      }).catch(() => {
        notification.error({
          message: 'Failed to create widget',
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <div className="d-flex flex-column gap-4">
        <div ref={titleRef}>
          <Input
            value={title}
            onChange={(e) => { setTitle(e.target.value); }}
            placeholder="Widget Title"
          />
        </div>

        {
          !propsDashboardId && (
            <div ref={dashboardSelectRef}>
              <SelectDashboard
                value={dashboardId}
                onChange={setDashboardId}
                projectId={projectId}
              />
            </div>
          )
        }

        <div>
          <Button
            ref={updateInBackgroundRef}
            type="text"
            className="d-flex justify-content-between align-items-center text-dark"
            onClick={() => {
              setUpdateInBackground((prev) => !prev);
            }}
            style={{
              padding: 0,
            }}
            block
          >
            <span>Update in background</span>
            <Switch checked={updateInBackground} />
          </Button>
          <span className="text-muted mt-2">
            Choose whether to update the data for this widget in the background or not.
          </span>
        </div>

        {
          updateInBackground && (
            <div>
              <p>Update schedule</p>
              <div className="d-flex align-items-center gap-2">
                <span>Every</span>
                <InputNumber
                  placeholder="X"
                  value={schedule.interval}
                  onChange={(value) => {
                    setSchedule((prev) => ({
                      ...prev,
                      interval: value || 0,
                    }));
                  }}
                  style={{
                    width: 100,
                  }}
                />
                <Select
                  options={[
                    { label: 'Minutes', value: 'minute' },
                    { label: 'Hours', value: 'hour' },
                    { label: 'Days', value: 'day' },
                    { label: 'Weeks', value: 'week' },
                  ]}
                  className="flex-grow-1"
                  value={schedule.unit}
                  onChange={(value) => {
                    setSchedule((prev) => ({ ...prev, unit: value }));
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>Start time</span>
                <DatePicker
                  value={dayjs(schedule.startTime)}
                  onChange={(value) => {
                    setSchedule((prev) => ({
                      ...prev,
                      startTime: value ? value.toDate() : new Date(),
                    }));
                  }}
                  className="mt-2"
                  showTime
                />
              </div>
            </div>
          )
        }

        <div>
          <Button
            ref={embeddableRef}
            type="text"
            className="d-flex justify-content-between align-items-center"
            onClick={() => {
              setEmbeddable((prev) => !prev);
            }}
            style={{
              padding: 0,
            }}
            block
          >
            <span>Embeddable</span>
            <Switch checked={embeddable} />
          </Button>
          <span className="text-muted mt-2">
            Choose whether this widget is embeddable or not.
            You can embed this widget in your website only if this option is enabled.
          </span>
        </div>
      </div>
      <Button
        ref={saveWidgetRef}
        type="primary"
        className="mt-5"
        onClick={onSaveWidget}
        loading={loading}
        disabled={loading}
        block
      >
        Save Widget
      </Button>
      {
        onboardingEnabled
        && titleRef.current
        && showTour
        && !tourShownAlready
        && (
          <Tour
            steps={[
              {
                title: 'Widget Title',
                description: 'Enter a title for your widget, this will be displayed on the dashboard.',
                target: () => titleRef.current,
                placement: 'bottomLeft',
              },
              {
                title: 'Dashboard',
                description: 'Select a dashboard to add this widget to. Dashboards are used to organize your widgets.',
                target: () => dashboardSelectRef.current,
                placement: 'bottomLeft',
              },
              {
                title: 'Update in background',
                description: 'Choose whether to update the data for this widget in the background or not.',
                target: () => updateInBackgroundRef.current,
                placement: 'bottomLeft',
              },
              {
                title: 'Embeddable',
                description: 'Choose whether this widget is embeddable or not. You can embed this widget in your website only if this option is enabled.',
                target: () => embeddableRef.current,
                placement: 'bottomLeft',
              },
              {
                title: 'Save Widget',
                description: 'Click here to save your widget.',
                target: () => saveWidgetRef.current,
                placement: 'bottomLeft',
              },
            ]}
            open={tourOpen}
            onClose={() => {
              setTourOpen(false);
              setCreateWidgetTourShown();
            }}
          />
        )
      }
    </div>
  );
}
