import React from 'react';

type ErrorBoundaryProps = {
  fallback: (error: any) => React.ReactNode;
  children: React.ReactNode;
  updateKey?: string;
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error: any; }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: false };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { updateKey } = this.props;
    if (prevProps.updateKey !== updateKey) {
      this.setState({ error: false });
    }
  }

  componentDidCatch(error: any) {
    console.error(error);
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { fallback, children } = this.props;
    if (error) {
      // You can render any custom fallback UI
      return fallback(error);
    }

    // eslint-disable-next-line react/destructuring-assignment
    return children;
  }
}
