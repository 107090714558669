import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';
import guessSeriesAndLabels from '../utils';

class TableToPieChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.TABLE;

  to = ResultRepresentationSubType.PIE_CHART;

  transformData(): any {
    const guess = guessSeriesAndLabels(this.resultRepresentation.data);

    return {
      series: guess?.series,
      options: {
        chart: {
          type: 'pie',
        },
        labels: guess?.labels,
      },
    };
  }
}

export default TableToPieChartTransformer;
