import { Button, Dropdown } from 'antd';
import SelectBox from '../../common/components/SelectBox';

export type InsightFlowSelectBoxOption = {
  title: string;
  subtitle?: string;
  value?: any;
};

interface InsightFlowSelectBoxProps {
  options: InsightFlowSelectBoxOption[];
  value?: InsightFlowSelectBoxOption['value'][];
  onChange?: (value: InsightFlowSelectBoxOption['value'][]) => void;
  maxItems?: number;
  minItems?: number;
  addButtonTitle?: string;
  expanded?: boolean;
}

export default function InsightFlowSelectBox(props: InsightFlowSelectBoxProps) {
  const {
    options,
    value = [],
    onChange,
    maxItems,
    minItems,
    addButtonTitle = 'Add',
    expanded,
  } = props;

  function optionIsSelected(option: InsightFlowSelectBoxOption) {
    return value.includes(option.value);
  }

  const isSingleMode = maxItems === 1;

  const availableOptions = options.filter((option) => !optionIsSelected(option));

  const selectedOptions = options.filter(optionIsSelected);

  const visibleOptions = expanded ? options : selectedOptions;

  const selectItem = (item: InsightFlowSelectBoxOption) => {
    if (onChange) {
      if (isSingleMode) {
        onChange([item.value]);
      } else {
        const newValue = [
          ...value,
          item.value,
        ];

        if (!maxItems || newValue.length <= maxItems) {
          onChange(newValue);
        }
      }
    }
  };

  const unselectItem = (item: InsightFlowSelectBoxOption) => {
    if (onChange) {
      if (!isSingleMode) {
        const newValue = value.filter((el) => el !== item.value);

        if (!minItems || newValue.length >= minItems) {
          onChange(newValue);
        }
      }
    }
  };

  const isSingle = maxItems === 1;

  return (
    <>
      <div className="d-flex flex-column gap-2">
        {
          visibleOptions.map((item) => {
            const selected = value.includes(item.value);
            const active = expanded && selected;

            return (
              <SelectBox
                key={String(item.title)}
                title={item.title}
                subtitle={item.subtitle}
                onSelect={() => {
                  if (selected) {
                    unselectItem(item);
                  } else {
                    selectItem(item);
                  }
                }}
                active={active}
                showCloseIcon={!expanded}
              />
            );
          })
        }
      </div>
      {
        availableOptions.length > 0 && !expanded && (
          <Dropdown
            overlayStyle={{
              minWidth: 300,
            }}
            menu={{
              items: availableOptions.map((item) => ({
                key: item.title,
                label: (
                  <div>
                    <p className="m-0 fw-bold">
                      {item.title}
                    </p>
                    {
                      item.subtitle && (
                        <p className="text-muted m-0">
                          {
                            item.subtitle
                          }
                        </p>
                      )
                    }
                  </div>
                ),
                onClick: () => {
                  selectItem(item);
                },
              })),
            }}
          >
            <Button type="link">
              {!isSingle ? `+ ${addButtonTitle}` : '+ More'}
            </Button>
          </Dropdown>
        )
      }
    </>
  );
}
