import React from 'react';
import { Col, Row } from 'antd';

export default function ErrorPage() {
  const params = new URLSearchParams(window.location.search);
  const errorCode = params.get('code') || 'unknown';

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#0b163f',
        boxSizing: 'border-box',
        backgroundImage: 'url("/page-header-bg.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
      }}
      className="d-flex align-items-center p-5"
    >
      <Row justify="center" className="flex-grow-1">
        <Col span={24} md={12} lg={10} xl={8} xxl={6}>
          <div className="d-flex flex-column align-items-center mb-5">
            <img src="/logo-white.svg" height={50} alt="logo" />
          </div>
          <h1 className="text-center text-white">
            There was an error while processing your request,
            please contact support.
          </h1>
          <h2 className="text-center text-white">
            Error code:
            {' '}
            {errorCode}
          </h2>
        </Col>
      </Row>
    </div>
  );
}
