import { useState } from 'react';
import { Button, Card } from 'antd';
import { ParameterData, ParameterScope } from '../../api';
import ParameterBuilder from './ParameterBuilder';

interface QueryParametersManagerProps {
  parameters: ParameterData[];
  onChange: (values: ParameterData[]) => void;
}

export default function QueryParametersManager(props: QueryParametersManagerProps) {
  const {
    parameters, onChange,
  } = props;
  const [mode, setMode] = useState<'view' | 'create'>('view');

  if (mode === 'view' && parameters.length) {
    return (
      <div>
        {
          parameters.map((parameter, index) => (
            <Card size="small" className="mb-2">
              <div className="d-flex align-items-center justify-content-between">
                <b style={{ flex: 1 }}>{parameter.name}</b>
                <span style={{ flex: 1 }}>{parameter.value}</span>
                <Button
                  onClick={() => {
                    const newParameters = [...parameters];
                    newParameters.splice(index, 1);
                    onChange(newParameters);
                  }}
                  disabled={Boolean(parameter.templateId)}
                  size="small"
                  danger
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))
        }
        <div className="d-flex justify-content-end mt-4">
          <Button onClick={() => setMode('create')}>Add parameter</Button>
        </div>
      </div>
    );
  }

  return (
    <ParameterBuilder
      onSubmit={(newParam) => {
        onChange([...parameters, newParam]);
        setMode('view');
      }}
      onCancel={() => {
        setMode('view');
      }}
      scope={ParameterScope.WIDGET}
    />
  );
}
