import ResultRepresentationManager from './ResultRepresentationManager';
import ScatterChartToTableTransformer from '../transformers/ScatterChartToTableTransformer';

class ScatterChartResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new ScatterChartToTableTransformer(this.representation),
  ];
}

export default ScatterChartResultRepresentationManager;
