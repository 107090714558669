import { Select } from 'antd';
import { useState } from 'react';
import useQuery from '../../common/hooks/useQuery';
import api, { DataSource } from '../../api';

interface DataSourceSelectProps {
  entityIds?: DataSource['_id'][];
  value?: DataSource['_id'];
  onChange?: (value: DataSource['_id']) => void;
  extraOptions?: { value: DataSource['_id'] | null; label: string }[];
}

export default function DataSourceSelect(props: DataSourceSelectProps) {
  const {
    value, onChange, entityIds, extraOptions,
  } = props;
  const [search, setSearch] = useState('');
  const [response, loading] = useQuery(() => api.dataSources.find({
    query: {
      name: {
        $regex: search,
        $options: 'i',
      },
      entityId: entityIds ? { $in: entityIds } : undefined,
      $limit: 100,
    },
  }), [search, entityIds]);

  return (
    <Select
      showSearch
      placeholder="Select a data source"
      options={[
        ...(extraOptions || []),
        // @ts-ignore
        ...(response?.data || []).map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]}
      loading={loading}
      onSearch={(searchValue) => {
        setSearch(searchValue);
      }}
      filterOption={false}
      value={value}
      onChange={onChange}
    />
  );
}
