import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class BarChartToPieChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.BAR_CHART;

  to = ResultRepresentationSubType.PIE_CHART;

  transformData() {
    let series = this.resultRepresentation.data.series[0]?.data || [];
    series = series.map(Number);

    return {
      series,
      options: {
        labels: this.resultRepresentation.data.options?.xaxis?.categories,
        chart: {
          type: 'pie',
        },
      },
    };
  }
}

export default BarChartToPieChartTransformer;
