import {
  ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Avatar, Button, Col, Drawer, notification, Row,
} from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import DataSourceAutocompleteInput from '../../data-sources/components/DataSourceAutocompleteInput';
import api, {
  Dashboard,
  DataSource,
  OnboardingStepKey,
  ParameterData,
  Query,
  QueryExecutionMode,
  QueryExecutionType, Widget,
} from '../../api';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useActiveProject from '../../common/hooks/useActiveProject';
import QueryExecutionView from '../../query-executions/components/QueryExecutionView';
import WidgetSetup from '../../widgets/components/WidgetSetup';
import Spin from '../../common/components/Spin';
import Typewriter from '../../common/components/TypeWriter';
import useOnboarding from '../../onboarding/hooks/useOnboarding';

interface ChatMessageWrapperProps {
  children: ReactNode;
  userAvatarSrc?: string;
  userName: string;
}

function ChatMessageWrapper(props: ChatMessageWrapperProps) {
  const { children, userAvatarSrc, userName } = props;

  return (
    <div className="d-flex justify-content-between mb-4" style={{ gap: 10 }}>
      <Avatar
        style={{ minWidth: 32 }}
        size={32}
        src={userAvatarSrc}
      >
        {userName[0]}
      </Avatar>
      <div style={{ flex: 1, maxWidth: 'calc(100% - 42px)' }}>
        <p className="fw-bold m-0">{userName}</p>
        <div className="mt-1">{children}</div>
      </div>
    </div>
  );
}

const loadingMessages = [
  'Analyzing data patterns...',
  'Computing statistical models...',
  'Processing analytical insights...',
  'Running advanced analytics...',
  'Evaluating data correlations...',
  'Synthesizing analytical results...',
  'Executing complex queries...',
  'Interpreting analytical findings...',
  'Extracting meaningful data...',
  'Profiling data segments...',
  'Deriving analytical insights...',
  'Cross-referencing analytical elements...',
  'Crafting data-driven solutions...',
  'Tabulating data insights...',
  'Illuminating data insights...',
  'Navigating data layers...',
  'Reviewing data sequences...',
  'Sorting through data points...',
  'Unearthing data treasures...',
  'Visualizing data connections...',
  'Wrestling with data challenges...',
  'X-raying data structures...',
  'Yielding comprehensive analyses...',
  'Accumulating data wisdom...',
  'Balancing data equations...',
  'Characterizing data sets...',
  'Delving into data mysteries...',
  'Encoding analytical logic...',
  'Framing data perspectives...',
  'Gleaning insights from data...',
  'Harmonizing data elements...',
  'Investigating data anomalies...',
  'Juxtaposing data variables...',
  'Knitting together data points...',
  'Layering analytical filters...',
  'Mining for data insights...',
  'Nurturing data understanding...',
  'Orchestrating data symphonies...',
  'Pursuing data excellence...',
  'Quarrying data depths...',
  'Resolving data puzzles...',
  'Sifting through data layers...',
  'Venturing into data jungles...',
  'Widening data horizons...',
  'Exploring data frontiers...',
  'Zeroing in on data insights...',
  'Amplifying data narratives...',
  'Building data bridges...',
  'Cultivating data knowledge...',
  'Demystifying data queries...',
  'Enriching data explorations...',
  'Focusing on data specifics...',
  'Generating data revelations...',
  'Honing data acumen...',
  'Illuminating data paths...',
];

const helpMessages = [
  'Hello there! How can I assist you with your data today?',
  'Greetings! What can I do for you in the world of business intelligence?',
  'Welcome! How may I enhance your data analysis experience today?',
  'Hi! Ready to dive into some data? How can I help?',
  'Good day! What business insights can I help you uncover today?',
  'Hello! How can I make your data journey easier today?',
  'Welcome back! What\'s on your data agenda today?',
  'Hi there! How can I support your business intelligence needs?',
  'Greetings! What data challenges can I help you solve today?',
  'Welcome! Let\'s make your data work for you. How can I assist?',
  'Hello! Ready to transform your data into insights? How can I help?',
  'Good day! How can I assist in your quest for smarter data analysis?',
  'Welcome to your data dashboard! How can I be of service today?',
  'Hi! Let\'s explore your data together. What can I help with?',
  'Hello! Eager to crack some numbers? How can I assist?',
  'Greetings! What business intelligence magic can I help you with today?',
  'Welcome! How can I help you make the most of your data today?',
  'Hi there! Ready to unlock new insights? How can I assist?',
  'Good day! What data-driven decisions can I help you with today?',
  'Hello! Let\'s achieve some data breakthroughs. How can I help?',
];

function getLoadingMessage() {
  const index = Math.floor(Math.random() * loadingMessages.length);
  return loadingMessages[index];
}

function getGreetingMessage() {
  const index = Math.floor(Math.random() * helpMessages.length);
  return helpMessages[index];
}

function getRandomSuggestedQuestions(suggestedQuestions: string[]) {
  let i = 0;
  const result: string[] = [];
  const maxQuestions = 4;

  if (suggestedQuestions.length <= maxQuestions) {
    return suggestedQuestions;
  }

  while (i < maxQuestions) {
    const index = Math.floor(Math.random() * suggestedQuestions.length);
    const question = suggestedQuestions[index];

    if (!result.includes(question)) {
      result.push(question);
      i += 1;
    }
  }

  return result;
}

interface DataSourceChatProps {
  heightSubtract?: number;
  paddingTop?: number;
  parameters?: ParameterData[];
  dataSource?: DataSource;
  dashboardId?: Dashboard['_id'];
  onWidgetCreated?: (widget: Widget) => void;
}

export default function DataSourceChat(props: DataSourceChatProps) {
  const {
    dataSource,
    parameters,
    dashboardId,
    onWidgetCreated,
    heightSubtract = 0,
    paddingTop = 0,
  } = props;
  const [prompt, setPrompt] = useState('');
  const [chatId, setChatId] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingPrompt, setLoadingPrompt] = useState('');
  const [error, setError] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(getLoadingMessage());
  const organization = useActiveOrganization();
  const project = useActiveProject();
  const [queries, setQueries] = useState<Query[]>([]);
  const [saveQuery, setSaveQuery] = useState<Query>();
  const [greetingMessage] = useState(getGreetingMessage());
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const onboarding = useOnboarding();
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>(
    getRandomSuggestedQuestions(dataSource?.suggestedQuestions || []),
  );

  useEffect(() => {
    setSuggestedQuestions(getRandomSuggestedQuestions(dataSource?.suggestedQuestions || []));
  }, [dataSource?._id]);

  const reset = useCallback(() => {
    setPrompt('');
    setQueries([]);
    setChatId(undefined);
    setLoading(false);
    setError('');
  }, []);

  useEffect(() => {
    reset();
  }, [project?._id]);

  function scrollToBottom() {
    if (containerRef.current) {
      containerRef.current?.scrollTo({
        top: containerRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  const onSubmit = async (newPrompt?: string) => {
    const promptToUse = newPrompt || prompt;

    if (newPrompt) {
      setPrompt(newPrompt);
    }

    if (onboarding.activeStep?.key === OnboardingStepKey.START_A_CHAT) {
      onboarding.goToNextStep();
    }

    if (organization && project) {
      setLoadingPrompt(promptToUse);
      setLoading(true);
      setPrompt('');
      setError('');
      setTimeout(scrollToBottom, 200);

      try {
        const query = await api.queries.create({
          mode: QueryExecutionMode.AI,
          prompt: promptToUse,
          organizationId: organization._id,
          projectId: project._id,
          dataSourceId: dataSource?._id,
          chatMode: true,
          disableDbaql: true,
          chatId, // If empty, it will create a new chat
          parameters,
        });
        setQueries((prev) => [...prev, query]);

        setChatId(query.chatId);
        setLoadingPrompt('');

        setTimeout(scrollToBottom, 200);
      } catch (e: any) {
        notification.error({
          message: 'Error',
          description: e.message,
        });
        setError('Something went wrong. Please try again later.');
      }

      setLoading(false);
      setLoadingMessage(getLoadingMessage());
    }
  };

  const height = `calc(100vh - ${heightSubtract}px)`;
  const containerHeight = 'calc(100% - 62px)';
  let saveWidgetOnboardingShown = false;

  return (
    <>
      <div style={{ height, position: 'relative' }}>
        <div
          style={{
            position: 'absolute', top: paddingTop - 48, left: 16, right: 16, zIndex: 2,
          }}
          className="d-flex justify-content-end"
        >
          <div>
            {
              chatId && (
                <Button onClick={reset}>
                  New Chat
                  <MessageOutlined />
                </Button>
              )
            }
          </div>
        </div>
        <div
          style={{
            maxHeight: containerHeight,
            height: containerHeight,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={containerRef}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                minHeight: (queries.length === 0 && !loading) ? '100%' : 'auto',
              }}
            >
              <div style={{ paddingTop }}>
                <div className="d-flex flex-column align-items-center">
                  <Avatar src="/logo-only-140.jpg" size={60} />
                  <h2>{greetingMessage}</h2>
                </div>
              </div>
              {
                onboarding.activeStep?.key === OnboardingStepKey.START_A_CHAT && (
                  <div
                    className="shadow mt-4"
                    style={{
                      maxWidth: 600,
                      borderRadius: 20,
                      display: 'flex',
                      justifyContent: 'space-between',
                      overflow: 'hidden',
                    }}
                  >
                    <div className="p-4" style={{ flex: 2 }}>
                      <div
                        style={{ zIndex: 1, position: 'relative' }}
                        className="my-3 pe-5 me-5"
                      >
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h2 className="m-0">Start a Chat</h2>
                        </div>
                        <h4
                          className="m-0"
                          style={{
                            fontSize: 15,
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontWeight: '500!important',
                          }}
                        >
                          Ask a question to get started.
                          {' '}
                          {
                            (dataSource?.suggestedQuestions?.length || 0) > 0
                              ? 'You can also choose from the suggested questions below.'
                              : 'Feel free to ask anything.'
                          }
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        background: 'url(/img/robot-hand-pointing.jpeg)',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                )
              }
              {
                dataSource?.suggestedQuestions && (
                  <div className="d-flex justify-content-center mt-4">
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 10,
                        maxWidth: 600,
                      }}
                    >
                      {
                        suggestedQuestions.map((q) => (
                          <div
                            className="shadow-sm p-3 border shadow-hover"
                            style={{ cursor: 'pointer', borderRadius: 12 }}
                            onClick={() => {
                              onSubmit(q);
                            }}
                            tabIndex={0}
                            role="button"
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                onSubmit(q);
                              }
                            }}
                          >
                            <p className="m-0" style={{ fontSize: 15 }}>{q}</p>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
            </div>
            {
              (queries.length > 0 || ((loading || error) && loadingPrompt)) && (
                <div className="mt-4">
                  <Row justify="center">
                    <Col span={24} md={22} lg={20} xl={18} xxl={14}>
                      <div
                        className="d-flex justify-content-end flex-column"
                        style={{
                          fontSize: 16,
                          paddingTop: 16,
                        }}
                      >
                        <div>
                          {
                            queries.map((query, index) => {
                              const highlightSaveAsWidgetButton = !saveWidgetOnboardingShown
                                && onboarding
                                  .activeStep
                                  ?.key === OnboardingStepKey.CREATE_A_WIDGET
                                && query.execution?.type === QueryExecutionType.RESULT;

                              if (highlightSaveAsWidgetButton) {
                                saveWidgetOnboardingShown = true;
                              }

                              return (
                                <div className="mb-4" key={query._id}>
                                  <ChatMessageWrapper userName="You">
                                    {query.prompt}
                                  </ChatMessageWrapper>
                                  <ChatMessageWrapper
                                    userName="InsightBaseAI"
                                    userAvatarSrc="/logo-only-140.jpg"
                                  >
                                    {
                                      query.execution && (
                                        <div style={{ maxWidth: '100%', overflow: 'auto' }}>
                                          <QueryExecutionView
                                            id={query.execution._id}
                                            resultType={query.execution.resultType}
                                            responseText={query.execution.responseText}
                                            result={query.execution.result}
                                            status={query.execution.status}
                                            calculationMethod={query.execution.calculationMethod}
                                            mode={query.execution.mode}
                                            prompt={query.execution.prompt}
                                            details={query.execution.details}
                                            showSaveAsWidgetButton
                                            onSaveAsWidgetClick={() => void setSaveQuery(query)}
                                            errorReason={query.execution.errorReason}
                                            resultRepresentationBuilderOptions={
                                              query
                                                .execution
                                                .resultRepresentationBuilderOptions
                                            }
                                            resultRepresentation={query
                                              .execution
                                              .resultRepresentation}
                                            resultRepresentationOverride={query
                                              .execution
                                              .resultRepresentationOverride}
                                            onCodeEditClick={() => {
                                              window.dispatchEvent(
                                                new CustomEvent(
                                                  'query-code-edit-click',
                                                  {
                                                    detail: {
                                                      rawCode: query.execution?.details?.rawCode,
                                                    },
                                                  },
                                                ),
                                              );
                                            }}
                                            onRepresentationTypeEnd={() => {
                                              setTimeout(scrollToBottom, 200);
                                            }}
                                            onExecutionChange={(newExecution) => {
                                              setQueries((prev) => prev.map((q) => {
                                                if (q._id === query._id) {
                                                  return {
                                                    ...q,
                                                    execution: newExecution,
                                                  };
                                                }
                                                return q;
                                              }));
                                            }}
                                            overflowRepresentation
                                            highlightSaveAsWidgetButton={
                                              highlightSaveAsWidgetButton
                                            }
                                            type={query.execution.type}
                                            clarification={query.execution.clarification}
                                            dataSource={dataSource || undefined}
                                            onClarificationSelect={(value) => {
                                              onSubmit(value);
                                            }}
                                            isInactive={index < queries.length - 1}
                                            totalCredits={query.execution.totalCredits}
                                          />
                                        </div>
                                      )
                                    }
                                  </ChatMessageWrapper>
                                </div>
                              );
                            })
                          }
                          {
                            (loading || error) && loadingPrompt && (
                              <div className="mb-4">
                                <ChatMessageWrapper userName="You">
                                  {loadingPrompt}
                                </ChatMessageWrapper>
                                <ChatMessageWrapper
                                  userName="InsightBaseAI"
                                  userAvatarSrc="/logo-only-140.jpg"
                                >
                                  {
                                    loading && (
                                      <div className="d-flex align-items-center gap-3">
                                        <Typewriter
                                          text={loadingMessage}
                                          typingDelay={60}
                                          doneSuffix={(
                                            <>
                                              &nbsp;
                                              <Spin />
                                            </>
                                          )}
                                        />
                                      </div>
                                    )
                                  }
                                  {
                                    error && (
                                      <div>{error}</div>
                                    )
                                  }
                                </ChatMessageWrapper>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            }
          </div>
        </div>
        <Row justify="center">
          <Col span={24} md={22} lg={20} xl={18} xxl={14}>
            <DataSourceAutocompleteInput
              placeholder="Type a question..."
              value={prompt}
              onChange={setPrompt}
              dataSource={dataSource}
              size="large"
              placement="topLeft"
              loading={loading}
              onSubmit={onSubmit}
              parameters={parameters}
              style={{
                minHeight: 52,
                paddingTop: 12,
                paddingBottom: 12,
                fontSize: 16,
              }}
            />
          </Col>
        </Row>
      </div>
      <Drawer
        open={Boolean(saveQuery)}
        onClose={() => void setSaveQuery(undefined)}
        title="Save as Widget"
      >
        {
          saveQuery && (
            <WidgetSetup
              queryId={saveQuery?._id}
              projectId={saveQuery?.projectId}
              dashboardId={dashboardId}
              onSuccess={(widget) => {
                setSaveQuery(undefined);

                if (onboarding?.activeStep?.key === OnboardingStepKey.CREATE_A_WIDGET) {
                  const nextStep = onboarding.goToNextStep();

                  if (nextStep?.url) {
                    navigate(nextStep.url);
                  }
                }

                if (onWidgetCreated) {
                  onWidgetCreated(widget);
                }
              }}
            />
          )
        }
      </Drawer>
    </>
  );
}
