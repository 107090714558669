import { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon';
import {
  AreaChartOutlined,
  BarChartOutlined,
  BoxPlotOutlined,
  CalendarOutlined, CodeOutlined,
  ControlOutlined,
  DotChartOutlined,
  HeatMapOutlined,
  LayoutOutlined,
  LineChartOutlined,
  NumberOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  TableOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import * as React from 'react';
import { ResultRepresentationSubType } from '../../../api';

interface IconProps extends Pick<AntdIconProps, 'size' | 'color'> {}

interface ResultRepresentationSubTypeIconProps extends IconProps {
  subType: ResultRepresentationSubType;
}

export default function ResultRepresentationSubTypeIcon(
  props: ResultRepresentationSubTypeIconProps,
) {
  const { subType, ...iconProps } = props;

  let Icon: React.ForwardRefExoticComponent<IconProps> | undefined;

  switch (subType) {
    case ResultRepresentationSubType.TEXT:
      Icon = TranslationOutlined;
      break;
    case ResultRepresentationSubType.NUMBER:
      Icon = NumberOutlined;
      break;
    case ResultRepresentationSubType.STRING:
      Icon = TranslationOutlined;
      break;
    case ResultRepresentationSubType.DATE:
      Icon = CalendarOutlined;
      break;
    case ResultRepresentationSubType.OBJECT_VIEW:
      Icon = BoxPlotOutlined;
      break;
    case ResultRepresentationSubType.TABLE:
      Icon = TableOutlined;
      break;
    case ResultRepresentationSubType.PIE_CHART:
      Icon = PieChartOutlined;
      break;
    case ResultRepresentationSubType.AREA_CHART:
      Icon = AreaChartOutlined;
      break;
    case ResultRepresentationSubType.LINE_CHART:
      Icon = LineChartOutlined;
      break;
    case ResultRepresentationSubType.BAR_CHART:
      Icon = BarChartOutlined;
      break;
    case ResultRepresentationSubType.SCATTER_CHART:
      Icon = DotChartOutlined;
      break;
    case ResultRepresentationSubType.RADAR_CHART:
      Icon = RadarChartOutlined;
      break;
    case ResultRepresentationSubType.HEATMAP_CHART:
      Icon = HeatMapOutlined;
      break;
    case ResultRepresentationSubType.TREE_MAP_CHART:
      Icon = LayoutOutlined;
      break;
    case ResultRepresentationSubType.CUSTOM_CODE:
      Icon = CodeOutlined;
      break;
    default:
      Icon = ControlOutlined;
  }

  return <Icon {...iconProps} />;
}
