import ResultRepresentationManager from './ResultRepresentationManager';
import NumberToDateTransformer from '../transformers/NumberToDateTransformer';

class NumberRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new NumberToDateTransformer(this.representation),
  ];
}

export default NumberRepresentationManager;
