import { notification } from 'antd';
import api, { Parameter, ParameterData, ParameterScope } from '../../api';
import ParameterBuilder from './ParameterBuilder';

export interface DashboardParameterBuilderProps {
  dashboardId: Parameter['dashboardId'];
  onCancel?: () => void;
  onSuccess?: (parameter: Parameter) => void;
  parameter?: Parameter;
}

export default function DashboardParameterBuilder(props: DashboardParameterBuilderProps) {
  const {
    dashboardId,
    onCancel,
    onSuccess,
    parameter,
  } = props;

  const onSubmit = async (newParam: ParameterData) => {
    try {
      const response = !parameter ? await api.parameters.create({
        ...newParam,
        dashboardId,
      }) : await api.parameters.patch(parameter._id, newParam);

      if (onSuccess) {
        onSuccess(response as Parameter);
      }
    } catch (e) {
      notification.error({
        message: 'Failed to save parameter',
        description: 'Please try again later.',
      });
    }
  };

  return (
    <ParameterBuilder
      parameter={parameter}
      onSubmit={(newParam) => {
        onSubmit(newParam);
      }}
      onCancel={onCancel}
      scope={ParameterScope.DASHBOARD}
    />
  );
}
