import {
  Button, Card, Col, Row,
} from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import useQuery from '../../common/hooks/useQuery';
import api, { Entity } from '../../api';
import Spin from '../../common/components/Spin';
import NotFoundResult from '../../common/components/NotFoundResult';
import AppPage from '../../layout/components/AppPage';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useActiveProject from '../../common/hooks/useActiveProject';

export default function EntitiesPage() {
  const organization = useActiveOrganization();
  const project = useActiveProject();

  const [entities, loading] = useQuery<Entity[]>(
    async () => {
      if (!project) {
        return [];
      }

      const response = await api.entities.find({
        query: {
          $limit: 100,
          $sort: {
            createdAt: -1,
          },
          $select: ['_id', 'displayName', 'createdAt'],
          organizationId: organization?._id,
          projectId: project?._id,
        },
      });

      return response.data;
    },
    [organization?._id, project?._id],
    !!project,
  );

  if (loading) {
    return (
      <Spin centered />
    );
  }

  return (
    <AppPage
      title="Entity"
      actionButtons={[
        <Link to="create">
          <Button type="primary">Create Entity</Button>
        </Link>,
      ]}
    >
      {
        (!loading && (!entities || entities.length === 0)) && (
          <div className="mt-5">
            <NotFoundResult
              title="No Entity Found"
              action={(
                <Link to="create">
                  <Button type="primary" size="large">
                    Create Entity
                  </Button>
                </Link>
              )}
            />
          </div>
        )
      }
      {
        entities && (
          <Row>
            <Col span={24} md={12} xl={6}>
              {
                entities && entities.map((entity, index) => (
                  <Card
                    key={entity._id}
                    className={index > 0 ? 'mt-2' : undefined}
                    size="small"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <b>{entity.displayName}</b>
                      <div className="d-flex align-items-center gap-2">
                        <small className="text-secondary">
                          {dayjs(entity.createdAt).format('MMM DD')}
                        </small>
                        <Link to={`${entity._id}`}>
                          <Button
                            type="default"
                            size="small"
                            icon={<EditOutlined />}
                          />
                        </Link>
                      </div>
                    </div>
                  </Card>
                ))
              }
            </Col>
          </Row>
        )
      }
    </AppPage>
  );
}
