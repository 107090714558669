import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';

type UseQuery<Response> = [
  Response | undefined,
  boolean,
  Dispatch<SetStateAction<Response | undefined>>,
];

export default function useQuery<Response>(
  getData: () => Promise<Response | undefined>,
  deps: any[] = [],
  isReady = true,
): UseQuery<Response> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Response | undefined>(undefined);

  useEffect(() => {
    if (isReady) {
      setLoading(true);
      getData().then((response) => {
        setData(response);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [...deps, isReady]);

  return [data, loading, setData];
}
