import { CalendarOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class DateResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = CalendarOutlined;

  title = 'Date';

  type = ResultRepresentationType.DATE;
}

export default DateResultRepresentationSubTypeConfig;
