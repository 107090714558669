import { Button, Popover } from 'antd';
import { useState } from 'react';
import RangePicker from '../../common/components/RangePicker';
import CustomDateSelection, { DatePreset } from '../../common/types/CustomDateSelection';
import { getDatePresets, getLastYearPreset } from '../../common/utils/dates';

interface InsightFlowDateFilterProps {
  value: CustomDateSelection;
  onChange: (value: CustomDateSelection) => void;
}

export function getDefaultPreset(): CustomDateSelection {
  return getLastYearPreset();
}

export function getDefaultValue(): CustomDateSelection {
  return getDefaultPreset();
}

export default function InsightFlowDateFilter(props: InsightFlowDateFilterProps) {
  const datePresets: CustomDateSelection[] = getDatePresets();
  const { value, onChange } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Button.Group size="small">
      {
        datePresets.map((preset) => (
          <Button
            key={preset.label}
            type={preset.preset === value.preset ? 'primary' : 'default'}
            onClick={() => {
              onChange(preset);
            }}
          >
            {preset.label}
          </Button>
        ))
      }
      <Popover
        open={popoverOpen}
        onOpenChange={setPopoverOpen}
        content={(
          <RangePicker
            value={value}
            onChange={onChange}
            showPresets={false}
          />
        )}
        placement="bottomRight"
      >
        <Button
          type={value.preset === DatePreset.CUSTOM ? 'primary' : 'default'}
        >
          Custom
        </Button>
      </Popover>
    </Button.Group>
  );
}
