import { useRecoilValue, useSetRecoilState } from 'recoil';
import useActiveProject from '../../common/hooks/useActiveProject';
import GeneralProjectSettings from '../components/GeneralProjectSettings';
import { globalAtom } from '../../common/state/global-state';
import AppTabbedPage from '../../layout/components/AppTabbedPage';

export default function ProjectSettingsPage() {
  const project = useActiveProject();
  const setGlobalState = useSetRecoilState(globalAtom);
  const { loadingActiveProject } = useRecoilValue(globalAtom);

  if (!project && !loadingActiveProject) {
    return <h1 className="text-center">Project not found</h1>;
  }

  return (
    <AppTabbedPage
      loading={loadingActiveProject}
      title="Project Settings"
      items={project ? [
        {
          key: 'general',
          label: 'General',
          children: (
            <GeneralProjectSettings
              project={project}
              onUpdateSuccess={(response) => {
                setGlobalState((prev) => ({
                  ...prev,
                  project: response,
                  projects: prev.projects.map((p) => {
                    if (p._id === response._id) {
                      return response;
                    }

                    return p;
                  }),
                }));
              }}
            />
          ),
        },
      ] : []}
    />
  );
}
