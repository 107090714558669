import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class TreeMapChartToPieChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.TREE_MAP_CHART;

  to = ResultRepresentationSubType.PIE_CHART;

  transformData(): any {
    const data = this.resultRepresentation.data.series[0].data?.map((item: any) => item.y);
    const labels = this.resultRepresentation.data.series[0].data?.map((item: any) => item.x);

    return {
      series: data,
      options: {
        chart: {
          type: 'pie',
        },
        labels,
      },
    };
  }
}

export default TreeMapChartToPieChartTransformer;
