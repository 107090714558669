import {
  Button,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from '../components/SignUpForm';
import Spin from '../../common/components/Spin';
import api from '../../api';
import AuthPageLayout from '../layouts/AuthPageLayout';

const REQUIRE_CODE = false;

export default function SignUpPage() {
  // Get the code from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const [codeValid, setCodeValid] = useState(true);
  const [loading, setLoading] = useState(Boolean(code));

  useEffect(() => {
    if (code) {
      setLoading(true);
      setCodeValid(false);
      api.users.checkInviteCode({ code }).then((res) => {
        setCodeValid(res.isValid);
      }).catch(() => {
        setCodeValid(false);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [code]);

  const signUpClosed = REQUIRE_CODE ? (!code || !codeValid) : false;

  return (
    <AuthPageLayout>
      <div className="bg-transparent">
        <h1 className="mt-0">
          Sign Up
        </h1>
        {
          loading ? (
            <Spin centered />
          ) : (
            <>
              <span />
              {
                signUpClosed ? (
                  <p>
                    We&apos;re scaling up our infrastructure,
                    and we&apos;re currently not accepting new users.
                    {' '}
                    Please check back later.
                  </p>
                ) : (
                  <>
                    <SignUpForm inviteCode={code || undefined} />
                    <div className="mt-5">
                      <Link to="/">
                        <Button type="link">
                          Already have an account? Sign In.
                        </Button>
                      </Link>
                    </div>
                  </>
                )
              }
            </>
          )
        }
      </div>
    </AuthPageLayout>
  );
}
