/* eslint-disable */
import { ReactNode, useState } from 'react';

interface Item {
  value: any;
  label: string;
  content: ReactNode;
}

interface SelectWithContentProps {
  onChange?: (value: Item['value']) => void;
  items: Item[];
  contentFooter?: ReactNode;
  footer?: ReactNode;
  trigger?: 'click' | 'hover';
}

export default function SelectWithContent(props: SelectWithContentProps) {
  const {
    items,
    footer,
    contentFooter,
    onChange,
    trigger = 'click',
  } = props;

  const [hoverItem, setHoverItem] = useState<Item>();
  const [activeItem, setActiveItem] = useState<Item>();

  // Use the current hover item to get the content
  // the content stored in the state is not good because
  // it is not updated when the items change
  const content = items.find((item) => item.value === activeItem?.value)?.content;

  function getItemBackgroundColor(item: Item) {
    if (activeItem?.value === item.value) {
      return '#f8f8f8';
    }

    if (hoverItem?.value === item.value) {
      return '#f5f5f5';
    }

    return 'transparent';
  }

  return (
    <div className="h-100">
      <div className="d-flex gap-2 h-100" style={{ alignItems: 'stretch' }}>
        <div
          className="d-flex flex-column"
          style={{ minWidth: 120 }}
        >
          {
            items.map((item) => (
              <div
                key={String(item.value)}
                onMouseEnter={() => {
                  setHoverItem(item);

                  if (trigger === 'hover') {
                    setActiveItem(item);

                    if (onChange) {
                      onChange(item.value);
                    }
                  }
                }}
                onMouseOut={() => {
                  setHoverItem(undefined);
                }}
                style={{
                  backgroundColor: getItemBackgroundColor(item),
                  padding: '3px 10px',
                  cursor: 'pointer',
                  color: activeItem?.value === item.value ? 'rgb(22, 119, 255)' : 'black',
                }}
                onClick={() => {
                  if (trigger === 'click') {
                    setActiveItem(item);

                    if (onChange) {
                      onChange(item.value);
                    }
                  }
                }}
              >
                {item.label}
              </div>
            ))
          }
        </div>
        {
          activeItem && (
            <div className="d-flex flex-column" style={{ alignItems: 'stretch' }}>
              <div style={{ flex: 1 }}>
                {content}
              </div>
              {contentFooter}
            </div>
          )
        }
      </div>
      {footer}
    </div>
  );
}
