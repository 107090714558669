import {
  Button, Popover, Tag, TagProps, Tooltip,
} from 'antd';
import { useMemo, useState } from 'react';
import {
  DataSource,
  DataSourceTableConfig,
  Entity,
  EntityField,
  InsightFlowFilter,
} from '../../../api';
import SelectWithContent from '../../../common/components/SelectWithContent';
import EntityFieldFilter from './EntityFieldFilter';

interface InsightFlowFiltersProps {
  fields: EntityField[];
  table?: DataSourceTableConfig;
  value?: InsightFlowFilter[];
  onChange?: (filters: InsightFlowFilter[]) => void;
  dataSourceId: string;
  entities: Entity[];
  dataSource: DataSource;
}

interface FilterTagProps extends Partial<TagProps> {
  label: string;
}

function FilterTag(props: FilterTagProps) {
  const { label, ...tagProps } = props;
  const context = useMemo(() => {
    let isOverflowing = false;
    let displayLabel = label;

    if (displayLabel.length > 40) {
      isOverflowing = true;
      displayLabel = `${displayLabel.slice(0, 40)}...`;
    }

    return {
      isOverflowing,
      displayLabel,
    };
  }, [label]);

  const { displayLabel, isOverflowing } = context;

  const content = (
    <Tag
      key={label}
      color="geekblue"
      closeIcon
      {...tagProps}
    >
      {displayLabel}
    </Tag>
  );

  if (isOverflowing) {
    return (
      <Tooltip title={label}>
        {content}
      </Tooltip>
    );
  }

  return content;
}

export default function InsightFlowFilters(props: InsightFlowFiltersProps) {
  const {
    fields,
    table,
    onChange,
    value = [],
    dataSourceId,
    entities,
    dataSource,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="d-flex align-items-center gap-2 flex-wrap">
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger={open ? 'click' : 'hover'}
        content={(
          <div className="d-flex" style={{ minHeight: 200, alignItems: 'stretch' }}>
            <div>
              <SelectWithContent
                items={fields.map((field) => ({
                  value: field.name,
                  label: field.displayName,
                  content: (
                    <EntityFieldFilter
                      key={field.name}
                      field={field}
                      table={table}
                      onAddFilter={(filter) => {
                        if (onChange) {
                          onChange([...value, filter]);
                        }

                        setOpen(false);
                      }}
                      dataSourceId={dataSourceId}
                      entities={entities}
                      dataSource={dataSource}
                    />
                  ),
                }))}
              />
            </div>
          </div>
        )}
        placement="bottomLeft"
        overlayStyle={{
          borderRadius: 0,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Button type="link">
          + Add Filter
        </Button>
      </Popover>
      {
        value.map((filter) => (
          <FilterTag
            key={filter.label}
            label={filter.label}
            onClose={() => {
              if (onChange) {
                onChange(value.filter((item) => item.label !== filter.label));
              }
            }}
          />
        ))
      }
    </div>
  );
}
