import {
  Button, Form, Input, notification,
} from 'antd';
import { useState } from 'react';
import { SignUpData, useAuth } from '../index';
import { getMarketingParams } from '../../common/utils/tracking';
import { User } from '../../api';

interface SignUpFormProps {
  inviteCode?: User['inviteCode'];
}

export default function SignUpForm(props: SignUpFormProps) {
  const { inviteCode } = props;
  const { signUp } = useAuth();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: SignUpData) => {
    setLoading(true);
    try {
      await signUp({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        params: getMarketingParams(),
        inviteCode,
      });
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e.message,
      });
      setLoading(false);
      return;
    }

    notification.success({
      message: 'Please verify your email address',
      description: 'We have sent you a verification email',
    });

    setLoading(false);
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
        <Input placeholder="Enter your first name" size="large" />
      </Form.Item>
      <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
        <Input placeholder="Enter your last name" size="large" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true }, { type: 'email', message: 'Please enter a valid email address' }]}
        style={{ marginBottom: 0 }}
      >
        <Input placeholder="Enter your email" size="large" />
      </Form.Item>
      <p className="text-muted mt-2">
        Please use a work email, we will send you a verification email
      </p>
      <Form.Item name="password" label="Password" rules={[{ required: true }]}>
        <Input.Password placeholder="Enter your password" size="large" />
      </Form.Item>
      <Form.Item name="confirmPassword" label="Confirm Password" rules={[{ required: true }]}>
        <Input.Password placeholder="Confirm your password" size="large" />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          size="large"
          block
        >
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
}
