import { TranslationOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

export default
class StringResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = TranslationOutlined;

  title = 'String';

  type = ResultRepresentationType.STRING;
}
