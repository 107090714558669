import {
  Collapse, List, Tag,
} from 'antd';
import { DataSource } from '../../api';

interface DataSourceSchemaExplorerBaseProps {
  dataSource?: Pick<DataSource, 'schemaConfig'>;
  includedOnly?: boolean;
}

export default function DataSourceSchemaExplorerBase(props: DataSourceSchemaExplorerBaseProps) {
  const { dataSource, includedOnly } = props;

  if (!dataSource?.schemaConfig) {
    return null;
  }

  return (
    <Collapse defaultActiveKey={[dataSource.schemaConfig?.tables[0]?.tableName]} size="small">
      {
        dataSource.schemaConfig?.tables.map((table) => {
          if (includedOnly && !table.included) {
            return null;
          }

          return (
            <Collapse.Panel
              key={table.tableName}
              header={(
                <div className="d-flex align-items-center justify-content-between">
                  <span>{table.tableName}</span>
                </div>
              )}
            >
              <List
                size="small"
                dataSource={Object.keys(table.schema.properties).map((key) => ({
                  title: key,
                  type: table.schema.properties[key].format || table.schema.properties[key].type,
                }))}
                renderItem={(item) => (
                  <List.Item key={item.title}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      {item.title}
                      <Tag
                        color="geekblue"
                        style={{
                          marginInlineEnd: 0,
                        }}
                      >
                        {item.type}
                      </Tag>
                    </div>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          );
        })
      }
    </Collapse>
  );
}
