import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class LineChartToAreaChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.LINE_CHART;

  to = ResultRepresentationSubType.AREA_CHART;

  transformData() {
    return {
      options: {
        xaxis: this.resultRepresentation.data.options.xaxis,
      },
      series: this.resultRepresentation.data.series,
      type: 'area',
    };
  }
}

export default LineChartToAreaChartTransformer;
