import { Outlet, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { globalAtom } from '../../common/state/global-state';
import useQuery from '../../common/hooks/useQuery';
import api, { DataSource } from '../../api';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';

export default function ProjectIndexPage() {
  const { projectSlug } = useParams();
  const activeOrganization = useActiveOrganization();
  const setGlobalState = useSetRecoilState(globalAtom);

  useQuery(async () => {
    const response = await api.projects.find({
      query: {
        slug: projectSlug,
        organizationId: activeOrganization?._id,
      },
    });

    const project = response.data[0] || null;

    setGlobalState((previous) => {
      const { dataSources, organization } = previous;

      let projectDataSource: DataSource | null = null;
      const dataSourceId = project?.defaultDataSourceId || organization?.defaultDataSourceId;

      if (dataSourceId) {
        projectDataSource = dataSources.find((ds) => ds._id === dataSourceId) || null;
      }

      return {
        ...previous,
        project,
        dataSource: projectDataSource,
        loadingActiveProject: false,
      };
    });
  }, [projectSlug]);

  return <Outlet />;
}
