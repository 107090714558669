import { FormatType } from '../definitions/Format';

export enum ResultRepresentationType {
  TEXT = 'text',
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
  GRAPH = 'graph',
  OBJECT_VIEW = 'object-view',
  TABLE = 'table',
  CUSTOM = 'custom',
}

export enum ResultRepresentationSubType {
  TEXT = 'text',
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
  OBJECT_VIEW = 'object-view',
  TABLE = 'table',
  PIE_CHART = 'pie-chart',
  AREA_CHART = 'area-chart',
  LINE_CHART = 'line-chart',
  BAR_CHART = 'bar-chart',
  SCATTER_CHART = 'scatter-chart',
  HEATMAP_CHART = 'heatmap-chart',
  RADAR_CHART = 'radar-chart',
  TREE_MAP_CHART = 'tree-map-chart',
  CUSTOM_CODE = 'custom-code',
}

export enum ResultRepresentationShape {
  PRIMITIVE = 'primitive',
  ARRAY_X_Y = 'array-x-y',
  ARRAY_GROUP_ID_AND_OTHER = 'array-group-id-and-other',
  ARRAY_OF_OBJECTS = 'array-of-objects',
  OBJECT = 'object',
}

export type FormatRule = {
  field?: string;
  format: {
    type: FormatType;
    options: any;
  };
};

export type ResultRepresentationFormat = {
  rules: FormatRule[];
};

export type SortRule = {
  columnKey: string;
  order?: 'ascend' | 'descend' | null | undefined;
};

export type Sort = {
  rules: SortRule[];
};

type ResultRepresentation = {
  type: ResultRepresentationType;
  shape: ResultRepresentationShape;
  data: any;
  subType?: ResultRepresentationSubType;
  transformer?: string | null;
  prompt?: string | null;
  id?: string | null;
  result?: any;
};

export type Filter = {
  /**
   * The query, for the react-querybuilder component
   */
  query?: any;
  /**
   * The jsonLogic, for the json-logic-js component
   */
  jsonLogic?: any;
};

export type ResultRepresentationOverride = Pick<ResultRepresentation, 'subType'> & {
  customCode?: string;
  format?: ResultRepresentationFormat;
  sort?: Sort;
  filter?: Filter;
};

export default ResultRepresentation;
