import {
  Button, Card, message, Popconfirm,
} from 'antd';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import api, { Alert, AlertRecurrence } from '../../api';
import useQuery from '../../common/hooks/useQuery';
import Spin from '../../common/components/Spin';
import alertDefinitions from '../config/alertDefinitions';
import useRequest from '../../common/hooks/useRequest';

interface WidgetAlertsListProps {
  widgetId: Alert['widgetId'];
  extraAlerts?: Alert[];
  onAlertDeleted?: (alert: Alert) => void;
}

export default function WidgetAlertsList(props: WidgetAlertsListProps) {
  const { widgetId, onAlertDeleted, extraAlerts = [] } = props;
  const [popConfirmAlertId, setPopConfirmAlertId] = useState<string | undefined>();

  const [response, loading, setResponse] = useQuery(async () => api.alert.find({
    query: {
      widgetId,
    },
  }));

  const removeRequest = useRequest<Alert | null>(async () => {
    if (popConfirmAlertId) {
      return api.alert.remove(popConfirmAlertId) as Promise<Alert>;
    }

    return null;
  }, {
    onSuccess: (removedAlert) => {
      message.success('Alert deleted successfully');
      setResponse((prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          data: prev.data.filter((alert) => alert._id !== popConfirmAlertId),
        };
      });

      if (popConfirmAlertId && onAlertDeleted && removedAlert) {
        onAlertDeleted(removedAlert);
      }
    },
  });

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }

  if (!response?.data) {
    return null;
  }

  const alerts = [...extraAlerts, ...response.data];

  if (alerts.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <p className="text-muted">No alerts for this widget yet</p>
      </div>
    );
  }

  return (
    <div>
      {
        alerts.map((alert) => {
          const definition = alertDefinitions.find((def) => def.type === alert.type);

          if (!definition) {
            return null;
          }

          return (
            <Card
              key={alert._id}
              size="small"
              className="mb-2"
              title={definition.name}
              extra={(
                <Popconfirm
                  title="Are you sure you want to delete this alert?"
                  description="This action cannot be undone"
                  open={popConfirmAlertId === alert._id}
                  onConfirm={removeRequest.submit}
                  okButtonProps={{ loading: removeRequest.loading }}
                  onCancel={() => { setPopConfirmAlertId(undefined); }}
                  okText="Delete Alert"
                  okType="danger"
                >
                  <Button
                    size="small"
                    type="default"
                    danger
                    onClick={() => {
                      setPopConfirmAlertId(alert._id);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              )}
            >
              <p className="mt-0">{`Triggered ${alert.triggerCount} times`}</p>
              <div className="d-flex align-items-center gap-2">
                <ReloadOutlined />
                {
                  alert.recurrence === AlertRecurrence.RECURRING ? 'Recurring' : 'One Time'
                }
              </div>
            </Card>
          );
        })
      }
    </div>
  );
}
