import { Entity, EntityField, EntityFieldType } from '../../api';

export default class EntityManager {
  constructor(readonly entity: Entity, readonly entities: Entity[]) {}

  getFieldByName(name: EntityField['name']) {
    const field = this.entity.fields.find((item) => item.name === name);

    if (!field) {
      throw new Error(`Can not find field ${name}`);
    }

    return field;
  }

  getForeignKeyToEntity(entity: Entity): EntityField | undefined {
    return this.entity.fields.find((field) => {
      if (field.definition.type !== EntityFieldType.FOREIGN_KEY) {
        return false;
      }

      return field.definition.entityName === entity.name;
    });
  }

  getRelatedFields(foreignEntity: Entity): EntityField[] {
    return this.entity.fields.filter((field) => {
      if (field.definition.type !== EntityFieldType.RELATED_ENTITY_FIELD) {
        return false;
      }

      return field.definition.entityName === foreignEntity.name;
    });
  }

  getRelatedForeignFields(foreignEntity: Entity): EntityField[] {
    const relatedFields = this.getRelatedFields(foreignEntity);
    const em = new EntityManager(foreignEntity, this.entities);

    const relatedForeignFields: EntityField[] = [];

    relatedFields.forEach((field) => {
      if (field.definition.type === EntityFieldType.RELATED_ENTITY_FIELD) {
        const relatedField = em.getFieldByName(field.definition.entityField);

        if (relatedField) {
          relatedForeignFields.push(relatedField);
        }
      }
    });

    return relatedForeignFields;
  }
}
