import {
  Organization, Plan, PlanLimits, PlanName,
} from '../../api';
import { PlanBillingPeriod } from '../../api/definitions/Plan';

const APP_SUMO_LIMITS_STACK_0: PlanLimits = {
  credits: 20000,
  maxUsers: 5,
  maxProjects: 3,
  maxDashboards: 10,
  maxWidgets: 200,
  maxDataSources: 2,
};

const APP_SUMO_LIMITS_STACK_1: PlanLimits = {
  credits: 50000,
  maxUsers: 10,
  maxProjects: 10,
  maxDashboards: Infinity,
  maxWidgets: Infinity,
  maxDataSources: 5,
};

const APP_SUMO_LIMITS_STACK_2: PlanLimits = {
  credits: 80000,
  maxUsers: Infinity,
  maxProjects: Infinity,
  maxDashboards: Infinity,
  maxWidgets: Infinity,
  maxDataSources: 10,
};

const individual: Plan = {
  name: PlanName.INDIVIDUAL,
  price: 27,
  yearlyPrice: 264,
  credits: 10000,
  stripeProductId: 'price_1Os7jQDUen0ZqmLRg0c7kCkN',
  stripeProductIdYearly: 'price_1Os7lGDUen0ZqmLRqdJCzEso',
  maxUsers: 1,
  maxProjects: 1,
  maxDashboards: 1,
  maxWidgets: 100,
  apiAccess: false,
  fullyEmbedded: false,
  whitelabel: false,
  dataMonitoring: true,
  maxDataSources: 1,
};

const starter: Plan = {
  name: PlanName.STARTER,
  price: 97,
  yearlyPrice: 924,
  credits: 40000,
  stripeProductId: 'price_1MsuB9DUen0ZqmLR69VRqk92',
  stripeProductIdYearly: 'price_1NOT5MDUen0ZqmLRR0bcBtTG',
  maxUsers: 5,
  maxProjects: 3,
  maxDashboards: 10,
  maxWidgets: 200,
  apiAccess: false,
  fullyEmbedded: false,
  whitelabel: false,
  dataMonitoring: true,
  maxDataSources: 2,
};

export default class Plans {
  static plans: Plan[] = [
    {
      name: PlanName.FREE,
      price: 0,
      yearlyPrice: 0,
      credits: 2500,
      stripeProductId: '',
      stripeProductIdYearly: '',
      maxUsers: 2,
      maxProjects: 1,
      maxDashboards: 1,
      maxWidgets: 10,
      maxDataSources: 1,
    },
    individual,
    starter,
    {
      name: PlanName.PROFESSIONAL,
      price: 300,
      yearlyPrice: 2880,
      credits: 100000,
      stripeProductId: 'price_1MsuBlDUen0ZqmLR4jXsRnTJ',
      stripeProductIdYearly: 'price_1NOT9lDUen0ZqmLRjtAnwduf',
      maxUsers: 15,
      maxProjects: 10,
      maxDashboards: Infinity,
      maxWidgets: Infinity,
      apiAccess: true,
      fullyEmbedded: false,
      whitelabel: false,
      dataMonitoring: true,
      maxDataSources: 5,
    },
    {
      ...starter,
      name: PlanName.ENTERPRISE_TIER_1_RESELL,
      restrictToOrganizations: ['65ad7389d963701462659621'],
      price: 1000,
      yearlyPrice: undefined,
      apiAccess: true,
      fullyEmbedded: true,
      whitelabel: true,
      dataMonitoring: true,
      maxDataSources: Infinity,
    },
    {
      name: PlanName.ENTERPRISE,
      price: Infinity,
      yearlyPrice: Infinity,
      credits: Infinity,
      stripeProductId: '',
      stripeProductIdYearly: '',
      maxUsers: Infinity,
      maxProjects: Infinity,
      maxDashboards: Infinity,
      maxWidgets: Infinity,
      apiAccess: true,
      fullyEmbedded: true,
      whitelabel: true,
      dataMonitoring: true,
      maxDataSources: Infinity,
    },
  ];

  static getOrganizationPlans(organizationId: string, billingCycle: PlanBillingPeriod) {
    return Plans.plans.filter((plan) => {
      if (billingCycle === PlanBillingPeriod.YEARLY && !plan.yearlyPrice) {
        return false;
      }

      if (!plan.restrictToOrganizations) {
        return true;
      }

      return plan.restrictToOrganizations.includes(organizationId);
    });
  }

  static getByName(name: PlanName, organization?: Organization): Plan {
    const plan = this.plans.find((item) => item.name === name);

    if (!plan) {
      throw new Error(`Plan not found: ${name}`);
    }

    if (organization) {
      return Plans.getPlanWithLimits(plan, organization);
    }

    return plan;
  }

  static isFree(planName: Plan['name']): boolean {
    return planName === 'free';
  }

  static limitReached(planName: PlanName, credits: number): boolean {
    const plan = this.plans.find((item) => item.name === planName);
    if (!plan) {
      throw new Error(`Plan not found: ${planName}`);
    }
    return credits >= plan.credits;
  }

  static getPrice(name: PlanName, billingPeriod: PlanBillingPeriod): number {
    const plan = this.getByName(name);

    if (billingPeriod === PlanBillingPeriod.MONTHLY) {
      return plan.price;
    }

    if (plan.yearlyPrice) {
      return plan.yearlyPrice;
    }

    throw new Error(`Yearly billing not supported for plan ${name}`);
  }

  static getPriceWithDiscount(
    name: PlanName,
    billingPeriod: PlanBillingPeriod,
    discount?: number,
  ): number {
    let price = Plans.getPrice(name, billingPeriod);

    if (discount) {
      price *= (1 - discount);
    }

    return price;
  }

  static getMonthlyPrice(name: PlanName, billingPeriod: PlanBillingPeriod): number {
    const plan = this.getByName(name);

    if (billingPeriod === PlanBillingPeriod.MONTHLY) {
      return plan.price;
    }

    if (plan.yearlyPrice) {
      return plan.yearlyPrice / 12;
    }

    throw new Error(`Yearly billing not supported for plan ${name}`);
  }

  static getPlanWithLimits(plan: Plan, organization?: Organization) {
    if (!organization) {
      return plan;
    }

    const { appSumoStackCodes } = organization;

    if (organization.appSumoPlan && !appSumoStackCodes) {
      // This is used in order to preserve the initial limits for the AppSumo plan
      // otherwise, the limits will be updated to the current plan limits, which are higher
      return {
        ...plan,
        ...APP_SUMO_LIMITS_STACK_0,
      };
    }

    if (appSumoStackCodes) {
      switch (appSumoStackCodes.length) {
        case 1: return {
          ...plan,
          ...APP_SUMO_LIMITS_STACK_1,
        };
        case 2: return {
          ...plan,
          ...APP_SUMO_LIMITS_STACK_2,
        };
        default:
          break;
      }
    }

    return plan;
  }
}
