import { Paginated } from '@feathersjs/feathers';
import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import RGL, { WidthProvider } from 'react-grid-layout';
import {
  Button, Drawer, Modal, notification,
} from 'antd';
import { useRecoilValue } from 'recoil';
import { PlusOutlined } from '@ant-design/icons';
import api, {
  Dashboard,
  QueryExecution,
  ResultRepresentationSubType,
  UserPreferences,
  UserPreferenceType,
  Widget,
} from '../../api';
import useQuery from '../../common/hooks/useQuery';
import Spin from '../../common/components/Spin';
import WidgetCard from '../../widgets/components/WidgetCard';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
import useModal from '../../common/hooks/useModal';
import QueryCard from '../../queries/components/QueryCard';
import { globalAtom } from '../../common/state/global-state';
import WidgetSetup from '../../widgets/components/WidgetSetup';
import useRefreshActiveOrganization from '../../common/hooks/useRefreshActiveOrganization';
import WidgetAlerts from '../../alerts/components/WidgetAlerts';
import EmbedWidgetModal from '../../widgets/components/EmbedWidgetModal';
import SelectDashboard from './SelectDashboard';
import useRequest from '../../common/hooks/useRequest';
import { getWidgetRepresentation } from '../../widgets/utils';
import CustomCodeResultRepresentationConfigurator
  from '../../results/components/CustomCodeResultRepresentationConfigurator';
import usePermissions from '../../common/hooks/usePermissions';

const ReactGridLayout = WidthProvider(RGL);

interface DashboardWidgetsProps extends Pick<Dashboard, 'projectId' | 'defaultGridLayout' | 'organizationId'> {
  dashboardId: Dashboard['_id'];
  openNewWidgetModalKey?: number | null;
  useSharedLayout?: boolean;
  gridLayout?: any;
  onDashboardChange?: (dashboard: Partial<Dashboard>) => void;
  slackEnabled?: boolean;
  sharedMode?: boolean;
}

function getLocalStorageKey(dashboardId: Dashboard['_id']) {
  return `dashboard-${dashboardId}-layout`;
}

function getFromLocalStorage(dashboardId: Dashboard['_id']) {
  const value = localStorage.getItem(getLocalStorageKey(dashboardId));

  return value ? JSON.parse(value) : null;
}

function saveToLocalStorage(dashboardId: Dashboard['_id'], layout: any) {
  localStorage.setItem(getLocalStorageKey(dashboardId), JSON.stringify(layout));
}

function getWidgetLayoutId(widget: Widget) {
  return widget.layoutId || widget._id;
}

function generateDefaultLayout(widgets: Widget[]) {
  return widgets.map((widget, i) => {
    const y = 12;
    return {
      i: getWidgetLayoutId(widget),
      x: (i * 6) % 12,
      y: Math.floor(i / 2) * y,
      w: 6,
      h: y,
    };
  });
}

function processLayout(layout: Record<string, any>[]) {
  return layout.map((item) => ({
    ...item,
    minH: 8,
    minW: 3,
    h: Math.max(item.h, 8), // Make sure the height is at least 10 rows
    w: Math.max(item.w, 3), // Make sure the width is at least 2 columns
  }));
}

function getLayout(
  layoutFromPreferences: any,
  dashboardId: Dashboard['_id'],
  widgets: Widget[],
  defaultLayout?: Record<string, any>[],
) {
  return processLayout(
    layoutFromPreferences
    || getFromLocalStorage(dashboardId)
    || defaultLayout
    || generateDefaultLayout(widgets),
  );
}

export default function DashboardWidgets(props: DashboardWidgetsProps) {
  const {
    dashboardId,
    openNewWidgetModalKey,
    projectId,
    defaultGridLayout,
    useSharedLayout,
    gridLayout,
    onDashboardChange,
    organizationId,
    slackEnabled,
    sharedMode,
  } = props;
  const { dataSource } = useRecoilValue(globalAtom);
  const refreshActiveOrganization = useRefreshActiveOrganization();

  const [editWidget, setEditWidget] = useState<Widget | undefined>();
  const widgetModal = useModal();

  const [embedWidget, setEmbedWidget] = useState<Widget | undefined>(undefined);
  const embedModal = useModal();

  const [alertWidget, setAlertWidget] = useState<Widget | null>(null);

  const [moveToDashboardWidget, setMoveToDashboardWidget] = useState<Widget | null>(null);
  const [moveToDashboard, setMoveToDashboard] = useState<Dashboard['_id'] | undefined>(undefined);

  const [customCodeWidget, setCustomCodeWidget] = useState<Widget>();
  const [preferences, setPreferences] = useState<UserPreferences>();
  const permissions = usePermissions();

  useEffect(() => {
    if (!widgetModal.props.open) {
      setTimeout(() => {
        setEditWidget(undefined);
      }, 500);
    }
  }, [widgetModal.props.open]);

  useEffect(() => {
    if (openNewWidgetModalKey) {
      widgetModal.open();
    }
  }, [openNewWidgetModalKey]);

  const onMoveToDashboardClick = useCallback((widget: Widget) => {
    setMoveToDashboardWidget(widget);
  }, []);

  const [
    response,
    loading,
    setResponse,
  ] = useQuery<Paginated<Widget>>(async () => await api.widgets.find({
    query: {
      dashboardId,
      $limit: 100,
    },
  }) as unknown as Promise<Paginated<Widget>>, [dashboardId]);

  const onDeleteSuccess = useCallback((id: Widget['_id']) => {
    refreshActiveOrganization();
    setResponse((prev) => {
      if (!prev) {
        return undefined;
      }

      return {
        ...prev,
        data: prev.data.filter((item) => item._id !== id),
      };
    });
  }, [refreshActiveOrganization]);

  const moveWidget = useRequest(async () => {
    if (!moveToDashboardWidget || !moveToDashboard) {
      throw new Error('No dashboard selected');
    }

    await api.widgets.patch(moveToDashboardWidget._id, {
      dashboardId: moveToDashboard,
    });
  }, {
    onSuccess: () => {
      if (moveToDashboardWidget) {
        onDeleteSuccess(moveToDashboardWidget._id);
      }
      setMoveToDashboardWidget(null);
      setMoveToDashboard(undefined);
      notification.success({
        message: 'Widget moved',
      });
    },
    onError: (error) => {
      notification.error({
        message: 'Error moving widget',
        description: error.message,
      });
    },
  });

  const onRenameSuccess = useCallback((id: Widget['_id'], title: Widget['title']) => {
    setResponse((prev) => {
      if (!prev) {
        return undefined;
      }

      return {
        ...prev,
        data: prev.data.map((item) => {
          if (item._id !== id) {
            return item;
          }

          return {
            ...item,
            title,
          };
        }),
      };
    });
  }, []);

  const onUpdateSuccess = useCallback((widget: Widget) => {
    setResponse((prev) => {
      if (!prev) {
        return undefined;
      }

      return {
        ...prev,
        data: prev.data.map((item) => {
          if (item._id !== widget._id) {
            return item;
          }

          return {
            ...item,
            ...widget,
          };
        }),
      };
    });
  }, []);

  const onPartialUpdateSuccess = useCallback((widget: Partial<Widget>) => {
    if (widget._id) {
      setResponse((prev) => {
        if (!prev) {
          return undefined;
        }

        return {
          ...prev,
          data: prev.data.map((item) => {
            if (item._id !== widget._id) {
              return item;
            }

            return {
              ...item,
              ...widget,
            };
          }),
        };
      });
    }
  }, []);

  // WARNING: do not set a default value for the layout state, it will cause the layout to be reset
  // wait for the layout to be initialized from the preferences or the local storage
  const [layout, setLayout] = useState<any>();

  function getDefaultLayout(preferencesLayout?: any) {
    return getLayout(
      useSharedLayout ? gridLayout : preferencesLayout,
      dashboardId,
      response ? response.data : [],
      defaultGridLayout,
    );
  }

  async function initLayout() {
    if (response && dashboardId && organizationId) {
      let preference: UserPreferences | undefined;

      try {
        if (!useSharedLayout) {
          const preferencesResponse = await api.userPreferences.find({
            query: {
              type: UserPreferenceType.DASHBOARD,
              dashboardId,
              $select: ['value', '_id'],
            },
          });

          [preference] = preferencesResponse.data;

          if (!preference) {
            const newLayout = getDefaultLayout();
            setLayout(newLayout);
            const result = await api.userPreferences.create({
              type: UserPreferenceType.DASHBOARD,
              organizationId,
              projectId,
              dashboardId,
              value: {
                layout: newLayout,
              },
            });
            setPreferences(result);
          } else if (!preference.value.layout) {
            setPreferences(preference);
            const newLayout = getDefaultLayout();
            setLayout(newLayout);
            // @ts-ignore
            await api.userPreferences.patch(preference._id, {
              value: {
                ...preference.value,
                layout: newLayout,
              },
            });
          } else {
            setPreferences(preference);
            setLayout(getDefaultLayout(preference.value.layout));
          }
        } else {
          const defaultLayout = getDefaultLayout();

          if (!gridLayout && onDashboardChange && !sharedMode) {
            onDashboardChange({
              _id: dashboardId,
              gridLayout: defaultLayout,
            });
          }

          setLayout(defaultLayout);
        }
      } catch (e) {
        setLayout(getDefaultLayout());
      }
    }
  }

  useEffect(() => {
    if (response && dashboardId) {
      initLayout();
    }
  }, [
    response,
    dashboardId,
    organizationId,
    JSON.stringify(defaultGridLayout),
    useSharedLayout,
  ]);

  const closeAlertWidget = useCallback(() => {
    setAlertWidget(null);
  }, []);

  const onEmbedWidgetClick = useCallback((widget: Widget) => {
    embedModal.open();
    setEmbedWidget(widget);
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center"><Spin /></div>
    );
  }

  if (!organizationId || !layout) {
    return null;
  }

  return (
    <div>
      {
        response && response.data.length === 0 && (
          (
            <div className="d-flex flex-column align-items-center justify-content-center min-h-100">
              <h1>No Widgets Found</h1>
              {
                permissions.widgets.write && (
                  <Button
                    type="primary"
                    onClick={widgetModal.open}
                    icon={<PlusOutlined />}
                  >
                    New Widget
                  </Button>
                )
              }
            </div>
          )
        )
      }
      {
        response && response.data.length > 0 && (
          <ReactGridLayout
            layout={layout}
            onLayoutChange={(newLayout: any) => {
              const processedLayout = processLayout(newLayout);
              setLayout(processedLayout);
              saveToLocalStorage(dashboardId, processedLayout);

              if (!useSharedLayout) {
                if (preferences) {
                  setPreferences((prev) => {
                    if (prev) {
                      return {
                        ...prev,
                        value: {
                          ...prev.value,
                          layout: processedLayout,
                        },
                      };
                    }

                    return prev;
                  });
                  api.userPreferences.patch(preferences._id, {
                    value: {
                      ...preferences.value,
                      layout: processedLayout,
                    },
                  });
                }
              } else if (!sharedMode) {
                if (onDashboardChange) {
                  onDashboardChange({
                    _id: dashboardId,
                    gridLayout: processedLayout,
                  });
                }
                api.dashboards.patch(dashboardId, {
                  gridLayout: processedLayout,
                });
              }
            }}
            cols={24}
            rowHeight={10}
            draggableHandle=".drag-handle"
            containerPadding={[0, 0]}
          >
            {
              response.data.map((widget) => (
                <div key={getWidgetLayoutId(widget)}>
                  <WidgetCard
                    key={`${widget._id}-${widget.title}`}
                    _id={widget._id}
                    title={widget.title}
                    resultRepresentationSubTypeOverride={widget.resultRepresentationSubTypeOverride}
                    execution={widget.execution}
                    updatedAt={widget.updatedAt}
                    onDeleteSuccess={onDeleteSuccess}
                    onRenameSuccess={onRenameSuccess}
                    onEditClick={() => {
                      setEditWidget(widget);
                      widgetModal.open();
                    }}
                    resultRepresentation={getWidgetRepresentation(widget)}
                    resultRepresentations={widget.resultRepresentations}
                    onUpdateSuccess={onUpdateSuccess}
                    onPartialUpdateSuccess={onPartialUpdateSuccess}
                    onAlertsClick={() => { setAlertWidget(widget); }}
                    alertsCount={widget.alertsCount}
                    onEmbedWidgetClick={() => { onEmbedWidgetClick(widget); }}
                    onMoveWidgetClick={() => { onMoveToDashboardClick(widget); }}
                    onClone={(newWidget: Widget) => {
                      setResponse((prev) => {
                        if (!prev) {
                          return prev;
                        }

                        return {
                          ...prev,
                          data: [...prev.data, newWidget],
                        };
                      });
                    }}
                    onCustomCodeResultRepresentationSubTypeSelect={() => {
                      setCustomCodeWidget(widget);
                    }}
                    embedded={sharedMode}
                  />
                </div>
              ))
            }
          </ReactGridLayout>
        )
      }
      <Drawer
        title={editWidget ? 'Edit Widget' : 'New Widget'}
        width={editWidget ? undefined : 'min(100vw, 1300px)'}
        styles={{
          body: editWidget ? undefined : {
            padding: 0,
          },
        }}
        {...widgetModal.props}
      >
        {
          !editWidget ? (
            <QueryCard
              key={String(openNewWidgetModalKey)}
              organizationId={organizationId}
              projectId={projectId}
              hasDataSource={Boolean(dataSource)}
              dataSource={dataSource || undefined}
              dashboardId={dashboardId}
              onWidgetCreated={(widget) => {
                refreshActiveOrganization();
                setResponse((prev) => {
                  if (!prev) {
                    return prev;
                  }

                  return {
                    ...prev,
                    data: [...prev.data, widget],
                  };
                });
              }}
              heightSubtract={58}
            />
          ) : (
            <div>
              <WidgetSetup
                queryId={editWidget.queryId}
                projectId={editWidget.projectId}
                dashboardId={editWidget.dashboardId}
                widget={editWidget}
                onSuccess={(widget) => {
                  setResponse((prev) => {
                    if (!prev) {
                      return prev;
                    }

                    return {
                      ...prev,
                      data: prev.data.map((item) => {
                        if (item._id !== widget._id) {
                          return item;
                        }

                        return widget;
                      }),
                    };
                  });
                  widgetModal.close();
                  setEditWidget(undefined);
                }}
              />
            </div>
          )
        }
      </Drawer>
      <Drawer
        open={Boolean(alertWidget)}
        onClose={closeAlertWidget}
        title="Widget Alerts"
        contentWrapperStyle={{
          maxWidth: '100vw',
        }}
      >
        {
          alertWidget && (
            <WidgetAlerts
              organizationId={organizationId}
              widgetId={alertWidget._id}
              widgetUpdateInBackground={alertWidget.updateInBackground}
              slackEnabled={slackEnabled}
              resultRepresentationType={alertWidget?.execution?.resultRepresentation?.type}
              onAlertCreated={() => {
                setResponse((prev) => {
                  if (!prev) {
                    return prev;
                  }

                  return {
                    ...prev,
                    data: prev.data.map((item) => {
                      if (item._id !== alertWidget._id) {
                        return item;
                      }

                      return {
                        ...item,
                        alertsCount: (item.alertsCount || 0) + 1,
                      };
                    }),
                  };
                });
              }}
              onAlertDeleted={() => {
                setResponse((prev) => {
                  if (!prev) {
                    return prev;
                  }

                  return {
                    ...prev,
                    data: prev.data.map((item) => {
                      if (item._id !== alertWidget._id) {
                        return item;
                      }

                      return {
                        ...item,
                        alertsCount: (item.alertsCount || 0) - 1,
                      };
                    }),
                  };
                });
              }}
            />
          )
        }
      </Drawer>

      <EmbedWidgetModal
        widget={embedWidget}
        {...embedModal.props}
      />

      <Modal
        title="Move widget to another dashboard"
        open={Boolean(moveToDashboardWidget)}
        onCancel={() => { setMoveToDashboardWidget(null); }}
        okText="Move Widget"
        okButtonProps={{
          loading: moveWidget.loading,
          disabled: moveWidget.loading,
        }}
        onOk={moveWidget.submit}
      >
        <SelectDashboard
          value={moveToDashboard}
          onChange={setMoveToDashboard}
          projectId={projectId}
        />
      </Modal>
      <CustomCodeResultRepresentationConfigurator
        open={Boolean(customCodeWidget)}
        onClose={() => void setCustomCodeWidget(undefined)}
        resultRepresentation={customCodeWidget?.execution?.resultRepresentation}
        code={customCodeWidget?.execution?.resultRepresentationOverride?.customCode}
        onChange={(code) => {
          if (customCodeWidget?.execution) {
            api.queryExecutions.patch(customCodeWidget.execution._id, {
              resultRepresentationOverride: {
                ...(customCodeWidget.execution.resultRepresentationOverride || {}),
                subType: ResultRepresentationSubType.CUSTOM_CODE,
                customCode: code,
              },
            }).then((res) => {
              if (res) {
                setCustomCodeWidget(undefined);
                if (onUpdateSuccess) {
                  onUpdateSuccess({
                    ...customCodeWidget,
                    execution: res as QueryExecution,
                  });
                }
              }
            });
          }
        }}
      />
    </div>
  );
}
