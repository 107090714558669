import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import SignInForm from './SignInForm';

export default function AuthForm() {
  return (
    <div
      style={{
        background: 'transparent',
        boxShadow: '0 0 0 0',
      }}
    >
      <h1 className="mt-0">
        Sign In
      </h1>
      <SignInForm />
      <div className="mt-5 d-flex justify-content-between">
        <Link to="/sign-up">
          <Button type="link">
            Don&apos;t have an account?
          </Button>
        </Link>
        <Link to="/forgot-password">
          <Button type="link">
            Forgot Password?
          </Button>
        </Link>
      </div>
    </div>
  );
}
