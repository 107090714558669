import {
  Button, Input, message, notification,
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../index';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';
import AuthPageLayout from '../layouts/AuthPageLayout';
import useOnboarding from '../../onboarding/hooks/useOnboarding';

export default function VerifyUserEmailPage() {
  const { user, logout, setUser } = useAuth();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const navigate = useNavigate();
  const onboarding = useOnboarding();

  const sendVerificationCodeRequest = useRequest(async () => api.users.sendVerificationEmail(), {
    onSuccess: () => {
      message.success('Verification code sent');
    },
  });

  const verifyEmail = useRequest(async () => api.users.verifyEmail({ code: verificationCode }), {
    onSuccess: async (responseUser) => {
      notification.success({
        message: 'Thank you for verifying your email address',
      });

      setUser(responseUser);
      onboarding.goToNextStep();
      navigate('/onboarding/info?fev=true');
    },
  });

  return (
    <AuthPageLayout>
      <div className="bg-transparent">
        <p>
          Please enter the verification code sent to
          {' '}
          <b>{user?.email}</b>
        </p>
        <Input
          placeholder="Enter verification code"
          size="large"
          className="mb-3"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <div className="d-flex justify-content-center">
          <Button
            size="large"
            type="primary"
            onClick={verifyEmail.submit}
            loading={verifyEmail.loading}
          >
            Verify Email
          </Button>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Button
            type="link"
            onClick={sendVerificationCodeRequest.submit}
            loading={sendVerificationCodeRequest.loading}
          >
            Re-send verification code
          </Button>
        </div>
        <p className="text-center m-0 py-1">
          or
        </p>
        <div className="d-flex justify-content-center">
          <Button
            type="link"
            onClick={logout}
          >
            Log out if you want to use a different email address
          </Button>
        </div>
      </div>
    </AuthPageLayout>
  );
}
