import { ResultRepresentationType } from '../models/ResultRepresentation';

export enum AlertType {
  VALUE_CHANGE = 'VALUE_CHANGE',
  VALUE_INCREASE_ABSOLUTE = 'VALUE_INCREASE_ABSOLUTE',
  VALUE_INCREASE_PERCENT = 'VALUE_INCREASE_PERCENT',
  VALUE_DECREASE_ABSOLUTE = 'VALUE_DECREASE_ABSOLUTE',
  VALUE_DECREASE_PERCENT = 'VALUE_DECREASE_PERCENT',
  VALUE_RAISES_ABOVE = 'VALUE_RAISES_ABOVE',
  VALUE_FALLS_BELOW = 'VALUE_FALLS_BELOW',
}

export enum AlertRecurrence {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

type AlertDefinition = {
  type: AlertType;
  name: string;
  description: string;
  hasValueInput: boolean;
  compatibleValueTypes: ResultRepresentationType[];
};

export default AlertDefinition;
