import {
  useEffect,
} from 'react';
import { useRecoilState } from 'recoil';
import { DataSource } from '../../api';
import {
  AggregationCommand,
  CommandName,
  CountCommand,
  Filter,
  Operator,
  Query,
} from '../types';
import { FlatFilter } from '../helper-types';
import dbaqlAtom from '../state';

export interface DbaqlQueryBuilder {
  generateQuery: () => Promise<Query>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function flattenFilters(filter: Filter): FlatFilter[] {
  const flatFilters: FlatFilter[] = [];

  Object.entries(filter).forEach(([fieldName, fieldFilter]) => {
    if (fieldFilter && typeof fieldFilter === 'object') {
      Object.entries(fieldFilter).forEach(([operator, value]) => {
        flatFilters.push({
          fieldName,
          operator: operator as Operator,
          value,
        });
      });
    } else {
      flatFilters.push({
        fieldName,
        operator: Operator.EQ,
        value: fieldFilter,
      });
    }
  });

  return flatFilters;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function unflattenFilters(flatFilters: FlatFilter[]): Filter {
  const filter: Filter = {};

  flatFilters.forEach((flatFilter) => {
    const { fieldName, operator, value } = flatFilter;

    if (filter[fieldName] && typeof filter[fieldName] === 'object') {
      // @ts-ignore
      filter[fieldName][operator] = value;
    } else {
      filter[fieldName] = {
        [operator]: value,
      };
    }
  });

  return filter;
}

export default function useDbaqlQueryBuilder(schema: DataSource['schemaConfig'] | undefined): DbaqlQueryBuilder {
  const [state, setState] = useRecoilState(dbaqlAtom);

  useEffect(() => {
    setState((value) => ({
      ...value,
      schema,
    }));
  }, []);

  const {
    table,
    command,
    enableFilter,
    filters,
    aggregationField,
    groupFields,
    enableGroup,
    enableSort,
    sortField,
    sortDirection,
    enableLimit,
    limit,
    enableSelect,
    select,
  } = state;

  async function generateQuery(): Promise<Query> {
    if (!table) {
      throw new Error('Please select a table');
    }

    if (!command) {
      throw new Error('Please select a command');
    }

    const filter = enableFilter ? unflattenFilters(filters) : undefined;
    const sort = enableSort && sortDirection && sortField ? {
      [sortField]: sortDirection,
    } : undefined;
    const limitValue = enableLimit ? limit : undefined;

    function buildGroupQuery(aggregation: AggregationCommand): Query {
      if (!table) {
        throw new Error('Please select a table');
      }

      if (!groupFields) {
        throw new Error('Please select a field to group by');
      }

      return {
        table,
        commands: [
          {
            command: CommandName.GROUP,
            by: groupFields,
            filter,
            aggregation,
            sort,
            limit: limitValue,
          },
        ],
      };
    }

    if (command === CommandName.LIST) {
      if (!limit) {
        throw new Error('Please select a maximum number of records to return');
      }

      if (limit < 0) {
        throw new Error('Limit must be a positive integer');
      }

      if (limit > 1000) {
        throw new Error('Limit must be less than 1000');
      }

      const selectOption = enableSelect ? select : undefined;

      if (selectOption?.length === 0) {
        throw new Error('Please select at least one field to return');
      }

      return {
        table,
        commands: [
          {
            command,
            filter,
            sort,
            limit,
            select: selectOption,
          },
        ],
      };
    }

    if (command === CommandName.SUM) {
      if (!aggregationField) {
        throw new Error('Please select a field to sum by');
      }

      if (enableGroup) {
        return buildGroupQuery({
          command: CommandName.SUM,
          field: aggregationField,
        });
      }

      return {
        table,
        commands: [
          {
            command: CommandName.SUM,
            field: aggregationField,
            filter,
          },
        ],
      };
    }

    if (command === CommandName.AVERAGE) {
      if (!aggregationField) {
        throw new Error('Please select a field to average by');
      }

      if (enableGroup) {
        return buildGroupQuery({
          command: CommandName.AVERAGE,
          field: aggregationField,
        });
      }

      return {
        table,
        commands: [
          {
            command: CommandName.AVERAGE,
            field: aggregationField,
            filter,
          },
        ],
      };
    }

    if (command === CommandName.COUNT) {
      const countCommand: CountCommand = {
        command: CommandName.COUNT,
        filter,
      };

      if (enableGroup) {
        return buildGroupQuery({
          command: CommandName.COUNT,
        });
      }

      return {
        table,
        commands: [
          countCommand,
        ],
      };
    }

    throw new Error('Command not implemented');
  }

  return {
    generateQuery,
  };
}
