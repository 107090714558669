import { useEffect } from 'react';

async function waitUntilLoaded(url: string) {
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      const script = document.querySelector(`script[src="${url}"]`);
      if (script) {
        const loaded = script.getAttribute('data-loaded');

        if (loaded) {
          clearInterval(interval);
          resolve();
        }
      }
    }, 100);
  });
}

export default function useExternalScript(
  url: string,
  onDone?: () => undefined,
  attributes: Record<string, any> = {},
): void {
  useEffect(() => {
    const exists = document.querySelector(`script[src="${url}"]`);

    if (exists) {
      waitUntilLoaded(url).then(onDone);
      return () => {};
    }

    const head = document.querySelector('head');
    const script = document.createElement('script');

    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    script.setAttribute('src', url);
    script.addEventListener('load', () => {
      script.setAttribute('data-loaded', 'true');
    });

    if (onDone) {
      script.addEventListener('load', onDone);
    }

    if (head) {
      head.appendChild(script);
    }

    return () => {
      if (head) {
        head.removeChild(script);
      }
    };
  }, [url]);
}
