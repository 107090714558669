import ResultRepresentationManager from './ResultRepresentationManager';
import StringToDateTransformer from '../transformers/StringToDateTransformer';

class StringRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new StringToDateTransformer(this.representation),
  ];
}

export default StringRepresentationManager;
