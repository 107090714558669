import ResultRepresentationManager from './ResultRepresentationManager';
import TableToPieChartTransformer from '../transformers/TableToPieChartTransformer';
import TableToBarChartTransformer from '../transformers/TableToBarChartTransformer';

class TableResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new TableToPieChartTransformer(this.representation),
    new TableToBarChartTransformer(this.representation),
  ];
}

export default TableResultRepresentationManager;
