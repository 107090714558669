import { useParams } from 'react-router-dom';
import useQuery from '../../common/hooks/useQuery';
import api, { Widget } from '../../api';
import WidgetCard from '../components/WidgetCard';
import Spin from '../../common/components/Spin';
import { getWidgetRepresentation } from '../utils';

export default function EmbedWidgetPage() {
  const { widgetId } = useParams();

  const [widget, loading] = useQuery<Widget>(async () => {
    if (widgetId) {
      return api.widgets.get(
        widgetId,
        {
          query: {
            // This won't work if embeddable is false because of the auth check
            embeddable: true,
          },
        },
      );
    }

    return null;
  }, [widgetId]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center p-4">
        <Spin />
      </div>
    );
  }

  if (!widget) {
    return (
      <p className="text-center">
        Widget Not Found
      </p>
    );
  }

  return (
    <WidgetCard
      key={`${widget._id}-${widget.title}-${widget.updatedAt}`}
      _id={widget._id}
      updatedAt={widget.updatedAt}
      title={widget.title}
      resultRepresentationSubTypeOverride={widget.resultRepresentationSubTypeOverride}
      execution={widget.execution}
      resultRepresentation={getWidgetRepresentation(widget)}
      resultRepresentations={widget.resultRepresentations}
      embedded
    />
  );
}
