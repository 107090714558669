import {
  Card, Col, Progress, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useActiveOrganizationPlan from '../../common/hooks/useActiveOrganizationPlan';
import api from '../../api';

type Count = {
  users: number;
  projects: number;
  dashboards: number;
  widgets: number;
};

interface UsageCardProps {
  title: string;
  text: string;
  usage: number;
  allowedUsage: number;
}

function UsageCard(props: UsageCardProps) {
  const {
    title, text, usage, allowedUsage,
  } = props;

  const percent = (usage / allowedUsage) * 100;

  return (
    <Card
      size="small"
      title={title}
    >
      {
        allowedUsage < Infinity ? (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h1>{text}</h1>
            </div>
            <div>
              <Progress
                percent={percent}
                status={percent > 90 ? 'exception' : 'active'}
                type="circle"
                format={() => `${percent.toFixed(2)}%`}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              Unlimited
            </div>
            <div>
              <Progress
                percent={0}
                status="active"
                type="circle"
                format={() => 'Unlimited'}
              />
            </div>
          </div>
        )
      }
    </Card>
  );
}

export default function OrganizationUsage() {
  const organization = useActiveOrganization();
  const plan = useActiveOrganizationPlan();
  const [count, setCount] = useState<Count>();

  useEffect(() => {
    if (organization) {
      const params = {
        query: {
          organizationId: organization._id,
          $limit: 0,
        },
      };
      Promise.all([
        api.organizationUsers.find(params),
        api.projects.find(params),
        api.dashboards.find(params),
        api.widgets.find(params),
      ]).then(([users, projects, dashboards, widgets]) => {
        setCount({
          users: users.total,
          projects: projects.total,
          dashboards: dashboards.total,
          widgets: widgets.total,
        });
      }).catch(() => {});
    }
  }, [organization?._id]);

  if (!organization) {
    return null;
  }

  const used = plan.credits - organization.creditsRemaining;

  return (
    <div className="w-100">
      <Row gutter={[16, 16]}>
        <Col span={24} md={12} xxl={6}>
          <UsageCard
            title="Credits"
            text={`${used.toLocaleString('en-US')} / ${plan.credits.toLocaleString('en-US')}`}
            usage={used}
            allowedUsage={plan.credits}
          />
        </Col>
        {
          count && (
            <>
              <Col span={24} md={12} xxl={6}>
                <UsageCard
                  title="Users"
                  text={`${count.users} / ${plan.maxUsers}`}
                  usage={count.users}
                  allowedUsage={plan.maxUsers}
                />
              </Col>
              <Col span={24} md={12} xxl={6}>
                <UsageCard
                  title="Projects"
                  text={`${count.projects} / ${plan.maxProjects}`}
                  usage={count.projects}
                  allowedUsage={plan.maxProjects}
                />
              </Col>
              <Col span={24} md={12} xxl={6}>
                <UsageCard
                  title="Dashboards"
                  text={`${count.dashboards} / ${plan.maxDashboards}`}
                  usage={count.dashboards}
                  allowedUsage={plan.maxDashboards}
                />
              </Col>
              <Col span={24} md={12} xxl={6}>
                <UsageCard
                  title="Widgets"
                  text={`${count.widgets} / ${plan.maxWidgets}`}
                  usage={count.widgets}
                  allowedUsage={plan.maxWidgets}
                />
              </Col>
            </>
          )
        }
      </Row>
    </div>
  );
}
