import { Col, Row } from 'antd';
import OrganizationForm from './OrganizationForm';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';

export default function OrganizationGeneralSettings() {
  const organization = useActiveOrganization();

  return (
    <Row>
      <Col span={24} md={12} lg={10} xl={8} xxl={6}>
        <OrganizationForm organization={organization || undefined} />
      </Col>
    </Row>
  );
}
