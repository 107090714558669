import { Input } from 'antd';
import React from 'react';
import BuilderStepCard from '../BuilderStepCard';
import { DbaqlBuilderState } from '../../state';
import { DbaqlBuilderStateUpdater } from '../../hooks/useDbaqlBuilderState';

interface LimitOptionProps extends Pick<DbaqlBuilderState, 'limit' | 'enableLimit'> {
  setLimit: DbaqlBuilderStateUpdater<'limit'>;
  setEnableLimit: DbaqlBuilderStateUpdater<'enableLimit'>;
  description?: string;
  forceEnable?: boolean;
}

export default function LimitOption(props: LimitOptionProps) {
  const {
    limit,
    setLimit,
    enableLimit,
    setEnableLimit,
    description = 'Use the limit to show only the first N results',
    forceEnable,
  } = props;

  return (
    <BuilderStepCard
      question="Limit the number of results"
      description={description}
      isVisible={enableLimit}
      setIsVisible={setEnableLimit}
      hideVisibilityToggle={forceEnable}
    >
      <Input
        placeholder="How many results?"
        type="number"
        value={limit}
        onChange={(e) => setLimit(Number(e.target.valueAsNumber))}
        min={0}
        max={1000}
      />
    </BuilderStepCard>
  );
}
