import { Card, Skeleton } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction } from 'react';
import EmptyChartIcon from '../../../common/components/EmptyChartIcon';
import InsightFlowFilters from '../InsightFlowFilters';
import InsightFlowDateFilter from '../../../dbaql-v2/components/InsightFlowDateFilter';
import QueryExecutionView from '../../../query-executions/components/QueryExecutionView';
import useSize from '../../../common/hooks/useSize';
import useWindowSize from '../../../common/hooks/useWindowSize';
import {
  DataSource, Entity, InsightFlowFilter, Query,
} from '../../../api';
import { AnalysisOptions } from './InsightFlowAnalysisSetup';
import CustomDateSelection from '../../../common/types/CustomDateSelection';
import { HEADER_HEIGHT } from '../../../layout/components/AppLayout';

interface InsightFlowVisualizationProps {
  entity?: Entity;
  entities: Entity[];
  query?: Query;
  loading?: boolean;
  analysis?: AnalysisOptions;
  dataSource?: DataSource;
  insightFlowFilters: InsightFlowFilter[];
  setInsightFlowFilters: (filters: InsightFlowFilter[]) => void;
  timeFilter: CustomDateSelection;
  setTimeFilter: (timeFilter: CustomDateSelection) => void;
  setSaveWidgetDrawerVisible: (visible: boolean) => void;
  setPage: (page: number) => void;
  setQuery: Dispatch<SetStateAction<Query | undefined>>;
  error?: boolean;
}

const sizes = {
  PAGE_CONTENT_SPACING: 16,
  CARD_CONTENT_SPACING: 12,
  FILTERS_MARGIN_BOTTOM: 24,
  FOOTER_HEIGHT: 32,
  FOOTER_MARGIN_TOP: 24,
};

export default function InsightFlowVisualization(props: InsightFlowVisualizationProps) {
  const {
    entity,
    query,
    loading,
    analysis,
    dataSource,
    insightFlowFilters,
    setInsightFlowFilters,
    entities,
    timeFilter,
    setTimeFilter,
    setSaveWidgetDrawerVisible,
    setPage,
    setQuery,
    error,
  } = props;
  const [resultContainerSize, resultContainerRef] = useSize();
  const filtersSizer = useSize();
  const windowSize = useWindowSize();

  const createdAtField = entity?.fields.find((field) => field.name === entity.createdAtFieldName);

  const fields = entity?.fields || [];

  const FILTERS_HEIGHT = filtersSizer[0].height || 0;

  const HEIGHT_SUBTRACT = HEADER_HEIGHT
    + (sizes.PAGE_CONTENT_SPACING * 2)
    + (sizes.CARD_CONTENT_SPACING * 2)
    + FILTERS_HEIGHT
    + sizes.FILTERS_MARGIN_BOTTOM
    + sizes.FOOTER_HEIGHT
    + sizes.FOOTER_MARGIN_TOP;

  const filterableFields = fields.filter(
    (field) => field.name !== entity?.createdAtFieldName && field.useInFilters,
  );

  const showInitialMessage = (!query && !loading) || analysis?.comingSoon || error;

  const table = dataSource
    ?.schemaConfig
    .tables
    .find((item) => item.tableName === entity?.table);

  return (
    <Card
      size="small"
      style={{
        borderRadius: 0,
        borderColor: 'rgb(222, 223, 226)',
      }}
    >
      <div
        ref={resultContainerRef}
        style={{ minHeight: showInitialMessage ? '50vh' : 'auto' }}
      >
        {
          error && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <div style={{ paddingTop: '15vh' }}>
                <EmptyChartIcon />
              </div>
              <h3>Something went wrong</h3>
              <p>
                Please try again later
              </p>
            </div>
          )
        }
        {
          !query && !loading && !error && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <div style={{ paddingTop: '15vh' }}>
                <EmptyChartIcon />
              </div>
              <h3>Your result will show up here</h3>
              <p>
                Use the left panel to start building your insight flow
              </p>
            </div>
          )
        }
        {
          query && !analysis?.comingSoon && !error && (
            <div>
              <div
                className="d-flex mb-4 justify-content-between"
                ref={filtersSizer[1]}
              >
                {
                  dataSource && (
                    <InsightFlowFilters
                      fields={filterableFields}
                      table={table}
                      value={insightFlowFilters}
                      onChange={setInsightFlowFilters}
                      dataSourceId={dataSource?._id}
                      entities={entities}
                      dataSource={dataSource}
                    />
                  )
                }
                <div className="d-flex align-items-center">
                  {
                    createdAtField && (
                      <InsightFlowDateFilter
                        value={timeFilter}
                        onChange={setTimeFilter}
                      />
                    )
                  }
                </div>
              </div>
              {
                query.execution && !loading && (
                  <div>
                    <QueryExecutionView
                      id={query.execution._id}
                      type={query.execution.type}
                      clarification={query.execution.clarification}
                      resultType={query.execution.resultType}
                      responseText={query.execution.responseText}
                      result={query.execution.result}
                      status={query.execution.status}
                      calculationMethod={query.execution.calculationMethod}
                      mode={query.execution.mode}
                      prompt={query.execution.prompt}
                      details={query.execution.details}
                      showSaveAsWidgetButton
                      resultRepresentation={query.execution.resultRepresentation}
                      resultRepresentationBuilderOptions={
                        query
                          .execution
                          .resultRepresentationBuilderOptions
                      }
                      resultRepresentationOverride={
                        query
                          .execution
                          .resultRepresentationOverride
                      }
                      // subTypeOverride={resultRepresentationSubTypeOverride}
                      loading={loading}
                      // onRetryClick={onRetryClick}
                      // onCodeEditClick={onCodeEditClick}
                      errorReason={query.execution.errorReason}
                      onExecutionChange={(newExecution) => {
                        setQuery((oldQuery) => {
                          if (oldQuery) {
                            return {
                              ...oldQuery,
                              execution: newExecution,
                            };
                          }

                          return oldQuery;
                        });
                      }}
                      representationContainerStyle={{
                        width: resultContainerSize.width,
                        maxWidth: resultContainerSize.width,
                        overflowX: 'auto',
                        overflowY: 'auto',
                        height: 'auto',
                        maxHeight: `calc(100vh - ${HEIGHT_SUBTRACT}px)`,
                      }}
                      onSaveAsWidgetClick={() => {
                        setSaveWidgetDrawerVisible(true);
                      }}
                      chartHeight={windowSize.height / 2}
                      isInsightFlowQuery={query.execution.isInsightFlowQuery}
                      insightFlowQueryBuilderData={
                        query
                          .execution
                          .insightFlowQueryBuilderData
                      }
                      onTablePageChange={setPage}
                      totalCredits={query.execution.totalCredits}
                      showRetryButton
                    />
                  </div>
                )
              }
            </div>
          )
        }
        {
          analysis?.comingSoon && (
            <div>
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div style={{ paddingTop: '15vh' }}>
                  <EmptyChartIcon />
                </div>
                <h3>Coming Soon</h3>
                <p>
                  {`${analysis.label} is coming soon. Stay tuned for updates!`}
                </p>
              </div>
            </div>
          )
        }
        {
          loading && !analysis?.comingSoon && (
            <Skeleton.Node className="w-100" style={{ height: 300 }} active>
              <DotChartOutlined style={{ fontSize: 150, color: '#bfbfbf' }} />
            </Skeleton.Node>
          )
        }
      </div>
    </Card>
  );
}
