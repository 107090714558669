import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, notification } from 'antd';
import api from '../api';
import AppPage from '../layout/components/AppPage';

export default function InvitationPage() {
  const { code } = useParams();

  const [codeValid, setCodeValid] = useState(true);
  const [loading, setLoading] = useState(Boolean(code));
  const [loadingAction, setLoadingAction] = useState<'accept' | 'reject'>();
  const [rejected, setRejected] = useState(false);

  useEffect(() => {
    if (code) {
      setLoading(true);
      setCodeValid(false);
      api.users.checkInviteCode({ code }).then((res) => {
        setCodeValid(res.isValid);
      }).catch(() => {
        setCodeValid(false);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [code]);

  const onAccept = async () => {
    if (code) {
      setLoadingAction('accept');
      api.users.manageInvite({ code, action: 'accept' }).then((response) => {
        notification.success({
          message: 'Invitation accepted',
          description: 'You will be redirected to your new organization',
        });
        window.location.href = `/${response.organizationSlug}`;
        setLoadingAction(undefined);
      }).catch(() => {
        notification.error({
          message: 'Failed to accept invitation',
          description: 'Please try again later',
        });
        setLoadingAction(undefined);
      });
    }
  };

  const onReject = async () => {
    if (code) {
      setLoadingAction('reject');
      api.users.manageInvite({ code, action: 'reject' }).then(() => {
        setRejected(true);
        setLoadingAction(undefined);
      }).catch(() => {
        notification.error({
          message: 'Failed to reject invitation',
          description: 'Please try again later',
        });
        setLoadingAction(undefined);
      });
    }
  };

  return (
    <AppPage title="Manage Invitation" loading={loading}>
      {
        !codeValid && (
          <p className="text-center">
            Invalid Invitation
          </p>
        )
      }
      {
        codeValid && (
          <div className="row d-flex justify-content-center mt-5 pt-5">
            <div className="col-12 col-lg-5 col-xl-4 col-xxl-3">
              <Card>
                <p className="text-center">
                  You have been invited to join a new organization.
                </p>
                {
                  !rejected ? (
                    <div className="d-flex gap-2 justify-content-between">
                      <Button
                        block
                        type="primary"
                        loading={loadingAction === 'accept'}
                        onClick={onAccept}
                      >
                        Accept
                      </Button>
                      <Button
                        block
                        danger
                        loading={loadingAction === 'reject'}
                        onClick={onReject}
                      >
                        Reject
                      </Button>
                    </div>
                  ) : (
                    <p className="text-center">
                      Invitation Rejected
                    </p>
                  )
                }
              </Card>
            </div>
          </div>
        )
      }
    </AppPage>
  );
}
