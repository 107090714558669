import {
  Button,
  Col, Form, notification, Row, Switch,
} from 'antd';
import { useAuth } from '../../auth';
import useRequest from '../../common/hooks/useRequest';
import api, { User } from '../../api';

export default function UserEmailPreferences() {
  const { user, setUser } = useAuth();

  const request = useRequest(
    async (values: Pick<User, 'name'> & { password?: string; currentPassword?: string }) => {
      if (user) {
        const response = await api.users.patch(user._id, values);
        setUser(response as User);
      }
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Settings updated',
        });
      },
    },
  );

  return (
    <Row>
      <Col span={24} md={12} lg={8} xl={6}>
        <Form
          layout="horizontal"
          initialValues={{
            receiveMarketingEmails: user?.receiveMarketingEmails,
          }}
          onFinish={request.submit}
        >
          <Form.Item
            label="Receive Marketing Emails"
            name="receiveMarketingEmails"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={request.loading}
              disabled={request.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
