import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import useActiveOrganization from './useActiveOrganization';
import { globalAtom } from '../state/global-state';
import api from '../../api';

export default function useRefreshActiveOrganization() {
  const activeOrganization = useActiveOrganization();
  const setGlobalState = useSetRecoilState(globalAtom);

  return useCallback(() => {
    if (activeOrganization) {
      api.organizations.get(activeOrganization._id).then((organization) => {
        setGlobalState((state) => ({
          ...state,
          organization,
        }));
      });
    }
  }, [activeOrganization?._id]);
}
