import BuilderStepCard from '../BuilderStepCard';
import { DataSourceTableConfig } from '../../../api';
import AdvancedSelect from '../../../common/components/AdvancedSelect';

interface SelectStepProps {
  enabled?: boolean;
  onEnabledChange: (enabled: boolean) => void;
  value?: string[];
  onChange: (value: string[]) => void;
  tableConfig: DataSourceTableConfig;
}

export default function SelectStep(props: SelectStepProps) {
  const {
    enabled,
    onEnabledChange,
    value,
    onChange,
    tableConfig,
  } = props;

  const options = Object.keys(tableConfig?.schema?.properties || {}).map((key) => ({
    label: key,
    value: key,
  }));

  return (
    <BuilderStepCard
      question="Select which fields you want to return"
      description="If this option is disabled, all the fields will be returned."
      isVisible={enabled}
      setIsVisible={onEnabledChange}
    >
      <AdvancedSelect
        options={options}
        value={value}
        onChange={onChange}
        mode="multiple"
        style={{ width: '100%' }}
      />
    </BuilderStepCard>
  );
}
