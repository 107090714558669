const MARKETING_PARAMS_KEY = 'marketing_params';

export const getMarketingParams = (): Record<string, any> => {
  try {
    const value = localStorage.getItem(MARKETING_PARAMS_KEY);

    if (!value) {
      return {};
    }

    return JSON.parse(value);
  } catch (e) {
    return {};
  }
};

export const updateMarketingParams = (params: Record<string, any>) => {
  const marketingParams = getMarketingParams();

  if (!marketingParams.ref) {
    const ref = document.referrer;

    if (ref !== '') {
      try {
        const url = new URL(ref);
        marketingParams.ref = url.hostname;
      } catch (e) {
        // do nothing
        marketingParams.ref = ref;
      }
    }
  }

  localStorage.setItem(MARKETING_PARAMS_KEY, JSON.stringify({
    ...marketingParams,
    ...params,
  }));
};
