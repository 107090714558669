import { Tag, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface EncryptedLabelProps {
  label: string;
}

export default function EncryptedLabel(props: EncryptedLabelProps) {
  const { label } = props;

  return (
    <span className="d-flex align-items-center">
      <span>{label}</span>
      <Tooltip title="This value is stored securely in an encrypted form">
        <Tag
          color="blue"
          className="ms-2"
          icon={<LockOutlined />}
        >
          Encrypted
        </Tag>
      </Tooltip>
    </span>
  );
}
