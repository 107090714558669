import {
  Button,
  Card, Col, notification, Row,
} from 'antd';
import { useState } from 'react';
import api, { Organization } from '../../api';

function buildSlackUrl(organizationSlug: string) {
  const url = 'https://slack.com/oauth/v2/authorize';
  const scopes = ['chat:write', 'channels:read', 'groups:read', 'mpim:read', 'im:read'];
  const redirectUri = 'https://app.insightbase.ai/authorize/slack';
  const clientId = '5070484577440.5163299151652';

  return `${url}?scope=${scopes.join(',')}&redirect_uri=${redirectUri}&client_id=${clientId}&state=${organizationSlug}`;
}

interface OrganizationIntegrationsProps {
  organization: Organization;
}

export default function OrganizationIntegrations(props: OrganizationIntegrationsProps) {
  const { organization } = props;
  const [buildingAirtableUrl, setBuildingAirtableUrl] = useState(false);

  const onAirtableEnable = () => {
    setBuildingAirtableUrl(true);
    api.organizations.getAirtableIntegrationUrl({ id: organization._id }).then((response) => {
      window.open(response.url, '_blank');
      setBuildingAirtableUrl(false);
    }).catch(() => {
      setBuildingAirtableUrl(false);
      notification.error({
        message: 'Something went wrong while enabling Airtable',
        description: 'Please try again.',
      });
    });
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col
          span={24}
          md={12}
          xl={6}
          xxl={4}
        >
          <Card
            title="Slack"
            size="small"
            extra={(
              <a
                href={buildSlackUrl(organization.slug)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  size="small"
                >
                  {
                    organization.integrations?.slack?.enabled ? 'Reconnect' : 'Enable'
                  }
                </Button>
              </a>
            )}
          >
            Connect your Slack workspace to receive alerts about your organization.
          </Card>
        </Col>

        <Col
          span={24}
          md={12}
          xl={6}
          xxl={4}
        >
          <Card
            title="Airtable"
            size="small"
            extra={(
              <Button
                size="small"
                loading={buildingAirtableUrl}
                disabled={buildingAirtableUrl}
                onClick={onAirtableEnable}
              >
                {
                  organization.integrations?.airtable?.enabled ? 'Reconnect' : 'Enable'
                }
              </Button>
            )}
          >
            Connect to Airtable to use it as a data source for your organization.
          </Card>
        </Col>
      </Row>
    </div>
  );
}
