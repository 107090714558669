import {
  Alert,
  Button, Form, Input, Select,
} from 'antd';
import {
  ParameterType, ParameterScope, ParameterData,
} from '../../api';
import ParameterValueInput from './ParameterValueInput';

const typeOptions = [
  {
    label: 'String',
    value: ParameterType.STRING,
  },
  {
    label: 'Number',
    value: ParameterType.NUMBER,
  },
  {
    label: 'Boolean',
    value: ParameterType.BOOLEAN,
  },
  {
    label: 'Date',
    value: ParameterType.DATE,
  },
];

const scopeOptions = [
  {
    label: 'Dashboard',
    value: ParameterScope.DASHBOARD,
  },
  {
    label: 'Widget',
    value: ParameterScope.WIDGET,
  },
];

export interface ParameterBuilderProps {
  onCancel?: () => void;
  onSubmit?: (values: ParameterData) => void;
  loading?: boolean;
  parameter?: Partial<ParameterData>;
  scope?: ParameterScope;
}

export default function ParameterBuilder(props: ParameterBuilderProps) {
  const {
    onCancel,
    loading,
    onSubmit,
    parameter,
    scope,
  } = props;

  return (
    <Form<ParameterData>
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        name: '',
        scope: scope || ParameterScope.DASHBOARD,
        type: ParameterType.STRING,
        description: '',
        value: null,
        ...parameter,
      }}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'Please enter parameter name' },
          { pattern: /^[a-zA-Z0-9_ ]+$/, message: 'Only letters, numbers, underscores, and spaces are allowed' },
        ]}
      >
        <Input
          placeholder="Parameter name"
        />
      </Form.Item>
      <Form.Item
        name="scope"
        label="Scope"
        rules={[{ required: true, message: 'Please select parameter scope' }]}
        style={{ display: 'none' }}
      >
        <Select
          placeholder="Select scope"
          options={scopeOptions}
        />
      </Form.Item>
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: 'Please select parameter type' }]}
      >
        <Select
          placeholder="Select type"
          options={typeOptions}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
      >
        <Input placeholder="Describe this parameter" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {
          ({ getFieldValue, setFieldValue }) => {
            const type = getFieldValue('type');
            const value = getFieldValue('value');

            return (
              <Form.Item name="value" label="Value">
                <ParameterValueInput
                  parameter={{ type }}
                  value={value}
                  onChange={(newValue) => setFieldValue('value', newValue)}
                />
              </Form.Item>
            );
          }
        }
      </Form.Item>
      <div className="d-flex align-items-center justify-content-between">
        <Form.Item noStyle>
          <span>
            {
              onCancel && (
                <Button onClick={onCancel}>
                  Cancel
                </Button>
              )
            }
          </span>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {parameter ? 'Save & Refresh' : 'Add Parameter'}
          </Button>
        </Form.Item>
      </div>
      {
        parameter && (
          <Alert
            message="Changing this parameter will trigger a refresh of all widgets that use it."
            className="mt-4"
            type="info"
          />
        )
      }
    </Form>
  );
}
