import BuilderStepCard from '../BuilderStepCard';
import { DataSourceTableConfig } from '../../../api';
import DataSourceTableFieldSelect from '../DataSourceTableFieldSelect';

interface AggregationFieldSelectStepProps {
  value?: string;
  onChange: (value: string) => void;
  tableConfig: DataSourceTableConfig;
}

export default function AggregationFieldSelectStep(props: AggregationFieldSelectStepProps) {
  const { value, onChange, tableConfig } = props;

  return (
    <BuilderStepCard
      question="What field do you want to use for the calculation?"
      hideVisibilityToggle
    >
      <DataSourceTableFieldSelect
        value={value}
        onChange={onChange}
        tableConfig={tableConfig}
        style={{ width: '100%' }}
      />
    </BuilderStepCard>
  );
}
