import ResultRepresentationManager from './ResultRepresentationManager';
import HeatmapChartToTableTransformer from '../transformers/HeatmapChartToTableTransformer';

class HeatmapResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new HeatmapChartToTableTransformer(this.representation),
  ];
}

export default HeatmapResultRepresentationManager;
