import {
  Button, Form, Input, notification,
} from 'antd';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import api, { Organization } from '../../api';
import useRequest from '../../common/hooks/useRequest';
import { globalAtom } from '../../common/state/global-state';

interface OrganizationFormProps {
  organization?: Organization;
}

export default function OrganizationForm(props: OrganizationFormProps) {
  const { organization } = props;
  const setGlobalState = useSetRecoilState(globalAtom);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    if (organization) {
      form.setFieldsValue(organization);
    }
  }, [organization]);

  const request = useRequest<Organization>(async (data: Pick<Organization, 'name'>) => {
    if (organization) {
      return await api.organizations.patch(
        organization._id,
        data,
      ) as unknown as Promise<Organization>;
    }

    return await api.organizations.create(data) as unknown as Promise<Organization>;
  }, {
    onSuccess: (response) => {
      setGlobalState((prev) => ({
        ...prev,
        organization: response._id === prev.organization?._id ? response : prev.organization,
        organizations: prev.organizations.map((org) => {
          if (org._id === response._id) {
            return response;
          }

          return org;
        }),
      }));

      if (!organization) {
        navigate(`/${response.slug}`);
      }

      notification.success({
        message: organization ? 'Organization updated' : 'Organization created',
      });
    },
  });

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ name: '' }}
      onFinish={request.submit}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter organization name' }]}
      >
        <Input placeholder="Organization Name" />
      </Form.Item>
      <Form.Item className="d-flex justify-content-center">
        <Button
          type="primary"
          htmlType="submit"
          loading={request.loading}
          disabled={request.loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
