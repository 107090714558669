import EntityConstructor from '../components/EntityConstructor';
import AppPage from '../../layout/components/AppPage';
import useActiveProject from '../../common/hooks/useActiveProject';

export default function CreateEntityPage() {
  const project = useActiveProject();

  return (
    <AppPage title="Create Entity">
      {
        project && (
          <EntityConstructor
            projectId={project?._id}
            defaultDataSourceId={project?.defaultDataSourceId}
          />
        )
      }
    </AppPage>
  );
}
