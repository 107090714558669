import { ResultRepresentationSubType } from '../../../api';
import ResultRepresentationTransformer from './ResultRepresentationTransformer';

class PieChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.PIE_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData(): any {
    return this.resultRepresentation.result;
  }
}

export default PieChartToTableTransformer;
