import Model from './Model';
import ResultRepresentation, {
  ResultRepresentationOverride,
  ResultRepresentationSubType,
} from './ResultRepresentation';
import RawCodeLanguage from './RawCodeLanguage';
import { ParameterData } from './Parameter';
import Entity, { EntityField } from './Entity';
import CustomDateSelection from '../../common/types/CustomDateSelection';
import {
  DateTrunc,
  ExpressionField, Field, FieldFilter, SortStage,
} from '../../dbaql-v2/types';
import Format from '../definitions/Format';

export type ResultRepresentationBuilderOptionsDataSet = {
  title?: string;
  valueFieldName: string;
  format?: Format;
};

export type TableResultRepresentationBuilderOptions = {
  columns?: {
    key: string;
    title: string;
    format?: Format;
  }[];
};

export type ResultRepresentationBuilderOptions = {
  enable?: boolean;
  labelsFieldName?: string;
  labelsFormat?: Format;
  dataSets?: ResultRepresentationBuilderOptionsDataSet[];
  resultRepresentationSubType: ResultRepresentationSubType;
  subTypeOptions?: {
    [ResultRepresentationSubType.TABLE]?: TableResultRepresentationBuilderOptions;
  };
};

type ComputedFilter = CustomDateSelection;

export interface InsightFlowFilter {
  label: string;
  filter: FieldFilter;
  filterFieldName: string;
  field: EntityField;
  computedFilter?: ComputedFilter;
  foreignEntity?: Entity;
}

export enum QueryExecutionStatus {
  RUNNING = 'running',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum QueryExecutionTriggerMethod {
  INITIAL = 'initial',
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum QueryTriggeredBy {
  WIDGET = 'widget',
  QUERY = 'query',
}

export enum QueryExecutionCalculationMethod {
  DBAQL = 'DBAQL',
  RAW_CODE = 'RAW_CODE',
}

export enum QueryExecutionMode {
  AI = 'ai',
  DBAQL = 'dbaql', // Use the DBAQL language
  DBAQLV2 = 'dbaql-v2', // Use the DBAQL language
  RAW_CODE = 'raw-code', // Use the raw code language
  ENTITY_QUERY_BUILDER = 'entity-query-builder', // Use the entity query builder
}

export enum QueryExecutionErrorReason {
  OUT_OF_CREDITS = 'out-of-credits',
  GENERATE_CODE_ERROR = 'generate-code-error',
  UNSAFE_QUERY = 'unsafe-query',
  GENERAL_ERROR = 'general-error',
  QUERY_EXECUTION_TIMEOUT = 'query-execution-timeout',
}

export enum QueryExecutionType {
  RESULT = 'result', // The query execution returns a result
  CLARIFICATION = 'clarification', // The query execution is a clarification
}

export enum ClarificationType {
  SELECT = 'select',
  TABLE_NAME_SELECT = 'table-name-select',
  TEXT = 'text',
}

export type Clarification = {
  type: ClarificationType;
  message?: string;
  options?: { label: string; value: string }[];
};

export type Metric = {
  key: string;
  label: string;
  description: string;
  expression: ExpressionField;
  dataSet?: ResultRepresentationBuilderOptionsDataSet;
};

export type Segment = {
  key: string;
  label: string;
  default?: boolean;
  truncate?: DateTrunc['truncate'];
  groupField: Field;
  field: EntityField;
  sort?: SortStage['sort'];
  dataSet?: ResultRepresentationBuilderOptionsDataSet;
  format?: Format;
};

export enum AnalysisType {
  SEE_DATASET_ENTRIES = 'see-dataset-entries',
  UNDERSTAND_TRENDS = 'understand-trends',
  ANALYZE_SEGMENTS = 'analyze-segments',
  ANALYZE_TOTALS = 'analyze-totals',
  GEOSPATIAL_ANALYSIS = 'geospatial-analysis',
}

export type InsightFlowQueryBuilderData = {
  entityId: Entity['_id'];
  analysisType: AnalysisType;
  insightFlowFilters: InsightFlowFilter[];
  timeFilter: CustomDateSelection;
  limit: number;
  page: number;
  metrics: Metric[];
  defaultSegment?: Segment;
  segments: Segment[];
};

export default interface QueryExecution extends Model {
  queryId: Model['_id'];
  mode: QueryExecutionMode;
  type: QueryExecutionType;
  prompt?: string;
  dbaqlQuery?: any; // TODO: add dbaql definition
  triggeredBy: QueryTriggeredBy;
  triggerMethod: QueryExecutionTriggerMethod;
  status: QueryExecutionStatus;
  errorReason?: QueryExecutionErrorReason;
  responseText?: string;
  result?: any;
  resultType: string;
  resultRepresentation?: ResultRepresentation;
  resultRepresentationOverride?: ResultRepresentationOverride;
  calculationMethod?: QueryExecutionCalculationMethod;
  totalCredits?: number;
  details?: {
    rawCode?: string;
    error?: {
      message?: string;
      hint: string;
    };
  };
  rawCode?: string;
  rawCodeLanguage?: RawCodeLanguage;
  resultRepresentationSubType?: ResultRepresentationSubType;
  parameters?: ParameterData[];
  clarification?: Clarification;
  isInsightFlowQuery?: boolean;
  insightFlowQueryBuilderData?: InsightFlowQueryBuilderData;
  resultRepresentationBuilderOptions?: ResultRepresentationBuilderOptions;
}
