import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import { ResultRepresentation } from '../../api';
import ErrorBoundary from '../../common/components/ErrorBoundary';
import useExternalScript from '../../common/hooks/useExternalScript';
import Spin from '../../common/components/Spin';

function renderFallback(error: any) {
  return (
    <div>
      <p className="fw-bold">Error rendering custom code representation:</p>
      <p className="fw-bold text-danger">
        {typeof error.toString === 'function' ? error.toString() : error.message}
      </p>
    </div>
  );
}

function TreeGrid(props: { options: Record<string, any> }) {
  const { options } = props;
  const [loading, setLoading] = useState(true);
  const [id] = useState(Math.random().toString(36).substring(7));

  useExternalScript(
    '/Grid/GridE.js',
    () => {
      // @ts-ignore
      window.TreeGrid({ ...options }, id);
      setLoading(false);
      return undefined;
    },
    {
      'data-presets': 'env,react',
    },
  );

  return (
    <div>
      {
        loading && (
          <Spin centered />
        )
      }
      <div id={id} />
    </div>
  );
}

function executeUserCode(
  userCode: string,
  renderDefault: () => JSX.Element,
  resultRepresentation?: ResultRepresentation,
) {
  try {
    // Transpile user-provided code using Babel
    // @ts-ignore
    const transpiledCode = Babel.transform(`function IBAPP() {${userCode}}`, {
      presets: ['react', 'env'],
    }).code;

    // Create a function from the transpiled code
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const userFunction = new Function(
      'React',
      'Components',
      'data',
      'renderDefault',
      `try { ${transpiledCode}; return IBAPP(); } catch(e) { return 'Err'; }`,
    );

    // Execute the function to render the component
    return userFunction(
      React,
      { Button, TreeGrid },
      resultRepresentation?.data,
      renderDefault,
    );
  } catch (error: any) {
    return renderFallback(error);
  }
}

interface CustomCodeResultRepresentationViewProps {
  code: string;
  resultRepresentation: ResultRepresentation;
  renderDefault: () => JSX.Element;
}

export default function CustomCodeResultRepresentationView(
  props: CustomCodeResultRepresentationViewProps,
) {
  const { code, resultRepresentation, renderDefault } = props;
  const [loading, setLoading] = useState(true);

  useExternalScript(
    'https://unpkg.com/@babel/standalone/babel.min.js',
    () => void setLoading(false),
    {
      'data-presets': 'env,react',
    },
  );

  const content = useMemo(() => {
    if (loading) {
      return <Spin centered />;
    }

    return executeUserCode(code, renderDefault, resultRepresentation);
  }, [loading, code, JSON.stringify(resultRepresentation)]);

  if (loading) {
    return (
      <Spin centered />
    );
  }

  return (
    <ErrorBoundary updateKey={code} fallback={renderFallback}>
      {content}
    </ErrorBoundary>
  );
}
