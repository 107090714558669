import { Button, ButtonProps, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import useModal from '../hooks/useModal';
import useRequest from '../hooks/useRequest';

interface DeleteButtonProps<T> extends ButtonProps {
  title?: string;
  subtitle?: string;
  onDelete: () => Promise<T>;
  onDeleteSuccess?: (response: T) => void;
}

export default function DeleteButton<T = any>(props: DeleteButtonProps<T>) {
  const {
    onDelete, onDeleteSuccess, title = 'Are you sure?', subtitle, ...rest
  } = props;
  const modal = useModal();
  const deleteRequest = useRequest<T>(onDelete, {
    onSuccess: (response) => {
      if (onDeleteSuccess) {
        onDeleteSuccess(response);
      }

      modal.close();
    },
  });

  return (
    <>
      <Button
        icon={<DeleteOutlined />}
        danger
        onClick={modal.open}
        loading={deleteRequest.loading}
        {...rest}
      >
        Delete
      </Button>
      <Modal
        title={title}
        {...modal.props}
        okButtonProps={{
          danger: true,
          loading: deleteRequest.loading,
        }}
        okText="Delete"
        onOk={deleteRequest.submit}
      >
        {subtitle}
      </Modal>
    </>
  );
}
