import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class NumberToDateTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.NUMBER;

  to = ResultRepresentationSubType.DATE;

  isCompatible(): boolean {
    return String(this.transformData()) !== 'Invalid Date';
  }

  transformData() {
    return new Date(this.resultRepresentation.data);
  }
}

export default NumberToDateTransformer;
