import AppTabbedPage from '../../layout/components/AppTabbedPage';
import UserGeneralSettings from '../components/UserGeneralSettings';
import UserPasswordSettings from '../components/UserPasswordSettings';
import UserEmailPreferences from '../components/UserEmailPreferences';

export default function UserSettingsPage() {
  return (
    <AppTabbedPage
      title="User Settings"
      items={[
        {
          key: 'general',
          label: 'General',
          children: <UserGeneralSettings />,
        },
        {
          key: 'password',
          label: 'Password',
          children: <UserPasswordSettings />,
        },
        {
          key: 'email-preferences',
          label: 'Email Preferences',
          children: <UserEmailPreferences />,
        },
      ]}
      syncToUrl
    />
  );
}
