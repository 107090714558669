import { TranslationOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class TextResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = TranslationOutlined;

  title = 'Text';

  type = ResultRepresentationType.TEXT;
}

export default TextResultRepresentationSubTypeConfig;
