import { Button, Tooltip, Tour } from 'antd';
import { AppstoreAddOutlined, InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { CSSProperties, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import api, {
  DataSource,
  QueryExecution,
  QueryExecutionCalculationMethod,
  QueryExecutionErrorReason,
  QueryExecutionStatus,
  QueryExecutionType,
  ResultRepresentationOverride,
  ResultRepresentationSubType,
} from '../../../api';
import ResultRepresentationView from '../../../results/components/ResultRepresentationView';
import DBAQLInfoButton from '../../../common/components/DBAQLInfoButton';
import ResultRepresentationSelectButton from '../ResultRepresentationSelectButton';
import QueryExecutionInfoDrawer from '../QueryExecutionInfoDrawer';
import Typewriter from '../../../common/components/TypeWriter';
import CustomCodeResultRepresentationConfigurator
  from '../../../results/components/CustomCodeResultRepresentationConfigurator';
import QueryExecutionClarificationView from '../QueryExecutionClarificationView';
import { getActiveOrganizationSlug } from '../../../organizations/utils/sync';

export interface QueryExecutionViewProps extends Pick<
QueryExecution, 'mode' | 'responseText' | 'result' | 'resultType'
| 'status' | 'resultRepresentation' | 'calculationMethod'
| 'details' | 'rawCode' | 'rawCodeLanguage' | 'prompt'
| 'resultRepresentationOverride' | 'errorReason'
| 'type' | 'clarification' | 'isInsightFlowQuery' | 'insightFlowQueryBuilderData' | 'totalCredits'
| 'resultRepresentationBuilderOptions'
> {
  showSaveAsWidgetButton?: boolean;
  onSaveAsWidgetClick?: () => void;
  id: QueryExecution['_id'];
  showRetryButton?: boolean;
  onRetryClick?: () => void;
  loading?: boolean;
  hideExplanation?: boolean;
  overflowRepresentation?: boolean;
  dbaqlButtonShortVersion?: boolean;
  hideDbaqlButton?: boolean;
  subTypeOverride?: ResultRepresentationSubType;
  onSubTypeOverrideChange?: (subType: ResultRepresentationSubType) => void;
  onCodeEditClick?: () => void;
  onRepresentationTypeEnd?: () => void;
  onExecutionChange?: (execution: QueryExecution) => void;
  onOverrideChange?: (override: Partial<ResultRepresentationOverride>) => void;
  highlightSaveAsWidgetButton?: boolean;
  dataSource?: DataSource;
  onClarificationSelect?: (value: string) => void;
  isInactive?: boolean;
  representationContainerStyle?: Partial<CSSProperties>;
  chartHeight?: number;
  onTablePageChange?: (newPage: number) => void;
}

function renderError(reason?: QueryExecution['errorReason']) {
  if (reason === QueryExecutionErrorReason.QUERY_EXECUTION_TIMEOUT) {
    return (
      <>
        <h4 className="m-0">
          Your query took more than 30 seconds to run.
        </h4>
        <p>
          Try to limit the amount of data you&apos;re querying.
        </p>
      </>
    );
  }

  if (reason === QueryExecutionErrorReason.OUT_OF_CREDITS) {
    return (
      <>
        <h4 className="m-0">
          You&apos;re out of credits.
        </h4>
        <p>
          Please upgrade your plan to get more credits.
        </p>
        <div>
          <Link to={`/${getActiveOrganizationSlug()}/settings/billing`}>
            <Button type="primary">
              Upgrade
            </Button>
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      <h4 className="m-0">
        Hey, we can&apos;t answer this question.
        {' '}
        But don&apos;t worry, here&apos;s what you can do:
      </h4>
      <ul className="mt-4">
        <li>
          Reformulate your question. Make sure it&apos;s clear and concise.
        </li>
        <li>
          Try to give us more context.
          {' '}
          The more details you give us, the better we can answer your question.
        </li>
      </ul>
    </>
  );
}

export default function QueryExecutionView(props: QueryExecutionViewProps) {
  const {
    status,
    responseText,
    showSaveAsWidgetButton,
    onSaveAsWidgetClick,
    resultRepresentation,
    resultRepresentationOverride,
    result,
    id,
    showRetryButton,
    onRetryClick,
    loading,
    hideExplanation,
    overflowRepresentation,
    calculationMethod,
    dbaqlButtonShortVersion,
    hideDbaqlButton,
    subTypeOverride: propsSubtypeOverride,
    onSubTypeOverrideChange,
    mode,
    details,
    rawCode,
    rawCodeLanguage,
    prompt,
    onCodeEditClick,
    onRepresentationTypeEnd,
    onExecutionChange,
    errorReason,
    onOverrideChange,
    highlightSaveAsWidgetButton,
    type = QueryExecutionType.RESULT,
    clarification,
    dataSource,
    onClarificationSelect,
    isInactive,
    representationContainerStyle = {},
    chartHeight,
    isInsightFlowQuery,
    insightFlowQueryBuilderData,
    onTablePageChange,
    totalCredits,
    resultRepresentationBuilderOptions,
  } = props;

  const subTypeOverride = resultRepresentationOverride?.subType || propsSubtypeOverride;

  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [showMainInfo, setShowMainInfo] = useState(!(!hideExplanation && responseText));
  const [customCodeConfigDrawerOpen, setCustomCodeConfigDrawerOpen] = useState(false);
  const [tourOpen, setTourOpen] = useState(highlightSaveAsWidgetButton);
  const saveAsWidgetButtonRef = useRef(null);

  const changeSubType = async (subType: ResultRepresentationSubType) => {
    await api.queryExecutions.patch(id, {
      resultRepresentationOverride: {
        ...(resultRepresentationOverride || {}),
        subType,
      },
    });

    if (onSubTypeOverrideChange) {
      onSubTypeOverrideChange(subType);
    }
  };

  if (type === QueryExecutionType.CLARIFICATION) {
    return (
      <QueryExecutionClarificationView
        clarification={clarification}
        dataSource={dataSource}
        onSelectionChange={onClarificationSelect}
        isInactive={isInactive}
      />
    );
  }

  return (
    <div>
      {
        status === QueryExecutionStatus.SUCCESS && (
          <>
            {
              !hideExplanation && responseText && (
                <Typewriter
                  className="m-0 mb-4"
                  text={responseText}
                  onDone={() => {
                    setShowMainInfo(true);

                    if (onRepresentationTypeEnd) {
                      onRepresentationTypeEnd();
                    }
                  }}
                />
              )
            }
            {
              showMainInfo && (
                <>
                  <div
                    style={{
                      overflow: overflowRepresentation ? 'auto' : 'initial',
                      maxHeight: overflowRepresentation ? '50vh' : 'initial',
                      ...representationContainerStyle,
                    }}
                  >
                    <ResultRepresentationView
                      key={id}
                      resultRepresentation={resultRepresentation}
                      resultRepresentationOverride={resultRepresentationOverride}
                      result={result}
                      subTypeOverride={subTypeOverride}
                      onOverrideChange={onOverrideChange}
                      chartHeight={chartHeight}
                      showPagination={isInsightFlowQuery && !subTypeOverride}
                      tablePage={insightFlowQueryBuilderData?.page}
                      onTablePageChange={onTablePageChange}
                      builderOptions={resultRepresentationBuilderOptions}
                    />
                  </div>
                  {
                    calculationMethod === QueryExecutionCalculationMethod.DBAQL
                    && !hideDbaqlButton && (
                      <div className="mt-2">
                        <DBAQLInfoButton shortVersion={dbaqlButtonShortVersion} />
                      </div>
                    )
                  }
                  {
                    showSaveAsWidgetButton && (
                      <div className="d-flex align-items-center justify-content-between mt-4">
                        <div className="d-flex gap-2">
                          <Button
                            ref={saveAsWidgetButtonRef}
                            type="primary"
                            icon={<AppstoreAddOutlined />}
                            onClick={() => {
                              if (onSaveAsWidgetClick) {
                                onSaveAsWidgetClick();
                              }
                              setTourOpen(false);
                            }}
                            disabled={loading}
                          >
                            Save as Widget
                          </Button>
                          {
                            highlightSaveAsWidgetButton && (
                              <Tour
                                steps={[
                                  {
                                    title: 'Save as Widget',
                                    description: 'You can save this result as a widget to your dashboard.',
                                    target: () => saveAsWidgetButtonRef.current,
                                    nextButtonProps: {
                                      children: 'I understand',
                                    },
                                  },
                                ]}
                                open={tourOpen}
                                onClose={() => {
                                  setTourOpen(false);
                                }}
                              />
                            )
                          }
                          {
                            showRetryButton && onRetryClick && (
                              <Button
                                icon={<ReloadOutlined />}
                                onClick={onRetryClick}
                                disabled={loading}
                              >
                                Re-run
                              </Button>
                            )
                          }
                          {
                            resultRepresentation && onSubTypeOverrideChange && (
                              <ResultRepresentationSelectButton
                                resultRepresentation={resultRepresentation}
                                subTypeOverride={subTypeOverride}
                                onChange={changeSubType}
                                type="default"
                                size="middle"
                                disabled={loading}
                                onCustomCodeSelect={() => {
                                  setCustomCodeConfigDrawerOpen(true);
                                }}
                              />
                            )
                          }
                        </div>
                        <Tooltip title="Click for details">
                          <Button
                            onClick={() => {
                              setInfoDrawerOpen(true);
                            }}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        </Tooltip>
                      </div>
                    )
                  }
                </>
              )
            }
          </>
        )
      }
      {
        status === QueryExecutionStatus.ERROR && (
          <div>
            {
              details?.error ? (
                <>
                  <h4 className="m-0">
                    There was an error while running your query:
                  </h4>
                  <pre
                    style={{
                      borderRadius: 8,
                      padding: 10,
                      backgroundColor: '#f0f0f0',
                      marginBottom: 0,
                      whiteSpace: 'pre-wrap',
                      fontSize: 14,
                    }}
                  >
                    <p className="m-0">
                      {details.error.message || 'Unknown error'}
                    </p>
                    {
                      details.error.hint && (
                        <p className="mt-4 mb-0">
                          <b>Hint:</b>
                          {' '}
                          {details.error.hint}
                        </p>
                      )
                    }
                  </pre>
                </>
              ) : renderError(errorReason)
            }
          </div>
        )
      }
      <QueryExecutionInfoDrawer
        open={infoDrawerOpen}
        onClose={() => { setInfoDrawerOpen(false); }}
        onCodeEditClick={onCodeEditClick ? () => {
          setInfoDrawerOpen(false);
          onCodeEditClick();
        } : undefined}
        execution={{
          mode,
          details,
          rawCode,
          rawCodeLanguage,
          result,
          responseText,
          prompt,
          totalCredits,
        }}
      />
      <CustomCodeResultRepresentationConfigurator
        open={Boolean(customCodeConfigDrawerOpen)}
        onClose={() => void setCustomCodeConfigDrawerOpen(false)}
        resultRepresentation={resultRepresentation}
        code={resultRepresentationOverride?.customCode}
        onChange={(customCode) => {
          api.queryExecutions.patch(id, {
            resultRepresentationOverride: {
              ...(resultRepresentationOverride || {}),
              subType: ResultRepresentationSubType.CUSTOM_CODE,
              customCode,
            },
          }).then((res) => {
            if (onExecutionChange) {
              onExecutionChange(res as QueryExecution);
            }
          });
        }}
      />
    </div>
  );
}
