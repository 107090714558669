import { atom } from 'recoil';
import { Organization } from '../api';

export interface OrganizationsState {
  organization: Organization | null;
}

export const organizationsAtom = atom<OrganizationsState>({
  key: 'organization',
  default: {
    organization: null,
  },
});
