import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class HeatmapChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.HEATMAP_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData(): any {
    return this.resultRepresentation.result;
  }
}

export default HeatmapChartToTableTransformer;
