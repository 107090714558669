import { Button, DatePicker, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DataSourceTableColumnConfig, DataSourceTableConfig } from '../../../api';
import { Operator } from '../../types';
import AdvancedSelect, { Option } from '../../../common/components/AdvancedSelect';
import BuilderStepCard from '../BuilderStepCard';
import { FlatFilter } from '../../helper-types';
import { DbaqlBuilderState } from '../../state';
import { DbaqlBuilderStateUpdater } from '../../hooks/useDbaqlBuilderState';

interface DataSourceFilterBuilderProps extends Pick<DbaqlBuilderState, 'filters' | 'enableFilter'> {
  tableConfig: DataSourceTableConfig;
  setFilters: DbaqlBuilderStateUpdater<'filters'>;
  setEnableFilter: DbaqlBuilderStateUpdater<'enableFilter'>;
}

const filterTypeOptions: Option[] = [
  {
    value: Operator.EQ,
    label: 'is equal',
    // subLabel: 'The value should be equal',
  },
  {
    value: Operator.NE,
    label: 'is not equal',
    // subLabel: 'The value should not be equal',
  },
  {
    value: Operator.GT,
    label: 'is greater than',
    // subLabel: 'The value should be greater than',
  },
  {
    value: Operator.GTE,
    label: 'is greater than or equal',
    // subLabel: 'The value should be greater than or equal',
  },
  {
    value: Operator.LT,
    label: 'is less than',
    // subLabel: 'The value should be less than',
  },
  {
    value: Operator.LTE,
    label: 'is less than or equal',
    // subLabel: 'The value should be less than or equal',
  },
  {
    value: Operator.IN,
    label: 'is one of',
    // subLabel: 'The value should be in',
  },
  {
    value: Operator.NIN,
    label: 'is not one of',
    // subLabel: 'The value should not be in',
  },
  {
    value: Operator.ILIKE,
    label: 'contains text',
    // subLabel: 'The value should be like',
  },
  {
    value: Operator.LIKE,
    label: 'contains text (case sensitive)',
    // subLabel: 'The value should be ilike',
  },
];

function getPropertyOperators(property: DataSourceTableColumnConfig): Operator[] {
  if (property.type === 'string') {
    if (property.format === 'date-time') {
      return [
        Operator.GT,
        Operator.GTE,
        Operator.LT,
        Operator.LTE,
      ];
    }

    return [
      Operator.EQ,
      Operator.NE,
      Operator.LIKE,
      Operator.ILIKE,
      Operator.IN,
      Operator.NIN,
    ];
  }

  if (property.type === 'number') {
    return [
      Operator.EQ,
      Operator.NE,
      Operator.GT,
      Operator.GTE,
      Operator.LT,
      Operator.LTE,
      Operator.IN,
      Operator.NIN,
    ];
  }

  if (property.type === 'boolean') {
    return [
      Operator.EQ,
      Operator.NE,
    ];
  }

  return [];
}

function getFieldDefaultValue(property: DataSourceTableColumnConfig): any {
  if (property.type === 'string') {
    if (property.format === 'date-time') {
      return dayjs().toISOString();
    }

    return '';
  }

  if (property.type === 'number') {
    return 0;
  }

  if (property.type === 'boolean') {
    return true;
  }

  return null;
}

function getPropertyOperatorsOptions(property: DataSourceTableColumnConfig): Option[] {
  const operators = getPropertyOperators(property);

  return filterTypeOptions.filter((option) => operators.includes(option.value as Operator));
}

function getPropertyInitialOperator(property: DataSourceTableColumnConfig): Operator {
  const operators = getPropertyOperators(property);

  return operators[0];
}

export default function DataSourceTableFilterBuilder(props: DataSourceFilterBuilderProps) {
  const {
    tableConfig,
    filters,
    setFilters,
    enableFilter,
    setEnableFilter,
  } = props;

  const tableFilterSelectOptions: Option[] = Object
    .entries(tableConfig.schema.properties)
    .map(([fieldName, fieldConfig]) => ({
      label: fieldName,
      value: fieldName,
      subLabel: fieldConfig.description,
    }));

  const onAddFilter = () => {
    const firstField = Object.keys(tableConfig.schema.properties)[0];

    setFilters((prev) => [
      ...prev,
      {
        fieldName: firstField,
        operator: getPropertyInitialOperator(tableConfig.schema.properties[firstField]),
        value: getFieldDefaultValue(tableConfig.schema.properties[firstField]),
      },
    ]);
  };

  const onFilterChange = (index: number, filter: Partial<FlatFilter>) => {
    setFilters((prev) => {
      const newFilters = [...prev];
      newFilters[index] = {
        ...newFilters[index],
        ...filter,
      };
      return newFilters;
    });
  };

  return (
    <BuilderStepCard
      question="Filter"
      description="Filter the data to be fetched from the database"
      isVisible={enableFilter}
      setIsVisible={setEnableFilter}
    >
      <div className="d-flex flex-column gap-2">
        {
          filters.map((filter, index) => {
            const property = tableConfig.schema.properties[filter.fieldName];

            const operatorOptions = getPropertyOperatorsOptions(property);

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${filter.fieldName}-${filter.operator}-${index}`}>
                {
                  index !== 0 && (
                    <div className="mb-2 fw-bold">AND</div>
                  )
                }
                <AdvancedSelect
                  options={tableFilterSelectOptions}
                  value={filter.fieldName}
                  onChange={(fieldName) => {
                    const newProperty = tableConfig.schema.properties[fieldName];
                    onFilterChange(index, {
                      fieldName,
                      operator: getPropertyInitialOperator(newProperty),
                      value: getFieldDefaultValue(newProperty),
                    });
                  }}
                  style={{ width: '100%' }}
                  className="mb-2"
                />
                <div className="d-flex justify-content-between gap-2">
                  <div className="d-flex gap-2" style={{ flex: 1 }}>

                    <div style={{ flex: 1 }}>
                      <AdvancedSelect
                        options={operatorOptions}
                        value={filter.operator}
                        onChange={(operator) => {
                          onFilterChange(index, {
                            operator,
                          });
                        }}
                        style={{ width: 200 }}
                      />
                    </div>
                    <div style={{ flex: 2, minWidth: 100 }}>
                      {
                        property.format === 'date-time' && (
                          <DatePicker
                            defaultValue={dayjs()}
                            onChange={(date) => {
                              onFilterChange(index, {
                                value: date?.toISOString(),
                              });
                            }}
                            needConfirm={false}
                            style={{ width: '100%' }}
                            showTime
                          />
                        )
                      }
                      {
                        property.format !== 'date-time' && (
                          <Input
                            placeholder="Enter value"
                            value={filter.value}
                            onChange={(e) => {
                              onFilterChange(index, {
                                value: e.target.value,
                              });
                            }}
                            style={{ width: '100%' }}
                          />
                        )
                      }
                    </div>
                  </div>
                  <Button
                    danger
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setFilters((prev) => prev.filter((_, i) => i !== index));
                    }}
                  />
                </div>
              </div>
            );
          })
        }
        <div className="d-flex justify-content-between">
          <span>
            {
              filters.length === 0 && (
                <span>
                  No filters added, click &quot;Add Filter&quot; to add a filter
                </span>
              )
            }
          </span>
          <Button onClick={onAddFilter}>Add Filter</Button>
        </div>
      </div>
    </BuilderStepCard>
  );
}
