import Dashboard from './Dashboard';
import Model from './Model';

enum ParameterScope {
  DASHBOARD = 'dashboard',
  WIDGET = 'widget',
}

enum ParameterType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export { ParameterScope, ParameterType };

export interface ParameterData {
  name: string;
  scope: ParameterScope;
  type: ParameterType;
  description: string;
  dashboardId: Dashboard['_id'];
  widgetId?: string;
  value?: any;
  templateId?: string;
  overwriteTemplateValue?: boolean;
}

export default interface Parameter extends Model, ParameterData {}
