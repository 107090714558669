import {
  Button, Col, Form, Input, notification, Row,
} from 'antd';
import { useAuth } from '../../auth';
import useRequest from '../../common/hooks/useRequest';
import api, { User } from '../../api';

export default function UserPasswordSettings() {
  const { user, setUser } = useAuth();

  const request = useRequest(
    async (values: Pick<User, 'name'> & { password?: string; currentPassword?: string }) => {
      if (user) {
        if (values.password && !values.currentPassword) {
          notification.error({
            message: 'Current password is required',
          });
        } else {
          if (!values.password) {
            // eslint-disable-next-line no-param-reassign
            delete values.password;
          }
          const response = await api.users.patch(user._id, values);
          setUser(response as User);
        }
      }
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Settings updated',
        });
      },
    },
  );

  return (
    <Row>
      <Col span={24} md={12} lg={8} xl={6}>
        <Form
          layout="vertical"
          onFinish={request.submit}
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ min: 8, message: 'Password must be at least 8 characters' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={request.loading}
              disabled={request.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
