import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class LineChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.LINE_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData() {
    return this.resultRepresentation.data;
  }
}

export default LineChartToTableTransformer;
