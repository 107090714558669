import {
  Button, Col, Drawer, notification, Row,
} from 'antd';
import { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useQuery from '../../common/hooks/useQuery';
import api, { DataSource, DataSourceScope } from '../../api';
import DataSourceCard from '../../data-sources/components/DataSourceCard';
import DataSourceForm from '../../data-sources/components/DataSourceForm';
import { globalAtom } from '../../common/state/global-state';
import AppPage from '../../layout/components/AppPage';
import usePermissions from '../../common/hooks/usePermissions';
import DataSourceSchemaEditor from '../../data-sources/components/DataSourceSchemaEditor';

export default function OrganizationDataSourcesPage() {
  const organization = useActiveOrganization();
  const [open, setOpen] = useState(false);
  const setGlobalState = useSetRecoilState(globalAtom);
  const permissions = usePermissions();
  const [newDataSource, setNewDataSource] = useState<DataSource>();
  const [response, loading, setResponse] = useQuery<DataSource[]>(async () => {
    if (!organization) {
      return [];
    }

    const res = await api.dataSources.find({
      query: {
        entityId: organization._id,
        scope: DataSourceScope.ORGANIZATION,
        $sort: {
          createdAt: -1,
        },
        $limit: 50,
        $select: ['_id', 'name', 'type'],
      },
    });

    return res.data;
  }, [organization?._id]);

  const onDeleteSuccess = useCallback((dataSource: DataSource) => {
    setResponse((prev) => {
      if (!prev) { return prev; }

      return prev.filter((item) => item._id !== dataSource._id);
    });
  }, []);

  const onCreate = useCallback((dataSource: DataSource) => {
    setNewDataSource(dataSource);

    notification.success({
      message: 'Data source created',
      description: 'You can now configure the data source schema',
    });

    setResponse((prev) => {
      if (!prev) {
        return [dataSource];
      }

      const exists = prev.find((item) => item._id === dataSource._id);

      if (exists) {
        return prev.map((item) => {
          if (item._id !== dataSource._id) {
            return item;
          }

          return dataSource;
        });
      }

      return [dataSource, ...prev];
    });

    setGlobalState((prev) => {
      const exists = prev.dataSources.find((item) => item._id === dataSource._id);

      if (exists) {
        return {
          ...prev,
          dataSources: prev.dataSources.map((item) => {
            if (item._id !== dataSource._id) {
              return item;
            }

            return dataSource;
          }),
        };
      }

      return {
        ...prev,
        dataSources: [dataSource, ...prev.dataSources],
      };
    });
  }, []);

  return (
    <AppPage
      title="Data Sources"
      loading={loading}
      error={!organization && 'Organization not found'}
      actionButtons={(
        <Link to="create">
          <Button
            onClick={() => { setOpen(true); }}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create Data Source
          </Button>
        </Link>
      )}
      allowed={permissions.dataSources.any}
    >
      {
        organization && (
          <div>
            {
              (!response || !response.length) && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h1 className="text-center">No data sources found</h1>
                </div>
              )
            }
            {
              response && (
                <Row gutter={[16, 16]}>
                  {
                    response.map((item) => (
                      <Col span={24} md={12} lg={8} key={item._id}>
                        <DataSourceCard
                          id={item._id}
                          name={item.name}
                          type={item.type}
                          isDefault={item.isDefault}
                          onDeleteSuccess={onDeleteSuccess}
                        />
                      </Col>
                    ))
                  }
                </Row>
              )
            }
            <Drawer
              title={newDataSource ? 'Configure Data Source Schema' : 'Create Data Source'}
              open={open}
              onClose={() => {
                setOpen(false);
                setTimeout(() => {
                  setNewDataSource(undefined);
                }, 500);
              }}
            >
              {
                !newDataSource ? (
                  <DataSourceForm
                    entityId={organization._id}
                    scope={DataSourceScope.ORGANIZATION}
                    onSuccess={onCreate}
                    organizationId={organization._id}
                    organization={organization}
                  />
                ) : (
                  <DataSourceSchemaEditor
                    id={newDataSource._id}
                    schemaConfig={newDataSource.schemaConfig}
                  />
                )
              }
            </Drawer>
          </div>
        )
      }
    </AppPage>
  );
}
