import { selector, useRecoilValue } from 'recoil';
import { globalAtom } from '../state/global-state';
import { Permissions } from '../../api';

const permissionsSelector = selector({
  key: 'global-permissions',
  get: ({ get }) => get(globalAtom).permissions,
});

export default function usePermissions(): Permissions {
  return useRecoilValue(permissionsSelector);
}
