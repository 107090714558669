import { Descriptions, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { ReactNode } from 'react';
import {
  Formatter,
  QueryExecution,
  ResultRepresentation,
  ResultRepresentationBuilderOptions,
  ResultRepresentationOverride,
  ResultRepresentationSubType,
  ResultRepresentationType,
  Sort,
  TableResultRepresentationBuilderOptions,
} from '../../../api';
import ResultRepresentationManagerFactory from '../../transforms/managers/ResultRepresentationManagerFactory';
import CustomCodeResultRepresentationView from '../CustomCodeResultRepresentationView';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import { formatValue } from '../../utils/format';
import { getSortOrder } from '../../utils/sort';
import ResultRepresentationHelper from '../../utils/ResultRepresentationHelper';
import ChartResultRepresentationView from './ChartResultRepresentationView';
import NumberResultRepresentationView from './NumberResultRepresentationView';

interface ResultRepresentationViewProps {
  resultRepresentation?: ResultRepresentation;
  resultRepresentationOverride?: ResultRepresentationOverride;
  result?: QueryExecution['result'];
  subTypeOverride?: ResultRepresentation['subType'];
  onOverrideChange?: (override: Partial<ResultRepresentationOverride>) => void;
  chartHeight?: number;
  showPagination?: boolean;
  tablePage?: number;
  onTablePageChange?: (page: number) => void;
  builderOptions?: ResultRepresentationBuilderOptions;
}

function camelCaseToTitleCase(str: string) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (replaceStr) => replaceStr.toUpperCase());
}

function ObjectView({ result }: { result: any }) {
  if (!result || typeof result !== 'object' || Array.isArray(result)) {
    return null;
  }

  const keys = Object.keys(result);

  return (
    <Descriptions column={1} size="small" bordered>
      {keys.map((key) => {
        const value = result[key];

        return (
          <Descriptions.Item
            key={key}
            label={camelCaseToTitleCase(key)}
          >
            {
              value && typeof value === 'object' ? (
                <pre>{JSON.stringify(value, null, 2)}</pre>
              ) : (
                <span>{value}</span>
              )
            }
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
}

interface TableViewProps {
  result: any;
  format?: ResultRepresentationOverride['format'];
  sort?: ResultRepresentationOverride['sort'];
  onSortChange?: (sort: Sort) => void;
  showPagination?: boolean;
  page?: number;
  onPageChange?: (page: number) => void;
  options?: TableResultRepresentationBuilderOptions;
  disableSorting?: boolean;
}

function TableView(props: TableViewProps) {
  const {
    result,
    format,
    onSortChange,
    sort,
    showPagination,
    page,
    onPageChange,
    options,
    disableSorting,
  } = props;

  if (!result || !Array.isArray(result) || result.length === 0) {
    return <p className="text-center">No data found</p>;
  }

  const generatedColumns: TableResultRepresentationBuilderOptions['columns'] = options?.columns
    ? options.columns
    : Object.keys(result[0]).map((key) => ({
      key,
      title: key,
    }));

  const columns: ColumnsType<any> = generatedColumns.map(({ key, title, format: itemFormat }) => ({
    key,
    title,
    render: (text: any, data: any) => {
      const value = data[key];

      let children: ReactNode = null;

      if (value && typeof value === 'object') {
        children = <pre>{JSON.stringify(value, null, 2)}</pre>;
      } else if (itemFormat) {
        children = Formatter.format(value, itemFormat);
      } else {
        children = formatValue(format, value, key);
      }

      return (
        <div
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            minWidth: 100,
          }}
        >
          {children}
        </div>
      );
    },
    width: 100,
    sorter: disableSorting ? false : function sorter(a, b) {
      return a[key] > b[key] ? 1 : -1;
    },
    defaultSortOrder: getSortOrder(key, sort),
  }));

  let totalPages = (page || 1);

  if (result.length >= 10) {
    totalPages += 1;
  }

  return (
    <Table
      size="small"
      bordered
      columns={columns}
      dataSource={result}
      pagination={
        showPagination
          ? {
            position: ['bottomLeft'],
            current: page,
            total: totalPages * 10,
            showSizeChanger: false,
            onChange: onPageChange,
          } : false
      }
      style={{
        borderRadius: 0,
      }}
      onChange={(pagination, filters, sorter) => {
        if (sorter) {
          if (Object.keys(sorter).length > 0) {
            const sortRules = Array.isArray(sorter) ? sorter : [sorter];
            if (onSortChange) {
              onSortChange({
                rules: sortRules.map((rule) => ({
                  columnKey: String(rule.columnKey),
                  order: rule.order,
                })),
              });
            }
          }
        }
      }}
    />
  );
}

function cloneData(data: any) {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return [...(data || [])];
    }

    return { ...(data || {}) };
  }

  return data;
}

const errorBoundaryFallback = () => (
  <p>
    Oops, something went wrong while rendering the result representation.
  </p>
);

export default function ResultRepresentationView(props: ResultRepresentationViewProps) {
  const {
    resultRepresentation: propsResultRepresentation,
    resultRepresentationOverride,
    subTypeOverride: propsSubType,
    result,
    onOverrideChange,
    showPagination,
    tablePage,
    onTablePageChange,
    builderOptions,
    chartHeight = 350,
  } = props;

  const format = resultRepresentationOverride?.format;
  const sort = resultRepresentationOverride?.sort;
  let subTypeOverride = resultRepresentationOverride?.subType || propsSubType;

  let resultRepresentation = propsResultRepresentation;

  if (!resultRepresentation) {
    return null;
  }

  let type = resultRepresentation?.type;

  const manager = ResultRepresentationManagerFactory
    .createManager({ ...resultRepresentation, result });

  let data = cloneData(resultRepresentation.data);

  if (subTypeOverride && subTypeOverride !== resultRepresentation.subType) {
    resultRepresentation = manager.transform(subTypeOverride);
    subTypeOverride = resultRepresentation.subType;

    if (subTypeOverride) {
      type = ResultRepresentationHelper.getSubTypeConfig(subTypeOverride).type;
    }

    data = resultRepresentation.data;
  }

  const renderRepresentation = () => {
    try {
      if (resultRepresentation) {
        if (subTypeOverride === ResultRepresentationSubType.CUSTOM_CODE) {
          return (
            <CustomCodeResultRepresentationView
              code={resultRepresentationOverride?.customCode || ''}
              resultRepresentation={resultRepresentation}
              renderDefault={() => (
                <ResultRepresentationView
                  resultRepresentation={resultRepresentation}
                  result={result}
                />
              )}
            />
          );
        }

        if (type === ResultRepresentationType.GRAPH && data) {
          return (
            <ChartResultRepresentationView
              resultRepresentation={{ ...resultRepresentation, data }}
              resultRepresentationOverride={resultRepresentationOverride}
              chartHeight={chartHeight}
              result={result}
              builderOptions={builderOptions}
            />
          );
        }

        if (type === ResultRepresentationType.STRING) {
          return (
            <h1>{String(data)}</h1>
          );
        }

        if (type === ResultRepresentationType.DATE) {
          let dateString = 'Error while parsing date';

          try {
            if (format) {
              dateString = formatValue(format, data, '');
            } else {
              dateString = new Date(data).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              });
            }
          } catch (e) {
            // Ignore
          }

          return (<Statistic value={dateString} />);
        }

        if (type === ResultRepresentationType.NUMBER) {
          return (
            <NumberResultRepresentationView
              resultRepresentationFormat={format}
              builderOptions={builderOptions}
              data={data}
            />
          );
        }

        if (type === ResultRepresentationType.OBJECT_VIEW) {
          return <ObjectView result={data} />;
        }

        if (type === ResultRepresentationType.TABLE) {
          if (
            subTypeOverride
            && resultRepresentation.type === ResultRepresentationType.GRAPH
            && Array.isArray(result)
          ) {
            data = [...(result || [])];
          }

          if (!Array.isArray(data)) {
            return (
              <p className="text-center fw-bold">
                Invalid data for table representation, expected an array.
                Please change the representation type to object view, chart, or the original type.
              </p>
            );
          }

          return (
            <TableView
              result={data}
              format={format}
              sort={sort}
              onSortChange={(newSort) => {
                if (onOverrideChange) {
                  onOverrideChange({ sort: newSort });
                }
              }}
              showPagination={showPagination}
              page={tablePage}
              onPageChange={onTablePageChange}
              options={builderOptions?.subTypeOptions?.[ResultRepresentationSubType.TABLE]}
              disableSorting={Boolean(
                builderOptions
                  ?.subTypeOptions
                  ?.[ResultRepresentationSubType.TABLE],
              )}
            />
          );
        }

        if (type === ResultRepresentationType.TEXT) {
          return (
            <Statistic value={formatValue(format, String(data), '')} />
          );
        }
      }
    } catch (e) {
      return (
        <p>
          Error rendering representation,
          please try again or change the representation type.
        </p>
      );
    }

    return (
      <p className="text-center">
        Oops, we don&apos;t have a representation for this result type yet.
      </p>
    );
  };

  return (
    <ErrorBoundary fallback={errorBoundaryFallback}>
      <div>
        {renderRepresentation()}
      </div>
    </ErrorBoundary>
  );
}
