import { Tabs, TabsProps } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import AppPage, { AppPageProps } from './AppPage';
import { HEADER_HEIGHT } from './AppLayout';
import Spin from '../../common/components/Spin';
import { LAYOUT_SIDEBAR_COLOR } from '../../config/styling';

interface AppTabbedPageProps extends AppPageProps {
  items?: Required<TabsProps>['items'];
  activeKey?: TabsProps['activeKey'];
  onChange?: TabsProps['onChange'];
  tabBarExtraContent?: TabsProps['tabBarExtraContent'];
  tabsProps?: Partial<TabsProps>;
  tabContentBg?: string;
  syncToUrl?: boolean;
}

const TABS_HEIGHT = 46;
const PAGE_HEADER_HEIGHT = HEADER_HEIGHT + TABS_HEIGHT;
const PAGE_CONTENT_HEIGHT = `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`;

export default function AppTabbedPage(props: AppTabbedPageProps) {
  const {
    items = [],
    tabsProps = {},
    activeKey,
    onChange,
    tabContentBg = 'transparent',
    children,
    loading,
    tabBarExtraContent,
    syncToUrl,
    ...rest
  } = props;

  const { tab } = useParams();
  useEffect(() => {
    if (syncToUrl) {
      onChange?.(tab || items[0]?.key || '');
    }
  }, [tab, syncToUrl, items.length]);

  const getLabelLink = (key: string) => {
    const isFirst = items[0]?.key === key;

    if (isFirst) {
      if (tab) {
        return '..';
      }

      return '';
    }

    if (!tab) {
      return key;
    }

    if (key === tab) {
      return '';
    }

    return `../${key}`;
  };

  const finalItems = items.map((item) => ({
    ...item,
    children: (
      <div
        style={{
          height: PAGE_CONTENT_HEIGHT,
          overflow: 'auto',
          backgroundColor: tabContentBg,
        }}
      >
        <div style={{ margin: 16 }}>
          {item.children}
        </div>
      </div>
    ),
    label: (
      <div>
        {
          syncToUrl ? (
            <Link to={getLabelLink(item.key)} className="link-unstyled">
              {item.label}
            </Link>
          ) : item.label
        }
      </div>
    ),
  }));

  return (
    <AppPage {...rest} contentPadding={0} hideBottomBorder>
      {
        items && !loading && (
          <Tabs
            tabBarStyle={{
              padding: '0 16px',
              margin: 0,
              background: LAYOUT_SIDEBAR_COLOR,
              minHeight: TABS_HEIGHT,
            }}
            items={finalItems}
            activeKey={activeKey}
            onChange={onChange}
            tabBarExtraContent={tabBarExtraContent}
            {...tabsProps}
          />
        )
      }
      {
        loading && (
          <div className="d-flex align-items-center justify-content-center py-5">
            <Spin />
          </div>
        )
      }
      {children}
    </AppPage>
  );
}
