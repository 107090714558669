import ResultRepresentationManager from './ResultRepresentationManager';
import TreeMapChartToPieChartTransformer from '../transformers/TreeMapChartToPieChartTransformer';
import TreeMapToBarChartTransformer from '../transformers/TreeMapToBarChartTransformer';
import TreeMapToTableTransformer from '../transformers/TreeMapToTableTransformer';

class TreeMapChartRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new TreeMapChartToPieChartTransformer(this.representation),
    new TreeMapToBarChartTransformer(this.representation),
    new TreeMapToTableTransformer(this.representation),
  ];
}

export default TreeMapChartRepresentationManager;
