import React, { useEffect } from 'react';
import { CommandBase, CommandName, Commands } from '../../types';
import AdvancedSelect, { Option } from '../../../common/components/AdvancedSelect';
import DataSourceTableFilterBuilder from '../DataSourceTableFilterBuilder';
import DataSourceTableGroupBuilder from '../DataSourceTableGroupBuilder';
import LimitOption from '../LimitOption';
import TableSelect from '../TableSelect';
import useDbaqlBuilderState from '../../hooks/useDbaqlBuilderState';
import { DbaqlBuilderState } from '../../state';
import AggregationFieldSelectStep from '../AggregationFieldSelectStep';
import SortStep from '../SortStep';
import SelectStep from '../SelectStep';

const commandOptions = Object.values(Commands);

interface DbaqlBuilderProps {
  schema: DbaqlBuilderState['schema'];
}

function getSortOptions(state: DbaqlBuilderState): Option[] {
  const {
    command,
    enableGroup,
    groupFields,
    tableConfig,
  } = state;

  if (command) {
    const commandConfig = Commands[command];

    if (
      [CommandName.AVERAGE, CommandName.COUNT, CommandName.SUM].includes(command)
      && enableGroup
      && groupFields
      && commandConfig.aggregationResultFieldName
    ) {
      return [
        {
          label: commandConfig.aggregationResultFieldName,
          value: commandConfig.aggregationResultFieldName,
        },
      ];
    }

    if (command === CommandName.LIST && tableConfig?.schema?.properties) {
      return Object.keys(tableConfig.schema.properties).map((key) => ({
        label: key,
        value: key,
      }));
    }
  }

  return [];
}

export default function DbaqlBuilder(props: DbaqlBuilderProps) {
  const { schema } = props;
  const {
    state,
    createChangeHandler,
  } = useDbaqlBuilderState();

  useEffect(() => {
    // Sync the schema
    createChangeHandler('schema')(schema);
  }, [schema]);

  const {
    table,
    commandConfig,
    command,
    tableConfig,
    groupFields,
    enableGroup,
    limit,
    enableLimit,
    filters,
    enableFilter,
    aggregationField,
    enableSort,
    sortField,
    sortDirection,
    enableSelect,
    select,
  } = state;

  const sortOptions = getSortOptions(state);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-2">
        <p style={{ flex: 1 }}>
          Which table do you want to explore?
        </p>
        <TableSelect filter={(item) => item.included} />
      </div>
      {table && (
        <div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <p style={{ flex: 1 }}>
              What do you want to calculate in &quot;
              {table}
              &quot;?
            </p>
            <AdvancedSelect
              value={commandConfig?.displayName}
              onChange={createChangeHandler('command')}
              placeholder="Select a command"
              options={commandOptions.map((item: CommandBase) => ({
                label: item.displayName,
                value: item.command,
                subLabel: item.description,
              }))}
              style={{ flex: 1 }}
            />
          </div>
          <div key={`${table}-${command}`}>
            {
              tableConfig && command && (
                <div className="d-flex flex-column gap-2">
                  {
                    commandConfig?.hasAggregationField && (
                      <AggregationFieldSelectStep
                        value={aggregationField}
                        onChange={createChangeHandler('aggregationField')}
                        tableConfig={tableConfig}
                      />
                    )
                  }
                  {
                    commandConfig?.hasSelect && (
                      <SelectStep
                        enabled={enableSelect}
                        onEnabledChange={createChangeHandler('enableSelect')}
                        value={select}
                        onChange={createChangeHandler('select')}
                        tableConfig={tableConfig}
                      />
                    )
                  }
                  {
                    commandConfig?.hasGroup && (
                      <DataSourceTableGroupBuilder
                        tableConfig={tableConfig}
                        selectedFields={groupFields}
                        onSelectedFieldsChange={createChangeHandler('groupFields')}
                        enableGroup={enableGroup}
                        setEnableGroup={createChangeHandler('enableGroup')}
                      />
                    )
                  }
                  {
                    commandConfig?.hasFilter && (
                      <DataSourceTableFilterBuilder
                        tableConfig={tableConfig}
                        filters={filters}
                        setFilters={createChangeHandler('filters')}
                        enableFilter={enableFilter}
                        setEnableFilter={createChangeHandler('enableFilter')}
                      />
                    )
                  }
                  {
                    commandConfig?.hasLimit && (
                      <LimitOption
                        limit={limit}
                        setLimit={createChangeHandler('limit')}
                        enableLimit={enableLimit}
                        setEnableLimit={createChangeHandler('enableLimit')}
                        forceEnable={commandConfig.forceEnableLimit}
                      />
                    )
                  }
                  {
                    enableGroup && commandConfig?.hasGroupLimit && (
                      <LimitOption
                        limit={limit}
                        setLimit={createChangeHandler('limit')}
                        enableLimit={enableLimit}
                        setEnableLimit={createChangeHandler('enableLimit')}
                        description={commandConfig?.groupLimitDescription}
                      />
                    )
                  }
                  {
                    (commandConfig?.hasSort || commandConfig?.hasGroupSort)
                    && sortOptions
                    && sortOptions.length > 0
                    && (
                      <SortStep
                        isEnabled={enableSort}
                        setIsEnabled={createChangeHandler('enableSort')}
                        onChange={createChangeHandler('sortDirection')}
                        onFieldChange={createChangeHandler('sortField')}
                        sortOptions={sortOptions}
                        field={sortField}
                        value={sortDirection}
                      />
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      )}
    </>
  );
}
