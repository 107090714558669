import { OrganizationUserRole } from '../models/OrganizationUser';

export type Permission = {
  read: boolean;
  write: boolean;
  any: boolean;
};

export type Permissions = Record<
'general' | 'organizationMembers' | 'dataSources' | 'projects' | 'dashboards' | 'widgets',
Permission
> & {
  organizations: Permission & {
    billing: boolean;
  };
};

function roleIs(role: OrganizationUserRole, ...roles: OrganizationUserRole[]) {
  return roles.includes(role);
}

function createPermission(permission: Omit<Permission, 'any'>): Permission {
  return {
    ...permission,
    any: permission.read || permission.write,
  } as const;
}

export const DEFAULT_PERMISSIONS: Permissions = {
  general: createPermission({
    read: true,
    write: false,
  }),
  organizations: {
    ...createPermission({
      read: true,
      write: false,
    }),
    billing: false,
  },
  organizationMembers: createPermission({
    read: true,
    write: false,
  }),
  dataSources: createPermission({
    read: true,
    write: false,
  }),
  projects: createPermission({
    read: true,
    write: false,
  }),
  dashboards: createPermission({
    read: true,
    write: false,
  }),
  widgets: createPermission({
    read: true,
    write: false,
  }),
} as const;

export default class PermissionsHelper {
  static getPermissions(role: OrganizationUserRole): Permissions {
    return {
      general: createPermission({
        read: true,
        write: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
          OrganizationUserRole.MEMBER,
        ),
      }),
      organizations: {
        ...createPermission({
          read: true,
          write: roleIs(
            role,
            OrganizationUserRole.OWNER,
            OrganizationUserRole.ADMIN,
          ),
        }),
        billing: roleIs(role, OrganizationUserRole.OWNER, OrganizationUserRole.ADMIN),
      },
      organizationMembers: createPermission({
        read: true,
        write: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
        ),
      }),
      dataSources: createPermission({
        read: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
        ),
        write: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
        ),
      }),
      projects: createPermission({
        read: true,
        write: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
        ),
      }),
      dashboards: createPermission({
        read: true,
        write: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
          OrganizationUserRole.MEMBER,
        ),
      }),
      widgets: createPermission({
        read: true,
        write: roleIs(
          role,
          OrganizationUserRole.OWNER,
          OrganizationUserRole.ADMIN,
          OrganizationUserRole.MEMBER,
        ),
      }),
    };
  }
}
