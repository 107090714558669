import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class AreaChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.AREA_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData() {
    return this.resultRepresentation.result;
  }
}

export default AreaChartToTableTransformer;
