import {
  DataSource, DataSourceTableConfig, Entity, EntityField, EntityFieldType, InsightFlowFilter,
} from '../../../api';
import SelectWithContent from '../../../common/components/SelectWithContent';
import {
  ExpressionOperation,
} from '../../../dbaql-v2/types';
import EntitiesRelationshipManager from '../../../entities/utils/EntitiesRelationshipManager';
import StringColumnFilter from './StringColumnFilter';
import NumberColumnFilter from './NumberColumnFilter';
import DateColumnFilter from './DateColumnFilter';
import FieldAdapter from '../../../dbaql-v2-entities-adapters/FieldAdapter';

interface FieldFilterProps {
  field: EntityField;
  onAddFilter: (filter: InsightFlowFilter) => void;
  table?: DataSourceTableConfig;
  dataSourceId: string;
  entities: Entity[];
  dataSource: DataSource;
}

export default function EntityFieldFilter(props: FieldFilterProps) {
  const {
    field,
    table,
    onAddFilter,
    dataSourceId,
    entities,
    dataSource,
  } = props;

  if (!table) {
    return null;
  }

  if (field.definition.type === EntityFieldType.COLUMN) {
    const tableColumn = table.schema.properties[field.definition.column];

    if (!tableColumn) {
      return null;
    }

    const isDate = tableColumn.format === 'date-time';
    const isNumber = tableColumn.type === 'number';
    const isString = tableColumn.type === 'string';

    if (isDate) {
      return (
        <DateColumnFilter
          field={field}
          onAddFilter={onAddFilter}
          columnName={field.definition.column}
        />
      );
    }

    if (isNumber) {
      return (
        <NumberColumnFilter
          field={field}
          onAddFilter={onAddFilter}
          columnName={field.definition.column}
        />
      );
    }

    if (isString) {
      return (
        <StringColumnFilter
          field={field}
          onAddFilter={onAddFilter}
          columnName={field.definition.column}
          dataSourceId={dataSourceId}
          tableName={table.tableName}
        />
      );
    }
  }

  if (field.definition.type === EntityFieldType.COMPUTED) {
    const { expression } = field.definition;

    const isNumber = [
      ExpressionOperation.SUM,
      ExpressionOperation.COUNT,
      ExpressionOperation.MULTIPLY,
      ExpressionOperation.AVERAGE,
    ].includes(expression);

    if (isNumber) {
      return (
        <NumberColumnFilter
          field={field}
          onAddFilter={onAddFilter}
          columnName={field.name}
        />
      );
    }
  }

  if (field.definition.type === EntityFieldType.FOREIGN_KEY) {
    const erm = new EntitiesRelationshipManager(entities);
    const entity = erm.getEntity(field.definition.entityName);
    const entityTable = dataSource
      ?.schemaConfig
      .tables
      .find((item) => item.tableName === entity?.table);
    return (
      <SelectWithContent
        items={entity.fields.map((item) => ({
          value: item.name,
          label: item.displayName,
          content: (
            <EntityFieldFilter
              key={item.name}
              field={item}
              table={entityTable}
              onAddFilter={(filter) => {
                if (onAddFilter) {
                  onAddFilter({
                    ...filter,
                    label: `${entity.displayName} ${filter.label}`,
                    foreignEntity: entity,
                    // Add the table name as a prefix to the filter field name
                    filterFieldName: FieldAdapter.getJoinedFieldAlias(
                      filter.filterFieldName,
                      entity.table,
                    ),
                  });
                }
              }}
              dataSourceId={dataSourceId}
              entities={entities}
              dataSource={dataSource}
            />
          ),
        }))}
      />
    );
  }

  return null;
}
