import {
  Avatar, Button, Drawer, List,
} from 'antd';
import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import api, { OrganizationUser, OrganizationUserRole } from '../../api';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useQuery from '../../common/hooks/useQuery';
import { getRoleLabel } from '../../permissions/utils';
import { UseModal } from '../../common/hooks/useModal';
import OrganizationMemberForm from './OrganizationMemberForm';
import DeleteButton from '../../common/components/DeleteButton';
import useRole from '../../common/hooks/useRole';

interface OrganizationMembersProps {
  modal: UseModal;
}

export default function OrganizationMembers(props: OrganizationMembersProps) {
  const { modal } = props;
  const organization = useActiveOrganization();
  const [editMember, setEditMember] = useState<OrganizationUser | undefined>(undefined);
  const role = useRole();

  const [members = [], loading, setMembers] = useQuery(async () => {
    if (organization) {
      const response = await api.organizationUsers.find({
        query: {
          organizationId: organization._id,
          $limit: 100,
        },
      });

      return response.data;
    }

    return [];
  }, [organization?._id]);

  const onDelete = async (
    userId: OrganizationUser['_id'],
  ) => await api.organizationUsers.remove(userId) as unknown as Promise<OrganizationUser>;

  useEffect(() => {
    if (!modal.props.open) {
      setTimeout(() => {
        setEditMember(undefined);
      }, 200);
    }
  }, [modal.props.open]);

  if (!organization) {
    return null;
  }

  return (
    <>
      <List<OrganizationUser>
        dataSource={members || []}
        itemLayout="horizontal"
        loading={loading}
        split
        renderItem={(item, index) => (
          <div
            className={`d-flex align-items-center gap-2 py-2${index > 0 ? ' border-top' : ''}`}
            key={item._id}
          >
            <Avatar shape="square" size="large">
              U
            </Avatar>
            <div className="d-flex align-items-center flex-grow-1">
              <div style={{ flex: 1 }}>
                <h4 className="m-0">
                  {item.name || item.email || 'Unknown'}
                </h4>
                {
                  item.name && (
                    <p className="m-0 text-muted">
                      {item.email}
                    </p>
                  )
                }
              </div>
              <div
                style={{ flex: 1 }}
                className="d-flex align-items-center justify-content-center"
              >
                <p className="m-0 text-center fw-bold">
                  {getRoleLabel(item.role)}
                </p>
                &nbsp;
                |
                &nbsp;
                <p className="m-0 fw-bold text-muted">
                  {item.isAccepted ? 'Accepted' : 'Pending'}
                </p>
              </div>
              <div style={{ flex: 1 }} className="d-flex justify-content-end gap-2">
                {
                  item.role !== OrganizationUserRole.OWNER
                  && role.is(OrganizationUserRole.OWNER, OrganizationUserRole.ADMIN)
                  && (
                    <>
                      {
                        (
                          item.role !== OrganizationUserRole.ADMIN
                          || role.is(OrganizationUserRole.OWNER)
                        ) && (
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => {
                              setEditMember(item);
                              modal.open();
                            }}
                          >
                            Edit
                          </Button>
                        )
                      }
                      <DeleteButton
                        onDelete={() => onDelete(item._id)}
                        onDeleteSuccess={() => {
                          setMembers((prev) => (prev || []).filter((el) => el._id !== item._id));
                        }}
                      >
                        Remove
                      </DeleteButton>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )}
      />
      <Drawer
        title={editMember ? 'Edit Member' : 'Invite New Member'}
        {...modal.props}
      >
        <OrganizationMemberForm
          user={editMember}
          organizationId={organization._id}
          onSuccess={(user) => {
            if (!editMember) {
              setMembers((prev) => [user, ...(prev || [])]);
            } else {
              setMembers(
                (prev) => (prev || []).map(
                  (el) => (el._id === user._id ? user : el),
                ),
              );
            }

            modal.close();
          }}
          onUsageReachedClick={() => {
            modal.close();
          }}
        />
      </Drawer>
    </>
  );
}
