import {
  Button, Card, Tag, TagProps,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Parameter, ParameterType } from '../../api';

interface ParameterCardProps {
  parameter: Pick<Parameter, 'name' | 'scope' | 'type'>;
  onDelete: () => void;
  onEdit: () => void;
}

const typeColorMap: Record<ParameterType, TagProps['color']> = {
  [ParameterType.STRING]: 'blue',
  [ParameterType.NUMBER]: 'green',
  [ParameterType.BOOLEAN]: 'orange',
  [ParameterType.DATE]: 'purple',
};

export default function ParameterCard(props: ParameterCardProps) {
  const { parameter, onDelete, onEdit } = props;

  return (
    <Card size="small">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ flex: 1 }}>
            <b>{parameter.name}</b>
          </div>
          <div className="d-flex justify-content-center" style={{ flex: 1 }}>
            <span>{parameter.scope}</span>
            &nbsp;
            |
            &nbsp;
            <Tag color={typeColorMap[parameter.type]}>
              {parameter.type}
            </Tag>
          </div>
          <div className="d-flex justify-content-end" style={{ flex: 1 }}>
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={onEdit}
            >
              Edit
            </Button>
            <Button
              className="ms-2"
              size="small"
              icon={<DeleteOutlined />}
              onClick={onDelete}
              danger
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
