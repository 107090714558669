import {
  Button, ButtonProps, Dropdown, message,
} from 'antd';
import { CodeOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { QueryExecution } from '../../../api';

interface DownloadExecutionButtonProps extends Partial<ButtonProps> {
  result: QueryExecution['result'];
  title?: string;
}

function getValueKeys(value: any) {
  if (!value || typeof value !== 'object') {
    return [];
  }

  try {
    return Object.keys(value);
  } catch (e) {
    return [];
  }
}

function downloadAsCsv(result: any[], title: string) {
  const headers: Record<string, boolean> = {};

  result.forEach((item) => {
    const keys = getValueKeys(item);
    keys.forEach((key) => {
      headers[key] = true;
    });
  });

  const headerKeys = Object.keys(headers);
  const csv = result.map((item) => headerKeys.map((key) => item[key]).join(','));
  const csvString = `${headerKeys.join(',')}\n${csv.join('\n')}`;

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${title}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadAsJSON(result: any[], title: string) {
  const blob = new Blob([JSON.stringify(result, null, 2)], { type: 'application/json;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${title}.json`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default function DownloadExecutionButton(props: DownloadExecutionButtonProps) {
  const { result, title = 'data' } = props;

  if (!Array.isArray(result)) {
    return null;
  }

  const items: ItemType[] = [
    {
      key: 'csv',
      label: 'Download as CSV',
      icon: <DownloadOutlined />,
      onClick: () => downloadAsCsv(result, title),
    },
    {
      key: 'json',
      label: 'Download as JSON',
      icon: <CodeOutlined />,
      onClick: () => downloadAsJSON(result, title),
    },
    {
      key: 'clipboard',
      label: 'Copy to clipboard',
      icon: <CopyOutlined />,
      onClick: () => {
        navigator.clipboard.writeText(JSON.stringify(result, null, 2));
        message.success({
          content: 'Copied to clipboard',
        });
      },
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button
        icon={<DownloadOutlined />}
        type="text"
      />
    </Dropdown>
  );
}
