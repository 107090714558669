import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class BarChartToTreeMapTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.BAR_CHART;

  to = ResultRepresentationSubType.TREE_MAP_CHART;

  transformData(): any {
    const { categories } = this.resultRepresentation.data.options.xaxis || {};
    const series = this.resultRepresentation.data.series[0]?.data.map(
      (item: any, index: number) => ({
        x: categories[index],
        y: item,
      }),
    );

    return {
      series: [{ data: series }],
      options: {
        chart: {
          type: 'treemap',
        },
      },
    };
  }
}

export default BarChartToTreeMapTransformer;
