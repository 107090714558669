type JsonDateModifier = {
  operator: 'add' | 'subtract';
  value: number;
  unit: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
};

export type JsonDate = {
  type: 'date',
  date: string | 'now';
  modifier: JsonDateModifier;
};

export type PrimitiveFilter = null | number | boolean | Date | string;

export type BaseFilter = PrimitiveFilter | JsonDate;

export type Limit = number;
export type Skip = number;

export enum Operator {
  EQ = '$eq',
  NE = '$ne',
  GT = '$gt',
  GTE = '$gte',
  LT = '$lt',
  LTE = '$lte',
  IN = '$in',
  NIN = '$nin',
  LIKE = '$like',
  ILIKE = '$ilike',
}

export type MultiFilter = Partial<{
  [Operator.EQ]: BaseFilter;
  [Operator.GT]: BaseFilter;
  [Operator.GTE]: BaseFilter;
  [Operator.LT]: BaseFilter;
  [Operator.LTE]: BaseFilter;
  [Operator.NE]: BaseFilter;
  [Operator.IN]: BaseFilter[];
  [Operator.NIN]: BaseFilter[];
  [Operator.LIKE]: BaseFilter;
  [Operator.ILIKE]: BaseFilter;
}>;

export type FieldFilter = BaseFilter | MultiFilter;

export type Filter = Record<string, FieldFilter>;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// All command name are lowercase
export enum CommandName {
  LIST = 'list',
  COUNT = 'count',
  SUM = 'sum',
  AVERAGE = 'average',
  GROUP = 'group',
}

export type Sort = Record<string, SortDirection>;

export type ListCommand = {
  command: CommandName.LIST;
  select?: string[];
  skip?: Skip;
  limit?: Limit;
  sort?: Sort;
  filter?: Filter;
};

export type CountCommand = {
  command: CommandName.COUNT;
  filter?: Filter;
};

export type SumCommand = {
  command: CommandName.SUM;
  field: string;
  filter?: Filter;
};

export type AverageCommand = {
  command: CommandName.AVERAGE;
  field: string;
  filter?: Filter;
};

export type AggregationCommand = AverageCommand | SumCommand | CountCommand;

export type GroupCommand = {
  command: CommandName.GROUP;
  by: string;
  filter?: Filter;
  aggregation?: AggregationCommand;
  sort?: Sort;
  limit?: Limit;
};

export type Command = AverageCommand | SumCommand | CountCommand | GroupCommand | ListCommand;

export type Query = {
  table: string;
  commands: [Command];
};

// Examples: find 10 users, select id and email only, sort by timestamp_created descending
// {
//   "table": "user",
//   "commands": [
//     {
//       "command": "list",
//       "select": ["id", "email", "timestamp_created"],
//       "limit": 10,
//       "sort": {
//         "timestamp_created": "DESC"
//       }
//     }
//   ]
// }

// Example: count all users
// {
//   "table": "user",
//   "commands": [
//     {
//       "command": "count"
//     }
//   ]
// }

// Example: count number of leads per organization
// {
//   "table": "lead",
//   "commands": [
//     {
//       "command": "group",
//       "by": "organization",
//       "aggregation": {
//         "command": "count"
//       }
//     }
//   ]
// }

// Find 10 leads with contact email ending in yahoo.com
// {
//   "table": "lead",
//   "commands": [
//     {
//       "command": "list",
//       "limit": 10,
//       "filter": {
//         "contact": {
//           "$ilike": "%yahoo.com"
//         }
//       }
//     }
//   ]
// }

// Show total data sources grouped by type, excluding demo data sources
// {
//   "table": "data-sources",
//   "commands": [
//     {
//       "command": "group",
//       "by": "type",
//       "filter": {
//         "isDemo": "false"
//       },
//       "aggregation": {
//         "command": "count"
//       }
//     }
//   ]
// }

// List non-demo data sources, sorted by createdAt field descending,
// and select only "_id", "name", "type", and "createdAt" fields
// {
//   "table": "data-sources",
//   "commands": [
//     {
//       "command": "list",
//       "limit": "100",
//       "select": ["_id", "name", "type", "createdAt"],
//       "filter": {
//         "isDemo": "false"
//       },
//       "sort": {
//         "createdAt": "DESC"
//       }
//     }
//   ]
// }

// Show total opportunities grouped by status
// {
//   "table": "Opportunities",
//   "commands": [
//     {
//       "command": "group",
//       "by": "Status",
//       "aggregation": {
//         "command": "count"
//       }
//     }
//   ]
// }

// Show top 5 opportunities by estimated value, sorted by estimated value descending
// {
//   "table": "Opportunities",
//   "commands": [
//     {
//       "command": "list",
//       "limit": "5",
//       "select": ["Opportunity name", "Status", "Estimated value"],
//       "sort": {
//         "Estimated value": "DESC"
//       }
//     }
//   ]
// }

// Show total estimated value for each opportunity status
// {
//   "table": "Opportunities",
//   "commands": [
//     {
//       "command": "group",
//       "by": "Status",
//       "aggregation": {
//         "command": "sum",
//         "field": "Estimated value"
//       }
//     }
//   ]
// }

// What is the average estimated value of an opportunity?
// {
//   "table": "Opportunities",
//   "commands": [
//     {
//       "command": "average",
//       "field": "Estimated value",
//     }
//   ]
// }

// Date usage example
// Find users who were last active within the last 24 hours
// {
//   "table": "users",
//   "commands": [
//     {
//       "command": "list",
//       "select": ["_id", "email", "creation data", "isAppSumoUser", "source"],
//       "filter": {
//         "lastActiveAt": {
//           "$gte": {
//             "type": "date",
//             "date": "now",
//             "modifier": {
//               "operator": "subtract",
//               "value": 24,
//               "unit": "hour"
//             }
//           }
//         }
//       }
//     }
//   ]
// }

// How many active data sources do we have?
// {
//   "table": "data-sources",
//   "commands": [
//     {
//       "command": "count",
//       "filter": {
//         "isActive": true
//       }
//     }
//   ]
// }

export interface CommandBase {
  command: CommandName;
  displayName: string;
  description: string;
  hasFilter?: boolean;
  hasGroup?: boolean;
  hasLimit?: boolean;
  hasGroupLimit?: boolean;
  groupLimitDescription?: string;
  outputType?: string; // table, chart, etc.
  hasAggregationField?: boolean;
  forceEnableLimit?: boolean;
  hasSelect?: boolean;
  hasSort?: boolean;
  hasGroupSort?: boolean;
  aggregationResultFieldName?: string;
}

export const Commands: { [key: string]: CommandBase } = {
  [CommandName.AVERAGE]: {
    command: CommandName.AVERAGE,
    displayName: 'Average',
    description: 'Average value of a column',
    hasFilter: true,
    hasGroup: true,
    hasGroupLimit: true,
    groupLimitDescription: 'Show only the first X average values',
    hasAggregationField: true,
    hasGroupSort: true,
    aggregationResultFieldName: 'average',
  },
  [CommandName.COUNT]: {
    command: CommandName.COUNT,
    displayName: 'Count',
    description: 'Count the number of entries',
    hasFilter: true,
    hasGroup: true,
    hasGroupLimit: true,
    groupLimitDescription: 'Show only the top X results',
    hasAggregationField: false,
    hasGroupSort: true,
    aggregationResultFieldName: 'count',
  },
  [CommandName.SUM]: {
    command: CommandName.SUM,
    displayName: 'Sum',
    description: 'Sum up the values of a column',
    hasGroup: true,
    hasFilter: true,
    hasGroupLimit: true,
    groupLimitDescription: 'Show only the top X sums',
    hasAggregationField: true,
    hasGroupSort: true,
    aggregationResultFieldName: 'sum',
  },
  [CommandName.LIST]: {
    command: CommandName.LIST,
    displayName: 'List',
    description: 'List the entries',
    hasGroup: false,
    hasFilter: true,
    hasGroupLimit: true,
    hasLimit: true,
    forceEnableLimit: true,
    hasSelect: true,
    hasSort: true,
  },
};
