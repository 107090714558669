import {
  Avatar, Card, Col, Row,
} from 'antd';
import { Link } from 'react-router-dom';
import useQuery from '../../common/hooks/useQuery';
import api, { Organization } from '../../api';
import Spin from '../../common/components/Spin';
import AppPage from '../../layout/components/AppPage';

type Totals = Record<Organization['_id'], { projects?: number; }>;

export default function UserOrganizationsPage() {
  const [response, loading] = useQuery(() => api.organizations.find({
    query: {
      $limit: 100,
    },
  }));
  const [totalsResponse] = useQuery<Totals | null>(async () => {
    if (!response) {
      return null;
    }

    const projectsResponse = await api.projects.find({
      query: {
        organizationId: {
          $in: response.data.map((organization) => organization._id),
        },
        $select: ['organizationId'],
        $limit: 1000,
      },
    });

    const totals: Totals = {};

    projectsResponse.data.forEach((project) => {
      if (!totals[project.organizationId]) {
        totals[project.organizationId] = {
          projects: 1,
        };
      } else {
        // eslint-disable-next-line max-len
        totals[project.organizationId].projects = (totals[project.organizationId].projects || 0) + 1;
      }
    });

    return totals;
  }, [response]);

  return (
    <AppPage title="Organizations">
      <Row>
        <Col span={24}>
          {
            loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Spin />
              </div>
            )
          }
          {
            response && (
              <Row justify="center" gutter={[16, 16]}>
                {
                  response.data.map((organization) => (
                    <Col span={24} md={10} lg={8} xl={6} key={organization._id}>
                      <Link to={`/${organization.slug}`}>
                        <Card
                          size="small"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <div className="d-flex flex-column align-items-center gap-2">
                            <Avatar size="large" shape="square" style={{ backgroundColor: 'rgb(34 35 13)' }}>
                              {organization.name[0]}
                            </Avatar>
                            <h3
                              className="mb-0 mt-2"
                              style={{ color: 'rgb(37, 98, 212)' }}
                            >
                              {organization.name}
                            </h3>
                            {
                              totalsResponse && totalsResponse[organization._id] && (
                                <p>
                                  {totalsResponse[organization._id].projects}
                                  {' '}
                                  Project
                                  {totalsResponse[organization._id].projects === 1 ? '' : 's'}
                                </p>
                              )
                            }
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  ))
                }
              </Row>
            )
          }
        </Col>
      </Row>
    </AppPage>
  );
}
