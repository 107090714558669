import ResultRepresentationManager from './ResultRepresentationManager';
import BarChartToPieChartTransformer from '../transformers/BarChartToPieChartTransformer';
import BarChartToTreeMapTransformer from '../transformers/BarChartToTreeMapTransformer';
import BarChartToTableTransformer from '../transformers/BarChartToTableTransformer';

class BarChartResultRepresentationManager extends ResultRepresentationManager {
  transformers = [
    new BarChartToPieChartTransformer(this.representation),
    new BarChartToTreeMapTransformer(this.representation),
    new BarChartToTableTransformer(this.representation),
  ];
}

export default BarChartResultRepresentationManager;
