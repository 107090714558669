import { ResultRepresentation, ResultRepresentationSubType } from '../../../api';
import ResultRepresentationTransformer from '../transformers/ResultRepresentationTransformer';
import ResultRepresentationSubTypeConfigFactory from '../configs/ResultRepresentationSubTypeConfigFactory';
import ResultRepresentationSubTypeConfig from '../configs/ResultRepresentationSubTypeConfig';
import NotFoundResultRepresentationTransformer from './NotFoundResultRepresentationTransformer';
import AnyToCustomCoderResultRepresentationTransformer from './AnyToCustomCoderResultRepresentationTransformer';

abstract class ResultRepresentationManager {
  config: ResultRepresentationSubTypeConfig | undefined;

  constructor(readonly representation: ResultRepresentation) {
    if (representation.subType) {
      this.config = ResultRepresentationSubTypeConfigFactory.create(representation.subType);
    }
  }

  abstract transformers: ResultRepresentationTransformer[];

  getCompatibleSubTypes(): ResultRepresentationSubType[] {
    return this.transformers
      .filter((transformer) => transformer.isCompatible())
      .map((transformer) => transformer.to);
  }

  getTransformer(
    to: ResultRepresentationSubType,
  ): ResultRepresentationTransformer {
    const transformer = this.transformers.find((item) => item.to === to);
    if (!transformer) {
      if (to === ResultRepresentationSubType.CUSTOM_CODE) {
        return new AnyToCustomCoderResultRepresentationTransformer(
          this.representation,
          this.representation.subType as ResultRepresentationSubType,
          to,
        );
      }

      return new NotFoundResultRepresentationTransformer(
        this.representation,
        this.representation.subType as ResultRepresentationSubType,
        to,
      );
    }

    return transformer as ResultRepresentationTransformer;
  }

  transform(to: ResultRepresentationSubType): ResultRepresentation {
    const transformer = this.getTransformer(to);
    return transformer.transform();
  }
}

export default ResultRepresentationManager;
