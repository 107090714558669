import React, { PropsWithChildren } from 'react';
import { Card, CardProps, Switch } from 'antd';

interface BuilderStepCardProps extends CardProps {
  question: string;
  description?: string;
  isVisible?: boolean;
  setIsVisible?: (isVisible: boolean) => void;
  hideVisibilityToggle?: boolean;
}

export default function BuilderStepCard(props: PropsWithChildren<BuilderStepCardProps>) {
  const {
    children,
    question,
    description,
    isVisible,
    setIsVisible,
    hideVisibilityToggle,
    ...resetProps
  } = props;

  return (
    <Card size="small" {...resetProps}>
      <div className="d-flex justify-content-between align-items-center">
        <p className="m-0">{question}</p>
        {
          !hideVisibilityToggle && (
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={isVisible}
              onChange={setIsVisible}
            />
          )
        }
      </div>
      <Card.Meta className="mt-1" description={description} />
      {
        (isVisible || hideVisibilityToggle) && <div className="mt-4">{children}</div>
      }
    </Card>
  );
}
