import {
  Alert,
  Badge,
  Button,
  Card,
  Drawer,
  Layout,
  message,
  notification,
  Radio,
  Result,
  Select,
  TabsProps,
  Tag,
} from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Highlight from 'react-highlight';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'highlight.js/styles/github.css';
import {
  ApartmentOutlined,
  CodeOutlined,
  FormOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  PlaySquareOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import api, {
  DataSource,
  DataSourceType,
  Parameter,
  ParameterData,
  Query,
  QueryExecutionMode,
  RawCodeLanguage,
  ResultRepresentationSubType,
  Widget,
} from '../../api';
import { Query as DBAQLQuery } from '../../dbaql/types';
import WidgetSetup from '../../widgets/components/WidgetSetup';
import QueryExecutionView from '../../query-executions/components/QueryExecutionView';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import DbaqlBuilder from '../../dbaql/components/DbaqlBuilder';
import useDbaqlQueryBuilder from '../../dbaql/hooks/useDbaqlQueryBuilder';
import useLocalStorageState from '../../common/hooks/useLocalStorageState';
import DataSourceCodeEditor, { getLanguage, getLanguages } from '../../data-sources/components/DataSourceCodeEditor';
import useSize from '../../common/hooks/useSize';
import DataSourceSchemaExplorerBase from '../../data-sources/components/DataSourceSchemaExplorerBase';
import ResultRepresentationSubTypeSelect from '../../query-executions/components/ResultRepresentationSubTypeSelect';
import QueryParametersManager from '../../parameters/components/QueryParametersManager';
import useDashboardParameters from '../../parameters/hooks/useDashboardParameters';
import QuerySettings, { QuerySettingsConfig } from './QuerySettings';
import { globalAtom } from '../../common/state/global-state';
import DataSourceChat from '../../chat/components/DataSourceChat';

export interface QueryCardProps extends Pick<
Query, 'organizationId' | 'projectId' | 'dashboardId' | 'widgetId'
> {
  hasDataSource: boolean;
  onWidgetCreated?: (widget: Widget) => void;
  dataSource?: DataSource;
  heightSubtract?: number;
}

function TabContentWrapper({ children }: { children: ReactNode }) {
  return (
    <div
      className="bg-white p-2"
      style={{
        maxHeight: 400,
        height: 400,
        overflow: 'scroll',
        border: '1px solid rgba(22, 22, 22, 0.09)',
        borderRadius: '0 0 10px 10px',
        borderTopWidth: 0,
      }}
    >
      {children}
    </div>
  );
}

const SHOW_DBAQL_SWITCH = false;

function getRawCode(language: RawCodeLanguage) {
  if (language === RawCodeLanguage.JSON) {
    return JSON.stringify({ collection: '', stages: [] }, null, 2);
  }

  return '';
}

function validateRawCode(
  code: string,
  language: RawCodeLanguage,
  dataSourceType?: DataSourceType,
): string | null {
  if (!code) {
    return 'Please enter some code.';
  }

  if (dataSourceType === DataSourceType.MONGODB && language === RawCodeLanguage.JSON) {
    try {
      const value = JSON.parse(code);

      if (value.collection === undefined) {
        return 'The object should have a "collection" property.';
      }

      if (typeof value.collection !== 'string') {
        return 'The "collection" property should be a string.';
      }

      if (value.collection.trim().length === 0) {
        return 'The "collection" property should not be empty.';
      }

      if (!value.stages) {
        return 'The object should have a "stages" property.';
      }

      if (!Array.isArray(value.stages)) {
        return 'The "stages" property should be an array.';
      }

      if (value.stages.length === 0) {
        return 'The "stages" property should have at least one stage.';
      }
    } catch (e) {
      return 'Invalid JSON';
    }
  }

  return null;
}

function getWidgetParameters(dashboardParameters: Parameter[]) {
  return dashboardParameters.map((parameter) => ({
    name: parameter.name,
    scope: parameter.scope,
    type: parameter.type,
    description: parameter.description,
    dashboardId: parameter.dashboardId,
    value: parameter.value,
    templateId: parameter._id,
  }));
}

const PADDING = 16;
const ACTION_BUTTONS_HEIGHT = 64 + PADDING;
const SECTION_PADDING_TOP = ACTION_BUTTONS_HEIGHT + PADDING + PADDING;

export default function QueryCard(props: QueryCardProps) {
  const {
    organizationId,
    projectId,
    dashboardId,
    widgetId,
    hasDataSource,
    onWidgetCreated,
    dataSource: defaultDataSource,
    heightSubtract = 0,
  } = props;

  const { organizationSlug, projectSlug } = useParams();
  const organization = useActiveOrganization();
  const outOfCredits = Boolean(organization && organization.creditsRemaining <= 0);
  const [prompt, setPrompt] = useState('');
  const [query, setQuery] = useState<Query | null>();
  const [loading, setLoading] = useState(false);
  const [saveWidgetDrawerVisible, setSaveWidgetDrawerVisible] = useState(false);
  const [mode, setMode] = useState(QueryExecutionMode.AI);
  const [disableDbaql] = useLocalStorageState('disable-dbaql-in-query', false);
  const [
    resultRepresentationSubTypeOverride,
    setResultRepresentationSubTypeOverride,
  ] = useState<ResultRepresentationSubType | undefined>();

  const [subType, setSubType] = useState<ResultRepresentationSubType | undefined>(
    ResultRepresentationSubType.TABLE,
  );
  const [size, ref] = useSize();
  const [collapsed, setCollapsed] = useState(true);
  const [parametersDrawerOpen, setParametersDrawerOpen] = useState(false);
  const [{ parameters: dashboardParameters }] = useDashboardParameters(dashboardId);
  const [parameters, setParameters] = useState<ParameterData[]>(
    getWidgetParameters(dashboardParameters),
  );
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [settings, setSettings] = useState<QuerySettingsConfig>({});
  const { dataSources } = useRecoilValue(globalAtom);

  let dataSource: QueryCardProps['dataSource'] = defaultDataSource;

  if (settings.dataSourceId) {
    dataSource = dataSources.find((ds) => ds._id === settings.dataSourceId) || defaultDataSource;
  }
  const dataSourceType = dataSource?.type;
  const suggestedQuestions = dataSource?.suggestedQuestions || [];

  const [language, setLanguage] = useState<RawCodeLanguage>(getLanguage(dataSourceType));
  const [rawCode, setRawCode] = useState(getRawCode(language));

  useEffect(() => {
    setParameters(getWidgetParameters(dashboardParameters));
  }, [dashboardParameters]);

  const queryBuilder = useDbaqlQueryBuilder(dataSource?.schemaConfig);

  useEffect(() => {
    setLanguage(getLanguage(dataSourceType));
  }, [dataSourceType]);

  useEffect(() => {
    setRawCode(getRawCode(language));
  }, [language]);

  // eslint-disable-next-line @typescript-eslint/no-shadow

  const onSearch = async (searchPrompt = prompt) => {
    if (hasDataSource) {
      setLoading(true);
      setResultRepresentationSubTypeOverride(undefined);
      api.queries.create({
        ...settings,
        mode: QueryExecutionMode.AI,
        prompt: searchPrompt,
        disableDbaql: SHOW_DBAQL_SWITCH ? disableDbaql : true,
        organizationId,
        projectId,
        dashboardId,
        widgetId,
        dataSourceId: dataSource?._id,
        parameters,
      }).then(setQuery).finally(() => { setLoading(false); });
    }
  };

  const onManualQuerySubmit = async (q?: DBAQLQuery) => {
    if (hasDataSource) {
      let dbaqlQuery: DBAQLQuery | undefined = q;

      if (!q) {
        try {
          dbaqlQuery = await queryBuilder.generateQuery();
        } catch (e: any) {
          notification.error({
            message: e.message,
          });
        }
      }

      if (dbaqlQuery) {
        setLoading(true);
        api.queries.create({
          mode: QueryExecutionMode.DBAQL,
          dbaqlQuery,
          organizationId,
          projectId,
          dashboardId,
          widgetId,
          dataSourceId: dataSource?._id,
          parameters,
        }).then(setQuery).finally(() => { setLoading(false); });
      }
    }
  };

  const onRawCodeSubmit = async () => {
    const isInvalid = validateRawCode(rawCode, language, dataSourceType);

    if (isInvalid) {
      notification.error({
        message: isInvalid,
      });
    } else if (hasDataSource) {
      setLoading(true);
      api.queries.create({
        mode: QueryExecutionMode.RAW_CODE,
        organizationId,
        projectId,
        dashboardId,
        widgetId,
        dataSourceId: dataSource?._id,
        resultRepresentationSubType: subType,
        rawCode,
        rawCodeLanguage: language,
        parameters,
      }).then(setQuery).finally(() => { setLoading(false); });
    } else {
      notification.error({
        message: 'No data source found for this project.',
        description: 'Please set a data source in the project settings.',
      });
    }
  };

  const onRetryClick = async () => {
    const newMode = query?.mode || mode;

    if (newMode === QueryExecutionMode.AI) {
      await onSearch(query?.prompt);
    } else if (newMode === QueryExecutionMode.DBAQL) {
      await onManualQuerySubmit(query?.dbaqlQuery);
    } else {
      await onRawCodeSubmit();
    }
  };

  const onRunClick = async (q?: DBAQLQuery) => {
    if (mode === QueryExecutionMode.AI) {
      await onSearch();
    } else if (mode === QueryExecutionMode.DBAQL) {
      await onManualQuerySubmit(q);
    } else {
      await onRawCodeSubmit();
    }
  };

  const onCodeEdit = (code?: string) => {
    if (!code) {
      return void message.error('No code found.');
    }

    setMode(QueryExecutionMode.RAW_CODE);
    setLanguage(RawCodeLanguage.JAVASCRIPT);

    // Set in a timeout because the language change triggers a code clear
    // which clears the new code as well, so we need to wait for the clear
    // and then set the new code.
    setTimeout(() => {
      setRawCode(code || '');
    }, 500);

    return void message.success('Code loaded');
  };

  const onCodeEditClick = () => {
    onCodeEdit(query?.execution?.details?.rawCode);
  };

  useEffect(() => {
    const onCodeEditClickListener = ((event: CustomEvent) => {
      onCodeEdit(event.detail.rawCode);
    }) as EventListener;

    window.addEventListener('query-code-edit-click', onCodeEditClickListener);

    return () => {
      window.removeEventListener('query-code-edit-click', onCodeEditClickListener);
    };
  }, []);

  const tabItems: TabsProps['items'] = [];

  if (suggestedQuestions && suggestedQuestions.length > 0) {
    tabItems.push({
      key: 'recent',
      label: 'Suggested Questions',
      children: (
        <TabContentWrapper>
          {
            suggestedQuestions.map((suggestedQuestion, index) => (
              <div
                key={suggestedQuestion}
                className={
                  `d-flex align-items-center justify-content-between ${index > 0 && 'mt-2 pt-2 border-top'}`
                }
              >
                <p className="m-0">{suggestedQuestion}</p>
                <Button
                  size="small"
                  onClick={() => {
                    setPrompt(suggestedQuestion);
                    onSearch(suggestedQuestion).then();
                  }}
                  disabled={loading}
                >
                  Use
                </Button>
              </div>
            ))
          }
        </TabContentWrapper>
      ),
    });
  }

  const isAI = mode === QueryExecutionMode.AI;
  const isManual = mode === QueryExecutionMode.DBAQL;
  const isRawCode = mode === QueryExecutionMode.RAW_CODE;

  if (query && query.execution) {
    tabItems.push({
      key: 'usage',
      label: 'Usage',
      children: (
        <TabContentWrapper>
          <div>
            <p className="p-0 m-0">
              {`Total credits: ${query.execution.totalCredits}`}
            </p>
          </div>
        </TabContentWrapper>
      ),
    });

    if (query?.execution?.details?.rawCode) {
      tabItems.push({
        key: 'debug',
        label: 'Debug',
        children: (
          <TabContentWrapper>
            <div>
              <Highlight className="javascript">
                {query.execution.details.rawCode}
              </Highlight>
            </div>
          </TabContentWrapper>
        ),
      });
    }
  }

  const siderWidth = mode === QueryExecutionMode.AI ? 0 : Math.min((size.width || 0) * 0.5, 700);

  // const newValues = JSON.stringify([
  //   organizationSlug,
  //   projectSlug,
  //   organization,
  //   outOfCredits,
  //   prompt,
  //   query,
  //   loading,
  //   saveWidgetDrawerVisible,
  //   mode,
  //   disableDbaql,
  //   resultRepresentationSubTypeOverride,
  //   subType,
  //   size,
  //   collapsed,
  //   parametersDrawerOpen,
  //   dashboardParameters,
  //   parameters,
  //   settingsOpen,
  //   settings,
  //   dataSources,
  //   dataSource,
  //   dataSourceType,
  //   suggestedQuestions,
  //   language,
  //   rawCode,
  //   height,
  //   siderWidth,
  //   isAI,
  //   isManual,
  //   isRawCode,
  // ]);

  return (
    <>
      <Layout
        className="bg-transparent"
        ref={ref}
      >
        <Layout.Content
          style={{ background: 'transparent' }}
          className="me-2"
        >
          <div
            style={{
              height: `calc(100vh - ${heightSubtract}px)`,
              position: 'relative',
              overflow: 'auto',
            }}
          >
            <div>
              <div
                style={{
                  height: '100%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: PADDING,
                    right: PADDING,
                    zIndex: 2,
                    paddingTop: PADDING,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
                    <div
                      className="d-flex align-items-center justify-content-center gap-2"
                    >
                      <Radio.Group
                        value={mode}
                        onChange={(e) => {
                          setMode(e.target.value as QueryExecutionMode);
                        }}
                      >
                        <Radio.Button value={QueryExecutionMode.AI}>
                          <MessageOutlined />
                          <span className="ms-2">AI</span>
                        </Radio.Button>
                        <Radio.Button value={QueryExecutionMode.DBAQL}>
                          <FormOutlined />
                          <span className="ms-2">Query Builder</span>
                        </Radio.Button>
                        <Radio.Button value={QueryExecutionMode.RAW_CODE}>
                          <CodeOutlined />
                          <span className="mx-2">Code</span>
                          <Tag color="green">NEW</Tag>
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                    <Button
                      icon={<InfoCircleOutlined />}
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      Schema
                    </Button>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ marginTop: PADDING }}
                  >
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <Button
                        icon={<SettingOutlined />}
                        onClick={() => void setSettingsOpen(true)}
                      />
                      {
                        isRawCode && (
                          <>
                            <ResultRepresentationSubTypeSelect
                              value={subType}
                              onChange={setSubType}
                            />
                            <Select
                              options={getLanguages(dataSourceType)}
                              value={language}
                              onChange={setLanguage}
                              style={{
                                minWidth: 120,
                              }}
                            />
                          </>
                        )
                      }
                      {
                        dashboardId && (
                          <Button
                            icon={<ApartmentOutlined />}
                            onClick={() => setParametersDrawerOpen(true)}
                          >
                            Parameters
                            <Badge
                              count={parameters.length}
                              className="ms-2"
                              style={{ backgroundColor: '#52c41a' }}
                              size="small"
                            />
                          </Button>
                        )
                      }
                    </div>
                    {
                      !isAI && (
                        <Button
                          icon={<PlaySquareOutlined />}
                          onClick={() => {
                            onRunClick().then();
                          }}
                          loading={loading}
                          disabled={loading}
                          type="primary"
                        >
                          Run
                        </Button>
                      )
                    }
                  </div>
                </div>
                <div
                  style={{
                    height: `calc(100vh - ${heightSubtract}px)`,
                    overflow: 'auto',
                  }}
                >
                  {
                    isAI ? (
                      <div>
                        <DataSourceChat
                          heightSubtract={heightSubtract}
                          paddingTop={SECTION_PADDING_TOP}
                          dataSource={dataSource}
                          parameters={parameters}
                          dashboardId={dashboardId}
                          onWidgetCreated={onWidgetCreated}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: 'auto',
                          padding: `${SECTION_PADDING_TOP}px ${PADDING}px ${PADDING}px ${PADDING}px`,
                        }}
                      >
                        {
                          isManual && dataSource?.schemaConfig && !outOfCredits && (
                            <div>
                              <Card>
                                <DbaqlBuilder schema={dataSource.schemaConfig} />
                              </Card>
                              <Alert
                                message="This feature is in beta."
                                description="Please use carefully and report any issues to us. DBAQL is a new language that we are developing to allow you to write queries manually, as well as improve the accuracy of our AI."
                                className="mt-4"
                                closable
                                type="info"
                              />
                            </div>
                          )
                        }
                        {
                          isRawCode && dataSource && (
                            <div
                              style={{ height: `calc(100vh - ${(SECTION_PADDING_TOP + 16 + heightSubtract)}px)` }}
                            >
                              <DataSourceCodeEditor
                                dataSource={dataSource}
                                onRun={onRawCodeSubmit}
                                loading={loading}
                                subType={subType}
                                onSubTypeChange={setSubType}
                                rawCode={rawCode}
                                setRawCode={setRawCode}
                                language={language}
                                setLanguage={setLanguage}
                                parameters={parameters}
                              />
                            </div>
                          )
                        }
                        {
                          outOfCredits && (
                            <Alert
                              className="mt-4"
                              type="error"
                              message="You have no credits remaining. Please upgrade your account to create new queries."
                              action={(
                                <Link to={`/${organizationSlug}/settings`} className="ms-2">
                                  <Button>Upgrade</Button>
                                </Link>
                              )}
                            />
                          )
                        }
                        {
                          !hasDataSource && (
                            <Alert
                              className="mt-4"
                              type="error"
                              message="No data source found for this project. Please choose a data source or create a new one."
                              action={(
                                <div className="ms-2">
                                  <Link to={`/${organizationSlug}/data-sources/create`}>
                                    <Button>Create Data Source</Button>
                                  </Link>
                                  <br />
                                  <Link to={`/${organizationSlug}/${projectSlug}/settings`}>
                                    <Button className="mt-2" block>Set Data Source</Button>
                                  </Link>
                                </div>
                              )}
                            />
                          )
                        }

                        {
                          dataSourceType === DataSourceType.AIRTABLE && (
                            <Alert
                              className="mt-4"
                              type="warning"
                              message="You are using Airtable as your data source. Please note that Airtable API has a limit of 5 requests per second, and is not very powerful for complex queries. We recommend using a regular database for better performance."
                              closable
                              showIcon
                            />
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Layout.Content>
        <Layout.Sider
          collapsed={false}
          width={siderWidth}
          theme="light"
          collapsedWidth={0}
        >
          <div>
            <Card
              style={{
                height: `calc(100vh - ${PADDING * 2 + heightSubtract}px)`,
                marginTop: PADDING,
                marginRight: PADDING,
              }}
              title={(
                <h4 className="m-0 p-0">Result</h4>
              )}
            >
              {
                query && query.execution ? (
                  <QueryExecutionView
                    id={query.execution._id}
                    type={query.execution.type}
                    clarification={query.execution.clarification}
                    resultType={query.execution.resultType}
                    responseText={query.execution.responseText}
                    result={query.execution.result}
                    status={query.execution.status}
                    calculationMethod={query.execution.calculationMethod}
                    mode={query.execution.mode}
                    prompt={query.execution.prompt}
                    details={query.execution.details}
                    showSaveAsWidgetButton
                    onSaveAsWidgetClick={() => setSaveWidgetDrawerVisible(true)}
                    resultRepresentation={query.execution.resultRepresentation}
                    resultRepresentationOverride={query.execution.resultRepresentationOverride}
                    subTypeOverride={resultRepresentationSubTypeOverride}
                    onSubTypeOverrideChange={setResultRepresentationSubTypeOverride}
                    resultRepresentationBuilderOptions={
                      query
                        .execution
                        .resultRepresentationBuilderOptions
                    }
                    loading={loading}
                    onRetryClick={onRetryClick}
                    onCodeEditClick={onCodeEditClick}
                    errorReason={query.execution.errorReason}
                    onExecutionChange={(newExecution) => {
                      setQuery((oldQuery) => {
                        if (oldQuery) {
                          return {
                            ...oldQuery,
                            execution: newExecution,
                          };
                        }

                        return oldQuery;
                      });
                    }}
                    totalCredits={query.execution.totalCredits}
                    showRetryButton
                    overflowRepresentation
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <Result
                      title={(
                        <h6>The result of your query will appear here once you run it.</h6>
                      )}
                    />
                  </div>
                )
              }
            </Card>
          </div>
        </Layout.Sider>
      </Layout>
      <Drawer
        open={saveWidgetDrawerVisible}
        onClose={() => setSaveWidgetDrawerVisible(false)}
        title="Save as Widget"
      >
        {
          query && (
            <WidgetSetup
              queryId={query?._id}
              projectId={query?.projectId}
              resultRepresentationSubTypeOverride={resultRepresentationSubTypeOverride}
              onSuccess={(widget) => {
                setSaveWidgetDrawerVisible(false);

                if (onWidgetCreated) {
                  onWidgetCreated(widget);
                }
              }}
              dashboardId={dashboardId}
            />
          )
        }
      </Drawer>
      <Drawer
        open={!collapsed}
        onClose={() => setCollapsed(true)}
        title="Schema"
        width={500}
        mask={false}
      >
        <DataSourceSchemaExplorerBase dataSource={dataSource || undefined} includedOnly />
      </Drawer>
      <Drawer
        open={parametersDrawerOpen}
        onClose={() => setParametersDrawerOpen(false)}
        title="Parameters"
        width={600}
      >
        {
          dashboardId && (
            <QueryParametersManager
              parameters={parameters}
              onChange={setParameters}
            />
          )
        }
      </Drawer>
      <Drawer
        title="Query Settings"
        open={settingsOpen}
        onClose={() => void setSettingsOpen(false)}
      >
        <QuerySettings
          value={settings}
          onChange={(newSettings) => {
            setSettings(newSettings);
            setSettingsOpen(false);
          }}
        />
      </Drawer>
    </>
  );
}
