import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';
import guessSeriesAndLabels from '../utils';

class TableToBarChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.TABLE;

  to = ResultRepresentationSubType.BAR_CHART;

  transformData(): any {
    const guess = guessSeriesAndLabels(this.resultRepresentation.data);
    return {
      series: [{ data: guess?.series }],
      options: {
        chart: {
          type: 'bar',
        },
        xaxis: {
          categories: guess?.labels,
        },
      },
    };
  }
}

export default TableToBarChartTransformer;
