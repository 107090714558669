import axios from 'axios';
import StatusCheckResponse from '../definitions/StatusCheckResponse';

export default class Security {
  constructor(readonly healthCheckUrl: string) {}

  async statusCheck(): Promise<StatusCheckResponse> {
    const { data } = await axios.get<StatusCheckResponse>(this.healthCheckUrl);
    return data;
  }
}
