import * as React from 'react';
import { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon';
import ResultRepresentationManagerFactory from '../transforms/managers/ResultRepresentationManagerFactory';
import {
  ResultRepresentation,
  ResultRepresentationSubType,
  ResultRepresentationType,
} from '../../api';

// Text --> String, Date
// Number --> Date
// String --> Text, Date
// Date --> Number, String
// ObjectView -->
// Table --> if 2 columns can be converted to
// a pie-chart, area-chart, line-chart, bar-chart, tree-map-chart
// Pie Chart --> bar-chart, tree-map-chart, table
// Area Chart --> line-chart, table
// Line Chart --> area-chart, table
// Bar Chart --> pie-chart, tree-map-chart, table
// Scatter Chart --> Table
// Heatmap Chart --> Table
// Radar Chart --> Table
// Tree Map Chart --> pie-chart, bar-chart, table
// Comm
// Comm

const MAP_SUB_TYPE_TO_TYPE: Record<
ResultRepresentationSubType,
ResultRepresentationType
> = {
  [ResultRepresentationSubType.TEXT]: ResultRepresentationType.TEXT,
  [ResultRepresentationSubType.NUMBER]: ResultRepresentationType.NUMBER,
  [ResultRepresentationSubType.STRING]: ResultRepresentationType.STRING,
  [ResultRepresentationSubType.DATE]: ResultRepresentationType.DATE,
  [ResultRepresentationSubType.OBJECT_VIEW]: ResultRepresentationType.OBJECT_VIEW,
  [ResultRepresentationSubType.TABLE]: ResultRepresentationType.TABLE,
  [ResultRepresentationSubType.PIE_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.AREA_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.LINE_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.BAR_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.SCATTER_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.HEATMAP_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.RADAR_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.TREE_MAP_CHART]: ResultRepresentationType.GRAPH,
  [ResultRepresentationSubType.CUSTOM_CODE]: ResultRepresentationType.CUSTOM,
};

export type ResultRepresentationSubTypeConfig = {
  title: string;
  type: ResultRepresentationType;
  icon?: React.ForwardRefExoticComponent<AntdIconProps>;
};

export const RESULT_REPRESENTATION_SUB_TYPE_CONFIG: Record<
ResultRepresentationSubType,
ResultRepresentationSubTypeConfig
> = {
  [ResultRepresentationSubType.TEXT]: {
    title: 'Text',
    type: ResultRepresentationType.TEXT,
  },
  [ResultRepresentationSubType.NUMBER]: {
    title: 'Number',
    type: ResultRepresentationType.NUMBER,
  },
  [ResultRepresentationSubType.STRING]: {
    title: 'String',
    type: ResultRepresentationType.STRING,
  },
  [ResultRepresentationSubType.DATE]: {
    title: 'Date',
    type: ResultRepresentationType.DATE,
  },
  [ResultRepresentationSubType.OBJECT_VIEW]: {
    title: 'Object',
    type: ResultRepresentationType.OBJECT_VIEW,
  },
  [ResultRepresentationSubType.TABLE]: {
    title: 'Table',
    type: ResultRepresentationType.TABLE,
  },
  [ResultRepresentationSubType.PIE_CHART]: {
    title: 'Pie Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.AREA_CHART]: {
    title: 'Area Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.LINE_CHART]: {
    title: 'Line Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.BAR_CHART]: {
    title: 'Bar Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.TREE_MAP_CHART]: {
    title: 'Tree Map Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.SCATTER_CHART]: {
    title: 'Scatter Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.HEATMAP_CHART]: {
    title: 'Heatmap Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.RADAR_CHART]: {
    title: 'Radar Chart',
    type: ResultRepresentationType.GRAPH,
  },
  [ResultRepresentationSubType.CUSTOM_CODE]: {
    title: 'Custom Code',
    type: ResultRepresentationType.CUSTOM,
  },
};

export default class ResultRepresentationHelper {
  static getCompatibleSubTypes(
    resultRepresentation: ResultRepresentation,
  ) {
    const { type, subType } = resultRepresentation;

    if (subType) {
      const compatibleSubTypesForSubType = ResultRepresentationHelper
        .getCompatibleSubTypesForSubType(resultRepresentation);
      return [subType, ...compatibleSubTypesForSubType];
    }

    return ResultRepresentationHelper.getCompatibleSubTypesForType(type);
  }

  static getCompatibleSubTypesForSubType(
    representation: ResultRepresentation,
  ): ResultRepresentationSubType[] {
    return ResultRepresentationManagerFactory.createManager(representation).getCompatibleSubTypes();
  }

  static getCompatibleSubTypesForType(
    type: ResultRepresentationType,
  ): ResultRepresentationSubType[] {
    const subTypes: ResultRepresentationSubType[] = [];

    Object.entries(MAP_SUB_TYPE_TO_TYPE).forEach(([subType, entryType]) => {
      if (type === entryType) {
        subTypes.push(subType as ResultRepresentationSubType);
      }
    });

    return subTypes;
  }

  static getSubTypeConfig(subType: ResultRepresentationSubType): ResultRepresentationSubTypeConfig {
    return RESULT_REPRESENTATION_SUB_TYPE_CONFIG[subType];
  }
}
