import {
  Button, Form, Input, notification,
} from 'antd';
import { useState } from 'react';
import { useAuth } from '../index';
import { User } from '../../api';

export default function SignInForm() {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Pick<User, 'name' | 'email'> & { password: string }) => {
    setLoading(true);
    try {
      await signIn({
        email: values.email,
        password: values.password,
      });
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e.message,
      });
    }
    setLoading(false);
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input
          placeholder="Enter your email"
          size="large"
        />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={[{ required: true }]}>
        <Input.Password placeholder="Enter your password" size="large" />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          size="large"
          block
        >
          Sign In
        </Button>
      </Form.Item>
    </Form>
  );
}
