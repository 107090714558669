import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AppPage from '../../layout/components/AppPage';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import DataSourceForm from '../components/DataSourceForm';
import api, { DataSourceScope, OnboardingStepKey } from '../../api';
import useOnboarding from '../../onboarding/hooks/useOnboarding';
import { globalAtom } from '../../common/state/global-state';
import useQuery from '../../common/hooks/useQuery';
import useActiveOrganizationPlan from '../../common/hooks/useActiveOrganizationPlan';
import UsageReachedView from '../../billing/components/UsageReachedView';

export default function NewDataSourcePage() {
  const organization = useActiveOrganization();
  const onboarding = useOnboarding();
  const navigate = useNavigate();
  const setGlobalState = useSetRecoilState(globalAtom);
  const plan = useActiveOrganizationPlan();
  const [total, loading] = useQuery<number>(async () => {
    if (!organization) {
      return 0;
    }

    const res = await api.dataSources.find({
      query: {
        entityId: organization._id,
        $limit: 100,
        $select: ['_id'],
      },
    });

    return res.total;
  }, [organization?._id]);

  let limitReached = false;

  if (!loading && plan && total && total >= plan.maxDataSources) {
    limitReached = true;
  }

  return (
    <AppPage title="New Data Source">
      <Row justify="center">
        <Col span={24} md={20} lg={16} xl={12} xxl={8}>
          {
            organization && !loading && !limitReached && (
              <Card title="Create Data Source">
                <DataSourceForm
                  entityId={organization._id}
                  scope={DataSourceScope.ORGANIZATION}
                  onSuccess={(dataSource) => {
                    const { activeStep } = onboarding;

                    if (activeStep?.key === OnboardingStepKey.CONNECT_DATABASE) {
                      const nextStep = onboarding.goToNextStep();

                      if (nextStep && nextStep.url) {
                        setGlobalState((previous) => ({
                          ...previous,
                          dataSources: [
                            ...previous.dataSources,
                            dataSource,
                          ],
                          projects: previous.projects.map((project) => ({
                            ...project,
                            defaultDataSourceId: project.defaultDataSourceId || dataSource._id,
                          })),
                          dataSource,
                        }));
                        return void setTimeout(() => {
                          if (nextStep.url) {
                            navigate(nextStep.url);
                          }
                        }, 500);
                      }
                    }

                    return void navigate(`../${dataSource._id}`);
                  }}
                  organizationId={organization._id}
                  organization={organization}
                />
              </Card>
            )
          }
          {
            limitReached && (
              <UsageReachedView subTitle="You have reached the maximum number of data sources allowed for your plan." />
            )
          }
        </Col>
      </Row>
    </AppPage>
  );
}
