import { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon';
import { ResultRepresentation } from '../../../api';
import ResultRepresentationSubTypeIcon from '../ResultRepresentationSubTypeIcon';
import ResultRepresentationTypeIcon from '../ResultRepresentationTypeIcon';

interface IconProps extends Pick<AntdIconProps, 'size' | 'color'> {}

interface ResultRepresentationIconProps extends IconProps {
  representation: Pick<ResultRepresentation, 'type' | 'subType'>;
}

export default function ResultRepresentationIcon(props: ResultRepresentationIconProps) {
  const { representation, ...iconProps } = props;
  const { type, subType } = representation;

  if (subType) {
    return (<ResultRepresentationSubTypeIcon subType={subType} {...iconProps} />);
  }

  return (
    <ResultRepresentationTypeIcon type={type} {...iconProps} />
  );
}
