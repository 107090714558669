import { Col, Layout, Row } from 'antd';
import React, { ReactNode } from 'react';

interface AuthPageLayoutProps {
  children: ReactNode;
  height?: string;
  title?: string | ReactNode;
}

export default function AuthPageLayout(props: AuthPageLayoutProps) {
  const {
    children,
    height = '100vh',
    title = (
      <>
        Chat with your Database
        <br />
        using AI
        <br />
      </>
    ),
  } = props;

  return (
    <>
      <Layout
        style={{
          height,
          background: 'linear-gradient(180deg, #F2F6F9 0%, #D9E6F2 100%)',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div className="picture-container">
          <img src="/img/robot.png" style={{ height }} alt="Robot" />
        </div>
        <div style={{ maxHeight: height, overflowY: 'auto' }}>
          <Row style={{ minHeight: height }} justify="center">
            <Col span={22} md={8} lg={9} xl={9} xxl={8}>
              <div className="d-flex justify-content-center align-items-center pt-4 content-container">
                <div style={{ flex: 1, maxWidth: 600 }}>
                  <div className="d-flex">
                    <img src="/logo-color.png" height={80} alt="InsightBase Logo" />
                  </div>
                  <div className="mt-4 pt-4" style={{ maxWidth: 700 }}>
                    <h1 className="mb-5 title">
                      {title}
                    </h1>
                    {children}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              span={24}
              md={12}
              lg={12}
              xl={11}
              xxl={12}
            />
          </Row>
        </div>
      </Layout>
      {/*
      @ts-ignore */}
      <style jsx>
        {`
          .picture-container {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              height: ${height};
              overflow: hidden;
          }
          .title {
              font-size: 3rem;
          }
          .content-container {
              margin-bottom: 50px;
          }
          @media screen and (max-width: 915px) {
              .title {
                  font-size: 2rem;
              }
              .picture-container {
                  top: 200px;
                  right: -100vw;
              }
          }
          
          @media screen and (min-width: 915px) and (max-width: 1192px) {
              .picture-container {
                  right: -50vw;
              }
          }

          @media screen and (min-width: 1193px) and (max-width: 1592px) {
              .picture-container {
                  right: -30vw;
              }
          }

          @media screen and (min-width: 1192px) {
              .content-container {
                  margin-top: 50px;
              }
          }
          
          @media screen and (min-width: 1592px) {
              .content-container {
                  margin-top: 200px;
              }
          }
      `}
      </style>
    </>
  );
}
