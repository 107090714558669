import { Button, notification, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import api, { Entity } from '../../api';
import useRequest from '../../common/hooks/useRequest';

interface DeleteEntitySectionProps {
  entityId: Entity['_id'];
}

export default function DeleteEntitySection(props: DeleteEntitySectionProps) {
  const { entityId } = props;
  const navigate = useNavigate();

  const request = useRequest(
    async () => api.entities.remove(entityId),
    {
      onSuccess: () => {
        navigate('../');
        notification.success({
          message: 'Entity deleted successfully',
        });
      },
      onError: () => {
        notification.error({
          message: 'Failed to delete entity',
        });
      },
    },
  );

  return (
    <div>
      <h3>Delete Entity</h3>
      <p>
        Please note that deleting an entity is irreversible.
        {' '}
        Are you sure you want to delete this entity?
      </p>
      <Popconfirm
        title="Are you sure you want to delete this entity?"
        okText="Yes"
        cancelText="No"
        okButtonProps={{
          loading: request.loading,
        }}
        onConfirm={request.submit}
      >
        <Button
          loading={request.loading}
          danger
        >
          Delete Entity
        </Button>
      </Popconfirm>
    </div>
  );
}
