import { DotChartOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class ScatterChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = DotChartOutlined;

  title = 'Scatter Chart';

  type = ResultRepresentationType.GRAPH;
}

export default ScatterChartResultRepresentationSubTypeConfig;
