import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class AreaChartToLineChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.AREA_CHART;

  to = ResultRepresentationSubType.LINE_CHART;

  transformData(): any {
    return {
      options: {
        xaxis: this.resultRepresentation.data.options.xaxis,
      },
      series: this.resultRepresentation.data.series,
      type: 'line',
    };
  }
}

export default AreaChartToLineChartTransformer;
