import React, {
  useState, useEffect, HTMLProps, ReactNode,
} from 'react';
import styles from './index.module.css';

interface TypewriterProps extends HTMLProps<HTMLParagraphElement> {
  text: string;
  onDone?: () => void;
  typingDelay?: number;
  doneSuffix?: ReactNode;
}

// Do not type in more than 2 seconds
const MAX_TYPING_TIME = 4000;

function getTypingTime(defaultTime: number, text: string) {
  const totalTimeRequired = text.length * defaultTime;

  if (totalTimeRequired > MAX_TYPING_TIME) {
    return Math.floor(MAX_TYPING_TIME / text.length);
  }

  return defaultTime;
}

export default function Typewriter(props: TypewriterProps) {
  const {
    text,
    onDone,
    typingDelay = 20,
    doneSuffix,
    ...rest
  } = props;
  const [done, setDone] = useState(false);
  const [displayedCharacters, setDisplayedCharacters] = useState(0);

  useEffect(() => {
    let index = 0;
    setDisplayedCharacters(0);
    const timer = setInterval(() => {
      setDisplayedCharacters(index);
      index += 1;
      if (index > text.length) {
        clearInterval(timer);
      }
    }, getTypingTime(typingDelay, text));

    return () => clearInterval(timer);
  }, [text]);

  useEffect(() => {
    if (displayedCharacters === text.length) {
      setDone(true);

      if (onDone) {
        onDone();
      }
    }
  }, [displayedCharacters, text, Boolean(onDone)]);

  return (
    <p {...rest}>
      {text.slice(0, displayedCharacters)}
      {!done && <span className={styles.cursor}>|</span>}
      {done && doneSuffix}
    </p>
  );
}
