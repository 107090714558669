import { Card, Col, Row } from 'antd';
import OrganizationForm from '../components/OrganizationForm';
import AppPage from '../../layout/components/AppPage';

export default function OrganizationCreatePage() {
  return (
    <AppPage title="Create Organization">
      <div className="h-100 d-flex flex-column justify-content-center">
        <Row justify="center">
          <Col span={24} md={12} lg={10} xl={8} xxl={6}>
            <Card title="Create Organization">
              <OrganizationForm />
            </Card>
          </Col>
        </Row>
      </div>
    </AppPage>
  );
}
