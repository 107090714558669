import React from 'react';
import { DataSourceTableConfig } from '../../../api';
import AdvancedSelect, { AdvancedSelectProps } from '../../../common/components/AdvancedSelect';

interface DataSourceTableFieldSelectProps extends Omit<AdvancedSelectProps, 'options'> {
  value?: string;
  onChange: (value: string) => void;
  tableConfig: DataSourceTableConfig;
}

export default function DataSourceTableFieldSelect(props: DataSourceTableFieldSelectProps) {
  const {
    value, onChange, tableConfig, ...rest
  } = props;

  const options = Object.entries(tableConfig.schema.properties).map(([fieldName, entry]) => ({
    label: fieldName,
    value: fieldName,
    subLabel: entry.description,
  }));

  return (
    <AdvancedSelect
      value={value || undefined}
      options={options}
      onChange={onChange}
      placeholder="Select a field"
      showSearch
      {...rest}
    />
  );
}
