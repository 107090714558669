import { Organization } from '../../api';
import { getUserId } from '../../auth/utils/sync';

const ORGANIZATION_KEY = 'organization';

function getKey() {
  const userId = getUserId();
  return `${ORGANIZATION_KEY}-${userId}`;
}

export function getActiveOrganization(): Organization | null {
  const organization = localStorage.getItem(getKey());

  if (organization) {
    return JSON.parse(organization) as Organization;
  }

  return null;
}

export function setActiveOrganization(organization: Organization) {
  localStorage.setItem(getKey(), JSON.stringify(organization));
}

export function getActiveOrganizationSlug(): string | null {
  const organization = getActiveOrganization();

  if (organization) {
    return organization.slug;
  }

  return null;
}
