import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import {
  Avatar, Button, Dropdown, Layout, Menu, message,
} from 'antd';
import {
  ApiOutlined,
  AuditOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  MessageOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Link, useParams,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { globalAtom } from '../../common/state/global-state';
import { useAuth } from '../../auth';
import useRole from '../../common/hooks/useRole';
import { OrganizationUserRole } from '../../api';
import usePermissions from '../../common/hooks/usePermissions';
import OrganizationFreeTrialAlert from '../../organizations/components/OrganizationFreeTrialAlert';
import { ENABLE_ZUPPORT, IS_PRODUCTION } from '../../config';
import { LAYOUT_SIDEBAR_COLOR } from '../../config/styling';
import useOnboarding from '../../onboarding/hooks/useOnboarding';

interface AppLayoutProps {
  children: ReactNode | ReactNode[];
}

export const HEADER_HEIGHT = 64;
const FOOTER_HEIGHT = 64;
export const APP_CONTENT_SUBTRACT = HEADER_HEIGHT;

export default function AppLayout(props: AppLayoutProps) {
  const { user, logout } = useAuth();
  const params = useParams();
  const { organizationSlug, projectSlug } = params;
  const { children } = props;
  const { organization, projects } = useRecoilValue(globalAtom);
  const role = useRole();
  const permissions = usePermissions();
  const onboarding = useOnboarding();
  const url = params['*'] || '';

  const [sync, setSync] = useState(true);

  function getDefaultActiveKeys(): string[] {
    return [params['*'] || ''];
  }

  function getSelectedKeys(): string[] {
    const parts = url.split('/');

    if (organization && projectSlug) {
      if (parts.length === 2) {
        return [
          `/${url}`,
          `/${url}/dashboards`,
        ];
      }

      return [
        `/${organizationSlug}/${projectSlug}`,
        `/${url}`,
      ];
    }

    return [url];
  }

  const [defaultActiveKeys] = useState<string[]>(getDefaultActiveKeys());
  const [selectedKeys, setSelectedKeys] = useState<string[]>(getSelectedKeys());
  const [openKeys, setOpenKeys] = useState<string[]>(getSelectedKeys());

  useEffect(() => {
    if (sync) {
      setSelectedKeys(getSelectedKeys());
      setOpenKeys(getSelectedKeys());

      // Disable sync after first sync
      // and allow the user to navigate freely
      setSync(false);
    }
  }, [url, sync]);

  const menuItems: ItemType[] = [
    {
      key: '/',
      label: (
        <Link to="/">
          Home
        </Link>
      ),
      icon: <HomeOutlined />,
    },
  ];

  if (organizationSlug) {
    if (permissions.dataSources.write) {
      menuItems.push({
        key: `/${organizationSlug}/data-sources`,
        label: (
          <Link to={`/${organizationSlug}/data-sources`}>
            Data Sources
          </Link>
        ),
        icon: <DatabaseOutlined />,
      });
    }

    menuItems.push(...[
      {
        label: 'Projects',
        type: 'group',
        children: [
          ...projects.map((item) => {
            const menuItem: ItemType = {
              key: `/${organizationSlug}/${item.slug}`,
              label: (
                <span style={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {item.name}
                </span>
              ),
              icon: <Avatar shape="square" size="small">{item.name[0]}</Avatar>,
              children: [],
            };

            if (permissions.general.write) {
              menuItem.children.push({
                key: `/${organizationSlug}/${item.slug}/entities`,
                label: (
                  <Link to={`/${organizationSlug}/${item.slug}/entities`}>
                    Entities
                  </Link>
                ),
                icon: <AuditOutlined />,
              });

              menuItem.children.push({
                key: `/${organizationSlug}/${item.slug}/insight-flow`,
                label: (
                  <Link
                    to={`/${organizationSlug}/${item.slug}/insight-flow`}
                  >
                    InsightFlow
                  </Link>
                ),
                icon: <ApiOutlined />,
              });

              menuItem.children.push({
                key: `/${organizationSlug}/${item.slug}/chat`,
                label: (
                  <Link to={`/${organizationSlug}/${item.slug}/chat`}>
                    Chat
                  </Link>
                ),
                icon: <MessageOutlined />,
              });
            }

            menuItem.children.push({
              key: `/${organizationSlug}/${item.slug}/dashboards`,
              label: (
                <Link to={`/${organizationSlug}/${item.slug}`}>
                  Dashboards
                </Link>
              ),
              icon: <DashboardOutlined />,
            });

            if (permissions.projects.write) {
              menuItem.children.push({
                key: `/${organizationSlug}/${item.slug}/settings`,
                label: (
                  <Link to={`/${organizationSlug}/${item.slug}/settings`}>
                    Settings
                  </Link>
                ),
                icon: <SettingOutlined />,
              });
            }

            return menuItem;
          }),
        ],
      },
    ] as ItemType[]);

    if (permissions.projects.write) {
      menuItems.push({
        key: `/${organizationSlug}/create-project`,
        label: (
          <Link to={`/${organizationSlug}/create-project`}>
            New Project
          </Link>
        ),
        icon: <PlusOutlined />,
      });
    }
  }

  const dropdownMenuItems: ItemType[] = useMemo(() => {
    const baseItems: ItemType[] = [
      {
        key: 'create-organization',
        label: (
          <Link to="/create-organization">
            Create Organization
          </Link>
        ),
      },
    ];

    if (organization && role.is(OrganizationUserRole.OWNER, OrganizationUserRole.ADMIN)) {
      baseItems.push({ type: 'divider' });
      baseItems.push({
        key: `/${organizationSlug}/settings`,
        label: 'Organization',
        type: 'group',
        children: [
          {
            key: 'organization/settings',
            label: (
              <Link to={`/${organizationSlug}/settings`}>
                Settings
              </Link>
            ),
          },
        ],
      });
    }

    baseItems.push({ type: 'divider' });
    baseItems.push({
      key: '/user/settings',
      label: 'User',
      type: 'group',
      children: [
        {
          key: 'user/settings',
          label: (
            <Link to="/user/settings">
              Settings
            </Link>
          ),
        },
        {
          key: 'user/logout',
          label: 'Logout',
          onClick: logout,
        },
      ],
    });

    return baseItems;
  }, [Boolean(organization), role.is]);

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Layout.Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: LAYOUT_SIDEBAR_COLOR,
          borderRight: '1px solid #e8e8e8',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          zIndex: 1,
        }}
        width={220}
      >
        <div
          style={{ height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)' }}
          className="d-flex align-items-center"
        >
          {
            user && (
              <Dropdown
                menu={{
                  items: dropdownMenuItems,
                }}
              >
                <Button size="small" className="h-auto py-2" block>
                  <div className="d-flex gap-2 align-items-center">
                    <Avatar shape="square">
                      {user.name[0]}
                    </Avatar>
                    <div className="d-flex flex-column align-items-baseline">
                      {
                        organization && (
                          <p
                            className="m-0 fw-bold overflow-hidden"
                            style={{ fontSize: 12, maxWidth: 125, textOverflow: 'ellipsis' }}
                          >
                            {organization?.name}
                          </p>
                        )
                      }
                      <p
                        className="m-0 overflow-hidden"
                        style={{ fontSize: 12, maxWidth: 125, textOverflow: 'ellipsis' }}
                      >
                        {user.name}
                      </p>
                    </div>
                  </div>
                </Button>
              </Dropdown>
            )
          }
        </div>
        <div
          style={{
            maxHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
            height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
            overflow: 'auto',
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={defaultActiveKeys}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={(keys) => setOpenKeys(keys)}
            onSelect={({ selectedKeys: newSelectedKeys }) => {
              setSelectedKeys(newSelectedKeys);
            }}
            items={menuItems}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          />
          <div style={{ padding: 16 }}>
            <OrganizationFreeTrialAlert hideWhenExpired block />
          </div>
          {
            onboarding?.activeStep?.showInSidebar && (
              <div style={{ padding: '0 16px' }}>
                <Link to={onboarding.activeStep.url || '#'}>
                  <div
                    className="shadow-sm shadow-hover"
                    style={{
                      border: '1px solid #1677ff',
                      padding: '10px',
                      color: 'black',
                    }}
                  >
                    <p className="text-center mt-0 fw-bold">
                      Continue Onboarding
                    </p>
                    <p className="text-center m-0">
                      {onboarding.activeStep.title}
                    </p>
                  </div>
                </Link>
              </div>
            )
          }
        </div>
        <div
          style={{ height: FOOTER_HEIGHT }}
          className="d-flex align-items-center justify-content-center px-2"
        >
          <Button
            size="middle"
            type="default"
            onClick={() => {
              if (!ENABLE_ZUPPORT) {
                if (!IS_PRODUCTION) {
                  message.info('This feature is not available in development mode');
                }
              } else {
                window.postMessage({ message: 'zupport.chat.button.clicked' }, '*');
              }
            }}
            block
          >
            Need Help?
          </Button>
        </div>
      </Layout.Sider>
      <Layout.Content>
        {children}
      </Layout.Content>
    </Layout>
  );
}
