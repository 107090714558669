import { useState } from 'react';
import { Button, notification } from 'antd';
import InsightFlowFilters from '../../insight-flow/components/InsightFlowFilters';
import api, { DataSource, Entity, InsightFlowFilter } from '../../api';
import useQuery from '../../common/hooks/useQuery';
import useRequest from '../../common/hooks/useRequest';

interface EntityFiltersSetupProps {
  entity: Entity;
}

export default function EntityFiltersSetup(props: EntityFiltersSetupProps) {
  const { entity } = props;
  const { fields } = entity;

  const [response] = useQuery(
    async () => api.entities.find({
      query: {
        $sort: {
          createdAt: -1,
        },
        organizationId: entity.organizationId,
      },
    }),
    [entity.organizationId],
  );
  const entities: Entity[] = response?.data || [];
  const [insightFlowFilters, setInsightFlowFilters] = useState<InsightFlowFilter[]>(
    (entity.filter || []) as InsightFlowFilter[],
  );
  const [dataSource] = useQuery<DataSource>(
    async () => (entity ? api.dataSources.get(entity?.dataSourceId) : undefined),
    [entity?.dataSourceId],
  );

  const table = dataSource
    ?.schemaConfig
    .tables
    .find((item) => item.tableName === entity?.table);

  const request = useRequest(
    async () => api.entities.patch(entity._id, {
      filter: insightFlowFilters,
    }),
    {
      onSuccess: () => {
        notification.success({
          message: 'Entity filters updated',
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Failed to update entity filters',
          description: error.message,
        });
      },
    },
  );

  return (
    <div>
      <p>
        Use the following filters to customize your entity
        to match specific values in your data source.
      </p>
      {
        dataSource && (
          <InsightFlowFilters
            fields={fields}
            table={table}
            value={insightFlowFilters}
            onChange={setInsightFlowFilters}
            dataSourceId={dataSource?._id}
            entities={entities}
            dataSource={dataSource}
          />
        )
      }
      <Button
        className="mt-2"
        type="primary"
        loading={request.loading}
        onClick={request.submit}
      >
        Save
      </Button>
    </div>
  );
}
