import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import AppPage from '../../layout/components/AppPage';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useActiveProject from '../../common/hooks/useActiveProject';
import { globalAtom } from '../../common/state/global-state';
import usePermissions from '../../common/hooks/usePermissions';
import dbaqlAtom, { DEFAULT_STATE } from '../../dbaql/state';
import { LAYOUT_SIDEBAR_COLOR } from '../../config/styling';
import QueryCard from '../../queries/components/QueryCard';

export default function ProjectChatPage() {
  const organization = useActiveOrganization();
  const project = useActiveProject();
  const { dataSource } = useRecoilValue(globalAtom);
  const permissions = usePermissions();
  const setDbaqlState = useSetRecoilState(dbaqlAtom);

  useEffect(() => () => {
    setDbaqlState(DEFAULT_STATE);
  }, []);

  if (!project || !organization) {
    return null;
  }

  return (
    <AppPage
      background={LAYOUT_SIDEBAR_COLOR}
      allowed={permissions.general.write}
      fullHeight
    >
      <QueryCard
        organizationId={organization._id}
        projectId={project._id}
        hasDataSource={Boolean(dataSource)}
        dataSource={dataSource || undefined}
      />
    </AppPage>
  );
}
