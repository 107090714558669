import { RadarChartOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class RadarChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = RadarChartOutlined;

  title = 'Radar Chart';

  type = ResultRepresentationType.GRAPH;
}

export default RadarChartResultRepresentationSubTypeConfig;
