import { LayoutOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class TreeMapChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = LayoutOutlined;

  title = 'Tree Map Chart';

  type = ResultRepresentationType.GRAPH;
}

export default TreeMapChartResultRepresentationSubTypeConfig;
