import useActiveOrganization from './useActiveOrganization';
import Plans from '../../billing/utils/plans';
import { PlanName } from '../../api';

export default function useActiveOrganizationPlan() {
  const organization = useActiveOrganization();
  return Plans.getByName(
    organization?.insightBasePlan
    || organization?.appSumoPlan
    || organization?.plan
    || PlanName.FREE,
    organization || undefined,
  );
}
