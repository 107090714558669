import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class ScatterChartToTableTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.SCATTER_CHART;

  to = ResultRepresentationSubType.TABLE;

  transformData(): any {
    return this.resultRepresentation.data;
  }
}

export default ScatterChartToTableTransformer;
