import React, { useCallback, useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import debounce from 'just-debounce';
import CodeEditor from '../../common/components/CodeEditor';
import { RawCodeLanguage, ResultRepresentation } from '../../api';
import CustomCodeResultRepresentationView from './CustomCodeResultRepresentationView';
import ResultRepresentationView from './ResultRepresentationView';

const initialCode = `/**
* This is a sample code to render a custom result representation
* 
* You have maximum flexibility to render anything you want here.
* 
* There are a few global variables available to you:
* React: The React library
* Components: The components library
* Components.Button: The Button component from the components library
* data: The data object from the result representation
* renderDefault: A function to render the default result representation
*/

const { Button } = Components;

function CustomCodeResultRepresentation() {
  return (
    <div>
      <Button 
        type="primary" 
        style={{ marginBottom: 20 }}
        onClick={() => {
          // Do anything here ... :)
          alert('Feels like magic, right?');
        }}
      >
        Custom Button
      </Button>
      {renderDefault()}
    </div>
  );
}

return <CustomCodeResultRepresentation />;
`;

interface CustomCodeResultRepresentationConfiguratorProps {
  open: boolean;
  onClose: () => void;
  code?: string;
  onChange: (code: string) => void;
  resultRepresentation?: ResultRepresentation;
}

export default function CustomCodeResultRepresentationConfigurator(
  props: CustomCodeResultRepresentationConfiguratorProps,
) {
  const {
    open,
    onClose,
    resultRepresentation,
    code: propsCode,
    onChange,
  } = props;
  const [code, setCode] = useState(propsCode || initialCode);

  useEffect(() => {
    if (propsCode) {
      setCode(propsCode);
    }
  }, [propsCode]);

  const onCodeChange = useCallback(debounce(setCode, 500), []);

  return (
    <Drawer
      title={(
        <div className="d-flex align-items-center justify-content-between">
          <p className="m-0">Custom Code Representation</p>
          <Button
            type="primary"
            onClick={() => {
              onChange(code);
              onClose();
            }}
          >
            Save
          </Button>
        </div>
      )}
      open={open}
      onClose={onClose}
      width="100%"
    >
      {
        resultRepresentation && (
          <div className="d-flex h-100 gap-2">
            <div
              style={{
                flex: 1,
                border: '1px solid #eee',
                height: '100%',
                overflow: 'auto',
              }}
            >
              <CodeEditor
                language={RawCodeLanguage.JAVASCRIPT}
                value={code}
                onChange={onCodeChange}
                jsx
              />
            </div>
            <div
              style={{
                flex: 1,
                border: '1px solid #eee',
                height: 'calc(100% - 20px)',
                overflow: 'auto',
                padding: 10,
              }}
            >
              <CustomCodeResultRepresentationView
                code={code}
                resultRepresentation={resultRepresentation}
                renderDefault={() => (
                  <ResultRepresentationView
                    resultRepresentation={resultRepresentation}
                    result={resultRepresentation.data}
                  />
                )}
              />
            </div>
          </div>
        )
      }
    </Drawer>
  );
}
