import { ResultRepresentationSubType } from '../../../api';
import ResultRepresentationTransformer from './ResultRepresentationTransformer';

class PieChartToBarChartTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.PIE_CHART;

  to = ResultRepresentationSubType.BAR_CHART;

  transformData() {
    return {
      series: [
        {
          data: this.resultRepresentation.data.series,
        },
      ],
      options: {
        ...(this.resultRepresentation.data.options || {}),
        chart: {
          type: 'bar',
        },
      },
    };
  }
}

export default PieChartToBarChartTransformer;
