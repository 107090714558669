import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';
import api, { User } from '../api';
import { setUserId } from './utils/sync';

type AuthState = {
  user: User | null;
  authenticated: boolean;
  loading: boolean;
};

export const authAtom = atom<AuthState>({
  key: 'authState',
  default: {
    user: null,
    authenticated: false,
    loading: true,
  },
});

export type SignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  appSumoCode?: string;
  params?: Record<string, any>;
  inviteCode?: User['inviteCode'];
};

export function useAuth() {
  const [state, setState] = useRecoilState(authAtom);
  const setUser = (user: User | null) => {
    setState((prev) => ({
      ...prev,
      user,
    }));
  };

  const updateUser = useCallback((user: Partial<User>) => {
    setState((prev) => ({
      ...prev,
      user: prev.user ? {
        ...prev.user,
        ...user,
      } : null,
    }));

    if (state.user?._id) {
      api.users.patch(state.user?._id, { ...user });
    }
  }, [state?.user?._id]);

  const setAuthenticated = (authenticated: boolean) => {
    setState((prev) => ({
      ...prev,
      authenticated,
    }));
  };

  const init = async () => {
    try {
      const { user } = await api.auth.reAuthenticate();
      setUser(user);
      setAuthenticated(true);
    } catch (error) {
      setUser(null);
    }

    setState((prev) => ({
      ...prev,
      loading: false,
      initialized: true,
    }));
  };

  const signIn = async (data: { email: string, password: string }) => {
    const { user } = await api.auth.signIn(data);
    setUserId(user._id);
    setState((prev) => ({
      ...prev,
      user,
      authenticated: true,
    }));
  };

  const signUp = async (data: SignUpData) => {
    await api.auth.signUp(data);
    await signIn(data);
  };

  const logout = async () => {
    try {
      await api.auth.logout();
      setUser(null);
      setAuthenticated(false);
    } catch (e) {
      // Ignore
    }
  };

  return {
    ...state,
    setUser,
    init,
    logout,
    signIn,
    signUp,
    updateUser,
  };
}
