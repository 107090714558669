import {
  Button, Form, Input, notification, Select,
} from 'antd';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import api, { OrganizationUser, OrganizationUserRole } from '../../api';
import { getRoleLabel, getRolePermissionsText } from '../../permissions/utils';
import useRequest from '../../common/hooks/useRequest';
import useRole from '../../common/hooks/useRole';
import { globalAtom } from '../../common/state/global-state';
import useOrganizationRemainingUsage from '../../common/hooks/useOrganizationRemainingUsage';
import UsageReachedView from '../../billing/components/UsageReachedView';

interface OrganizationMemberFormProps {
  organizationId: OrganizationUser['organizationId'];
  user?: OrganizationUser;
  onSuccess?: (user: OrganizationUser) => void;
  onUsageReachedClick?: () => void;
}

const INITIAL_VALUES: Pick<OrganizationUser, 'email' | 'role'> = {
  email: '',
  role: OrganizationUserRole.MEMBER,
};

export default function OrganizationMemberForm(props: OrganizationMemberFormProps) {
  const {
    onSuccess,
    organizationId,
    user,
    onUsageReachedClick,
  } = props;
  const [form] = Form.useForm<Pick<OrganizationUser, 'email' | 'role' | 'projectsIds'>>();
  const { projects } = useRecoilValue(globalAtom);
  const remainingUsage = useOrganizationRemainingUsage();
  const roles = useRole();
  const request = useRequest<OrganizationUser>(
    async (values: Pick<OrganizationUser, 'email' | 'role' | 'projectsIds'>) => {
      if (!user) {
        return await api.organizationUsers.create({
          ...values,
          organizationId,
        }) as unknown as Promise<OrganizationUser>;
      }

      return await api.organizationUsers.patch(user._id, {
        role: values.role,
        projectsIds: values.projectsIds,
      }) as unknown as Promise<OrganizationUser>;
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response);
        }

        form.resetFields();
      },
      onError: (error) => {
        if (error && error.message) {
          notification.error({
            message: error.message,
            description: 'Please try again',
          });
        }
      },
    },
  );

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        role: user.role,
        projectsIds: user.projectsIds,
      });
    } else {
      form.resetFields();
    }
  }, [Boolean(user)]);

  if (!user && remainingUsage.users <= 0) {
    return (
      <UsageReachedView
        subTitle="You have reached the maximum number of members allowed in your current plan. Upgrade your plan to add more members."
        onClick={onUsageReachedClick}
      />
    );
  }

  return (
    <Form
      initialValues={INITIAL_VALUES}
      layout="vertical"
      onFinish={request.submit}
      form={form}
    >
      {
        !user && (
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'Please enter a valid email' },
              { required: true, message: 'Please enter member email' },
            ]}
          >
            <Input placeholder="Member Email" />
          </Form.Item>
        )
      }
      <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select user role' }]}>
        <Select
          placeholder="Select role"
          options={Object
            .values(OrganizationUserRole)
            .filter((role) => {
              // Hide owner role
              if (role === OrganizationUserRole.OWNER) {
                return false;
              }

              if (role === OrganizationUserRole.ADMIN) {
                return roles.is(OrganizationUserRole.OWNER);
              }

              return true;
            })
            .map((role) => ({
              value: role,
              label: (
                <div>
                  <p className="m-0">{getRoleLabel(role)}</p>
                  <p
                    className="m-0 text-muted"
                    style={{ whiteSpace: 'break-spaces', fontSize: 13 }}
                  >
                    {getRolePermissionsText(role)}
                  </p>
                </div>
              ),
              title: getRoleLabel(role),
            }))}
          optionLabelProp="title"
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {
          ({ getFieldValue }) => {
            const role = getFieldValue('role');

            if (role === OrganizationUserRole.ADMIN) {
              return null;
            }

            return (
              <Form.Item
                label="Projects"
                name="projectsIds"
              >
                <Select
                  placeholder="Select one ore more projects"
                  options={projects.map((project) => ({
                    value: project._id,
                    label: project.name,
                  }))}
                  mode="multiple"
                />
              </Form.Item>
            );
          }
        }
      </Form.Item>
      <Form.Item
        label="Message"
        name="message"
      >
        <Input.TextArea placeholder="Message to send to the user" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          loading={request.loading}
          disabled={request.loading}
          htmlType="submit"
          block
        >
          {
            user ? 'Save' : 'Invite Member'
          }
        </Button>
      </Form.Item>
    </Form>
  );
}
