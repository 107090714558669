import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppPage from '../../layout/components/AppPage';
import api, { Organization } from '../../api';

export default function AuthorizeSlackPage() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [connectedOrganization, setConnectedOrganization] = useState<Organization | null>(null);

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  useEffect(() => {
    setError(false);

    if (!code || !state) {
      setError(true);
    } else {
      api.organizations.find({
        query: {
          slug: state,
          $limit: 1,
        },
      }).then((response) => {
        const [organization] = response.data;

        if (!organization) {
          setError(true);
        } else {
          api.organizations.connectSlack({
            id: organization._id,
            code,
          }).then(setConnectedOrganization).catch(() => {
            setError(true);
          });
        }
      });
    }
  }, [code, state]);

  return (
    <AppPage title="Authorize Slack">
      {
        error && (
          <p className="text-center">
            Something went wrong while authorizing slack, please try again.
          </p>
        )
      }
      {
        connectedOrganization && (
          <p className="text-center">
            Slack has been successfully connected to
            {' '}
            {connectedOrganization.name}
            .
          </p>
        )
      }
    </AppPage>
  );
}
