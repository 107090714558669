import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import AppPage from '../../layout/components/AppPage';
import AuthPageLayout from '../../auth/layouts/AuthPageLayout';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';

export default function SubscribeThankYouPage() {
  const organization = useActiveOrganization();

  return (
    <AppPage title="Subscription Successful" contentPadding={0}>
      <AuthPageLayout
        height="calc(100vh - 64px)"
        title="Thank you for subscribing"
      >
        <h1>
          Your subscription is active now, enjoy the benefits!
        </h1>
        {
          organization && (
            <Link to={`/${organization.slug}`}>
              <Button
                type="primary"
                size="large"
              >
                Continue
              </Button>
            </Link>
          )
        }
      </AuthPageLayout>
    </AppPage>
  );
}
