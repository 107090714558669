import { AlertDefinition, AlertType, ResultRepresentationType } from '../../api';

const alertDefinitions: AlertDefinition[] = [
  {
    type: AlertType.VALUE_CHANGE,
    name: 'Value Change',
    description: 'Alert when the widget value changes by any amount',
    hasValueInput: false,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
  {
    type: AlertType.VALUE_INCREASE_ABSOLUTE,
    name: 'Value Increase (Absolute)',
    description: 'Alert when the widget value increases by a specified amount',
    hasValueInput: true,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
  {
    type: AlertType.VALUE_INCREASE_PERCENT,
    name: 'Value Increase (Percent)',
    description: 'Alert when the widget value increases by a specified percentage',
    hasValueInput: true,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
  {
    type: AlertType.VALUE_DECREASE_ABSOLUTE,
    name: 'Value Decrease (Absolute)',
    description: 'Alert when the widget value decreases by a specified amount',
    hasValueInput: true,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
  {
    type: AlertType.VALUE_DECREASE_PERCENT,
    name: 'Value Decrease (Percent)',
    description: 'Alert when the widget value decreases by a specified percentage',
    hasValueInput: true,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
  {
    type: AlertType.VALUE_RAISES_ABOVE,
    name: 'Value Raises Above',
    description: 'Alert when the widget value raises above a specified value',
    hasValueInput: true,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
  {
    type: AlertType.VALUE_FALLS_BELOW,
    name: 'Value Falls Below',
    description: 'Alert when the widget value falls below a specified value',
    hasValueInput: true,
    compatibleValueTypes: [ResultRepresentationType.NUMBER],
  },
];

export default alertDefinitions;
