import { Button, Modal, Tooltip } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';

interface DBAQLInfoButtonProps {
  shortVersion?: boolean;
  iconOnly?: boolean;
}

export default function DBAQLInfoButton(props: DBAQLInfoButtonProps) {
  const { shortVersion, iconOnly } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip
        title="This result was calculated using DBAQL (click for more info)"
        trigger="hover"
      >
        <Button
          icon={<CheckCircleOutlined />}
          className="px-0"
          onClick={() => setOpen(true)}
          type="link"
        >
          {!shortVersion ? 'This response has a ' : ''}
          {!iconOnly ? 'High Accuracy' : ''}
          {!shortVersion ? ' (DBAQL)' : ''}
        </Button>
      </Tooltip>
      <Modal
        title="This response has a High Accuracy (DBAQL)"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
      >
        <p>
          This response has a
          {' '}
          <b>high accuracy</b>
          {' '}
          because it was calculated using DBAQL,
          which delivers the most accurate results.
        </p>
        <p>
          DBAQL stands for
          {' '}
          <b>Database Agnostic Query Language</b>
          .
        </p>
        <p>
          It is a query language developed by InsightBase that allows you to perform aggregations
          on data stored in the database, no matter the database type,
          {' '}
          with a high level of accuracy.
        </p>
      </Modal>
    </>
  );
}
