import {
  AutoComplete, AutoCompleteProps, Button, Input, Tag,
} from 'antd';
import {
  Dispatch,
  ReactNode, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { SendOutlined } from '@ant-design/icons';
import { DataSource, ParameterData } from '../../api';

interface DataSourceAutocompleteInputProps extends Omit<AutoCompleteProps, 'dataSource' | 'onChange' | 'filterOption' | 'options' | 'placeholder'> {
  onChange: Dispatch<SetStateAction<string>>;
  loading?: boolean;
  dataSource?: Pick<DataSource, '_id' | 'schemaConfig'> | null;
  onSubmit?: () => void;
  placeholder?: string;
  hideRunButton?: boolean;
  parameters?: ParameterData[];
}

type Option = {
  value: string;
  replaceValue: string;
  label: ReactNode;
  options?: Option[];
};

export default function DataSourceAutocompleteInput(props: DataSourceAutocompleteInputProps) {
  const {
    dataSource,
    value: prompt,
    onChange,
    onSubmit,
    placeholder,
    loading,
    disabled,
    hideRunButton,
    parameters,
    style = {},
    ...rest
  } = props;

  const [autocompleteOptions, setAutocompleteOptions] = useState<Option[]>([]);
  const [open, setOpen] = useState(false);

  function getAutocompleteOptions() {
    if (!prompt.trim()) {
      return [];
    }

    const renderTable = (title: string) => (
      <Button
        type="default"
        className="d-flex justify-content-between"
        block
        onClick={() => {
          if (onChange) {
            onChange((prev) => {
              const promptWords = prev.split(' ');
              promptWords.pop();
              return `${promptWords.join(' ')} ${title} `;
            });
          }
        }}
      >
        {title}
        <span style={{ float: 'right' }}>
          table
        </span>
      </Button>
    );

    const renderColumn = ({ name, type }: { name: string; type: string; }) => ({
      value: name,
      replaceValue: name,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {name}
          <Tag>
            {type}
          </Tag>
        </div>
      ),
    });

    const options: Option[] = [];

    if (dataSource) {
      dataSource.schemaConfig.tables.forEach((table) => {
        if (table.included) {
          options.push({
            value: table.tableName,
            replaceValue: table.tableName,
            label: renderTable(table.displayTableName || table.tableName),
            options: Object.keys((table.schema.properties))
              .map((columnName) => {
                const column = table.schema.properties[columnName];

                const option = renderColumn({ name: columnName, type: column.type });
                option.value = `${table.displayTableName || table.tableName}.${option.value}`;

                return option;
              }),
          });
        }
      });
    }

    if (parameters) {
      parameters.forEach((parameter) => {
        options.push({
          value: `{{${parameter.name}}}`,
          replaceValue: `{{${parameter.name}}}`,
          label: `${parameter.name} (Parameter)`,
        });
      });
    }

    return options;
  }

  const filterOption = useCallback((inputValue: string, option?: Option) => {
    const searchString = (inputValue.split(' ').pop() || '').trim().toLowerCase();

    if (
      (!searchString || searchString.length <= 1 || !option || !option.value)
      && !searchString.startsWith('{')
    ) {
      return false;
    }

    if (!option) {
      return false;
    }

    const label = option.value.toLowerCase();

    return label.includes(searchString);
  }, []);

  useEffect(() => {
    if (dataSource) {
      setAutocompleteOptions(getAutocompleteOptions());
    }
  }, [dataSource?._id, !!prompt.trim(), parameters]);

  let drawerOpen = open;

  if (drawerOpen) {
    drawerOpen = autocompleteOptions.some((option) => filterOption(prompt, option));
  }

  return (
    <div className="position-relative">
      <AutoComplete
        size="large"
        value={prompt}
        onChange={onChange}
        style={{
          width: '100%',
          border: 'none',
        }}
        options={autocompleteOptions}
        filterOption={filterOption}
        onSelect={(v, option) => {
          const { replaceValue } = option as Option;
          // Remove last word from prompt
          const promptWords = prompt.split(' ');
          promptWords.pop();
          const newPrompt = promptWords.join(' ');
          if (onChange) {
            onChange(`${newPrompt} ${replaceValue} `);
          }
        }}
        defaultOpen={false}
        disabled={disabled || loading}
        open={open}
        onDropdownVisibleChange={(value) => {
          setOpen(value);
        }}
        {...rest}
      >
        <Input.TextArea
          autoSize
          size="large"
          placeholder={placeholder}
          onPressEnter={(e) => {
            // If the drawer is open then we don't want to submit the form
            // but instead just close the drawer so the user can select an option
            if (onSubmit && !drawerOpen) {
              onSubmit();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          style={{
            paddingRight: 60,
            ...style,
          }}
          disabled={disabled || loading}
        />
      </AutoComplete>
      {
        !hideRunButton && (
          <Button
            style={{
              position: 'absolute',
              right: 5,
              bottom: 5,
              minWidth: 46,
            }}
            onClick={() => {
              if (onSubmit) {
                onSubmit();
              }
            }}
            type="primary"
            disabled={loading || !prompt.trim()}
            loading={loading}
          >
            { !loading && <SendOutlined /> }
          </Button>
        )
      }
    </div>
  );
}
