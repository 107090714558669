import useActiveOrganization from './useActiveOrganization';
import { Organization } from '../../api';
import useActiveOrganizationPlan from './useActiveOrganizationPlan';

export default function useOrganizationRemainingUsage(): Organization['usage'] {
  const organization = useActiveOrganization();
  const plan = useActiveOrganizationPlan();

  if (!organization) {
    return {
      projects: 0,
      dashboards: 0,
      widgets: 0,
      users: 0,
    };
  }

  return {
    projects: plan.maxProjects - organization.usage.projects,
    dashboards: plan.maxDashboards - organization.usage.dashboards,
    widgets: plan.maxWidgets - organization.usage.widgets,
    users: plan.maxUsers - organization.usage.users,
  };
}
