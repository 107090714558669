import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IS_SELF_HOSTED,
  REACT_APP_ALLOWED_ORIGINS,
  SKIP_FRONTEND_ORIGIN_VERIFICATION,
  REACT_APP_VERIFY_BACKEND_ORIGIN,
} from '../../config';
import api from '../../api';
import ErrorCode from '../../api/definitions/ErrorCode';
import retry from '../../common/utils/functions';

export default function useStatusCheck() {
  const navigate = useNavigate();

  useEffect(() => {
    if (IS_SELF_HOSTED) {
      const origin = window.location.origin.replace('www.', '');

      if (!SKIP_FRONTEND_ORIGIN_VERIFICATION && !REACT_APP_ALLOWED_ORIGINS.includes(origin)) {
        navigate(`/error?code=${ErrorCode.SELF_HOST_INVALID_ORIGIN}`);
      } else if (REACT_APP_VERIFY_BACKEND_ORIGIN) {
        retry(
          () => api.security.statusCheck(),
          5,
          5000,
        ).then((response) => {
          if (response.invalidOrigin) {
            navigate(`/error?code=${response.errorCode || 'unknown'}`);
          }
        }).catch(() => {
          navigate(`/error?code=${ErrorCode.SELF_HOST_ORIGIN_VALIDATION_ERROR}`);
        });
      }
    }
  }, []);
}
