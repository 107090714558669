import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import dbaqlAtom, { DbaqlBuilderState } from '../state';

export type DbaqlBuilderStateUpdater<T extends keyof DbaqlBuilderState> = (
  value: ((prev: DbaqlBuilderState[T]) => DbaqlBuilderState[T]) | DbaqlBuilderState[T],
) => void;

type UseDbaqlBuilderState = {
  state: DbaqlBuilderState;
  setState: (state: Partial<DbaqlBuilderState>) => void;
  createChangeHandler: <T extends keyof DbaqlBuilderState>(key: T) => DbaqlBuilderStateUpdater<T>;
};

export default function useDbaqlBuilderState(): UseDbaqlBuilderState {
  const [state, setState] = useRecoilState(dbaqlAtom);

  const setStateCustom = useCallback((value: Partial<DbaqlBuilderState>) => {
    setState((prev) => ({
      ...prev,
      ...value,
    }));
  }, []);

  const createChangeHandler = useCallback(<T extends keyof DbaqlBuilderState>(key: T) => (
    value: ((prev: DbaqlBuilderState[T]) => DbaqlBuilderState[T]) | DbaqlBuilderState[T],
  ) => {
    if (typeof value !== 'function') {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [key]: value(prev[key]),
      }));
    }
  }, []);

  return {
    state,
    setState: setStateCustom,
    createChangeHandler,
  };
}
