import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import api, { Parameter } from '../../api';

export default function useDashboardParameters(dashboardId?: Parameter['dashboardId']): [{ loading: boolean; parameters: Parameter[] }, React.Dispatch<React.SetStateAction<Parameter[]>>] {
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadParameters = async () => {
      setLoading(true);

      try {
        if (dashboardId) {
          const response = await api.parameters.find({
            query: {
              dashboardId,
            },
          });
          setParameters(response.data);
        }
      } catch {
        notification.error({
          message: 'Failed to load parameters',
        });
      } finally {
        setLoading(false);
      }
    };

    loadParameters();
  }, [dashboardId]);

  return [{ parameters, loading }, setParameters];
}
