import useOnboardingSteps from './useOnboardingSteps';
import { OnboardingStepKey } from '../../api';
import { OnboardingStep } from '../utils/onboarding';
import { useAuth } from '../../auth';

type UseOnboarding = {
  isActiveStep: (key: OnboardingStepKey) => boolean;
  getNextStep: () => OnboardingStep | undefined;
  goToNextStep: () => OnboardingStep | undefined;
  activeStep?: OnboardingStep;
  nextStep?: OnboardingStep;
};

export default function useOnboarding(): UseOnboarding {
  const { user, updateUser } = useAuth();
  const steps = useOnboardingSteps();
  const activeStepKey = user?.onboardingStepKey;
  const activeStep = steps.find((step) => step.key === activeStepKey);
  const getNextStep = () => {
    const currentIndex = steps.findIndex((step) => step.key === activeStepKey);
    return steps[currentIndex + 1];
  };
  const nextStep = getNextStep();

  return {
    isActiveStep: (key: OnboardingStepKey) => key === activeStepKey,
    activeStep,
    getNextStep,
    nextStep,
    goToNextStep: () => {
      if (nextStep) {
        updateUser({
          onboardingStepKey: nextStep.key,
        });
      }

      return nextStep;
    },
  };
}
