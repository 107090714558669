import { useState } from 'react';
import { Button, InputNumber, message } from 'antd';
import { EntityField, InsightFlowFilter } from '../../../api';
import SelectWithContent from '../../../common/components/SelectWithContent';

enum NumberFilterType {
  EQUALS = 'equals',
  IS_NOT = 'is-not',
  MORE_THAN = 'more-than',
  LESS_THAN = 'less-than',
}

interface NumberColumnFilterProps {
  field: EntityField;
  onAddFilter: (filter: InsightFlowFilter) => void;
  columnName: string;
}

export default function NumberColumnFilter(props: NumberColumnFilterProps) {
  const { onAddFilter, columnName, field } = props;

  const [value, setValue] = useState<number | undefined | null>(undefined);
  const [mode, setMode] = useState<NumberFilterType>();
  const { displayName } = field;

  const onApply = () => {
    if (onAddFilter) {
      if (value === null || value === undefined) {
        message.error('Please enter a number');
      } else if (mode === NumberFilterType.MORE_THAN) {
        onAddFilter({
          label: `${displayName} is more than ${value}`,
          filter: {
            $gt: value,
          },
          filterFieldName: columnName,
          field,
        });
      } else if (mode === NumberFilterType.LESS_THAN) {
        onAddFilter({
          label: `${displayName} is less than ${value}`,
          filter: {
            $lt: value,
          },
          filterFieldName: columnName,
          field,
        });
      } else if (mode === NumberFilterType.EQUALS) {
        onAddFilter({
          label: `${displayName} equals ${value}`,
          filter: value,
          filterFieldName: columnName,
          field,
        });
      } else if (mode === NumberFilterType.IS_NOT) {
        onAddFilter({
          label: `${displayName} is not ${value}`,
          filter: {
            $ne: value,
          },
          filterFieldName: columnName,
          field,
        });
      }
    }
  };

  return (
    <SelectWithContent
      onChange={setMode}
      items={[
        {
          value: NumberFilterType.EQUALS,
          label: 'Equals',
          content: (
            <InputNumber
              type="number"
              value={value}
              onChange={setValue}
              style={{
                minWidth: 200,
              }}
              placeholder="Enter a number"
            />
          ),
        },
        {
          value: NumberFilterType.IS_NOT,
          label: 'Is not',
          content: (
            <InputNumber
              type="number"
              value={value}
              onChange={setValue}
              style={{
                minWidth: 200,
              }}
              placeholder="Enter a number"
            />
          ),
        },
        {
          value: NumberFilterType.MORE_THAN,
          label: 'Is more than',
          content: (
            <InputNumber
              type="number"
              value={value}
              onChange={setValue}
              style={{
                minWidth: 200,
              }}
              placeholder="Enter a number"
            />
          ),
        },
        {
          value: NumberFilterType.LESS_THAN,
          label: 'Is less than',
          content: (
            <InputNumber
              type="number"
              value={value}
              onChange={setValue}
              style={{
                minWidth: 200,
              }}
              placeholder="Enter a number"
            />
          ),
        },
      ]}
      contentFooter={(
        <div className="d-flex justify-content-end">
          <Button
            size="small"
            type="primary"
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      )}
    />
  );
}
