import { useEffect, useState } from 'react';

function getFromLocalStorage(key: string, defaultValue?: unknown) {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return defaultValue;
}

function saveToLocalStorage(key: string, value: any) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export default function useLocalStorageState(key: string, defaultValue?: unknown) {
  const [value, setValue] = useState(getFromLocalStorage(key, defaultValue));

  useEffect(() => {
    saveToLocalStorage(key, value);
  }, [key, value]);

  return [
    value,
    setValue,
  ];
}
