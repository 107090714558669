import { Switch } from 'antd';
import { ParameterData } from '../../api';
import ParameterValueInput from './ParameterValueInput';

interface QueryExecutionParametersEditorProps {
  parameters: ParameterData[];
  onChange: (parameters: ParameterData[]) => void;
}
export default function QueryExecutionParametersEditor(props: QueryExecutionParametersEditorProps) {
  const { parameters, onChange } = props;

  return (
    <div>
      {
        parameters.map((parameter, index) => (
          <div key={`${parameter.name}-${parameter.type}`} className="mb-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <p className="m-0">{parameter.name}</p>
              {
                parameter.templateId && (
                  <div>
                    <Switch
                      size="small"
                      value={parameter.overwriteTemplateValue}
                      onChange={(checked) => {
                        const newParameters = [...parameters];
                        newParameters[index].overwriteTemplateValue = checked;
                        onChange(newParameters);
                      }}
                    />
                    {' '}
                    overwrite global value
                  </div>
                )
              }
            </div>
            <ParameterValueInput
              parameter={parameter}
              disabled={Boolean(parameter.templateId && !parameter.overwriteTemplateValue)}
              value={parameter.value}
              onChange={(newValue) => {
                const newParameters = [...parameters];
                newParameters[index].value = newValue;
                onChange(newParameters);
              }}
            />
          </div>
        ))
      }
    </div>
  );
}
