import Model from './Model';
import ObjectId from '../definitions/ObjectId';

export enum UserPreferenceType {
  DASHBOARD = 'dashboard',
  LAYOUT = 'layout',
}

export default interface UserPreferences extends Model {
  type: UserPreferenceType;
  userId: ObjectId;
  organizationId: ObjectId;
  projectId?: ObjectId;
  dashboardId?: ObjectId;
  value?: any;
}
