/* eslint-disable no-template-curly-in-string */
import CodeMirror, { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import { javascriptLanguage, jsxLanguage, localCompletionSource } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { sql, SQLConfig } from '@codemirror/lang-sql';
import { githubLight } from '@uiw/codemirror-theme-github';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Completion, ifNotIn, completeFromList, snippetCompletion as snip,
} from '@codemirror/autocomplete';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LanguageSupport } from '@codemirror/language';
import { RawCodeLanguage } from '../../../api';

export interface CodeEditorProps extends ReactCodeMirrorProps {
  language: RawCodeLanguage;
  sqlConfig?: SQLConfig;
  extraCompletions?: Completion[];
  jsx?: boolean;
}

const kwCompletion = (name: string) => ({ label: name, type: 'keyword' });

const snippets: readonly Completion[] = [
  snip('function ${name}(${params}) {\n\t${}\n}', {
    label: 'function',
    detail: 'definition',
    type: 'keyword',
  }),
  snip('for (let ${index} = 0; ${index} < ${bound}; ${index}++) {\n\t${}\n}', {
    label: 'for',
    detail: 'loop',
    type: 'keyword',
  }),
  snip('for (let ${name} of ${collection}) {\n\t${}\n}', {
    label: 'for',
    detail: 'of loop',
    type: 'keyword',
  }),
  snip('do {\n\t${}\n} while (${})', {
    label: 'do',
    detail: 'loop',
    type: 'keyword',
  }),
  snip('while (${}) {\n\t${}\n}', {
    label: 'while',
    detail: 'loop',
    type: 'keyword',
  }),
  snip('try {\n\t${}\n} catch (${error}) {\n\t${}\n}', {
    label: 'try',
    detail: '/ catch block',
    type: 'keyword',
  }),
  snip('if (${}) {\n\t${}\n}', {
    label: 'if',
    detail: 'block',
    type: 'keyword',
  }),
  snip('if (${}) {\n\t${}\n} else {\n\t${}\n}', {
    label: 'if',
    detail: '/ else block',
    type: 'keyword',
  }),
  snip('class ${name} {\n\tconstructor(${params}) {\n\t\t${}\n\t}\n}', {
    label: 'class',
    detail: 'definition',
    type: 'keyword',
  }),
  snip('import {${names}} from "${module}"\n${}', {
    label: 'import',
    detail: 'named',
    type: 'keyword',
  }),
  snip('import ${name} from "${module}"\n${}', {
    label: 'import',
    detail: 'default',
    type: 'keyword',
  }),
];

const dontComplete = [
  'TemplateString', 'String', 'RegExp',
  'LineComment', 'BlockComment',
  'VariableDefinition', 'TypeDefinition', 'Label',
  'PropertyDefinition', 'PropertyName',
  'PrivatePropertyDefinition', 'PrivatePropertyName',
  '.', '?.',
];

const keywords = 'break case const continue default delete export extends false finally in instanceof let new return static super switch this throw true typeof var yield'
  .split(' ')
  .map(kwCompletion);

function javascript(extraCompletions: Completion[] = [], jsx?: boolean) {
  return new LanguageSupport(jsx ? jsxLanguage : javascriptLanguage, [
    javascriptLanguage.data.of({
      autocomplete: ifNotIn(
        dontComplete,
        completeFromList([...snippets, ...keywords, ...extraCompletions]),
      ),
    }),
    javascriptLanguage.data.of({
      autocomplete: localCompletionSource,
    }),
  ]);
}

export default function CodeEditor(props: CodeEditorProps) {
  const {
    language,
    value,
    onChange,
    sqlConfig,
    height,
    extraCompletions,
    jsx,
  } = props;

  const [extensions, setExtensions] = useState<ReactCodeMirrorProps['extensions']>([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (language === RawCodeLanguage.JAVASCRIPT) {
      setExtensions([javascript(extraCompletions, jsx)]);
    } else if (language === RawCodeLanguage.JSON) {
      setExtensions([json()]);
    } else {
      setExtensions([sql(sqlConfig)]);
    }
    setInitialized(true);
  }, [sqlConfig, language, JSON.stringify(extraCompletions)]);

  if (!initialized) {
    return null;
  }

  return (
    <div className="ib-cm-editor">
      <CodeMirror
        key={language}
        extensions={extensions}
        value={value}
        onChange={onChange}
        theme={githubLight}
        height={height}
      />
      <style>
        {`
        .cm-editor.cm-focused {
          outline: none;
        }
        .ib-cm-editor .cm-tooltip-autocomplete.cm-tooltip.cm-tooltip-below {
          border-radius: 4px;
          background-color: rgb(245, 245, 245);
          overflow: hidden;
        }
        .ib-cm-editor .cm-tooltip-autocomplete.cm-tooltip.cm-tooltip-below ul li:not([aria-selected="true"]):hover {
          background-color: #e6f3ff;
        }
        .ib-cm-editor .cm-tooltip-autocomplete.cm-tooltip.cm-tooltip-below ul li {
          padding: 4px 3px;
          text-decoration: none;
        }
      `}
      </style>
    </div>
  );
}
