import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge, Button, Drawer, Dropdown, message, Modal, notification, Space, Switch, Tour,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  ApartmentOutlined, DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined,
} from '@ant-design/icons';
import type { ItemType } from 'antd/es/menu/hooks/useItems';
import useQuery from '../../common/hooks/useQuery';
import api, { Dashboard, OnboardingStepKey, Parameter } from '../../api';
import Spin from '../../common/components/Spin';
import { globalAtom } from '../../common/state/global-state';
import DashboardWidgets from '../../dashboards/components/DashboardWidgets';
import useModal from '../../common/hooks/useModal';
import DashboardForm from '../../dashboards/components/DashboardForm';
import AppTabbedPage from '../../layout/components/AppTabbedPage';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useOrganizationRemainingUsage from '../../common/hooks/useOrganizationRemainingUsage';
import useRefreshActiveOrganization from '../../common/hooks/useRefreshActiveOrganization';
import ParametersManagement from '../../parameters/components/ParametersManagement';
import DashboardParameterChangeEvent from '../../parameters/utils/DashboardParameterChangeEvent';
import useRequest from '../../common/hooks/useRequest';
import useUserLayoutPreferences from '../../common/hooks/useUserLayoutPreferences';
import usePermissions from '../../common/hooks/usePermissions';
import useOnboarding from '../../onboarding/hooks/useOnboarding';

export default function ProjectPage() {
  const [activeKey, setActiveKey] = useState<string>('');
  const setGlobalState = useSetRecoilState(globalAtom);
  const organization = useActiveOrganization();
  const [editDashboard, setEditDashboard] = useState<Dashboard | null>(null);
  const [openNewWidgetModalKey, setOpenNewWidgetModalKey] = useState<number | null>(null);
  const remainingUsage = useOrganizationRemainingUsage();
  const refreshActiveOrganization = useRefreshActiveOrganization();
  const navigate = useNavigate();
  const [parametersOpen, setParametersOpen] = useState<boolean>(false);
  const [parametersCount, setParametersCount] = useState<number>(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const permissions = usePermissions();

  const modal = useModal();
  const { projectSlug } = useParams();
  const { layoutPreferences, setLayoutPreferences } = useUserLayoutPreferences();

  useEffect(() => {
    setOpenNewWidgetModalKey(null);
  }, [activeKey]);

  const [project, loading] = useQuery(async () => {
    const response = await api.projects.find({
      query: {
        slug: projectSlug,
        organizationId: organization?._id,
        $limit: 1,
      },
    });

    setGlobalState((previous) => ({
      ...previous,
      project: response.data[0] || null,
      loadingActiveProject: false,
    }));

    return response.data[0];
  }, [projectSlug]);

  const [dashboards, loadingDashboards, setDashboards] = useQuery(async () => {
    if (!project?._id) {
      return null;
    }

    const response = await api.dashboards.find({
      query: {
        projectId: project?._id,
        $limit: 100,
      },
    });

    setActiveKey(response.data[0]?._id || '');

    return response.data;
  }, [project?._id]);

  const deleteRequest = useRequest(async () => {
    if (activeKey) {
      return api.dashboards.remove(activeKey);
    }

    throw new Error('No active dashboard');
  }, {
    onSuccess: () => {
      refreshActiveOrganization();
      setDashboards((previous) => {
        if (!previous) {
          return null;
        }

        return previous.filter((item) => item._id !== activeKey);
      });
      setActiveKey(dashboards?.[0]?._id || '');
      setDeleteModalOpen(false);
    },
  });

  const onboarding = useOnboarding();

  const [showTour, setShowTour] = useState(false);
  const [tourEnabled, setTourEnabled] = useState(false);

  useEffect(() => {
    if (onboarding?.activeStep?.key === OnboardingStepKey.VISIT_DASHBOARD) {
      setTourEnabled(true);
      setShowTour(true);
    }
  }, [onboarding?.activeStep?.key]);

  const sharedLayoutRef = useRef(null);
  const createWidgetButton = useRef(null);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spin />
      </div>
    );
  }

  if (!project) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <h1>Project not found</h1>
      </div>
    );
  }

  const onChange = (newActiveKey: string) => {
    setOpenNewWidgetModalKey(null);
    setActiveKey(newActiveKey);
  };

  const onParameterChange = (parameter: Parameter) => {
    DashboardParameterChangeEvent.emit(parameter);
  };

  const moreItems: ItemType[] = [
    {
      key: 'new',
      label: 'New Dashboard',
      icon: <PlusOutlined />,
      onClick: () => {
        if (remainingUsage.dashboards > 0) {
          modal.open();
          setOpenNewWidgetModalKey(null);
        } else {
          notification.error({
            message: 'You have reached your dashboards limit',
            description: 'Please upgrade your plan to create more dashboards',
            btn: (
              <Button
                onClick={() => {
                  navigate(`/${organization?.slug}/settings`);
                }}
              >
                Upgrade Plan
              </Button>
            ),
          });
        }
      },
    },
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: () => {
        const dashboard = dashboards?.find((item) => item._id === activeKey);
        if (dashboard) {
          modal.open();
          setEditDashboard(dashboard);
        }
      },
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />,
      onClick: () => {
        setDeleteModalOpen(true);
      },
      danger: true,
    },
  ];

  return (
    <AppTabbedPage
      title="Dashboards"
      onChange={onChange}
      activeKey={activeKey}
      loading={loadingDashboards}
      actionButtons={layoutPreferences && (
        <div
          ref={sharedLayoutRef}
          className="d-flex align-items-center justify-content-between gap-2"
        >
          <span>
            Shared Layout
          </span>
          <Switch
            size="small"
            checked={layoutPreferences?.value?.useSharedLayout}
            onChange={(checked) => {
              setLayoutPreferences({
                value: {
                  ...layoutPreferences.value,
                  useSharedLayout: checked,
                },
              });
              message.success(checked ? 'Shared layout enabled' : 'Custom layout enabled');
            }}
          />
        </div>
      )}
      items={dashboards ? dashboards.map((dashboard) => ({
        key: dashboard._id,
        label: dashboard.name,
        closable: false,
        children: (
          <DashboardWidgets
            dashboardId={dashboard._id}
            openNewWidgetModalKey={activeKey === dashboard._id ? openNewWidgetModalKey : null}
            defaultGridLayout={dashboard.defaultGridLayout}
            projectId={project._id}
            useSharedLayout={layoutPreferences?.value?.useSharedLayout}
            gridLayout={dashboard.gridLayout}
            onDashboardChange={({ _id, ...newDashboard }) => {
              setDashboards((previous) => {
                if (!previous) {
                  return null;
                }

                return previous.map((item) => {
                  if (item._id === _id) {
                    return {
                      ...item,
                      ...newDashboard,
                    };
                  }

                  return item;
                });
              });
              api.dashboards.patch(dashboard._id, newDashboard);
            }}
            organizationId={dashboard.organizationId}
            slackEnabled={organization?.integrations?.slack?.enabled}
          />
        ),
      })) : []}
      tabsProps={{
        tabBarExtraContent: {
          right: (
            <div className="d-flex align-items-center gap-2">
              <Button
                icon={<ApartmentOutlined />}
                onClick={() => setParametersOpen(true)}
              >
                Parameters
                {
                  parametersCount > 0 && (
                    <Badge count={parametersCount} size="small" color="green" className="ms-2" />
                  )
                }
              </Button>

              {
                permissions.widgets.write && (
                  <Button
                    ref={createWidgetButton}
                    onClick={() => {
                      if (remainingUsage.widgets > 0) {
                        setOpenNewWidgetModalKey(Date.now());
                      } else {
                        notification.error({
                          message: 'You have reached your widgets limit',
                          description: 'Please upgrade your plan to create more widgets',
                          btn: (
                            <Button
                              onClick={() => {
                                navigate(`/${organization?.slug}/settings`);
                              }}
                            >
                              Upgrade Plan
                            </Button>
                          ),
                        });
                      }
                    }}
                    icon={<PlusOutlined />}
                  >
                    Widget
                  </Button>
                )
              }

              {
                permissions.dashboards.write && (
                  <Dropdown
                    menu={{
                      items: moreItems,
                    }}
                  >
                    <Button>
                      <Space>
                        More
                        <MoreOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                )
              }
            </div>
          ),
        },
      }}
    >
      <Drawer
        title={editDashboard ? 'Edit Dashboard' : 'New Dashboard'}
        {...modal.props}
      >
        <DashboardForm
          projectId={project._id}
          onSuccess={(dashboard) => {
            modal.close();
            refreshActiveOrganization();
            setDashboards((previous) => {
              if (!previous) {
                return [dashboard];
              }

              if (editDashboard) {
                return previous.map((item) => {
                  if (item._id === dashboard._id) {
                    return dashboard;
                  }

                  return item;
                });
              }

              return [...previous, dashboard];
            });
            setActiveKey(dashboard._id);
            setEditDashboard(null);
          }}
          dashboard={editDashboard || undefined}
        />
      </Drawer>
      <Drawer
        title="Parameters"
        open={parametersOpen}
        onClose={() => setParametersOpen(false)}
        width={600}
        forceRender
      >
        {
          activeKey && (
            <ParametersManagement
              dashboardId={activeKey}
              onParameterChange={onParameterChange}
              onCountChange={setParametersCount}
            />
          )
        }
      </Drawer>
      <Modal
        title="Delete Dashboard"
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        okText="Delete"
        onOk={deleteRequest.submit}
        okButtonProps={{
          danger: true,
          loading: deleteRequest.loading,
        }}
      >
        This action cannot be undone. All the widgets in this dashboard will be deleted.
      </Modal>
      {
        tourEnabled && (
          <Tour
            steps={[
              {
                title: 'Explore your Dashboards',
                description: 'Dashboards are a collection of widgets that help you visualize your data. You can create multiple dashboards to organize your widgets.',
              },
              {
                title: 'Shared Layout',
                description: 'By default, all dashboards use a shared layout among all the users. Toggle this switch off to use your own layout. You can toggle this on and off at any time.',
                target: () => sharedLayoutRef.current,
              },
              {
                title: 'Create a Widget',
                description: 'You can create a new widget by clicking on the "Widget" button',
                target: () => createWidgetButton.current,
              },
              {
                title: 'Finish',
                description: 'You have completed the tour, and you are ready to explore your data, congratulations!',
              },
            ]}
            open={showTour}
            onClose={() => {
              const nextStep = onboarding.goToNextStep();

              if (nextStep?.url) {
                navigate(nextStep.url);
              }

              setShowTour(false);
            }}
          />
        )
      }
    </AppTabbedPage>
  );
}
