import {
  Alert, Button, Form, Input, notification,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import api, { DataSource, DataSourceTerm } from '../../api';
import useRequest from '../../common/hooks/useRequest';

interface DataSourceTermsEditorProps {
  id: DataSource['_id'];
  value: DataSourceTerm[];
  onChange: (terms: DataSourceTerm[]) => void;
}

export default function DataSourceTermsEditor(props: DataSourceTermsEditorProps) {
  const { id, value, onChange } = props;
  const [form] = Form.useForm();

  const request = useRequest<DataSource>(
    // @ts-ignore
    async (values: Pick<DataSource, 'terms'>) => api.dataSources.patch(id, values),
    {
      onSuccess: (dataSource) => {
        notification.success({
          message: 'Data source terms updated',
        });

        if (onChange) {
          onChange(dataSource.terms || []);
        }
      },
    },
  );

  return (
    <div>
      <Alert
        message="Define a list of terms and their definitions to be used in the data source."
        type="info"
      />
      <Form
        form={form}
        className="mt-4"
        onFinish={request.submit}
        initialValues={{ terms: value }}
        preserve
      >
        <Form.Item shouldUpdate>
          {
            ({ getFieldValue }) => {
              const terms = getFieldValue('terms') as DataSourceTerm[];

              return (
                <>
                  {
                    !terms.length && (
                      <p className="fw-bold text-center">
                        No terms defined yet.
                      </p>
                    )
                  }
                  {
                    terms.map((term, index) => (
                      <div className="d-flex gap-2" key={term.id}>
                        <Form.Item
                          name={['terms', index, 'id']}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          name={['terms', index, 'term']}
                          rules={[{ required: true, message: 'Please enter the term' }]}
                          style={{ flex: 1 }}
                        >
                          <Input placeholder="Term" />
                        </Form.Item>
                        <Form.Item
                          name={['terms', index, 'definition']}
                          rules={[{ required: true, message: 'Please enter the definition' }]}
                          style={{ flex: 2 }}
                        >
                          <Input.TextArea
                            placeholder="Definition"
                            autoSize={{ minRows: 1, maxRows: 5 }}
                          />
                        </Form.Item>
                        <Button
                          type="default"
                          danger
                          onClick={() => {
                            const newTerms = [...terms];
                            newTerms.splice(index, 1);
                            form.setFieldsValue({ terms: newTerms });
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    ))
                  }
                </>
              );
            }
          }
        </Form.Item>
        <div className="d-flex justify-content-between mt-4">
          <Form.Item noStyle>
            <Button
              htmlType="submit"
              type="primary"
              loading={request.loading}
            >
              Save
            </Button>
          </Form.Item>
          <Button
            type="default"
            onClick={() => {
              const newTerms = [...form.getFieldValue('terms') as DataSourceTerm[]];
              newTerms.push({ id: String(Date.now()), term: '', definition: '' });
              form.setFieldsValue({ terms: newTerms });
            }}
          >
            Add Term
          </Button>
        </div>
      </Form>
    </div>
  );
}
