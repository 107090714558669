import Model from './Model';

export enum OnboardingVersion {
  OWNER_V1 = 'owner-v1',
  MEMBER_V1 = 'member-v1',
}

export enum OnboardingStepKey {
  EMAIL_VERIFICATION = 'email-verification',
  USER_INFO = 'user-info',
  WELCOME = 'welcome',
  CONNECT_DATABASE = 'connect-your-database',
  START_A_CHAT = 'start-a-chat',
  CREATE_A_WIDGET = 'create-a-widget',
  VISIT_DASHBOARD = 'visit-dashboard',
  THANK_YOU = 'thank-you',
}

export default interface User extends Model {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  isVerified?: boolean;
  requiresInitialVerification?: boolean;
  inviteCode?: string;
  quizReplies?: {
    companyWebsite: string;
    jobTitle: string;
    useCase: string;
  };

  initialOrganizationId?: Model['_id'];
  onboardingStepKey?: OnboardingStepKey;
  onboardingVersion?: OnboardingVersion;
  onboardingFinished?: boolean;
  receiveMarketingEmails?: boolean;
}
