import { useCallback, useEffect, useState } from 'react';
import {
  Button, Input, message, Select,
} from 'antd';
import debounce from 'just-debounce';
import {
  ExpressionOperation, FieldType, Operator, Query, StageType,
} from '../../../dbaql-v2/types';
import api, { EntityField, InsightFlowFilter } from '../../../api';
import SelectWithContent from '../../../common/components/SelectWithContent';
import Spin from '../../../common/components/Spin';

type StringColumnFilterOption = {
  label: string;
  value: string;
};

enum StringFilterType {
  IS_ONE_OF = 'is-one-of',
  IS_NOT_ONE_OF = 'is-not-one-of',
  CONTAINS = 'contains',
}

interface StringColumnFilterProps {
  field: EntityField;
  onAddFilter: (filter: InsightFlowFilter) => void;
  columnName: string;
  dataSourceId: string;
  tableName: string;
}

export default function StringColumnFilter(props: StringColumnFilterProps) {
  const {
    field, onAddFilter, columnName, dataSourceId, tableName,
  } = props;

  const { displayName } = field;

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<string>();
  const [multiValue, setMultiValue] = useState<string[]>([]);
  const [mode, setMode] = useState<StringFilterType>();
  const [options, setOptions] = useState<StringColumnFilterOption[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
    setValue(undefined);
    setOptions([]);
  }, [tableName, columnName, dataSourceId]);

  useEffect(() => {
    const query: Query = {
      table: tableName,
      pipeline: [
        {
          command: StageType.GROUP,
          by: [
            columnName,
            {
              type: FieldType.EXPRESSION,
              operation: ExpressionOperation.COUNT,
              fields: [columnName],
              as: 'count',
            },
          ],
        },
      ],
    };

    if (search) {
      query.pipeline.push({
        command: StageType.FILTER,
        filters: {
          [columnName]: {
            [Operator.ILIKE]: search,
          },
        },
      });
    }

    query.pipeline.push({
      command: StageType.LIMIT,
      limit: 10,
    });

    setLoading(true);
    api.dataSources.runDbaqlV2Query<Record<string, string>[]>({
      id: dataSourceId,
      query,
    }).then((result: Record<string, string>[]) => {
      setOptions(result.map((item) => ({
        label: item[columnName] || 'Unknown',
        value: item[columnName] || 'Unknown',
      })));
    }).finally(() => {
      setLoading(false);
    });
  }, [search, columnName, tableName]);

  const onApply = () => {
    if (onAddFilter) {
      if (mode === StringFilterType.CONTAINS) {
        if (!value) {
          message.error('Please enter a value');
          return;
        }
        onAddFilter({
          label: `${displayName} contains "${value}"`,
          filter: {
            $ilike: value,
          },
          filterFieldName: columnName,
          field,
        });
      } else {
        if (multiValue.length === 0) {
          message.error('Please select one or more values');
          return;
        }

        if (mode === StringFilterType.IS_NOT_ONE_OF) {
          onAddFilter({
            label: `${displayName} is not ${multiValue.join(' or ')}`,
            filter: {
              $nin: multiValue,
            },
            filterFieldName: columnName,
            field,
          });
        } else if (mode === StringFilterType.IS_ONE_OF) {
          onAddFilter({
            label: `${displayName} is either ${multiValue.join(' or ')}`,
            filter: {
              $in: multiValue,
            },
            filterFieldName: columnName,
            field,
          });
        }
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce(setSearch, 500), [setSearch]);

  return (
    <SelectWithContent
      onChange={setMode}
      items={[
        {
          value: StringFilterType.CONTAINS,
          label: 'Contains',
          content: (
            <Input
              type="text"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              style={{
                minWidth: 400,
              }}
              placeholder="Enter the string"
            />
          ),
        },
        {
          value: StringFilterType.IS_ONE_OF,
          label: 'Is one of',
          content: (
            <Select
              placeholder="Please select one or more values"
              options={options}
              style={{
                minWidth: 400,
              }}
              mode="multiple"
              onSearch={handleSearch}
              value={multiValue}
              onChange={(newValue) => {
                setMultiValue(newValue);
                setSearch('');
              }}
              notFoundContent={loading ? <Spin centered /> : null}
              showSearch
              allowClear
            />
          ),
        },
        {
          value: StringFilterType.IS_NOT_ONE_OF,
          label: 'Is not one of',
          content: (
            <Select
              placeholder="Please select one or more values"
              options={options}
              style={{
                minWidth: 400,
              }}
              mode="multiple"
              onSearch={handleSearch}
              value={multiValue}
              onChange={(newValue) => {
                setMultiValue(newValue);
                setSearch('');
              }}
              notFoundContent={loading ? <Spin centered /> : null}
              showSearch
              allowClear
            />
          ),
        },
      ]}
      contentFooter={(
        <div className="d-flex justify-content-end">
          <Button
            size="small"
            type="primary"
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      )}
    />
  );
}
