import {
  Button, Col, Form, notification, Row,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import { IS_DEVELOPMENT } from '../../config';
import DataSourceSelect from '../../data-sources/components/DataSourceSelect';
import api, { DevCommand } from '../../api';
import AppTabbedPage from '../../layout/components/AppTabbedPage';

function getResultString(result: any): string {
  try {
    return JSON.stringify(result, null, 2);
  } catch (e) {
    return String(result);
  }
}

export default function OrganizationDevPage() {
  const organization = useActiveOrganization();
  const [result, setResult] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState<DevCommand>(DevCommand.DBAQL_TEST);

  if (!organization || (!organization?.features?.devPage && !IS_DEVELOPMENT)) {
    return null;
  }

  const onFinish = (values: any) => {
    try {
      setLoading(true);
      api.dev.create({
        command: activeKey,
        data: values,
      }).then((res) => {
        setResult(res.result);
        setLoading(false);
      }).catch((e) => {
        console.error(e);
        notification.error({
          message: 'Something went wrong',
          description: e.message,
        });
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Invalid Query',
      });
    }
  };

  return (
    <AppTabbedPage
      title="Dev Page"
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key as DevCommand)}
      items={[
        {
          key: DevCommand.DBAQL_TEST,
          label: 'DBAQL Test',
          children: (
            <Row gutter={[16, 16]}>
              <Col span={24} md={12}>
                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    name="dataSourceId"
                    label="Data Source"
                    rules={[{ required: true, message: 'Please select a data source' }]}
                  >
                    <DataSourceSelect
                      entityIds={[organization._id]}
                    />
                  </Form.Item>
                  <Form.Item name="query" label="Query">
                    <TextArea rows={10} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      Test Query
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={24} md={12} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                {
                  result && (
                    <pre>
                      {getResultString(result)}
                    </pre>
                  )
                }
              </Col>
            </Row>
          ),
        },
        {
          key: DevCommand.JAVASCRIPT_CODE_RUNNER,
          label: 'Javascript Code Runner',
          children: (
            <Row gutter={[16, 16]}>
              <Col span={24} md={12}>
                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    name="dataSourceId"
                    label="Data Source"
                    rules={[{ required: true, message: 'Please select a data source' }]}
                  >
                    <DataSourceSelect
                      entityIds={[organization._id]}
                    />
                  </Form.Item>
                  <Form.Item name="query" label="Query">
                    <TextArea rows={10} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      Test Query
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={24} md={12} style={{ maxHeight: '80vh', overflow: 'auto' }}>
                {
                  result && (
                    <pre>
                      {getResultString(result)}
                    </pre>
                  )
                }
              </Col>
            </Row>
          ),
        },
      ]}
    />
  );
}
