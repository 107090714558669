import { Modal, ModalProps, Typography } from 'antd';
import { Widget } from '../../../api';

interface EmbedWidgetModalProps extends Pick<ModalProps, 'open' | 'onCancel'> {
  widget?: Pick<Widget, '_id' | 'embeddable'>;
}

export default function EmbedWidgetModal(props: EmbedWidgetModalProps) {
  const { widget, ...modalProps } = props;

  return (
    <Modal
      title="Embed Widget"
      onOk={modalProps.onCancel}
      {...modalProps}
    >
      {
        widget && !widget.embeddable && (
          <p>
            This widget cannot be embedded.
            {' '}
            Please edit the widget and enable the embed option first.
          </p>
        )
      }
      {
        widget && widget.embeddable && (
          <div>
            <p>
              This widget can be embedded using the following (html) code:
            </p>
            <div
              style={{
                backgroundColor: '#eee',
                borderRadius: '8px',
                padding: 6,
              }}
            >
              <Typography.Text style={{ whiteSpace: 'pre-wrap' }} copyable>
                {`<iframe src="https://app.insightbase.ai/embed/widget/${widget._id}" width="100%" height="100%" style="border: 0;"></iframe>`}
              </Typography.Text>
            </div>
            <p>
              Please check the following link for an example:
              {' '}
              <a href="https://app.insightbase.ai/examples/embed-widget/index.html" target="_blank" rel="noreferrer">Embed Widget Example</a>
            </p>
          </div>
        )
      }
    </Modal>
  );
}
