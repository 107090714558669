import { ReactNode } from 'react';
import { LAYOUT_CONTENT_COLOR } from '../../config/styling';

interface AppSharedPageProps {
  title: string;
  children: ReactNode;
  noBorderSeparator?: boolean;
}

export const SHARED_PAGE_HEADER_HEIGHT = 64;

export default function AppSharedPage(props: AppSharedPageProps) {
  const { title, children, noBorderSeparator } = props;

  const borderWidth = noBorderSeparator ? 0 : 1;

  return (
    <div style={{ height: '100vh' }}>
      <div
        style={{
          height: SHARED_PAGE_HEADER_HEIGHT,
          borderBottomWidth: borderWidth,
          borderBottomColor: '#eee',
          borderBottomStyle: 'solid',
        }}
        className="d-flex align-items-center"
      >
        <div className="p-2 d-flex align-items-center justify-content-between" style={{ flex: 1 }}>
          <h2 className="m-0">{title}</h2>
          <a
            href="https://insightbase.ai"
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'black',
              textDecoration: 'none',
            }}
          >
            <span className="d-flex align-items-center gap-2">
              <span className="fw-bold">Powered By</span>
              <img src="/logo-color.png" alt="InsightBase" style={{ height: 40 }} />
            </span>
          </a>
        </div>
      </div>
      <div
        style={{
          height: `calc(100vh - ${(SHARED_PAGE_HEADER_HEIGHT + borderWidth)}px)`,
          minHeight: `calc(100vh - ${(SHARED_PAGE_HEADER_HEIGHT + borderWidth)}px)`,
          overflowY: 'auto',
          backgroundColor: LAYOUT_CONTENT_COLOR,
        }}
      >
        {children}
      </div>
    </div>
  );
}
