import { NumberOutlined } from '@ant-design/icons';
import { ResultRepresentationType } from '../../../api';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';

class NumberResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = NumberOutlined;

  title = 'Number';

  type = ResultRepresentationType.NUMBER;
}

export default NumberResultRepresentationSubTypeConfig;
