import { Button, Card, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  FormatRule, Formatter,
  FormatType,
  ResultRepresentation,
  ResultRepresentationFormat,
  ResultRepresentationSubType,
} from '../../api';
import AdvancedSelect, { Option } from '../../common/components/AdvancedSelect';

interface ResultRepresentationFormatSetupProps {
  resultRepresentation?: ResultRepresentation;
  rawData: any;
  value?: ResultRepresentationFormat;
  onChange?: (format: ResultRepresentationFormat) => void;
  loading?: boolean;
}

const formatTypeOptions: Option[] = [
  {
    label: 'Currency',
    value: FormatType.CURRENCY,
  },
  {
    label: 'Date',
    value: FormatType.DATE,
  },
];

const singleValueRepresentations: ResultRepresentationSubType[] = [
  ResultRepresentationSubType.TEXT,
  ResultRepresentationSubType.NUMBER,
  ResultRepresentationSubType.DATE,
  ResultRepresentationSubType.STRING,
];

function extractPossibleFields(rawData: any, resultRepresentation?: ResultRepresentation) {
  const subType = resultRepresentation?.subType;

  if (subType && singleValueRepresentations.includes(subType)) {
    return [];
  }

  if (Array.isArray(rawData)) {
    return Object.keys(rawData[0] || {});
  }

  if (typeof rawData === 'object') {
    return Object.keys(rawData);
  }

  return [];
}

const currencyOptions = Formatter.currencyList.map((currency) => ({
  label: `${currency.symbolNative} - ${currency.name} [${currency.code}]`,
  value: currency.code,
}));

export const dateFormatOptions: Option[] = Formatter.dateFormats.map((format) => ({
  label: `${dayjs().format(format)} - [${format}]`,
  value: format,
}));

export default function ResultRepresentationFormatSetup(
  props: ResultRepresentationFormatSetupProps,
) {
  const {
    resultRepresentation,
    rawData,
    onChange,
    loading,
    value,
  } = props;

  if (resultRepresentation === undefined || rawData === undefined) {
    return (
      <p>
        Result is unknown, can not apply format.
      </p>
    );
  }

  const possibleFields = extractPossibleFields(rawData, resultRepresentation);

  const initialValues: ResultRepresentationFormat = {
    rules: [
      {
        field: '',
        format: {
          type: '' as FormatType,
          options: {},
        },
      },
    ],
  };

  return (
    <div>
      <Form<ResultRepresentationFormat>
        layout="vertical"
        initialValues={value || initialValues}
        onFinish={(values) => {
          if (onChange) {
            onChange({
              ...values,
              // This field gets removed by the Form when there are no rules
              // We need to add it back in to ensure the backend knows that
              rules: values.rules || [],
            });
          }
        }}
        preserve
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            const rules = getFieldValue('rules') || [];

            if (!rules.length) {
              return (
                <p className="text-center fw-bold">No formatting rules</p>
              );
            }

            return rules.map((rule: FormatRule, index: number) => (
              <Card size="small" className="mb-2">
                <div className="d-flex gap-2">
                  <div className="d-flex gap-2" style={{ flex: 1 }}>
                    <Form.Item
                      label="Field"
                      name={['rules', index, 'field']}
                      style={{
                        flex: 1,
                        display: possibleFields.length > 0 ? 'block' : 'none',
                      }}
                      rules={[
                        {
                          required: possibleFields.length > 0,
                          message: 'Please select a field',
                        },
                      ]}
                    >
                      <AdvancedSelect
                        placeholder="Select a field"
                        options={possibleFields.map((field) => ({
                          label: field,
                          value: field,
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Format"
                      name={['rules', index, 'format', 'type']}
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a format',
                        },
                      ]}
                    >
                      <AdvancedSelect
                        placeholder="Select a format"
                        options={formatTypeOptions}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label={(<>&nbsp;</>)}>
                    <Button
                      danger
                      onClick={() => {
                        console.info('new rules', rules.filter((_: any, i: number) => i !== index));
                        setFieldValue('rules', rules.filter((_: any, i: number) => i !== index));
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Form.Item>
                </div>
                {
                  rule.format?.type === FormatType.CURRENCY && (
                    <Form.Item
                      label="Currency"
                      name={['rules', index, 'format', 'options', 'currency']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a currency',
                        },
                      ]}
                    >
                      <AdvancedSelect
                        placeholder="Select a currency"
                        options={currencyOptions}
                        showSearch
                      />
                    </Form.Item>
                  )
                }
                {
                  rule.format?.type === FormatType.DATE && (
                    <Form.Item
                      label="Date Format"
                      name={['rules', index, 'format', 'options', 'dateFormat']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a date format',
                        },
                      ]}
                    >
                      <AdvancedSelect
                        placeholder="Select a date format"
                        options={dateFormatOptions}
                        showSearch
                      />
                    </Form.Item>
                  )
                }
              </Card>
            ));
          }}
        </Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => {
                const rules = getFieldValue('rules') || [];
                if (!possibleFields.length && rules.length) {
                  return null;
                }

                return (
                  <Button
                    onClick={() => {
                      setFieldValue('rules', [
                        ...rules,
                        {
                          field: null,
                          format: {
                            type: null,
                            options: {},
                          },
                        },
                      ]);
                    }}
                  >
                    + Add Rule
                  </Button>
                );
              }}
            </Form.Item>
          </div>
          <Form.Item noStyle>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
