import { useRecoilState } from 'recoil';
import { globalAtom } from '../state/global-state';
import api, { UserPreferences } from '../../api';

export default function useUserLayoutPreferences() {
  const [{ layoutPreferences }, setState] = useRecoilState(globalAtom);

  const setLayoutPreferences = (newLayoutPreferences: Partial<UserPreferences>) => {
    setState((prev) => {
      if (prev.layoutPreferences) {
        return {
          ...prev,
          layoutPreferences: {
            ...prev.layoutPreferences,
            ...newLayoutPreferences,
          },
        };
      }

      return prev;
    });

    if (layoutPreferences) {
      api.userPreferences.patch(layoutPreferences._id, newLayoutPreferences).then((result) => {
        setLayoutPreferences(result as UserPreferences);
      });
    }
  };

  return { layoutPreferences, setLayoutPreferences };
}
