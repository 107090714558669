import { useParams } from 'react-router-dom';
import useQuery from '../../common/hooks/useQuery';
import api, { Entity } from '../../api';
import AppTabbedPage from '../../layout/components/AppTabbedPage';
import EntityConstructor from '../components/EntityConstructor';
import EntityFieldsSetup from '../components/EntityFieldsSetup';
import DeleteEntitySection from '../components/DeleteEntitySection';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import EntityFiltersSetup from '../components/EntityFiltersSetup';
import useActiveProject from '../../common/hooks/useActiveProject';

export default function EntityPage() {
  const organization = useActiveOrganization();
  const project = useActiveProject();

  const { entityId } = useParams();
  const [entity, loading] = useQuery(
    async () => api.entities.get(entityId || ''),
    [entityId],
  );

  const [entities] = useQuery<Entity[]>(
    async () => {
      const response = await api.entities.find({
        query: {
          $limit: 100,
          $sort: {
            createdAt: -1,
          },
          $select: ['_id', 'displayName', 'createdAt', 'name', 'fields'],
          organizationId: organization?._id,
          projectId: project?._id,
          dataSourceId: entity?.dataSourceId,
        },
      });

      return response.data;
    },
    [organization?._id],
    Boolean(project && entity),
  );

  return (
    <AppTabbedPage
      title={entity?.displayName || 'Entity'}
      loading={loading}
      items={[
        {
          key: 'general',
          label: 'General',
          children: <EntityConstructor defaultEntity={entity} />,
        },
        {
          key: 'fields',
          label: 'Fields',
          children: (
            <EntityFieldsSetup
              defaultEntity={entity}
              entities={entities}
            />
          ),
        },
        {
          key: 'filters',
          label: 'Filters',
          children: entity ? <EntityFiltersSetup entity={entity} /> : null,
        },
        {
          key: 'delete',
          label: 'Delete',
          children: <DeleteEntitySection entityId={entityId || ''} />,
        },
      ]}
    />
  );
}
