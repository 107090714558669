import {
  OnboardingStepKey, OnboardingVersion, Organization, Project, User,
} from '../../api';

export type OnboardingStep = {
  key: OnboardingStepKey;
  title: string;
  version: OnboardingVersion | OnboardingVersion[];
  url?: string;
  showInSidebar?: boolean;
};

export type OnboardingStepGetterData = {
  organization?: Organization | null;
  project?: Project | null;
  user?: User | null;
};

export type OnboardingStepGetter = OnboardingStep
| ((data: OnboardingStepGetterData) => OnboardingStep);

export const onboardingUserInfoStep: OnboardingStep = {
  key: OnboardingStepKey.USER_INFO,
  title: 'Complete your Profile',
  version: [OnboardingVersion.OWNER_V1, OnboardingVersion.MEMBER_V1],
  url: '/onboarding/info',
};

export const onboardingSteps: OnboardingStepGetter[] = [
  {
    key: OnboardingStepKey.EMAIL_VERIFICATION,
    title: 'Verify your Email',
    version: [OnboardingVersion.OWNER_V1, OnboardingVersion.MEMBER_V1],
    url: '/onboarding/verify-email',
  },
  onboardingUserInfoStep,
  {
    key: OnboardingStepKey.WELCOME,
    title: 'Welcome to InsightBase',
    version: [OnboardingVersion.OWNER_V1, OnboardingVersion.MEMBER_V1],
    url: '/onboarding/welcome',
  },
  ({ organization }) => ({
    key: OnboardingStepKey.CONNECT_DATABASE,
    title: 'Connect your Database',
    showInSidebar: true,
    url: `/${organization?.slug}/data-sources/create`,
    version: OnboardingVersion.OWNER_V1,
  }),
  ({ organization, project }) => ({
    key: OnboardingStepKey.START_A_CHAT,
    title: 'Start a Chat',
    showInSidebar: true,
    url: `/${organization?.slug}/${project?.slug}/chat`,
    version: OnboardingVersion.OWNER_V1,
  }),
  ({ organization, project }) => ({
    key: OnboardingStepKey.CREATE_A_WIDGET,
    title: 'Create a Widget',
    showInSidebar: true,
    url: `/${organization?.slug}/${project?.slug}/chat`,
    version: OnboardingVersion.OWNER_V1,
  }),
  ({ organization, project }) => ({
    key: OnboardingStepKey.VISIT_DASHBOARD,
    title: 'Explore Dashboards',
    showInSidebar: true,
    url: `/${organization?.slug}/${project?.slug}`,
    version: OnboardingVersion.OWNER_V1,
  }),
  {
    key: OnboardingStepKey.THANK_YOU,
    title: 'Thank You',
    version: OnboardingVersion.OWNER_V1,
  },
];
