import {
  Button, Dropdown,
} from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { ResultRepresentationSubType } from '../../../api';
import ResultRepresentationSubTypeIcon from '../ResultRepresentationSubTypeIcon';
import ResultRepresentationHelper from '../../../results/utils/ResultRepresentationHelper';

interface ResultRepresentationSubTypeSelectProps {
  value?: ResultRepresentationSubType;
  onChange?: (subType: ResultRepresentationSubType) => void;
}

export default function ResultRepresentationSubTypeSelect(
  props: ResultRepresentationSubTypeSelectProps,
) {
  const { value, onChange } = props;

  const items: ItemType[] = Object.values(ResultRepresentationSubType).map((subType) => {
    const config = ResultRepresentationHelper.getSubTypeConfig(subType);

    return {
      key: config.title,
      label: config.title,
      icon: <ResultRepresentationSubTypeIcon subType={subType} />,
      onClick: () => {
        if (onChange) {
          onChange(subType);
        }
      },
    };
  });

  return (
    <Dropdown menu={{ items }}>
      <Button
        type="default"
        onClick={() => {}}
        icon={value ? <ResultRepresentationSubTypeIcon subType={value} /> : undefined}
      />
    </Dropdown>
  );
}
