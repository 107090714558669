import { useRecoilState } from 'recoil';
import { OnboardingStep, onboardingSteps } from '../utils/onboarding';
import { useAuth } from '../../auth';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import onboardingAtom from '../state/onboardingAtom';

export default function useOnboardingSteps(): OnboardingStep[] {
  const { user } = useAuth();
  const [state, setState] = useRecoilState(onboardingAtom);

  useQuery(
    async () => {
      if (user?.initialOrganizationId) {
        const organization = await api.organizations.get(user.initialOrganizationId);
        const projectsResponse = await api.projects.find({
          query: {
            $sort: {
              createdAt: 1,
            },
          },
        });
        const [project] = projectsResponse.data;

        setState({
          initialized: true,
          organization,
          project,
        });
      }
    },
    [user?.initialOrganizationId],
  );

  const { organization, project } = state;

  if (!organization || !user) {
    return [];
  }

  const steps: OnboardingStep[] = [];

  onboardingSteps.forEach((stepGetter) => {
    const step = typeof stepGetter === 'function'
      ? stepGetter({ organization, project, user })
      : stepGetter;

    if (user.onboardingVersion) {
      const versionMatches = Array.isArray(step.version)
        ? step.version.includes(user.onboardingVersion)
        : step.version === user.onboardingVersion;

      if (versionMatches) {
        steps.push(step);
      }
    }
  });

  return steps;
}
