import {
  Button, Col, Divider, Form, Input, notification, Row, Select,
} from 'antd';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import capitalize from 'antd/es/_util/capitalize';
import DataSourceSelect from '../../data-sources/components/DataSourceSelect';
import { globalAtom } from '../../common/state/global-state';
import api, { Entity, Project } from '../../api';
import useRequest from '../../common/hooks/useRequest';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';

interface EntityConstructorProps {
  defaultEntity?: Partial<Entity> & Pick<Entity, '_id'>;
  projectId?: Project['_id'];
  defaultDataSourceId?: Project['defaultDataSourceId'];
}

export default function EntityConstructor(props: EntityConstructorProps) {
  const {
    defaultEntity,
    defaultDataSourceId,
    projectId,
  } = props;
  const { dataSources } = useRecoilValue(globalAtom);
  const organization = useActiveOrganization();
  const navigate = useNavigate();

  const request = useRequest(
    async (entity: Entity) => {
      if (!defaultEntity) {
        return api.entities.create({
          ...entity,
          organizationId: organization?._id,
          projectId,
          fields: [],
        });
      }

      return await api.entities.patch(defaultEntity._id, entity) as Entity;
    },
    {
      onSuccess: (entity) => {
        if (!defaultEntity) {
          navigate(`../${entity._id}`);
        } else {
          notification.success({
            message: 'Entity updated',
            description: 'The entity has been updated successfully',
          });
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Failed to create entity',
          description: error.message,
        });
      },
    },
  );

  return (
    <Form
      layout="vertical"
      initialValues={{
        fields: [],
        dataSourceId: defaultDataSourceId,
        ...(defaultEntity || {}),
      }}
      onFinish={request.submit}
    >
      <Row>
        <Col span={24} xl={16} xxl={10}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">Source</Divider>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="dataSourceId"
                label="Data Source"
                rules={[
                  {
                    required: true,
                    message: 'Please select a data source',
                  },
                ]}
              >
                <DataSourceSelect />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item shouldUpdate>
                {({ getFieldValue, setFieldValue }) => {
                  const dataSourceId = getFieldValue('dataSourceId');
                  const dataSource = dataSources.find((ds) => ds._id === dataSourceId);

                  if (!dataSourceId) {
                    return null;
                  }

                  if (!dataSource) {
                    return <div>Data source not found</div>;
                  }

                  return (
                    <Form.Item
                      name="table"
                      label="Table"
                    >
                      <Select
                        placeholder="Select a table"
                        options={
                          dataSource
                            .schemaConfig
                            .tables
                            .filter((table) => table.included)
                            .map((table) => ({
                              label: table.displayTableName || table.tableName,
                              value: table.tableName,
                            }))
                        }
                        onChange={(table) => {
                          const name = getFieldValue('name');
                          const displayName = getFieldValue('displayName');

                          if (!name) {
                            setFieldValue('name', table);
                          }

                          if (!displayName) {
                            setFieldValue('displayName', capitalize(table));
                          }
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">Name</Divider>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Entity Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the entity name',
                  },
                  {
                    // starts with letter, then only letters and underscore
                    pattern: /^[a-zA-Z][a-zA-Z_]*$/,
                    message: 'Entity name must start with a letter and only contain letters and underscores',
                  },
                ]}
                extra="In most cases, this will be the name of the table in the database."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Display Name"
                name="displayName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the display name',
                  },
                ]}
                extra="This is what will be displayed in the UI, choose something user-friendly."
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider orientation="left">Description</Divider>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<>&nbsp;</>}
                name="description"
                extra="A brief description of the entity. Use this to help others understand what this entity is for."
              >
                <Input.TextArea rows={5} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={request.loading}>
              {
                defaultEntity ? 'Save Changes' : 'Create Entity'
              }
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
