import {
  Button, Form, Input, message, Switch,
} from 'antd';
import { useEffect } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import api, { Dashboard } from '../../api';
import useRequest from '../../common/hooks/useRequest';

interface DashboardFormProps extends Pick<Dashboard, 'projectId'> {
  dashboard?: Pick<Dashboard, '_id'> & Partial<Dashboard>;
  onSuccess?: (dashboard: Dashboard) => void;
}

export default function DashboardForm(props: DashboardFormProps) {
  const { projectId, dashboard, onSuccess } = props;
  const request = useRequest<Dashboard>(async (values: Pick<Dashboard, 'name'>) => {
    if (!dashboard) {
      return await api.dashboards.create({
        ...values,
        projectId,
      }) as unknown as Dashboard;
    }

    return await api.dashboards.patch(dashboard._id, values) as unknown as Dashboard;
  }, {
    onSuccess,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (dashboard) {
      form.setFieldsValue(dashboard);
    } else {
      form.setFieldsValue({
        name: '',
      });
    }
  }, [dashboard]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={request.submit}
      initialValues={{
        name: '',
        ...(dashboard || {}),
      }}
    >
      <Form.Item label="Name" name="name">
        <Input placeholder="Dashboard name" />
      </Form.Item>
      <Form.Item>
        <div className="d-flex align-items-center justify-content-between">
          <span>Share Dashboard</span>
          <Form.Item
            name="isShared"
            valuePropName="checked"
            noStyle
          >
            <Switch />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const share = getFieldValue('isShared');

          if (!share) {
            return null;
          }

          return (
            <div style={{ marginTop: -20, marginBottom: 20 }}>
              <div className="fw-bold">
                Everyone with the link can view this dashboard,
                {' '}
                until you turn off the share option.
              </div>
              <Button
                type="text"
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}/share/dashboard/${dashboard?._id}`);
                  message.success('Link copied to clipboard');
                }}
                icon={<CopyOutlined />}
                style={{ marginLeft: -12 }}
              >
                Copy Link
              </Button>
            </div>
          );
        }}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={request.loading}
          block
        >
          Save Dashboard
        </Button>
      </Form.Item>
    </Form>
  );
}
