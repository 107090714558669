import React, { useState } from 'react';
import {
  Button, Form, Input, notification,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { useAuth } from '../index';
import AuthPageLayout from '../layouts/AuthPageLayout';

export default function ResetPasswordPage() {
  const { signIn } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: { password: string }): Promise<void> => {
    if (!token) {
      return notification.error({
        message: 'Error',
        description: 'Missing token',
      });
    }

    setLoading(true);
    try {
      const response = await api.users.resetPassword({ password: values.password, token });
      notification.success({
        message: 'Your password has been reset',
      });
      await signIn({
        email: response.email,
        password: values.password,
      });
      navigate('/');
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e.message,
      });
    }
    setLoading(false);

    return undefined;
  };

  return (
    <AuthPageLayout>
      <div className="bg-transparent">
        <h1 className="mt-0">
          Reset Password
        </h1>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="password" label="New Password" rules={[{ required: true }]}>
            <Input
              placeholder="Password"
              size="large"
              type="password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              { required: true },
              {
                validator: (rule, value, callback) => {
                  if (value !== form.getFieldValue('password')) {
                    callback('Passwords do not match');
                  } else {
                    callback();
                  }
                },
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters',
              },
            ]}
          >
            <Input
              placeholder="Confirm Password"
              size="large"
              type="password"
            />
          </Form.Item>
          <div className="d-flex justify-content-center">
            <Form.Item noStyle>
              <Button
                type="primary"
                size="large"
                loading={loading}
                htmlType="submit"
              >
                Reset Password
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </AuthPageLayout>
  );
}
