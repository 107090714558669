import {
  Button, Col, Form, Input, notification, Row,
} from 'antd';
import { useAuth } from '../../auth';
import useRequest from '../../common/hooks/useRequest';
import api, { User } from '../../api';

export default function UserGeneralSettings() {
  const { user, setUser } = useAuth();

  const request = useRequest(
    async (values: Pick<User, 'name'> & { password?: string; currentPassword?: string }) => {
      if (user) {
        const response = await api.users.patch(user._id, values);
        setUser(response as User);
      }
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Settings updated',
        });
      },
    },
  );

  return (
    <Row>
      <Col span={24} md={12} lg={8} xl={6}>
        <Form
          layout="vertical"
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
          }}
          onFinish={request.submit}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={request.loading}
              disabled={request.loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
