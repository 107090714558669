import ResultRepresentationTransformer from '../transformers/ResultRepresentationTransformer';
import {
  ResultRepresentation,
  ResultRepresentationShape,
  ResultRepresentationSubType,
  ResultRepresentationType,
} from '../../../api';

export default class AnyToCustomCoderResultRepresentationTransformer
  extends ResultRepresentationTransformer {
  constructor(
    readonly resultRepresentation: ResultRepresentation,
    readonly from: ResultRepresentationSubType,
    readonly to: ResultRepresentationSubType,
  ) {
    super(resultRepresentation);
  }

  transformData(): any {
    return this.resultRepresentation.data;
  }

  // eslint-disable-next-line class-methods-use-this
  transform(): ResultRepresentation {
    return {
      type: ResultRepresentationType.CUSTOM,
      shape: ResultRepresentationShape.PRIMITIVE,
      subType: ResultRepresentationSubType.CUSTOM_CODE,
      data: this.resultRepresentation.data,
    };
  }
}
