import { Col, Row } from 'antd';
import React from 'react';
import AuthForm from '../../auth/components/AuthForm';
import AuthPageLayout from '../../auth/layouts/AuthPageLayout';

export default function HomePage() {
  return (
    <AuthPageLayout>
      <Row justify="center" className="flex-grow-1">
        <Col span={24}>
          <AuthForm />
        </Col>
      </Row>
    </AuthPageLayout>
  );
}
