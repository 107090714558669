import {
  MSSQL, MySQL, PostgreSQL, SQLConfig, SQLDialect,
} from '@codemirror/lang-sql';
import { useEffect, useState } from 'react';
import {
  Button, Drawer, Layout, Result, SelectProps,
} from 'antd';
import { ClockCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Completion } from '@codemirror/autocomplete';
import CodeEditor, { CodeEditorProps } from '../../common/components/CodeEditor';
import api, {
  DataSource, DataSourceType, ParameterData, RawCodeLanguage, ResultRepresentationSubType,
} from '../../api';
import useSize from '../../common/hooks/useSize';
import { TYPE_LABELS } from './DataSourceCard';
import DataSourceAutocompleteInput from './DataSourceAutocompleteInput';

interface DataSourceCodeEditorProps extends Omit<Partial<CodeEditorProps>, 'language'> {
  dataSource: Pick<DataSource, 'schemaConfig' | '_id' | 'type'>;
  rawCode: string;
  setRawCode: (rawCode: string) => void;
  language: RawCodeLanguage;
  setLanguage: (language: RawCodeLanguage) => void;
  onRun: () => void;
  loading?: boolean;
  subType?: ResultRepresentationSubType;
  onSubTypeChange?: (subType: ResultRepresentationSubType) => void;
  parameters?: ParameterData[];
}

type Options = Pick<CodeEditorProps, 'language' | 'sqlConfig'>;

function getSqlConfig(dataSource: DataSourceCodeEditorProps['dataSource']): SQLConfig {
  const schemaOption: SQLConfig['schema'] = {};
  const { schemaConfig } = dataSource;

  if (schemaConfig) {
    const { tables } = schemaConfig;
    if (tables) {
      tables.forEach((table) => {
        schemaOption[table.tableName] = Object.keys(table.schema.properties);
      });
    }
  }

  let dialect: SQLDialect | undefined;

  if (dataSource.type === DataSourceType.MYSQL) {
    dialect = MySQL;
  } else if (dataSource.type === DataSourceType.MSSQL) {
    dialect = MSSQL;
  } else if (dataSource.type === DataSourceType.POSTGRESQL) {
    dialect = PostgreSQL;
  }

  return {
    schema: schemaOption,
    dialect,
  };
}

export function getLanguage(dataSourceType?: DataSourceType) {
  if (!dataSourceType) return RawCodeLanguage.JAVASCRIPT;

  switch (dataSourceType) {
    case DataSourceType.MONGODB:
      return RawCodeLanguage.JAVASCRIPT;
    case DataSourceType.MYSQL:
    case DataSourceType.MSSQL:
    case DataSourceType.POSTGRESQL:
      return RawCodeLanguage.SQL;
    default:
      return RawCodeLanguage.JAVASCRIPT;
  }
}

export function getLanguages(dataSourceType?: DataSourceType): SelectProps['options'] {
  const languages: SelectProps['options'] = [
    {
      label: 'JavaScript',
      value: 'javascript',
    },
  ];

  if (
    dataSourceType === DataSourceType.MYSQL
    || dataSourceType === DataSourceType.MSSQL
    || dataSourceType === DataSourceType.POSTGRESQL
  ) {
    languages.push({
      label: 'SQL',
      value: 'sql',
    });
  }

  return languages;
}

export default function DataSourceCodeEditor(props: DataSourceCodeEditorProps) {
  const {
    dataSource,
    onRun,
    loading,
    subType,
    onSubTypeChange,
    rawCode,
    setRawCode,
    language,
    setLanguage,
    parameters,
    ...rest
  } = props;
  const [options, setOptions] = useState<Options>({
    language: RawCodeLanguage.SQL,
    sqlConfig: getSqlConfig(dataSource),
  });
  const [size, contentRef] = useSize();
  const [promptInputSize, promptInputRef] = useSize();
  const [loadingCompletion, setLoadingCompletion] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [showParametersInfoWindow, setShowParametersInfoWindow] = useState(false);

  const { sqlConfig } = options;

  const onSearch = async () => {
    setLoadingCompletion(true);
    api.queries.completion({
      prompt,
      language,
      dataSourceId: dataSource._id,
    }).then(({ completion }) => {
      setRawCode(completion);
    }).finally(() => {
      setLoadingCompletion(false);
    });
  };

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      sqlConfig: getSqlConfig(dataSource),
    }));
  }, [dataSource?._id]);

  if (
    dataSource?.type !== DataSourceType.MYSQL
    && dataSource?.type !== DataSourceType.MSSQL
    && dataSource?.type !== DataSourceType.POSTGRESQL
    && dataSource?.type !== DataSourceType.MONGODB
  ) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center flex-column">
        <Result
          icon={
            <ClockCircleTwoTone />
          }
          title={(
            <>
              <h1>
                {`Code editor for ${TYPE_LABELS[dataSource.type]} is coming soon`}
              </h1>
              <p style={{ fontSize: 16 }}>
                In the meantime, you can use the SQL editor to write your queries
                {' '}
                for MySQL and PostgreSQL data sources
              </p>
            </>
          )}
        />
      </div>
    );
  }

  const extraCompletions: Completion[] = [];

  if (parameters?.length) {
    extraCompletions.push({
      label: 'parameters',
      detail: 'Dynamic parameters available in the query',
      type: 'constant',
    });

    parameters.forEach((parameter) => {
      extraCompletions.push({
        label: `parameters['${parameter.name}']`,
        displayLabel: `parameters['${parameter.name}']`,
        detail: parameter.description,
        type: 'property',
      });
    });
  }

  return (
    <Layout style={{ background: 'transparent', height: '100%' }}>
      <Layout.Content
        style={{ background: 'transparent' }}
        ref={contentRef}
      >
        <div ref={promptInputRef} className="pb-2">
          <DataSourceAutocompleteInput
            placeholder="Ask InsightBase AI to help you with the code"
            loading={loadingCompletion}
            onSubmit={onSearch}
            value={prompt}
            onChange={setPrompt}
            dataSource={dataSource}
            parameters={parameters}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <CodeEditor
            key={`${language}-${JSON.stringify(parameters)}`}
            language={language}
            sqlConfig={sqlConfig}
            value={rawCode}
            onChange={setRawCode}
            height={`${(size.height || 0) - (promptInputSize.height || 0) - 20}px`}
            extraCompletions={extraCompletions}
            {...rest}
          />
          {
            language === RawCodeLanguage.JAVASCRIPT && parameters && parameters.length > 0 && (
              <Button
                style={{
                  position: 'absolute',
                  right: 5,
                  top: 0,
                }}
                size="small"
                onClick={() => setShowParametersInfoWindow(true)}
              >
                Params
                <InfoCircleOutlined />
              </Button>
            )
          }
        </div>
      </Layout.Content>
      <Drawer
        title="Parameters"
        placement="right"
        closable
        onClose={() => setShowParametersInfoWindow(false)}
        open={showParametersInfoWindow}
        width={500}
      >
        {
          parameters && parameters.length > 0 && (
            <div>
              <p className="mt-0">
                The parameters you define in dashboard and in the query are available
                in the Javascript code editor as an object called
                {' '}
                <b>parameters</b>
                .
              </p>
              <p>
                Start typing the word
                {' '}
                <b>parameter</b>
                , or type a parameter name to see the autocomplete suggestions.
              </p>
            </div>
          )
        }
      </Drawer>
    </Layout>
  );
}
