import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import AppPage from '../../layout/components/AppPage';
import useQuery from '../../common/hooks/useQuery';
import api, { Widget } from '../../api';
import WidgetCard from '../components/WidgetCard';
import { getWidgetRepresentation } from '../utils';

export default function WidgetPage() {
  const { widgetId } = useParams();

  const [widget, loading, setWidget] = useQuery<Widget>(async () => {
    if (widgetId) {
      return api.widgets.get(widgetId);
    }

    return null;
  }, [widgetId]);

  return (
    <AppPage
      title={widget ? `${widget.title} Widget` : 'Widget'}
      loading={loading}
    >
      {
        widget && (
          <Row justify="center">
            <Col span={24} md={20} lg={16} xl={12} xxl={10}>
              <WidgetCard
                key={`${widget._id}-${widget.title}-${widget.updatedAt}`}
                _id={widget._id}
                title={widget.title}
                updatedAt={widget.updatedAt}
                resultRepresentationSubTypeOverride={widget.resultRepresentationSubTypeOverride}
                execution={widget.execution}
                onRenameSuccess={(id, title) => {
                  setWidget((prev) => {
                    if (!prev) {
                      return undefined;
                    }

                    return {
                      ...prev,
                      title,
                    };
                  });
                }}
                onUpdateSuccess={setWidget}
                alertsCount={widget.alertsCount}
                resultRepresentation={getWidgetRepresentation(widget)}
                resultRepresentations={widget.resultRepresentations}
              />
            </Col>
          </Row>
        )
      }
    </AppPage>
  );
}
