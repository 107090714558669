import {
  Alert, Button,
  Form, Input, Select,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import alertDefinitions from '../config/alertDefinitions';
import api, {
  AlertRecurrence, AlertType, Widget, Alert as AppAlert, Organization, ResultRepresentationType,
} from '../../api';
import useRequest from '../../common/hooks/useRequest';
import SlackChannelSelect from './SlackChannelSelect';
import CommunicationChannelType from '../../api/definitions/CommunicationChannelType';

export interface AlertSetupProps {
  organizationId: Organization['_id'];
  widgetId: Widget['_id'];
  widgetUpdateInBackground: Widget['updateInBackground'];
  onCreated: (alert: AppAlert) => void;
  resultRepresentationType?: ResultRepresentationType;
  slackEnabled?: boolean;
}

export default function AlertSetup(props: AlertSetupProps) {
  const { organizationSlug } = useParams();

  const {
    organizationId,
    widgetId,
    widgetUpdateInBackground,
    onCreated,
    slackEnabled,
    resultRepresentationType,
  } = props;

  const typeOptions = alertDefinitions
    .filter(
      (definition) => resultRepresentationType
        && definition.compatibleValueTypes.includes(resultRepresentationType),
    )
    .map((definition) => ({
      type: definition.type,
      label: (
        <div>
          <p className="m-0">{definition.name}</p>
          <p
            className="m-0 text-muted"
            style={{ whiteSpace: 'break-spaces', fontSize: 13 }}
          >
            {definition.description}
          </p>
        </div>
      ),
      value: definition.type,
    }));

  const request = useRequest(async (data: Partial<AppAlert>) => api.alert.create({
    config: {},
    deliveryChannel: CommunicationChannelType.SLACK,
    widgetId,
    ...data,
  }), {
    onSuccess: onCreated,
  });

  if (typeOptions.length === 0) {
    return (
      <Alert
        message="No alerts available for this widget."
        description="Please change the widget type to enable alerts."
        type="error"
      />
    );
  }

  if (!slackEnabled) {
    return (
      <Alert
        message="Slack integration is not enabled."
        description={(
          <div>
            <p>
              Please enable Slack integration to receive alerts.
            </p>
            <Link to={`/${organizationSlug}/settings`}>
              <Button block>Enable</Button>
            </Link>
          </div>
        )}
        type="error"
      />
    );
  }

  return (
    <Form
      layout="vertical"
      initialValues={{
        type: AlertType.VALUE_CHANGE,
        recurrence: AlertRecurrence.RECURRING,
      }}
      onFinish={request.submit}
    >
      <Form.Item name="type" label="Type">
        <Select
          options={typeOptions}
          optionLabelProp="label"
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
      >
        {({ getFieldValue }) => {
          const type = getFieldValue('type');

          const definition = alertDefinitions.find((item) => item.type === type);

          if (!definition?.hasValueInput) {
            return null;
          }

          return (
            <Form.Item name={['config', 'value']} label="Value">
              <Input type="number" placeholder="Value" />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name="recurrence" label="Recurrence">
        <Select
          options={[
            {
              label: 'One Time',
              value: AlertRecurrence.ONE_TIME,
            },
            {
              label: 'Recurring',
              value: AlertRecurrence.RECURRING,
            },
          ]}
        />
      </Form.Item>
      <Form.Item name={['config', 'slackChannel']} label="Slack Channel">
        <SlackChannelSelect organizationId={organizationId} />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={request.loading}
          type="primary"
          size="large"
          block
        >
          Save
        </Button>
      </Form.Item>
      {
        !widgetUpdateInBackground && (
          <Alert
            message="This widget is not set to update in the background."
            description="You will receive alerts only when this widget will be refreshed manually. To receive alerts in real time, please set this widget to update in the background."
            type="warning"
          />
        )
      }
    </Form>
  );
}
