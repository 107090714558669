import { AreaChartOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class AreaChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = AreaChartOutlined;

  title = 'Area Chart';

  type = ResultRepresentationType.GRAPH;
}

export default AreaChartResultRepresentationSubTypeConfig;
