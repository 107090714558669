import User from './User';
import Organization from './Organization';
import Model from './Model';

export enum OrganizationUserRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  READONLY = 'readonly',
}

export default interface OrganizationUser extends Model {
  email: string;
  organizationId: Organization['_id'];
  role: OrganizationUserRole;
  name?: string;
  userId?: User['_id'];
  projectsIds?: string[];
  isAccepted?: boolean;
}
