import { Button } from 'antd';
import { Link, useParams } from 'react-router-dom';

interface UsageReachedViewProps {
  subTitle: string;
  onClick?: () => void;
}
export default function UsageReachedView(props: UsageReachedViewProps) {
  const { subTitle, onClick } = props;

  const { organizationSlug } = useParams<{ organizationSlug: string }>();

  return (
    <Link
      to={`/${organizationSlug}/settings/billing`}
      onClick={onClick}
    >
      <div
        className="shadow-sm shadow-hover"
        style={{
          padding: '10px',
          // A shade of black
          color: 'rgba(0, 0, 0, 0.88)',
          background: 'white',
          border: '1px solid #175cff',
        }}
      >
        <h1 className="text-center">
          Usage Reached
        </h1>
        <h2 className="m-0 text-center">
          {subTitle}
        </h2>
        <h3 className="text-center">
          Please upgrade your plan to unlock more resources.
        </h3>
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            className="mt-4"
            size="large"
            block
          >
            Upgrade
          </Button>
        </div>
      </div>
    </Link>
  );
}
