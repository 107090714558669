import {
  Divider, Drawer, DrawerProps, Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { QueryExecution, QueryExecutionMode, RawCodeLanguage } from '../../../api';
import Highlight from '../../../common/components/Highlight';

interface QueryExecutionInfoDrawerProps extends Partial<Omit<DrawerProps, 'children'>> {
  execution: Pick<QueryExecution, 'mode' | 'details' | 'rawCode' | 'rawCodeLanguage' | 'result' | 'responseText' | 'prompt' | 'totalCredits'>
  onCodeEditClick?: () => void;
}

function getModeLabel(mode: QueryExecutionMode) {
  switch (mode) {
    case QueryExecutionMode.AI:
      return 'AI';
    case QueryExecutionMode.DBAQL:
      return 'Query Builder';
    case QueryExecutionMode.RAW_CODE:
      return 'Code';
    case QueryExecutionMode.DBAQLV2:
      return 'Insight Flow';
    default:
      return 'Unknown';
  }
}

export default function QueryExecutionInfoDrawer(props: QueryExecutionInfoDrawerProps) {
  const {
    open,
    execution,
    onCodeEditClick,
    ...drawerProps
  } = props;

  const [openOnce, setOpenOnce] = useState(false);
  const {
    mode,
    details,
    result,
    rawCode,
    responseText,
    rawCodeLanguage = RawCodeLanguage.SQL,
    prompt,
  } = execution;

  useEffect(() => {
    if (open) {
      setOpenOnce(true);
    }
  }, [open]);

  if (!openOnce) {
    return null;
  }

  return (
    <Drawer
      title="Info"
      width="min(700px)"
      open={open}
      {...drawerProps}
    >
      <div className="d-flex align-items-center justify-content-between">
        <p className="p-0 m-0">
          Execution Mode
        </p>
        <Tag color="purple">{getModeLabel(mode)}</Tag>
      </div>
      {
        execution.totalCredits && (
          <div className="d-flex align-items-center justify-content-between mt-2">
            <p className="p-0 m-0">
              Total Credits
            </p>
            <Tag color="purple">{execution.totalCredits}</Tag>
          </div>
        )
      }
      {
        prompt && (
          <>
            <Divider orientation="left">Prompt</Divider>
            <h4 className="fw-normal" style={{ lineHeight: '20px' }}>
              {prompt}
            </h4>
          </>
        )
      }
      {
        responseText && (
          <>
            <Divider orientation="left">Explanation</Divider>
            <h4 className="fw-normal" style={{ lineHeight: '20px' }}>
              {responseText}
            </h4>
          </>
        )
      }
      <Divider orientation="left">Raw Result</Divider>
      <Highlight
        className="json"
        style={{
          maxHeight: 400,
          overflow: 'auto',
        }}
      >
        {JSON.stringify(result, null, 2)}
      </Highlight>
      {
        details?.rawCode && (
          <>
            <Divider orientation="left">Code</Divider>
            <Highlight
              className={rawCodeLanguage}
              onEditClick={onCodeEditClick}
            >
              {rawCode || details?.rawCode}
            </Highlight>
          </>
        )
      }
    </Drawer>
  );
}
