import {
  Alert, Button, Form, Input, notification,
} from 'antd';
import useRequest from '../../common/hooks/useRequest';
import api, { DataSource } from '../../api';

interface DataSourceCustomInstructionsEditorProps {
  id: DataSource['_id'];
  value: DataSource['customInstructions'];
  onChange: (value: DataSource['customInstructions']) => void;
}

export default function DataSourceCustomInstructionsEditor(
  props: DataSourceCustomInstructionsEditorProps,
) {
  const { id, value, onChange } = props;

  const request = useRequest<DataSource>(
    // @ts-ignore
    async (values: Pick<DataSource, 'terms'>) => api.dataSources.patch(id, values),
    {
      onSuccess: (dataSource) => {
        notification.success({
          message: 'Data source terms updated',
        });

        if (onChange) {
          onChange(dataSource.customInstructions);
        }
      },
    },
  );

  return (
    <div>
      <Alert
        message="Define custom instructions to be used by our system when querying this data source"
        type="info"
      />
      <Form
        className="mt-4"
        onFinish={request.submit}
        initialValues={{ customInstructions: value }}
        preserve
      >
        <Form.Item name="customInstructions">
          <Input.TextArea
            placeholder="Write your custom instructions here..."
            autoSize={{
              minRows: 5,
              maxRows: 20,
            }}
          />
        </Form.Item>
        <Form.Item>
          <div className="d-flex justify-content-end">
            <Button type="primary" htmlType="submit">Save</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
