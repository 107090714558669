import ResultRepresentationTransformer from './ResultRepresentationTransformer';
import { ResultRepresentationSubType } from '../../../api';

class DateToStringTransformer extends ResultRepresentationTransformer {
  from = ResultRepresentationSubType.DATE;

  to = ResultRepresentationSubType.STRING;

  isCompatible(): boolean {
    return String(new Date(this.resultRepresentation.data)) !== 'Invalid Date';
  }

  transformData() {
    return new Date(this.resultRepresentation.data).toISOString();
  }
}

export default DateToStringTransformer;
