import ResultRepresentationManager from './ResultRepresentationManager';
import ResultRepresentationTransformer from '../transformers/ResultRepresentationTransformer';
import DateToNumberTransformer from '../transformers/DateToNumberTransformer';
import DateToStringTransformer from '../transformers/DateToStringTransformer';

class DateRepresentationManager extends ResultRepresentationManager {
  transformers: ResultRepresentationTransformer[] = [
    new DateToNumberTransformer(this.representation),
    new DateToStringTransformer(this.representation),
  ];
}

export default DateRepresentationManager;
