import { useRecoilValue } from 'recoil';
import {
  Avatar,
  Button, Card, Col, Row,
} from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { organizationsAtom } from '../state';
import { globalAtom } from '../../common/state/global-state';
import AppPage from '../../layout/components/AppPage';
import { dayMonthYear } from '../../common/utils/dates';
import { plural } from '../../common/utils/strings';
import usePermissions from '../../common/hooks/usePermissions';

export default function OrganizationPage() {
  const { projects, loadingOrganizationProjects } = useRecoilValue(globalAtom);
  const { organization } = useRecoilValue(organizationsAtom);
  const permissions = usePermissions();

  return (
    <AppPage
      title="Projects"
      loading={loadingOrganizationProjects && !projects.length}
      actionButtons={organization && permissions.projects.write && (
        <Link to={`/${organization.slug}/create-project`}>
          <Button type="primary" icon={<PlusOutlined />}>
            New Project
          </Button>
        </Link>
      )}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            {
              projects && projects.map((project) => (
                <Col span={24} md={12} lg={8} xl={6} key={project._id}>
                  <Link to={`/${organization?.slug}/${project.slug}`} className="h-100">
                    <Card
                      size="small"
                      style={{
                        cursor: 'pointer',
                      }}
                      className="h-100"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <Avatar shape="square" style={{ backgroundColor: 'rgb(34 35 13)' }}>
                          {project.name[0]}
                        </Avatar>
                        <h4 className="text-center m-0" style={{ color: 'rgb(37, 98, 212)' }}>{project.name}</h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <span className="text-secondary">
                          {project.totals?.dashboards ? `${plural(project.totals.dashboards, 'Dashboard')}` : 'No dashboards'}
                        </span>
                        <p style={{ textAlign: 'right' }} className="p-0 m-0 text-secondary">
                          {dayMonthYear(project.createdAt)}
                        </p>
                      </div>
                    </Card>
                  </Link>
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    </AppPage>
  );
}
