import { atom, DefaultValue } from 'recoil';
import {
  CommandBase, CommandName, Commands, SortDirection,
} from '../types';
import { FlatFilter } from '../helper-types';
import { DataSource, DataSourceTableConfig } from '../../api';

export type DbaqlBuilderState = {
  schema?: DataSource['schemaConfig'];
  table?: string;
  command?: CommandName;
  filters: FlatFilter[];
  aggregationField?: string;
  groupFields?: string;
  limit?: number;
  skip?: number;
  enableGroup: boolean;
  enableFilter: boolean;
  enableLimit: boolean;
  tableConfig?: DataSourceTableConfig;
  commandConfig?: CommandBase;
  enableFixedSort: boolean;
  fixedSort: SortDirection;
  enableSort?: boolean;
  sortField?: string;
  sortDirection?: SortDirection;
  select?: string[];
  enableSelect?: boolean;
};

export const DEFAULT_STATE: DbaqlBuilderState = {
  table: '',
  filters: [],
  enableGroup: false,
  enableFilter: false,
  enableLimit: false,
  enableFixedSort: false,
  fixedSort: SortDirection.DESC,
  enableSort: false,
  limit: 10,
  select: [],
  enableSelect: false,
};

const dbaqlAtom = atom<DbaqlBuilderState>({
  key: 'dbaql-builder-atom',
  default: { ...DEFAULT_STATE },
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue, oldValue) => {
        if (!(oldValue instanceof DefaultValue)) {
          const { table, command } = newValue;

          let tableChanged = false;
          let commandChanged = false;

          if (table !== oldValue.table) {
            tableChanged = true;
            const tableConfig = newValue?.schema?.tables.find(
              (item) => item.tableName === newValue.table,
            );

            setSelf({
              ...newValue,
              tableConfig,
            });
          }

          if (command !== oldValue.command) {
            commandChanged = true;
            const commandConfig = newValue.command
              ? Commands[newValue.command]
              : undefined;

            setSelf({
              ...newValue,
              commandConfig,
              sortDirection: SortDirection.DESC,
              sortField: undefined,
            });
          }

          if (tableChanged && commandChanged) {
            setSelf({
              ...newValue,
              enableGroup: false,
              filters: [],
              enableFilter: false,
              enableLimit: false,
            });
          }
        }
      });
    },
  ],
});

export default dbaqlAtom;
