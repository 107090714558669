import {
  Button, Form, Input, notification, Select,
} from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api, { User } from '../../api';
import { useAuth } from '../../auth';
import AuthPageLayout from '../../auth/layouts/AuthPageLayout';
import useOnboarding from '../../onboarding/hooks/useOnboarding';

const jobTitles: string[] = [
  'Founder',
  'Chief Executive Officer',
  'Chief Technology Officer',
  'Chief Analytics Officer',
  'Chief Data Officer',
  'Data Analyst',
  'Data Scientist',
  'Data Engineer',
  'Data Architect',
  'Software Engineer',
  'Chief Operating Officer',
  'Chief Product Officer',
  'Chief Financial Officer',
  'Chief Marketing Officer',
  'Chief Information Officer',
  'Chief Human Resources Officer',
  'Chief Legal Officer',
  'Chief Sales Officer',
  'Chief Strategy Officer',
  'Chief Security Officer',
  'Chief Experience Officer',
  'Chief Customer Officer',
  'Chief Innovation Officer',
  'Chief Transformation Officer',
  'Other',
];

const industries: string[] = [
  'Information Technology & Services',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts & Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies & Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'Education Management',
  'E-learning',
  'Electrical & Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fundraising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness & Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Human Resources',
  'Import & Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Insurance',
  'International Affairs',
  'International Trade & Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure & Travel',
  'Libraries',
  'Logistics & Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Marketing & Advertising',
  'Market Research',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Device',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures & Film',
  'Museums & Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Nonprofit Organization Management',
  'Oil & Energy',
  'Online Publishing',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging & Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Policy',
  'Public Relations',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational',
  'Facilities & Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security & Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing & Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation & Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine & Spirits',
  'Wireless',
  'Writing & Editing',
];

export default function UserQuizPage() {
  const { search } = useLocation();
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onboarding = useOnboarding();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goToDemo = async () => {
    const fromEmailVerification = search.includes('fev=true');

    if (fromEmailVerification && user?.requiresInitialVerification) {
      const response = await api.organizations.find();
      const [organization] = response.data;

      if (organization) {
        const projectResponse = await api.projects.find({});

        const project = projectResponse.data.find((el) => el.isDemoProject)
          || projectResponse.data[0];

        if (project) {
          setTimeout(() => {
            navigate(`/${organization.slug}/${project.slug}`);
            if (project.isDemoProject) {
              notification.success({
                message: 'This is a demo project',
                description: 'Feel free to explore it and ask questions.',
              });
            } else {
              notification.success({
                message: 'Welcome to InsightBase',
                description: 'Feel free to explore!',
              });
            }
          });
        }
      }
    } else {
      navigate('/onboarding/welcome');
    }
  };

  const onSubmit = async (quizReplies: any) => {
    if (user) {
      setLoading(true);
      try {
        const newUser = await api.users.patch(user._id, { quizReplies }) as User;
        setUser(newUser);
        const nextStep = onboarding.goToNextStep();
        navigate(nextStep?.url || '/onboarding/welcome');
      } catch (e) {
        notification.error({
          message: 'Error',
          description: 'Something went wrong, please try again',
        });
      }
      setLoading(false);
    }
  };

  return (
    <AuthPageLayout>
      <div>
        <p>
          We want to make sure you get the most out of InsightBase.
          {' '}
          Please take a moment to tell us a little more about yourself.
        </p>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Company Website"
            name="companyWebsite"
            rules={[
              {
                required: true,
                message: 'Please enter your company website',
              },
              {
                pattern: /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([\w .-]*)*\/?$/,
                message: 'Please enter a valid URL',
              },
            ]}
          >
            <Input
              placeholder="Enter your company website"
            />
          </Form.Item>
          <Form.Item
            label="Job Title"
            name="jobTitle"
            rules={[
              {
                required: true,
                message: 'Please select your job title',
              },
            ]}
          >
            <Select
              placeholder="Select your job title"
              options={jobTitles.map((title) => ({ label: title, value: title }))}
              allowClear
              showSearch
            />
          </Form.Item>
          <Form.Item
            label="Industry"
            name="industry"
            rules={[
              {
                required: true,
                message: 'Please select your industry',
              },
            ]}
          >
            <Select
              placeholder="Select your industry"
              options={industries.map((industry) => ({ label: industry, value: industry }))}
              allowClear
              showSearch
            />
          </Form.Item>
          <Form.Item
            label="How do you plan to use InsightBase?"
            name="useCase"
            rules={[
              {
                required: true,
                message: 'Please tell us how you plan to use InsightBase',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Please tell us how you plan to use InsightBase"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </AuthPageLayout>
  );
}
