import {
  Outlet, Route, Routes, useLocation, useMatch, useNavigate,
} from 'react-router-dom';
import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';
import queryString from 'query-string';
import { useSetRecoilState } from 'recoil';
// @ts-ignore
import { Helmet } from 'react-helmet';
import UserOrganizationsPage from '../../organizations/pages/UserOrganizationsPage';
import OrganizationIndexPage from '../../organizations/pages/OrganizationIndexPage';
import ProjectPage from '../../projects/pages/ProjectPage';
import OrganizationPage from '../../organizations/pages/OrganizationPage';
import AppLayout from '../../layout/components/AppLayout';
import OrganizationDataSourcesPage from '../../organizations/pages/OrganizationDataSourcesPage';
import ProjectIndexPage from '../../projects/pages/ProjectIndexPage';
import ProjectSettingsPage from '../../projects/pages/ProjectSettingsPage';
import ProjectCreatePage from '../../projects/pages/ProjectCreatePage';
import { useAuth } from '../../auth';
import HomePage from '../../misc/pages/HomePage';
import OrganizationCreatePage from '../../organizations/pages/OrganizationCreatePage';
import OrganizationSettingsPage from '../../organizations/pages/OrganizationSettingsPage';
import UserSettingsPage from '../../users/pages/UserSettingsPage';
import DataSourcePage from '../../data-sources/pages/DataSourcePage';
// import { IS_PRODUCTION, IS_DEVELOPMENT } from '../../config';
import AuthorizeSlackPage from '../../authorization/pages/AuthorizeSlackPage';
import WidgetPage from '../../widgets/pages/WidgetPage';
import AppSumoPage from '../../appsumo/pages/AppSumoPage';
import AppSumoThankYouPage from '../../appsumo/pages/AppSumoThankYouPage';
import { updateMarketingParams } from '../../common/utils/tracking';
import AuthorizeAirtablePage from '../../authorization/pages/AuthorizeAirtablePage';
import EmbedWidgetPage from '../../widgets/pages/EmbedWidgetPage';
import OrganizationDevPage from '../../organizations/pages/OrganiazationDevPage';
import api, { User, UserPreferenceType } from '../../api';
import VerifyUserEmailPage from '../../auth/pages/VerifyUserEmailPage';
import SignUpPage from '../../auth/pages/SignUpPage';
import { ENABLE_CRISP, ENABLE_ZUPPORT, IS_PRODUCTION } from '../../config';
import AirtableExtensionPage from '../../airtable/pages/AirtableExtensionPage';
import UserQuizPage from '../../users/pages/UserQuizPage';
import InvitationPage from '../../users/InvitationPage';
import ForgotPasswordPage from '../../auth/pages/ForgotPasswordPage';
import ResetPasswordPage from '../../auth/pages/ResetPasswordPage';
import ProjectChatPage from '../../chat/page/ProjectChatPage';
import ErrorPage from '../../common/pages/ErrorPage';
import { globalAtom } from '../../common/state/global-state';
import SharedDashboardPage from '../../dashboards/pages/SharedDashboardPage';
import SharedProjectPage from '../../projects/pages/SharedProjectPage';
import OnboardingWelcomePage from '../../onboarding/pages/OnboardingWelcomePage';
import NewDataSourcePage from '../../data-sources/pages/NewDataSourcePage';
import SubscribeThankYouPage from '../../organizations/pages/SubscribeThankYouPage';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import EntitiesPage from '../../entities/pages/EntitiesPage';
import CreateEntityPage from '../../entities/pages/CreateEntityPage';
import EntityPage from '../../entities/pages/EntityPage';
import ProjectInsightFlowPage from '../../projects/pages/ProjectInsightFlowPage';
import { onboardingUserInfoStep } from '../../onboarding/utils/onboarding';
import useScript from '../../common/hooks/useScript';

function AuthenticatedWrapper() {
  return (
    <AppLayout>
      {
        IS_PRODUCTION && (
          <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-HYP1R8848V" />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-HYP1R8848V');
              `}
            </script>
          </Helmet>
        )
      }
      <Outlet />
    </AppLayout>
  );
}

function Tracker() {
  const { search } = useLocation();

  useEffect(() => {
    const cleanSearch = search.replace('?', '');
    const params = cleanSearch ? queryString.parse(cleanSearch) : {};
    updateMarketingParams(params);
  }, [search]);

  return (<Outlet />);
}

interface RedirectProps {
  to?: string;
}

function Redirect(props: RedirectProps) {
  const { to = '/' } = props;

  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, []);

  return null;
}

const APP_SUMO_THANK_YOU_PATH = 'appsumo/thank-you';

interface UserNavigatorProps {
  user?: User | null;
}

function UserNavigator(props: UserNavigatorProps) {
  const { user } = props;
  const match = useMatch(APP_SUMO_THANK_YOU_PATH);
  const isVerifyPage = useMatch('onboarding/verify-email');
  const isOnboardingInfoPage = useMatch(onboardingUserInfoStep.url || 'onboarding/info');
  const setState = useSetRecoilState(globalAtom);
  const isMainPage = useMatch('/');
  const organization = useActiveOrganization();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMainPage && organization) {
      navigate(`/${organization.slug}`);
    }
  }, []);

  useEffect(() => {
    api.userPreferences.find({
      query: {
        type: UserPreferenceType.LAYOUT,
        $limit: 1,
      },
    }).then((response) => {
      const [preference] = response.data;

      if (!preference) {
        api.userPreferences.create({
          type: UserPreferenceType.LAYOUT,
          value: {
            useSharedLayout: true,
          },
        }).then((result) => {
          setState((state) => ({
            ...state,
            layoutPreferences: result,
          }));
        });
      } else {
        setState((state) => ({
          ...state,
          layoutPreferences: preference,
        }));
      }
    });
  }, []);

  if (user && !match) {
    if (!user.isVerified) {
      if (!isVerifyPage) {
        return (
          <Redirect to="/onboarding/verify-email" />
        );
      }
    } else if (!user.quizReplies) {
      if (!isOnboardingInfoPage) {
        return (
          <Redirect to="/onboarding/info" />
        );
      }
    }
  }

  return (
    <Routes>
      <Route path="/sign-up" element={<Redirect />} />
      <Route path="/forgot-password" element={<Redirect />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="appsumo/thank-you" element={<AppSumoThankYouPage />} />
      <Route path="onboarding" element={<Outlet />}>
        <Route path="verify-email" element={<VerifyUserEmailPage />} />
        <Route path="info" element={<UserQuizPage />} />
      </Route>
      <Route element={<AuthenticatedWrapper />}>
        <Route path="" element={<UserOrganizationsPage />} />
        <Route path="onboarding">
          <Route path="welcome" element={<OnboardingWelcomePage />} />
        </Route>
        <Route path="create-organization" element={<OrganizationCreatePage />} />
        <Route path="user/settings" element={<Outlet />}>
          <Route index element={<UserSettingsPage />} />
          <Route path=":tab" element={<UserSettingsPage />} />
        </Route>
        <Route path="authorize" element={<Outlet />}>
          <Route path="slack" element={<AuthorizeSlackPage />} />
          <Route path="airtable" element={<AuthorizeAirtablePage />} />
        </Route>
        <Route path="widgets/:widgetId" element={<WidgetPage />} />
        <Route path="invitations/:code" element={<InvitationPage />} />
        <Route path=":organizationSlug" element={<OrganizationIndexPage />}>
          <Route index element={<OrganizationPage />} />
          <Route path="create-project" element={<ProjectCreatePage />} />
          <Route path="settings" element={<Outlet />}>
            <Route index element={<OrganizationSettingsPage />} />
            <Route path=":tab" element={<OrganizationSettingsPage />} />
          </Route>
          <Route path="data-sources" element={(<Outlet />)}>
            <Route index element={<OrganizationDataSourcesPage />} />
            <Route path="create" element={<NewDataSourcePage />} />
            <Route path=":dataSourceId" element={<Outlet />}>
              <Route index element={<DataSourcePage />} />
              <Route path=":tab" element={<DataSourcePage />} />
            </Route>
          </Route>
          <Route path=":projectSlug" element={<ProjectIndexPage />}>
            <Route index element={<ProjectPage />} />
            <Route path="entities" element={<Outlet />}>
              <Route index element={<EntitiesPage />} />
              <Route path="create" element={<CreateEntityPage />} />
              <Route path=":entityId" element={<EntityPage />} />
            </Route>
            <Route path="chat" element={(<ProjectChatPage />)} />
            <Route path="settings" element={(<ProjectSettingsPage />)} />
            <Route path="insight-flow" element={(<ProjectInsightFlowPage />)} />
          </Route>
          <Route path="dev" element={<OrganizationDevPage />} />
        </Route>
        <Route path="subscribe/thank-you" element={<SubscribeThankYouPage />} />
      </Route>
    </Routes>
  );
}

const HIDE_CRISP_PATHS = [
  '/embed',
];

export default function MainNavigator() {
  const { authenticated, user } = useAuth();
  const { pathname } = useLocation();

  const shouldHideCrisp = HIDE_CRISP_PATHS.some((path) => pathname.startsWith(path));

  useEffect(() => {
    if (ENABLE_CRISP) {
      Crisp.configure('bc16f99c-78cf-4fde-be43-689ce4f4d191');
    }
  }, []);

  useScript('https://app.zupport.ai/widget.js', user?.isVerified && ENABLE_ZUPPORT);

  useEffect(() => {
    if (user?.isVerified && ENABLE_ZUPPORT) {
      // @ts-ignore
      window.zupportSettings = {
        baseAPI: 'https://api.zupport.ai',
        websiteUid: 'x1TlYkKeXiqPKOAXMBWKz2LG',
        userUid: user?._id,
        userEmail: user?.email,
        userSecret: user?._id,
        userFirstName: user?.firstName,
        userLastName: user?.lastName,
      };
    }
  }, [user?.isVerified]);

  useEffect(() => {
    if (user && ENABLE_CRISP && !shouldHideCrisp) {
      Crisp.user.setEmail(user.email);
      Crisp.user.setNickname(user.name);
    }
  }, [user?.email, shouldHideCrisp]);

  return (
    <Routes>
      <Route path="embed" element={<Outlet />}>
        <Route path="widget/:widgetId" element={<EmbedWidgetPage />} />
        <Route path="airtable/extension" element={<AirtableExtensionPage />} />
      </Route>
      <Route path="share" element={<Outlet />}>
        <Route path="dashboard/:dashboardId" element={<SharedDashboardPage />} />
        <Route path="project/:projectId" element={<SharedProjectPage />} />
      </Route>
      {
        authenticated ? (
          <Route path="*" element={<UserNavigator user={user} />} />
        ) : (
          <Route element={<Tracker />}>
            <Route path="" element={<HomePage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="appsumo/:code" element={<AppSumoPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<Redirect />} />
          </Route>
        )
      }
    </Routes>
  );
}
