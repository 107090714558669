import { Alert, Button, Layout } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { APP_CONTENT_SUBTRACT, HEADER_HEIGHT } from './AppLayout';
import Spin from '../../common/components/Spin';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import { LAYOUT_CONTENT_COLOR, LAYOUT_SIDEBAR_COLOR } from '../../config/styling';

export interface AppPageProps {
  children?: ReactNode | ReactNode[];
  title?: string;
  actionButtons?: ReactNode;
  loading?: boolean;
  error?: string | false;
  allowed?: boolean;
  contentPadding?: number,
  background?: string;
  overflow?: 'scroll' | 'auto';
  fullHeight?: boolean;
  hideBottomBorder?: boolean;
}

export default function AppPage(props: AppPageProps) {
  const organization = useActiveOrganization();
  const {
    children,
    title,
    actionButtons,
    loading,
    error,
    allowed = true,
    contentPadding = 16,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    background,
    overflow = 'auto',
    fullHeight = false,
    hideBottomBorder,
  } = props;

  let borderBottomWidth = 1;

  if (hideBottomBorder) {
    borderBottomWidth = 0;
  }

  return (
    <Layout
      className="site-layout"
      style={{ marginLeft: 220, height: '100vh' }}
    >
      {
        !fullHeight && title && (
          <Layout.Header
            style={{
              padding: 16,
              background: LAYOUT_SIDEBAR_COLOR,
              height: HEADER_HEIGHT - borderBottomWidth,
              borderBottomColor: '#eee',
              borderBottomStyle: 'solid',
              borderBottomWidth,
            }}
            className="d-flex align-items-center justify-content-between"
          >
            <h2>{title}</h2>
            <div className="d-flex align-items-center gap-2 flex-grow-1 justify-content-end">
              {
                organization && organization.creditsRemaining < 500 && (
                  <Alert
                    type={organization.creditsRemaining <= 0 ? 'error' : 'warning'}
                    message={`You have ${Math.max(organization.creditsRemaining, 0)} credits remaining`}
                    action={(
                      <Link to={`/${organization.slug}/settings`}>
                        <Button className="ms-2" size="small">Upgrade</Button>
                      </Link>
                    )}
                  />
                )
              }
              {actionButtons}
            </div>
          </Layout.Header>
        )
      }
      <Layout.Content
        style={{
          padding: !fullHeight ? contentPadding : 0,
          overflowY: overflow,
          maxHeight: !fullHeight ? `calc(100vh - ${APP_CONTENT_SUBTRACT}px)` : '100vh',
          height: !fullHeight ? `calc(100vh - ${APP_CONTENT_SUBTRACT}px)` : '100vh',
          background: background || LAYOUT_CONTENT_COLOR,
        }}
      >
        {
          !loading && error && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h1 className="text-center">{error}</h1>
            </div>
          )
        }
        {
          loading && (
            <div className="d-flex align-items-center justify-content-center">
              <Spin />
            </div>
          )
        }
        {
          allowed && !loading && !error && children
        }
      </Layout.Content>
    </Layout>
  );
}
