import { useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import api, { Parameter } from '../../api';
import Spin from '../../common/components/Spin';
import ParameterCard from './ParameterCard';
import useDashboardParameters from '../hooks/useDashboardParameters';
import DashboardParameterBuilder from './DashboardParameterBuilder';

interface ParametersManagementProps {
  dashboardId: Parameter['dashboardId'];
  onParameterChange?: (parameter: Parameter) => void;
  onCountChange?: (count: number) => void;
}

export default function ParametersManagement(props: ParametersManagementProps) {
  const { dashboardId, onParameterChange, onCountChange } = props;
  const [mode, setMode] = useState<'list' | 'create' | 'edit'>('list');
  const [deleteParameter, setDeleteParameter] = useState<Parameter | null>(null);
  const [editParameter, setEditParameter] = useState<Parameter>();
  const [{ loading, parameters }, setParameters] = useDashboardParameters(dashboardId);

  useEffect(() => {
    if (onCountChange) {
      onCountChange(parameters.length);
    }
  }, [onCountChange, parameters]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }

  if (mode === 'create' || mode === 'edit') {
    return (
      <DashboardParameterBuilder
        parameter={editParameter}
        dashboardId={dashboardId}
        onCancel={() => { setMode('list'); }}
        onSuccess={(newParameter) => {
          if (!editParameter) {
            setParameters((prev) => [...prev, newParameter]);
          } else {
            setParameters((prev) => prev.map((item) => {
              if (item._id === newParameter._id) {
                return newParameter;
              }

              return item;
            }));
            setEditParameter(undefined);

            if (onParameterChange) {
              onParameterChange(newParameter);
            }
          }

          setMode('list');
        }}
      />
    );
  }

  return (
    <div>
      {
        parameters.map((item) => (
          <div className="mb-2" key={item._id}>
            <ParameterCard
              parameter={item}
              onDelete={() => {
                setDeleteParameter(item);
              }}
              onEdit={() => {
                setEditParameter(item);
                setMode('edit');
              }}
            />
          </div>
        ))
      }
      <div className="d-flex justify-content-end">
        <Button
          type="primary"
          onClick={() => setMode('create')}
        >
          Add Parameter
        </Button>
      </div>
      <Modal
        open={!!deleteParameter}
        onCancel={() => setDeleteParameter(null)}
        onOk={async () => {
          if (!deleteParameter) {
            return;
          }

          try {
            await api.parameters.remove(deleteParameter._id);
            setParameters((prev) => prev.filter((item) => item._id !== deleteParameter._id));
            notification.success({
              message: 'Parameter deleted',
            });
          } catch {
            notification.error({
              message: 'Failed to delete parameter',
            });
          } finally {
            setDeleteParameter(null);
          }
        }}
        okText="Delete"
        okButtonProps={{
          danger: true,
        }}
        title="Delete parameter"
      >
        This action cannot be undone. Are you sure you want to delete this parameter?
      </Modal>
    </div>
  );
}
