import {
  Button, Card, Col, Collapse, Divider, Form, Input, notification, Row, Select, Switch,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import capitalize from 'antd/es/_util/capitalize';
import { useState } from 'react';
import dayjs from 'dayjs';
import api, {
  DataSource, Entity, EntityFieldType, Formatter,
} from '../../api';
import useRequest from '../../common/hooks/useRequest';
import useActiveOrganization from '../../common/hooks/useActiveOrganization';
import useQuery from '../../common/hooks/useQuery';
import { ExpressionOperation } from '../../dbaql-v2/types';

interface EntityConstructorProps {
  defaultEntity?: Entity;
  entities?: Entity[];
}

export default function EntityFieldsSetup(props: EntityConstructorProps) {
  const { defaultEntity, entities = [] } = props;
  const organization = useActiveOrganization();
  const navigate = useNavigate();
  const [activeKeys, setActiveKeys] = useState<string | string[]>(
    defaultEntity?.fields[0] ? [defaultEntity?.fields[0].id] : [],
  );

  const [dataSource] = useQuery<DataSource>(
    async () => api.dataSources.get(defaultEntity?.dataSourceId || ''),
    [defaultEntity?.dataSourceId],
  );

  const request = useRequest<Entity>(
    async (entity: Entity) => {
      const { fields: initialFields } = entity;

      // This is a very specific error that shouldn't usually happen, but
      // sometimes it happens. Some of the fields are being sent as null,
      // resulting in breaking a lot of the functionality.
      if (initialFields && initialFields.some((field) => !field)) {
        throw new Error('Some fields are empty, please try again');
      }

      const fields = initialFields.map((field) => {
        if (field.format && !field.format.type) {
          return {
            ...field,
            format: undefined,
          };
        }

        return field;
      });

      const newEntity = {
        ...entity,
        fields,
      };

      if (!defaultEntity) {
        return api.entities.create({
          ...newEntity,
          organizationId: organization?._id,
          fields: [],
        }) as Promise<Entity>;
      }

      return api.entities.patch(defaultEntity._id, newEntity) as Promise<Entity>;
    },
    {
      onSuccess: (entity) => {
        if (!defaultEntity) {
          navigate(`../${entity._id}`);
        } else {
          notification.success({
            message: 'Entity updated',
          });
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Failed to create entity',
          description: error.message,
        });
      },
    },
  );

  const tableSchema = dataSource
    ?.schemaConfig
    ?.tables
    .find((table) => table.tableName === defaultEntity?.table);

  const columns: string[] = Object.keys(tableSchema?.schema?.properties || {}) as string[];
  const columnOptions = columns.map((column) => ({
    label: column,
    value: column,
  }));

  return (
    <Row>
      <Col span={24} xl={16} xxl={12}>
        <Form
          layout="vertical"
          initialValues={{
            fields: [],
            ...(defaultEntity || {}),
          }}
          onFinish={request.submit}
        >
          <Form.Item shouldUpdate>
            {({ getFieldValue, setFieldValue }) => {
              const fields = getFieldValue('fields') as Entity['fields'];

              const fieldOptions = fields.map((field) => ({
                label: field.displayName,
                value: field.name,
              }));

              const foreignEntities = entities.filter((item) => fields.some(
                (field) => field.definition?.type === EntityFieldType.FOREIGN_KEY
                  && field.definition?.entityName === item.name,
              ));

              return (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Divider orientation="left">
                        Field Mapping
                      </Divider>
                      <small>
                        Map fields to pre-defined entity properties in order to
                        use them in the application.
                        They will provide powerful features like
                        breaking your data by country, date, etc.
                      </small>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card
                            title="ID Field"
                            size="small"
                            style={{
                              borderRadius: 0,
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between gap-4">
                              <div>
                                <small>
                                  The unique identifier for each record in the entity.
                                </small>
                                <br />
                                <small>
                                  Used for identifying records and linking them to other entities.
                                </small>
                              </div>
                              <Form.Item name="idFieldName" noStyle>
                                <Select
                                  placeholder="Select a field"
                                  options={fieldOptions}
                                  style={{
                                    width: 200,
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card
                            title="Date Created Field"
                            size="small"
                            style={{
                              borderRadius: 0,
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between gap-4">
                              <div>
                                <small>
                                  Date Created Field
                                </small>
                                <br />
                                <small>
                                  The field that stores the date when the record was created.
                                </small>
                              </div>
                              <Form.Item name="createdAtFieldName" noStyle>
                                <Select
                                  placeholder="Select a field"
                                  options={fieldOptions}
                                  style={{
                                    width: 200,
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card
                            title="Title Field"
                            size="small"
                            style={{
                              borderRadius: 0,
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between gap-4">
                              <div>
                                <small>
                                  The field that stores the title of the record.
                                </small>
                                <br />
                                <small>
                                  Used for displaying the record in lists and details.
                                </small>
                              </div>
                              <Form.Item name="titleFieldName" noStyle>
                                <Select
                                  placeholder="Select a field"
                                  options={fieldOptions}
                                  style={{
                                    width: 200,
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card
                            title="Country Field"
                            size="small"
                            style={{
                              borderRadius: 0,
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between gap-4">
                              <div>
                                <small>
                                  The field that stores the country code of the record.
                                </small>
                                <br />
                                <small>
                                  Used for displaying the data on a map and filtering by country.
                                </small>
                              </div>
                              <Form.Item name="countryFieldName" noStyle>
                                <Select
                                  placeholder="Select a field"
                                  options={fieldOptions}
                                  style={{
                                    width: 200,
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Divider orientation="left">
                        Fields
                      </Divider>
                    </Col>
                    <Col span={24}>
                      {
                        !fields.length && (
                          <p className="text-center">
                            No fields added yet
                          </p>
                        )
                      }
                      <Collapse
                        style={{
                          borderRadius: 0,
                          background: 'white',
                        }}
                        activeKey={activeKeys}
                        onChange={(keys) => setActiveKeys(keys)}
                        defaultActiveKey={fields[0] ? [fields[0].id] : undefined}
                        items={fields.map((field, index) => ({
                          key: field.id,
                          label: field.displayName,
                          // IMPORTANT: do not remove this, it forces the
                          // children to render when the panel is collapsed.
                          // Removing it would cause the form to ignore the
                          // fields that are not visible, and thus lead to
                          // the data being lost when the form is submitted.
                          forceRender: true,
                          extra: (
                            <Button
                              size="small"
                              type="default"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setFieldValue(
                                  'fields',
                                  fields.filter((_, i) => i !== index),
                                );
                              }}
                              danger
                            />
                          ),
                          children: (
                            <>
                              <Form.Item
                                name={['fields', index, 'id']}
                                initialValue={field.id}
                                noStyle
                              >
                                <Input type="hidden" />
                              </Form.Item>
                              <Row gutter={[8, 8]}>
                                <Col span={24} md={8}>
                                  <Form.Item
                                    label="Type"
                                    name={['fields', index, 'type']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select a type',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select a type"
                                      options={[
                                        {
                                          label: 'Column',
                                          value: EntityFieldType.COLUMN,
                                        },
                                        {
                                          label: 'Computed',
                                          value: EntityFieldType.COMPUTED,
                                        },
                                        {
                                          label: 'Foreign Key',
                                          value: EntityFieldType.FOREIGN_KEY,
                                        },
                                        {
                                          label: 'Related Entity Field',
                                          value: EntityFieldType.RELATED_ENTITY_FIELD,
                                        },
                                      ]}
                                      onChange={(type) => {
                                        setFieldValue(
                                          ['fields', index, 'definition', 'type'],
                                          type,
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                {
                                  field.type && (
                                    <>
                                      {
                                        field.type === EntityFieldType.COLUMN && (
                                          <Col span={24} md={8}>
                                            <Form.Item
                                              label="Column"
                                              name={['fields', index, 'definition', 'column']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please select a column',
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select a column"
                                                options={columnOptions}
                                                onChange={(column) => {
                                                  setFieldValue(
                                                    ['fields', index, 'name'],
                                                    column,
                                                  );
                                                  setFieldValue(
                                                    ['fields', index, 'displayName'],
                                                    capitalize(column),
                                                  );
                                                }}
                                                showSearch
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              name={['fields', index, 'definition', 'type']}
                                              initialValue={EntityFieldType.COLUMN}
                                              noStyle
                                            >
                                              <Input type="hidden" />
                                            </Form.Item>
                                          </Col>
                                        )
                                      }
                                      {
                                        field.type === EntityFieldType.FOREIGN_KEY && (
                                          <>
                                            <Col span={24} md={8}>
                                              <Form.Item
                                                label="Entity"
                                                name={['fields', index, 'definition', 'entityName']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please select an entity',
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  placeholder="Select entity"
                                                  options={entities.map((item) => ({
                                                    label: item.displayName,
                                                    value: item.name,
                                                  }))}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                name={['fields', index, 'definition', 'type']}
                                                initialValue={EntityFieldType.FOREIGN_KEY}
                                                noStyle
                                              >
                                                <Input type="hidden" />
                                              </Form.Item>
                                            </Col>
                                            <Form.Item noStyle shouldUpdate>
                                              {() => {
                                                // @ts-ignore
                                                const selectedEntity = field.definition.entityName;
                                                const entity = entities.find(
                                                  (item) => item.name === selectedEntity,
                                                );

                                                if (!entity) {
                                                  return null;
                                                }

                                                const entityColumnOptions = entity
                                                  .fields
                                                  .map((item) => ({
                                                    label: item.displayName,
                                                    value: item.name,
                                                  }));

                                                return (
                                                  <>
                                                    <Col span={24} md={8}>
                                                      <Form.Item
                                                        label="Entity Field"
                                                        name={['fields', index, 'definition', 'entityField']}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'Please select an entity field',
                                                          },
                                                        ]}
                                                      >
                                                        <Select
                                                          placeholder="Select entity field"
                                                          options={entityColumnOptions}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={24} md={8}>
                                                      <Form.Item
                                                        label="Local Field"
                                                        name={['fields', index, 'definition', 'localColumn']}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: 'Please select the local field',
                                                          },
                                                        ]}
                                                      >
                                                        <Select
                                                          placeholder="Select entity field"
                                                          options={columnOptions}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  </>
                                                );
                                              }}
                                            </Form.Item>
                                          </>
                                        )
                                      }
                                      {
                                        field.type === EntityFieldType.RELATED_ENTITY_FIELD && (
                                          <>
                                            <Col span={24} md={8}>
                                              <Form.Item
                                                label="Entity"
                                                name={['fields', index, 'definition', 'entityName']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please select an entity',
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  placeholder="Select entity"
                                                  options={foreignEntities.map((item) => ({
                                                    label: item.displayName,
                                                    value: item.name,
                                                  }))}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                name={['fields', index, 'definition', 'type']}
                                                initialValue={EntityFieldType.RELATED_ENTITY_FIELD}
                                                noStyle
                                              >
                                                <Input type="hidden" />
                                              </Form.Item>
                                            </Col>
                                            <Form.Item noStyle shouldUpdate>
                                              {() => {
                                                // @ts-ignore
                                                const selectedEntity = field.definition.entityName;
                                                const entity = entities.find(
                                                  (item) => item.name === selectedEntity,
                                                );

                                                if (!entity) {
                                                  return null;
                                                }

                                                const entityColumnOptions = entity
                                                  .fields
                                                  .map((item) => ({
                                                    label: item.displayName,
                                                    value: item.name,
                                                  }));

                                                return (
                                                  <Col span={24} md={8}>
                                                    <Form.Item
                                                      label="Entity Field"
                                                      name={['fields', index, 'definition', 'entityField']}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'Please select an entity field',
                                                        },
                                                      ]}
                                                    >
                                                      <Select
                                                        placeholder="Select entity field"
                                                        options={entityColumnOptions}
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                );
                                              }}
                                            </Form.Item>
                                          </>
                                        )
                                      }
                                      {
                                        field.type === EntityFieldType.COMPUTED && (
                                          <>
                                            <Col span={24} md={8}>
                                              <Form.Item
                                                label="Operation"
                                                name={['fields', index, 'definition', 'expression']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please select the operation',
                                                  },
                                                ]}
                                                initialValue={ExpressionOperation.SUM}
                                              >
                                                <Select
                                                  options={[
                                                    {
                                                      label: 'Sum',
                                                      value: ExpressionOperation.SUM,
                                                    },
                                                    {
                                                      label: 'Multiply',
                                                      value: ExpressionOperation.MULTIPLY,
                                                    },
                                                    {
                                                      label: 'Subtract',
                                                      value: ExpressionOperation.SUBTRACT,
                                                    },
                                                  ]}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                name={['fields', index, 'definition', 'type']}
                                                initialValue={EntityFieldType.COMPUTED}
                                                noStyle
                                              >
                                                <Input type="hidden" />
                                              </Form.Item>
                                            </Col>
                                            <Col span={24} md={8}>
                                              <Form.Item
                                                label="Fields"
                                                name={['fields', index, 'definition', 'arguments']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please select the fields to perform the operation on',
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  mode="multiple"
                                                  options={columnOptions}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )
                                      }
                                      <Col span={24} md={8}>
                                        <Form.Item
                                          label="Field Name"
                                          name={['fields', index, 'name']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please enter the field name',
                                            },
                                            {
                                              // starts with letter,
                                              // then only letters and underscore
                                              pattern: /^[a-zA-Z_]*$/,
                                              message: 'Field name must start with a letter and only contain letters and underscores',
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Enter field name" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={24} md={8}>
                                        <Form.Item
                                          label="Display Name"
                                          name={['fields', index, 'displayName']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please enter the display name',
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Enter a descriptive name" />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )
                                }
                              </Row>
                              {
                                field.type && (
                                  <>
                                    <Row gutter={[16, 16]}>
                                      <Col span={24}>
                                        <Divider>Options</Divider>
                                      </Col>
                                      <Col span={24}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <div>
                                              Show in table view
                                            </div>
                                            <small>
                                              Select whether this field should be displayed
                                              in the table view when viewing the records
                                            </small>
                                          </div>
                                          <Form.Item
                                            name={['fields', index, 'showInTableView']}
                                            noStyle
                                          >
                                            <Switch />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col span={24}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <div>
                                              Use in Segmentation
                                            </div>
                                            <small>
                                              Select whether this field can be used to
                                              segment (group) the data for the analysis
                                            </small>
                                          </div>
                                          <Form.Item
                                            name={['fields', index, 'useInSegmentation']}
                                            noStyle
                                          >
                                            <Switch />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col span={24}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <div>
                                              Use in Metrics
                                            </div>
                                            <small>
                                              Select whether this field can be used to
                                              in the metrics calculations, like sum, average, etc.
                                            </small>
                                          </div>
                                          <Form.Item
                                            name={['fields', index, 'useInMetrics']}
                                            noStyle
                                          >
                                            <Switch />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      <Col span={24}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <div>
                                              Use in Filters
                                            </div>
                                            <small>
                                              Select whether this field can be used to
                                              filter the data in the analysis
                                            </small>
                                          </div>
                                          <Form.Item
                                            name={['fields', index, 'useInFilters']}
                                            noStyle
                                          >
                                            <Switch />
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                      <Col span={24}>
                                        <Divider>Format</Divider>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          label="Format"
                                          name={['fields', index, 'format', 'type']}
                                        >
                                          <Select
                                            placeholder="Select a format"
                                            options={Formatter.formatOptions}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {
                                        field.format?.type === Formatter.FormatType.CURRENCY && (
                                          <Col span={8}>
                                            <Form.Item
                                              label="Currency"
                                              name={['fields', index, 'format', 'currency']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please select a currency',
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select a currency"
                                                options={Formatter.currencyList.map((currency) => ({
                                                  label: `${currency.name} (${currency.code})`,
                                                  value: currency.code,
                                                }))}
                                                optionFilterProp="label"
                                                showSearch
                                              />
                                            </Form.Item>
                                          </Col>
                                        )
                                      }
                                      {
                                        field.format?.type === Formatter.FormatType.DATE && (
                                          <Col span={8}>
                                            <Form.Item
                                              label="Date Format"
                                              name={['fields', index, 'format', 'format']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please select a date format',
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select a date format"
                                                options={Formatter.dateFormats.map((format) => ({
                                                  label: `${dayjs().format(format)} (${format})`,
                                                  value: format,
                                                }))}
                                              />
                                            </Form.Item>
                                          </Col>
                                        )
                                      }
                                    </Row>
                                  </>
                                )
                              }
                            </>
                          ),
                        }))}
                      />
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ setFieldValue, getFieldValue }) => {
              const fields = getFieldValue('fields') as Entity['fields'];

              return (
                <div className="d-flex justify-content-between">
                  <Button type="primary" htmlType="submit" loading={request.loading}>
                    {
                      defaultEntity ? 'Save Changes' : 'Create Entity'
                    }
                  </Button>
                  <Button
                    onClick={() => {
                      const newField = {
                        id: Math.random().toString(36).substring(7),
                        name: 'new_field',
                      };
                      setFieldValue('fields', [
                        ...fields,
                        newField,
                      ]);
                      setActiveKeys((prev) => (typeof prev === 'string' ? prev : [
                        ...prev,
                        newField.id,
                      ]));
                    }}
                  >
                    + Add Field
                  </Button>
                </div>
              );
            }}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
