import { BarChartOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class BarChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = BarChartOutlined;

  title = 'Bar Chart';

  type = ResultRepresentationType.GRAPH;
}

export default BarChartResultRepresentationSubTypeConfig;
