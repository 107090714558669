import { HeatMapOutlined } from '@ant-design/icons';
import ResultRepresentationSubTypeConfig from './ResultRepresentationSubTypeConfig';
import { ResultRepresentationType } from '../../../api';

class HeatmapChartResultRepresentationSubTypeConfig extends ResultRepresentationSubTypeConfig {
  Icon = HeatMapOutlined;

  title = 'Heatmap Chart';

  type = ResultRepresentationType.GRAPH;
}

export default HeatmapChartResultRepresentationSubTypeConfig;
