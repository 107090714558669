import { useState } from 'react';
import { Dayjs } from 'dayjs';
import { Button, message } from 'antd';
import CustomDateSelection from '../../../common/types/CustomDateSelection';
import { EntityField, InsightFlowFilter } from '../../../api';
import SelectWithContent from '../../../common/components/SelectWithContent';
import DatePicker from '../../../common/components/DatePicker';
import RangePicker from '../../../common/components/RangePicker';

enum DateFilterType {
  BEFORE = 'before',
  AFTER = 'after',
  BETWEEN = 'between',
}

interface DateColumnFilterProps {
  field: EntityField;
  onAddFilter: (filter: InsightFlowFilter) => void;
  columnName: string;
}

export default function DateColumnFilter(props: DateColumnFilterProps) {
  const { onAddFilter, field, columnName } = props;
  const [date, setDate] = useState<Dayjs>();
  const [dateSelection, setDateSelection] = useState<CustomDateSelection>();
  const [mode, setMode] = useState<DateFilterType>();
  const { displayName } = field;

  const onAddClick = () => {
    if (mode === DateFilterType.BEFORE || mode === DateFilterType.AFTER) {
      if (!date) {
        message.error('Please select a date');
      } else if (mode === DateFilterType.BEFORE) {
        onAddFilter({
          label: `${displayName}: Before ${date.format('MMM DD YYYY')}`,
          filter: {
            $lt: date.toISOString(),
          },
          filterFieldName: columnName,
          field,
        });
      } else {
        onAddFilter({
          label: `${displayName}: After ${date.format('MMM DD YYYY')}`,
          filter: {
            $gt: date.toISOString(),
          },
          filterFieldName: columnName,
          field,
        });
      }
    } else if (mode === DateFilterType.BETWEEN) {
      const { from, to } = dateSelection || {};

      if (!from) {
        message.error('Please select the start date');
      } else {
        let label = `${displayName} after ${from.format('MMM DD YYYY')}`;

        if (dateSelection?.longLabel) {
          label = `${displayName}: ${dateSelection.longLabel}`;
        } else if (to) {
          label = `${displayName}: Between ${from.format('MMM DD YYYY')} and ${to.format('MMM DD YYYY')}`;
        }

        onAddFilter({
          label,
          filter: {
            $gt: from.toISOString(),
            $lt: to ? to.toISOString() : undefined,
          },
          filterFieldName: columnName,
          field,
          computedFilter: dateSelection,
        });
      }
    }
  };

  return (
    <SelectWithContent
      onChange={setMode}
      items={[
        {
          value: DateFilterType.BEFORE,
          label: 'Before',
          content: (
            <DatePicker
              value={date}
              onChange={setDate}
              standalone
            />
          ),
        },
        {
          value: DateFilterType.AFTER,
          label: 'After',
          content: (
            <DatePicker
              value={date}
              onChange={setDate}
              standalone
            />
          ),
        },
        {
          value: DateFilterType.BETWEEN,
          label: 'Between',
          content: (
            <RangePicker
              value={dateSelection}
              onChange={setDateSelection}
            />
          ),
        },
      ]}
      contentFooter={(
        <div className="d-flex align-items-center justify-content-end">
          <Button
            type="primary"
            size="small"
            onClick={onAddClick}
          >
            Apply
          </Button>
        </div>
      )}
    />
  );
}
