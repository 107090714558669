import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Paginated } from '@feathersjs/feathers';
import api, {
  DataSource, DataSourceScope, DataSourceType, Organization,
} from '../../api';
import QueryCard from '../../queries/components/QueryCard';
import Spin from '../../common/components/Spin';

export default function AirtableExtensionPage() {
  const [searchParams] = useSearchParams();
  const secretKey = searchParams.get('sk');
  const baseId = searchParams.get('bid');
  const [organization, setOrganization] = useState<Organization>();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<DataSource>();
  const [error, setError] = useState<string>();
  const [creatingDataSource, setCreatingDataSource] = useState<boolean>(false);

  useEffect(() => {
    if (baseId && organization?._id) {
      api.dataSources.find({
        query: {
          type: DataSourceType.AIRTABLE,
          organizationId: organization._id,
          'config.baseId': baseId,
        },
      }).then((response: Paginated<DataSource>) => {
        const [source] = response.data;
        if (source) {
          setDataSource(source);
        } else {
          setCreatingDataSource(true);
          api.dataSources.create({
            type: DataSourceType.AIRTABLE,
            config: { baseId },
            scope: DataSourceScope.ORGANIZATION,
            entityId: organization._id,
            organizationId: organization._id,
            name: `Airtable Base ${baseId}`,
          }).then((newDataSource: DataSource) => {
            setDataSource(newDataSource);
            setCreatingDataSource(false);
          }).catch(() => {
            setError('Error syncing with InsightBase, please try again later');
          });
        }
      });
    }
  }, [baseId, organization?._id]);

  useEffect(() => {
    if (secretKey) {
      api.setApiKey(secretKey);
      api.organizations.find().then((response) => {
        const [org] = response.data;

        if (org) {
          setOrganization(org);
        }
        setLoading(false);
      }).catch(() => {
        setError('Invalid Secret Key');
        setLoading(false);
      });
    }
  }, [secretKey]);

  if (loading) {
    return (
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 10,
      }}
      >
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <p className="text-center">{error}</p>
    );
  }

  if (!baseId) {
    return (
      <p>Invalid base ID</p>
    );
  }

  if (!secretKey) {
    return (
      <p>Invalid secret key</p>
    );
  }

  if (creatingDataSource) {
    return (
      <p className="text-center">
        Syncing with InsightBase, please wait (this is a one-time process for each Airtable base)...
      </p>
    );
  }

  if (!organization) {
    return (
      <p className="text-center">
        Invalid Secret Key
      </p>
    );
  }

  return (
    <QueryCard
      hasDataSource
      organizationId={organization._id}
      dataSource={dataSource || undefined}
    />
  );
}
