import React from 'react';
import BuilderStepCard from '../BuilderStepCard';
import { DataSourceTableConfig } from '../../../api';
import { DbaqlBuilderStateUpdater } from '../../hooks/useDbaqlBuilderState';
import { DbaqlBuilderState } from '../../state';
import DataSourceTableFieldSelect from '../DataSourceTableFieldSelect';

interface DataSourceTableGroupBuilderProps extends Pick<DbaqlBuilderState, 'enableGroup'> {
  tableConfig: DataSourceTableConfig;
  selectedFields: DbaqlBuilderState['groupFields'];
  onSelectedFieldsChange: DbaqlBuilderStateUpdater<'groupFields'>;
  setEnableGroup: DbaqlBuilderStateUpdater<'enableGroup'>;
}

export default function DataSourceTableGroupBuilder(props: DataSourceTableGroupBuilderProps) {
  const {
    tableConfig,
    selectedFields,
    onSelectedFieldsChange,
    enableGroup,
    setEnableGroup,
  } = props;

  return (
    <BuilderStepCard
      className="mt-2"
      question="Do you want to group the data?"
      description="Group the data by a field. For instance, group users by their country to get results per country."
      isVisible={enableGroup}
      setIsVisible={setEnableGroup}
    >
      <div className="d-flex align-items-center">
        <p className="me-2">group by</p>
        <DataSourceTableFieldSelect
          value={selectedFields}
          onChange={onSelectedFieldsChange}
          tableConfig={tableConfig}
          style={{ flex: 1 }}
        />
      </div>
    </BuilderStepCard>
  );
}
