import { DatePicker, Input, Switch } from 'antd';
import dayjs from 'dayjs';
import { Parameter, ParameterType } from '../../api';

interface ParameterValueInputProps {
  parameter: Partial<Parameter>;
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}

export default function ParameterValueInput(props: ParameterValueInputProps) {
  const {
    parameter, value, onChange, disabled,
  } = props;

  switch (parameter.type) {
    case ParameterType.NUMBER:
      return (
        <Input
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
      );
    case ParameterType.BOOLEAN:
      return (
        <Switch
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case ParameterType.DATE:
      return (
        <DatePicker
          value={value ? dayjs(value) : value}
          onChange={onChange}
          disabled={disabled}
          showTime
        />
      );
    default:
      return (
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
      );
  }
}
