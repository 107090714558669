import { Field, ValueEditorType } from 'react-querybuilder';

export enum DataSchemaFieldType {
  NULL = 'null',
  UNDEFINED = 'undefined',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ARRAY = 'array',
  OBJECT = 'object',
  UNKNOWN = 'unknown',
}

interface DataSchemaField {
  type: DataSchemaFieldType;
  name: string;
  items?: DataSchemaField | DataSchemaField[];
  properties?: { [key: string]: DataSchemaField };
}

type EnhancedField = Field & {
  valueEditorTypeMap: Record<string, ValueEditorType>;
};

type DataSchemaFieldConfig = {
  type: DataSchemaFieldType;
  filterable?: boolean;
  filterField?: Partial<EnhancedField>;
};

const OPERATORS = {
  eq: { name: '=', label: '=' },
  ne: { name: '!=', label: '!=' },
  lt: { name: '<', label: '<' },
  gt: { name: '>', label: '>' },
  lte: { name: '<=', label: '<=' },
  gte: { name: '>=', label: '>=' },
  contains: { name: 'contains', label: 'contains' },
  begins: { name: 'beginsWith', label: 'begins with' },
  ends: { name: 'endsWith', label: 'ends with' },
  ncontains: { name: 'doesNotContain', label: 'does not contain' },
  nbegins: { name: 'doesNotBeginWith', label: 'does not begin with' },
  nends: { name: 'doesNotEndWith', label: 'does not end with' },
  null: { name: 'null', label: 'is null' },
  nnull: { name: 'notNull', label: 'is not null' },
  in: { name: 'in', label: 'is one of' },
  nin: { name: 'notIn', label: 'is not one of' },
  between: { name: 'between', label: 'between' },
  nbetween: { name: 'notBetween', label: 'not between' },
};

const FIELD_CONFIG_MAP: Record<DataSchemaFieldType, DataSchemaFieldConfig> = {
  [DataSchemaFieldType.NULL]: {
    type: DataSchemaFieldType.NULL,
    filterable: false,
  },
  [DataSchemaFieldType.UNDEFINED]: {
    type: DataSchemaFieldType.UNDEFINED,
    filterable: false,
  },
  [DataSchemaFieldType.STRING]: {
    type: DataSchemaFieldType.STRING,
    filterable: true,
    filterField: {
      operators: [
        OPERATORS.in,
        OPERATORS.eq,
        OPERATORS.ne,
        OPERATORS.contains,
        OPERATORS.begins,
        OPERATORS.ends,
        OPERATORS.ncontains,
        OPERATORS.nbegins,
        OPERATORS.nends,
        OPERATORS.null,
        OPERATORS.nnull,
        OPERATORS.nin,
      ],
      valueEditorTypeMap: {
        '=': 'select',
        '!=': 'select',
        in: 'multiselect',
        notIn: 'multiselect',
      },
    },
  },
  [DataSchemaFieldType.NUMBER]: {
    type: DataSchemaFieldType.NUMBER,
    filterable: true,
    filterField: {
      defaultValue: null,
      operators: [
        OPERATORS.eq,
        OPERATORS.ne,
        OPERATORS.lt,
        OPERATORS.gt,
        OPERATORS.lte,
        OPERATORS.gte,
        OPERATORS.null,
        OPERATORS.nnull,
        OPERATORS.in,
        OPERATORS.nin,
        OPERATORS.between,
        OPERATORS.nbetween,
      ],
    },
  },
  [DataSchemaFieldType.BOOLEAN]: {
    type: DataSchemaFieldType.BOOLEAN,
    filterable: true,
    filterField: {
      operators: [
        OPERATORS.eq,
        OPERATORS.ne,
        OPERATORS.null,
        OPERATORS.nnull,
      ],
    },
  },
  [DataSchemaFieldType.DATE]: {
    type: DataSchemaFieldType.DATE,
    filterable: true,
    filterField: {
      operators: [
        OPERATORS.lte,
        OPERATORS.gte,
        OPERATORS.null,
        OPERATORS.nnull,
        OPERATORS.between,
        OPERATORS.nbetween,
      ],
      inputType: 'datetime-local',
    },
  },
  [DataSchemaFieldType.ARRAY]: {
    type: DataSchemaFieldType.ARRAY,
    filterable: false,
  },
  [DataSchemaFieldType.OBJECT]: {
    type: DataSchemaFieldType.OBJECT,
    filterable: false,
  },
  [DataSchemaFieldType.UNKNOWN]: {
    type: DataSchemaFieldType.UNKNOWN,
    filterable: false,
  },
};

export default class DataSchema {
  constructor(private data: any) {}

  static getFieldType(value: any): DataSchemaFieldType {
    if (value === null) {
      return DataSchemaFieldType.NULL;
    }

    if (value === undefined) {
      return DataSchemaFieldType.UNDEFINED;
    }

    if ([true, false, 'true', 'false'].includes(value)) {
      return DataSchemaFieldType.BOOLEAN;
    }

    if (!Number.isNaN(Number(value)) && !Number.isNaN(parseFloat(value))) {
      return DataSchemaFieldType.NUMBER;
    }

    if (value instanceof Date || (new Date(value)).toString() !== 'Invalid Date') {
      return DataSchemaFieldType.DATE;
    }

    if (typeof value === 'string') {
      return DataSchemaFieldType.STRING;
    }

    if (Array.isArray(value)) {
      return DataSchemaFieldType.ARRAY;
    }

    if (typeof value === 'object') {
      return DataSchemaFieldType.OBJECT;
    }

    return DataSchemaFieldType.UNKNOWN;
  }

  static getField(value: any, name: string): DataSchemaField {
    const type = DataSchema.getFieldType(value);
    let items: DataSchemaField['items'] | undefined;
    let properties: DataSchemaField['properties'] | undefined;

    if (type === DataSchemaFieldType.ARRAY) {
      const isArrayOfObjects = value.every((item: any) => typeof item === 'object');

      if (isArrayOfObjects) {
        const fieldNames = new Set<string>(
          ...value.map((item: any) => Object.keys(item)),
        );

        const propertyMap: Record<string, DataSchemaField> = {};

        fieldNames.forEach((fieldName) => {
          const fieldValues = value.map((item: any) => item[fieldName]);
          // @ts-ignore
          const types = [...new Set<DataSchemaFieldType>(
            fieldValues.map(DataSchema.getFieldType),
          )];
          propertyMap[fieldName] = {
            name: fieldName,
            type: types.length === 1 ? Array.from(types)[0] : DataSchemaFieldType.UNKNOWN,
          };
        });

        items = Object.values(propertyMap);

        if (items.length === 1) {
          [items] = items;
        }
      } else {
        const types = new Set<DataSchemaFieldType>(
          ...value.map(DataSchema.getFieldType),
        );

        items = {
          name,
          type: types.size === 1 ? Array.from(types)[0] : DataSchemaFieldType.UNKNOWN,
        };
      }
    }

    if (type === DataSchemaFieldType.OBJECT) {
      const propertyMap: Record<string, DataSchemaField> = {};
      Object.entries(value).forEach(([key, item]) => {
        propertyMap[key] = DataSchema.getField(item, key);
      });

      properties = propertyMap;
    }

    return {
      name,
      type,
      items,
      properties,
    };
  }

  extract(): DataSchemaField {
    return DataSchema.getField(this.data, 'root');
  }

  extractItem(fieldName: string): DataSchemaField | undefined {
    const schema = this.extract();

    if (!schema.items) {
      return undefined;
    }

    const { items } = schema;

    if (!items) {
      return undefined;
    }

    if (Array.isArray(items)) {
      return items.find((item) => item.name === fieldName);
    }

    if (items.name === fieldName) {
      return items;
    }

    return undefined;
  }

  static getFieldConfig(fieldType: DataSchemaFieldType): DataSchemaFieldConfig {
    return FIELD_CONFIG_MAP[fieldType];
  }
}
