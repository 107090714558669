class ReactEvent<T> {
  private handlers: ((data: T) => void)[] = [];

  public subscribe(handler: (data: T) => void) {
    this.handlers.push(handler);
  }

  public unsubscribe(handler: (data: T) => void) {
    this.handlers = this.handlers.filter((h) => h !== handler);
  }

  public emit(data: T) {
    this.handlers.forEach((h) => h(data));
  }
}

export default ReactEvent;
