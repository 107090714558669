import { useCallback } from 'react';
import useOrganizationUser from './useOrganizationUser';
import { OrganizationUserRole } from '../../api';

interface UseRole {
  is: (...roles: OrganizationUserRole[]) => boolean;
  role?: OrganizationUserRole;
}

export default function useRole(): UseRole {
  const organizationUser = useOrganizationUser();

  const is = useCallback((...roles: OrganizationUserRole[]) => {
    if (!organizationUser) {
      return false;
    }

    return roles.includes(organizationUser.role);
  }, [organizationUser?._id]);

  return { is, role: organizationUser?.role };
}
