type JsonDateModifier = {
  operator: 'add' | 'subtract';
  value: number;
  unit: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
};

export type JsonDate = {
  type: 'date',
  date: string | 'now';
  modifier: JsonDateModifier;
};

export type PrimitiveFilter = null | number | boolean | Date | string;

export type BaseFilter = PrimitiveFilter | JsonDate;

export type Limit = number;
export type Skip = number;

export enum Operator {
  EQ = '$eq',
  GT = '$gt',
  GTE = '$gte',
  LT = '$lt',
  LTE = '$lte',
  NE = '$ne',
  IN = '$in',
  NIN = '$nin',
  LIKE = '$like',
  ILIKE = '$ilike',
}

export type MultiFilter = Partial<{
  [Operator.GT]: BaseFilter;
  [Operator.GTE]: BaseFilter;
  [Operator.LT]: BaseFilter;
  [Operator.LTE]: BaseFilter;
  [Operator.NE]: BaseFilter;
  [Operator.IN]: BaseFilter[];
  [Operator.NIN]: BaseFilter[];
  [Operator.LIKE]: BaseFilter;
  [Operator.ILIKE]: BaseFilter;
}>;

export type FieldFilter = BaseFilter | MultiFilter;

export type Filter = Record<string, FieldFilter>;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum StageType {
  SELECT = 'SELECT',
  GROUP = 'GROUP',
  LIMIT = 'LIMIT',
  SORT = 'SORT',
  FILTER = 'FILTER',
  JOIN = 'JOIN',
}

export enum FieldType {
  ALIAS = 'ALIAS',
  EXPRESSION = 'EXPRESSION',
  DATE_TRUNC = 'DATE_TRUNC',
}

type StringField = string;
type BaseField = StringField;
export enum ExpressionOperation {
  COUNT = 'count',
  AVERAGE = 'average',
  SUM = 'sum',
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
  SUBTRACT = 'subtract',
}
export type ExpressionField = {
  type: FieldType.EXPRESSION;
  operation: ExpressionOperation;
  fields: BaseField[];
  as: string;
};
export type AliasField = {
  type: FieldType.ALIAS;
  field: string;
  as: string;
};
export type Field = StringField | ExpressionField | AliasField | DateTrunc;
export type DateTrunc = {
  type: FieldType.DATE_TRUNC;
  as: string;
  column: string;
  truncate: 'day' | 'month' | 'year';
};

export type SelectStage = {
  command: StageType.SELECT;
  fields: Field[];
};

export type GroupStage = {
  command: StageType.GROUP;
  by: Field[];
};

export type JoinStage = {
  command: StageType.JOIN;
  table: string;
  column: string;
  on: string;
  select: Field[];
};

export type LimitStage = {
  command: StageType.LIMIT;
  limit: Limit;
};

export type FilterStage = {
  command: StageType.FILTER;
  filters: Filter;
};

export type SortStage = {
  command: StageType.SORT;
  sort: Record<string, SortDirection>;
};

type Stage = SelectStage | GroupStage | JoinStage | LimitStage | FilterStage | SortStage;

export type Query = {
  table: string;
  pipeline: Stage[];
};
