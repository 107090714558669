import dayjs from 'dayjs';
import {
  FormatRule, Formatter, FormatType, ResultRepresentationFormat,
} from '../../api';

export function getFormatRule(format?: ResultRepresentationFormat, field?: FormatRule['field']): FormatRule | undefined {
  if (!format) {
    return undefined;
  }

  return format.rules.reverse().find((rule) => rule.field === field);
}

// Check if the value is a primitive (string, number, boolean, null, undefined)
function isPrimitive(value: any) {
  if (value instanceof Date) {
    return true;
  }

  if (typeof value === 'string') {
    return true;
  }

  if (typeof value === 'number') {
    return true;
  }

  if (typeof value === 'boolean') {
    return true;
  }

  if (value === null) {
    return true;
  }

  return value === undefined;
}

export function formatValueWithRule(value: any, rule?: FormatRule) {
  if (!isPrimitive(value) || !rule) {
    return value;
  }

  switch (rule.format.type) {
    case FormatType.CURRENCY: {
      const currency = Formatter.currencies[rule.format.options.currency];

      if (currency) {
        return Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency.code,
        }).format(value);
      }

      return value;
    }
    case FormatType.DATE:
      return dayjs(value).format(rule.format.options.dateFormat);
    default:
      return value;
  }
}

export function formatValue(format?: ResultRepresentationFormat, value?: any, field?: FormatRule['field']) {
  if (!format || !value) {
    return value;
  }

  const rule = getFormatRule(format, field);
  return formatValueWithRule(value, rule);
}
